import { Component, OnInit, Input } from '@angular/core';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { MissingModelRights } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';

@Component({
    templateUrl: './mnb-dashboard-widget-forbidden.component.html',
    selector: '[mnbDashboardWidgetForbidden]'
})
export class MnbDashboardWidgetForbiddenComponent implements OnInit {

    @Input('mnbDashboardWidgetForbidden') missingModelRights: MissingModelRights;

    public forbiddenFields = new Array<string>();

    constructor(
        private modelService: MnbModelService
    ) { }

    ngOnInit() {
        if (this.missingModelRights.attributeCodes) {
            this.missingModelRights.attributeCodes.forEach(code => this.modelService.getAttribute(code).then(attribute => this.forbiddenFields.push(attribute.name)));
        }
        if (this.missingModelRights.measureCodes) {
            this.missingModelRights.measureCodes.forEach(code => this.modelService.getMeasure(code).then(measure => this.forbiddenFields.push(measure.name)));
        }
    }

}