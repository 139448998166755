import { Component, Input, ViewChild, ElementRef, Renderer2, AfterViewChecked, OnInit } from '@angular/core';
import { DashboardGridWidget } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { isNullOrUndefined } from 'util';
import { DashboardWidgetData, DashboardWidgetDetail, DashboardWidgetQuerySettings } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { MnbChartTooltipData } from '@shared-lib/modules/charts/components/chart-tooltip-content.component';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbUnitInfo } from '@shared-lib/modules/core/pipes/unit.pipe';

@Component({
  selector: 'mnb-dashboard-list-widget',
  templateUrl: './mnb-dashboard-list-widget.component.html'
})
export class MnbDashboardListWidgetComponent implements AfterViewChecked, OnInit {

    @Input() public gridWidget: DashboardGridWidget;

    public model: MnbDashboardListWidgetModel;

    @ViewChild('tableElement', { static: false }) private tableElement: ElementRef;
    @ViewChild('bodyElement', { static: false }) private bodyElement: ElementRef;

    private hasComparisonFilter: boolean;
    private hasPlan: boolean;

    private lastCheckedHeight = 0;

    constructor(private renderer: Renderer2) {
    }

    ngOnInit() {
        const dashboardCurrencyCode = QueryFilter.getCurrencyCode(this.gridWidget.dashboard.settings.filters);
        const widgetCurrencyCode = QueryFilter.getCurrencyCode(this.gridWidget.widget.querySettings.filters) || dashboardCurrencyCode;

        const dashboardSettings = this.gridWidget.dashboard.settings;

        const filters = this.gridWidget.widget.querySettings.filters ? this.gridWidget.widget.querySettings.filters : (dashboardSettings ? dashboardSettings.filters : null);
        const timeFilter = this.gridWidget.widget.querySettings.timeFilter ? this.gridWidget.widget.querySettings.timeFilter : (dashboardSettings ? dashboardSettings.timeFilter : null);
        const comparisonFilter = this.gridWidget.widget.querySettings.timeFilter ? this.gridWidget.widget.querySettings.comparisonFilter : (dashboardSettings ? dashboardSettings.comparisonFilter : null);
        this.hasComparisonFilter = !isNullOrUndefined(comparisonFilter);

        this.hasPlan = (
            (this.gridWidget.widget.querySettings.plan && this.gridWidget.widget.querySettings.plan.name)
              || (this.gridWidget.dashboard.settings.plan && this.gridWidget.dashboard.settings.plan.name && !this.gridWidget.widget.querySettings.timeFilter)) && !!this.gridWidget.model.measure.planMeasure;

        const model = new MnbDashboardListWidgetModel();

        const attribute = this.gridWidget.model.attribute;
        const measure = this.gridWidget.model.measure;
        const isOriginalCurrency = measure.modifiers.isOriginalCurrency;

        model.rows = (this.gridWidget.data.breakdown[attribute.code] || []).map(breakdown => {
            const row = new MnbDashboardListWidgetRowModel();
            row.attribute = breakdown.attributes[attribute.code];
            row.value = breakdown.values[measure.code];
            row.data = breakdown;
            row.unit = new MnbUnitInfo(measure.unit.code, isOriginalCurrency, isOriginalCurrency ? DashboardWidgetDetail.getCurrencyCode(breakdown) || widgetCurrencyCode : null);
            return row;
        });
        model.mergedSettings = {
            comparisonFilter: comparisonFilter,
            filters: filters,
            timeFilter: timeFilter,
            measureCode: measure.code,
        };

        this.model = model;
    }

    ngAfterViewChecked(): void {
        const tableEl = this.tableElement.nativeElement;
        const widgetContentHeight = tableEl.parentNode.parentNode.offsetHeight;

        if (this.lastCheckedHeight != widgetContentHeight) {

            let remainingHeight = widgetContentHeight;
            // TODO: This is the top margin of the table (would be better to retrieve it instead of have it fixed here)
            // additional -20 for widget meta information
            remainingHeight -= 8 + 20;
            const rowEls = this.bodyElement.nativeElement.children;

            for (let i = 0; i < rowEls.length; i++) {
                const rowEl = rowEls[i];
                    remainingHeight = remainingHeight - rowEl.offsetHeight;

                if (remainingHeight < 0) {
                    this.renderer.addClass(rowEl, 'hidden-row');
                } else {
                    this.renderer.removeClass(rowEl, 'hidden-row');
                }
            }

            this.lastCheckedHeight = widgetContentHeight;
        }
    }


    public initRowTooltipData(row: MnbDashboardListWidgetRowModel) {
        if (row.tooltipData) {
            return;
        }
        const tooltipData = new MnbChartTooltipData();
        tooltipData.values = {
            unitCode: this.gridWidget.model.measure.unit.code,
            unit: row.unit
        };

        tooltipData.withComparison = this.hasComparisonFilter;
        tooltipData.plan = this.hasPlan ? this.gridWidget.dashboard.settings.plan.name : null;

        tooltipData.headerData = {
            label: row.attribute
        };
        tooltipData.data = {
            label: this.gridWidget.model.measure.name,
            value: row.value,
            comparisonValue: this.hasComparisonFilter ? row.data.comparison.comparisonPeriod.values[this.gridWidget.model.measure.code] : null,
            planValue: this.hasPlan && this.gridWidget.model.measure.planMeasure ? row.data.values[this.gridWidget.model.measure.planMeasure.code] : null
        };


        row.tooltipData = tooltipData;

    }

}

export class MnbDashboardListWidgetModel {

    rows: MnbDashboardListWidgetRowModel[];
    mergedSettings: DashboardWidgetQuerySettings;
}

export class MnbDashboardListWidgetRowModel {

    attribute: string;
    value: number;
    unitConfig: any;

    data: DashboardWidgetData;

    tooltipData: MnbChartTooltipData;

    unit: MnbUnitInfo;
}