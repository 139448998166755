import { QuerySettingsTimeFilterOptionCode as TimeFilterOptionCode, TimeTypeCode } from '@shared-lib/modules/data/model/mnb-data-query.model';


export class TimeFilter  {

    constructor(public optionCode: string | TimeFilterOptionCode, public from: TimeFilterPart, public to: TimeFilterPart) {

    }
}
export class TimeFilterPart {
    constructor(public index?: number, public typeCode?: string, public relativeToYesterday?: boolean, public date?: Date | number) {
      this.relativeToYesterday = !!relativeToYesterday;
    }
}

export class TimeFilterOption {
    constructor(public code: TimeFilterOptionCode, public typeCode: TimeTypeCode, public label: string, public timeFilter: TimeFilter) {
    }
}

export class TimeFilterOptions {
    public static CUSTOM_OPTION = new TimeFilterOption(TimeFilterOptionCode.CUSTOM, null, 'GENERAL.TIME.TIME_OPTIONS.CUSTOM_DATE_RANGE', null);

    public static TODAY_OPTION = new TimeFilterOption(TimeFilterOptionCode.TODAY, TimeTypeCode.Days, 'GENERAL.TIME.TIME_OPTIONS.TODAY', new TimeFilter(TimeFilterOptionCode.TODAY, new TimeFilterPart(0, TimeTypeCode.Days), new TimeFilterPart(0, TimeTypeCode.Days)));

    public static get CUSTOM_OPTION_YEAR() {
        return new TimeFilterOption(TimeFilterOptionCode.CUSTOM, null, 'GENERAL.TIME.TIME_OPTIONS.CUSTOM', new TimeFilter(TimeFilterOptionCode.CUSTOM, new TimeFilterPart(0, TimeTypeCode.Years), new TimeFilterPart(0, TimeTypeCode.Days)));
    }

    private static VALUES = [
        // Days
        new TimeFilterOption(TimeFilterOptionCode.YESTERDAY, TimeTypeCode.Days, 'GENERAL.TIME.TIME_OPTIONS.YESTERDAY', new TimeFilter(TimeFilterOptionCode.YESTERDAY, new TimeFilterPart(-1, TimeTypeCode.Days), new TimeFilterPart(-1, TimeTypeCode.Days))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_7_DAYS, TimeTypeCode.Days, 'GENERAL.TIME.TIME_OPTIONS.LAST_7_DAYS', new TimeFilter(TimeFilterOptionCode.LAST_7_DAYS, new TimeFilterPart(-7, TimeTypeCode.Days), new TimeFilterPart(-1, TimeTypeCode.Days))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_30_DAYS, TimeTypeCode.Days, 'GENERAL.TIME.TIME_OPTIONS.LAST_30_DAYS', new TimeFilter(TimeFilterOptionCode.LAST_30_DAYS, new TimeFilterPart(-30, TimeTypeCode.Days), new TimeFilterPart(-1, TimeTypeCode.Days))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_365_DAYS, TimeTypeCode.Days, 'GENERAL.TIME.TIME_OPTIONS.LAST_365_DAYS', new TimeFilter(TimeFilterOptionCode.LAST_365_DAYS, new TimeFilterPart(-365, TimeTypeCode.Days), new TimeFilterPart(-1, TimeTypeCode.Days))),
        new TimeFilterOption(TimeFilterOptionCode.CUSTOM, TimeTypeCode.Days, 'GENERAL.TIME.TIME_OPTIONS.CUSTOM', new TimeFilter(TimeFilterOptionCode.CUSTOM, new TimeFilterPart(0, TimeTypeCode.Days), new TimeFilterPart(0, TimeTypeCode.Days))),

        // Weeks
        new TimeFilterOption(TimeFilterOptionCode.THIS_WEEK_TO_DATE, TimeTypeCode.Weeks, 'GENERAL.TIME.TIME_OPTIONS.WEEK_TO_DATE', new TimeFilter(TimeFilterOptionCode.THIS_WEEK_TO_DATE, new TimeFilterPart(0, TimeTypeCode.Weeks, true), new TimeFilterPart(0, TimeTypeCode.Days, true))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_WEEK, TimeTypeCode.Weeks, 'GENERAL.TIME.TIME_OPTIONS.LAST_WEEK', new TimeFilter(TimeFilterOptionCode.LAST_WEEK, new TimeFilterPart(-1, TimeTypeCode.Weeks), new TimeFilterPart(-1, TimeTypeCode.Weeks))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_52_WEEKS, TimeTypeCode.Weeks, 'GENERAL.TIME.TIME_OPTIONS.LAST_52_WEEKS', new TimeFilter(TimeFilterOptionCode.LAST_52_WEEKS, new TimeFilterPart(-52, TimeTypeCode.Weeks), new TimeFilterPart(-1, TimeTypeCode.Weeks))),
        new TimeFilterOption(TimeFilterOptionCode.CUSTOM, TimeTypeCode.Weeks, 'GENERAL.TIME.TIME_OPTIONS.CUSTOM', new TimeFilter(TimeFilterOptionCode.CUSTOM, new TimeFilterPart(0, TimeTypeCode.Weeks), new TimeFilterPart(0, TimeTypeCode.Days))),

        // Months
        new TimeFilterOption(TimeFilterOptionCode.THIS_MONTH_TO_DATE, TimeTypeCode.Months, 'GENERAL.TIME.TIME_OPTIONS.MONTH_TO_DATE', new TimeFilter(TimeFilterOptionCode.THIS_MONTH_TO_DATE, new TimeFilterPart(0, TimeTypeCode.Months, true), new TimeFilterPart(0, TimeTypeCode.Days, true))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_MONTH, TimeTypeCode.Months, 'GENERAL.TIME.TIME_OPTIONS.LAST_MONTH', new TimeFilter(TimeFilterOptionCode.LAST_MONTH, new TimeFilterPart(-1, TimeTypeCode.Months), new TimeFilterPart(-1, TimeTypeCode.Months))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_12_MONTHS, TimeTypeCode.Months, 'GENERAL.TIME.TIME_OPTIONS.LAST_12_MONTHS', new TimeFilter(TimeFilterOptionCode.LAST_12_MONTHS, new TimeFilterPart(-12, TimeTypeCode.Months), new TimeFilterPart(-1, TimeTypeCode.Months))),
        new TimeFilterOption(TimeFilterOptionCode.CUSTOM, TimeTypeCode.Months, 'GENERAL.TIME.TIME_OPTIONS.CUSTOM', new TimeFilter(TimeFilterOptionCode.CUSTOM, new TimeFilterPart(0, TimeTypeCode.Months), new TimeFilterPart(0, TimeTypeCode.Days))),

        // Quarters
        new TimeFilterOption(TimeFilterOptionCode.THIS_QUARTER_TO_DATE, TimeTypeCode.Quarters, 'GENERAL.TIME.TIME_OPTIONS.QUARTER_TO_DATE', new TimeFilter(TimeFilterOptionCode.THIS_QUARTER_TO_DATE, new TimeFilterPart(0, TimeTypeCode.Quarters, true), new TimeFilterPart(0, TimeTypeCode.Days, true))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_QUARTER, TimeTypeCode.Quarters, 'GENERAL.TIME.TIME_OPTIONS.LAST_QUARTER', new TimeFilter(TimeFilterOptionCode.LAST_QUARTER, new TimeFilterPart(-1, TimeTypeCode.Quarters), new TimeFilterPart(-1, TimeTypeCode.Quarters))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_3_QUARTERS, TimeTypeCode.Quarters, 'GENERAL.TIME.TIME_OPTIONS.LAST_3_QUARTERS', new TimeFilter(TimeFilterOptionCode.LAST_3_QUARTERS, new TimeFilterPart(-4, TimeTypeCode.Quarters), new TimeFilterPart(-1, TimeTypeCode.Quarters))),
        new TimeFilterOption(TimeFilterOptionCode.CUSTOM, TimeTypeCode.Quarters, 'GENERAL.TIME.TIME_OPTIONS.CUSTOM', new TimeFilter(TimeFilterOptionCode.CUSTOM, new TimeFilterPart(0, TimeTypeCode.Quarters), new TimeFilterPart(0, TimeTypeCode.Days))),


        // Fiscal Years
        new TimeFilterOption(TimeFilterOptionCode.THIS_FISCAL_YEAR_TO_DATE, TimeTypeCode.FiscalYears, 'GENERAL.TIME.TIME_OPTIONS.FISCAL_YEAR_TO_DATE', new TimeFilter(TimeFilterOptionCode.THIS_FISCAL_YEAR_TO_DATE, new TimeFilterPart(0, TimeTypeCode.FiscalYears, true), new TimeFilterPart(0, TimeTypeCode.Days, true))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_FISCAL_YEAR, TimeTypeCode.FiscalYears, 'GENERAL.TIME.TIME_OPTIONS.LAST_FISCAL_YEAR', new TimeFilter(TimeFilterOptionCode.LAST_FISCAL_YEAR, new TimeFilterPart(-1, TimeTypeCode.FiscalYears), new TimeFilterPart(-1, TimeTypeCode.FiscalYears))),

        // Years
        new TimeFilterOption(TimeFilterOptionCode.THIS_YEAR_TO_DATE, TimeTypeCode.Years, 'GENERAL.TIME.TIME_OPTIONS.YEAR_TO_DATE', new TimeFilter(TimeFilterOptionCode.THIS_YEAR_TO_DATE, new TimeFilterPart(0, TimeTypeCode.Years, true), new TimeFilterPart(0, TimeTypeCode.Days, true))),
        new TimeFilterOption(TimeFilterOptionCode.LAST_YEAR, TimeTypeCode.Years, 'GENERAL.TIME.TIME_OPTIONS.LAST_YEAR', new TimeFilter(TimeFilterOptionCode.LAST_YEAR, new TimeFilterPart(-1, TimeTypeCode.Years), new TimeFilterPart(-1, TimeTypeCode.Years))),
        new TimeFilterOption(TimeFilterOptionCode.CUSTOM, TimeTypeCode.Years, 'GENERAL.TIME.TIME_OPTIONS.CUSTOM', new TimeFilter(TimeFilterOptionCode.CUSTOM, new TimeFilterPart(0, TimeTypeCode.Years), new TimeFilterPart(0, TimeTypeCode.Days)))
    ];


    private static VALUES_WITH_TODAY = [TimeFilterOptions.TODAY_OPTION, ...TimeFilterOptions.VALUES];

    public static getByCode(optionCode: string | TimeFilterOptionCode): TimeFilterOption {
        const option = TimeFilterOptions.VALUES_WITH_TODAY.find(op => op.code === optionCode);
        // if there is no option with this code we assume it is custom
        return option || TimeFilterOptions.VALUES_WITH_TODAY.find(op => op.code === TimeFilterOptionCode.CUSTOM);
    }

    public static getAll(includeToday?: boolean): Array<TimeFilterOption> {
        return includeToday ? TimeFilterOptions.VALUES_WITH_TODAY : TimeFilterOptions.VALUES;
    }

    public static getByType(optionTypeCode: TimeTypeCode, includeToday?: boolean): Array<TimeFilterOption> {
        const options = this.getAll(includeToday).filter(option => option.typeCode === optionTypeCode);
        return options;
    }

}

export class TimeComparisonFilter {
    constructor(
        public typeCode?: TimeTypeCode,
        public index?: number,
        public fromDate?: Date | number,
        public toDate?: Date | number,
        public optionCode?: TimeComparisonOptionCode) {
    }
}

export enum TimeComparisonOptionCode {
    AdjoiningPeriod = 'adjoiningPeriod',
    YearOverYearByDate = 'yearOverYearByDate',
    YearOverYearByWeekNumber = 'yearOverYearByWeekNumber',
    OffsetByXPeriods = 'offsetByXPeriods',
    DateRange = 'dateRange'
}

export enum ExtendedComparisonCode {
    WEEK_OVER_WEEK = 'week_over_week',
    MONTH_OVER_MONTH = 'month_over_month'
}

export class TimeComparisonOption {

    constructor(
        public code?: TimeComparisonOptionCode,
        public optionLabelCode?: string,
        public labelCode?: string,
        public shortLabelCode?: string,
        public filter?: TimeComparisonFilter,
        public extendedCode?: ExtendedComparisonCode
    ) {}


    public withFilter(filter: TimeComparisonFilter): TimeComparisonOption {
        return new TimeComparisonOption(
            this.code, this.optionLabelCode, this.labelCode, this.shortLabelCode, filter);
    }
}

export class TimeComparisonFilterOptions {

    public static ADJOINING_PERIOD = new TimeComparisonOption(
        TimeComparisonOptionCode.AdjoiningPeriod,
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.ADJOINING_PERIOD',
        'GENERAL.TIME.COMPARISON_FILTER.ADJOINING_PERIOD',
        'GENERAL.TIME.COMPARISON_FILTER.ADJOINING_PERIOD_SHORT',
        new TimeComparisonFilter(TimeTypeCode.Days, null));

    public static DATE_RANGE = new TimeComparisonOption(
        TimeComparisonOptionCode.DateRange,
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.CUSTOM_DATE_RANGE',
        'GENERAL.TIME.COMPARISON_FILTER.DATE_RANGE',
        'GENERAL.TIME.COMPARISON_FILTER.DATE_RANGE_SHORT',
        new TimeComparisonFilter());

    public static OFFSET_BY_PERIOD = new TimeComparisonOption(
        TimeComparisonOptionCode.OffsetByXPeriods,
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.OFFSET',
        'GENERAL.TIME.COMPARISON_FILTER.OFFSET',
        'GENERAL.TIME.COMPARISON_FILTER.OFFSET_SHORT',
        new TimeComparisonFilter(null, 0));

    public static YEAR_OVER_YEAR_BY_DATE = new TimeComparisonOption(
        TimeComparisonOptionCode.YearOverYearByDate,
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.YOY_BY_DATE',
        'GENERAL.TIME.COMPARISON_FILTER.YOY_BY_DATE',
        'GENERAL.TIME.COMPARISON_FILTER.YOY_BY_DATE_SHORT',
        new TimeComparisonFilter(TimeTypeCode.Years, -1));

    public static YEAR_OVER_YEAR_BY_WEEK_NUMBER = new TimeComparisonOption(
        TimeComparisonOptionCode.YearOverYearByWeekNumber,
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.YOY_BY_WEEK',
        'GENERAL.TIME.COMPARISON_FILTER.YOY_BY_WEEK',
        'GENERAL.TIME.COMPARISON_FILTER.YOY_BY_WEEK_SHORT',
        new TimeComparisonFilter(TimeTypeCode.Weeks, -52));

    public static VALUES = [
        TimeComparisonFilterOptions.DATE_RANGE,
        TimeComparisonFilterOptions.ADJOINING_PERIOD,
        TimeComparisonFilterOptions.OFFSET_BY_PERIOD,
        TimeComparisonFilterOptions.YEAR_OVER_YEAR_BY_DATE,
        TimeComparisonFilterOptions.YEAR_OVER_YEAR_BY_WEEK_NUMBER
    ];

    public static WEEK_OVER_WEEK = new TimeComparisonOption(
        TimeComparisonOptionCode.OffsetByXPeriods,
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.WOW',
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.WOW',
        '',
        new TimeComparisonFilter(TimeTypeCode.Weeks, -1), ExtendedComparisonCode.WEEK_OVER_WEEK);

    public static MONTH_OVER_MONTH = new TimeComparisonOption(
        TimeComparisonOptionCode.OffsetByXPeriods,
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.MOM',
        'GENERAL.TIME.COMPARISON_FILTER.OPTION.MOM',
        '',
        new TimeComparisonFilter(TimeTypeCode.Months, -1,), ExtendedComparisonCode.MONTH_OVER_MONTH);

    public static EXTENDED_VALUES = [
        TimeComparisonFilterOptions.WEEK_OVER_WEEK,
        TimeComparisonFilterOptions.MONTH_OVER_MONTH
    ];

    public static isExtendedCode(code: TimeComparisonOptionCode | ExtendedComparisonCode) {
        return Object.keys(ExtendedComparisonCode).map(op => ExtendedComparisonCode[op]).includes(code);
    }

    public static getByCode(code: TimeComparisonOptionCode | ExtendedComparisonCode): TimeComparisonOption {
        const isExtendedCode = TimeComparisonFilterOptions.isExtendedCode(code);
        const comparisonOption = isExtendedCode ? this.EXTENDED_VALUES.find(op => op.extendedCode === code) : this.VALUES.find(opt => opt.code === code);


        if (comparisonOption && !isExtendedCode) {
            Object.keys(comparisonOption.filter).forEach((key) => {
                if (!comparisonOption.filter[key]) {
                    delete comparisonOption.filter[key];
                }
            });
        }

        return comparisonOption;
    }
}

export enum DatePart {
    Year = 'Y', Month = 'M', Day = 'D'
}

