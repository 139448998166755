import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { filterTypeOptions } from '../model/query-filter.model';

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    constructor(
        private translateService: TranslateService
    ) {}

    public createFilterLabel(filter: QueryFilter, nativeElement: any): string {
        if (!filter.values || !filter.values.length) {
            return '-';
        }

        let valuesLabel = this.getFilterTypeName(filter.typeCode);
        valuesLabel = valuesLabel.charAt(0).toUpperCase() + valuesLabel.slice(1);

        let i = 0;
        let tmpValuesLabel = '';

        while (i < 3) {
            tmpValuesLabel = valuesLabel + (i === 0 ? ' ' : ', ') + filter.values[i];

            if (!this.valuesLabelFitsInTile(tmpValuesLabel + this.getNotFittingFilterValuesLabel(filter, i + 1), nativeElement) || !filter.values[i]) {
                break;
            } else {
                i++;
                valuesLabel = tmpValuesLabel;
            }
        }

        return valuesLabel + this.getNotFittingFilterValuesLabel(filter, i);
    }

    private getNotFittingFilterValuesLabel(filter: QueryFilter, numberOfFittingValues: number): string {
        // no value fits in
        if (numberOfFittingValues === 0) {
            return ' ' + filter.values.length + ' ' + (filter.values.length === 1 ? this.translateService.instant('PAGE.ALY.DASHBOARD.FILTERS.VALUE') : this.translateService.instant('PAGE.ALY.DASHBOARD.FILTERS.VALUES'));
        // some values do not fit in
        } else if (numberOfFittingValues < filter.values.length) {
            return ' ' + this.translateService.instant('GENERAL.MISC.AND') + ' ' + (filter.values.length - numberOfFittingValues) + ' '
            + (filter.values.length - numberOfFittingValues === 1 ? this.translateService.instant('PAGE.ALY.DASHBOARD.FILTERS.OTHER') : this.translateService.instant('PAGE.ALY.DASHBOARD.FILTERS.OTHERS'));
        // all values fit in
        } else {
            return '';
        }
    }

    private valuesLabelFitsInTile(valuesLabel: string, nativeElement: any): boolean {
        const containerWidth =  nativeElement.offsetWidth;
        const textWidth = this.getTextWidth(valuesLabel, getComputedStyle(nativeElement).getPropertyValue('font'));
        return containerWidth > textWidth;
    }

    private getTextWidth(text: string, font: string) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    public getFilterTypeName(typeCode: string): string {
        const type = filterTypeOptions.find(t => t.code === typeCode);

        if (type) {
            return this.translateService.instant(type.label);
        } else {
            return '';
        }
    }
}
