export class Periods {
    
    public static get Days(): Array<string> {
        return [ 'SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    }

    public static get Months(): Array<string> {
        return ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    }

}

export enum Days {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6
}

export class DatePickerDate {
    private date: Date;
    public dayCount: number;

    constructor(private day: number, private month: number, private year: number, private startDay: number) {
        this.date = new Date(Date.UTC(this.year, this.month - 1, this.day));
        this.dayCount = this.date.getUTCDay() - this.startDay;
        this.dayCount = this.dayCount < 0 ? this.dayCount + 7 : this.dayCount;
    }

    getDayNumber() {
        return this.day;
    }

    getMonth() {
        return this.month;
    }

    getYear() {
        return this.year;
    }

    getDayCount() {
        return this.dayCount;
    }

    getDateObject(): Date {
        return this.date;
    }
}

export class DisplayMonth {

    private _length: number;

    private _previous: DisplayMonth;
    private _next: DisplayMonth;

    constructor(public month: number, public year: number) {
        let date = new Date(Date.UTC(year, month - 1, 1));

        //move to the last of this month
        date.setUTCMonth(date.getUTCMonth() + 1);
        date.setUTCDate(date.getUTCDate() - 1);

        this._length = date.getUTCDate();
    }

    get length(): number {
        return this._length;
    }

    get previous(): DisplayMonth {
        let year = this.year;
        let month = this.month - 1;
        if (month < 1) {
            year--;
            month = 12;
        }
        this._previous = new DisplayMonth(month, year);
        return this._previous;
    }

    get next(): DisplayMonth {
        let year = this.year;
        let month = this.month + 1;
        if (month > 12) {
            year++;
            month = 1;
        }
        this._next = new DisplayMonth(month, year);
        return this._next;
    }

    public equals(year: number, month: number) {
        return this.month == month && this.year == year;
    }


}

export class WeekDays {
    constructor(public current: Array<number>, public other: Array<number>) {}
}
