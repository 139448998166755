import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { MnbFeatureRightsRequiredDirective } from '../../components/feature-rights-required/feature-rights-required.directive';
import { InputFocusDirective } from '../../directives/input-focus/input-focus.directive';
import { MnbTimeTenantPipe } from '../../pipes/time-tenant.pipe';
import { MnbNotificationOwnerDirective } from './components/notification-owner/mnb-notification-owner.directive';
import { MnbPaginationPageComponent } from './components/pagination/pagination-page.component';
import { MnbPaginationComponent } from './components/pagination/pagination.component';
import { MnbTextAreaHandlerDirective } from './components/text-area-handler/text-area-handler.directive';
import { UiSrefDirective } from './components/ui-sref/ui-sref.directive';
import { MnbCapitalizePipe } from './pipes/capitalize.pipe';
import { MnbFrequencyPipe } from './pipes/frequency.pipe';
import { MnbPrettyjsonPipe } from './pipes/jsonpretty.pipe';
import { MnbMarketplaceNamePipe } from './pipes/mnb-marketplace-name.pipe';
import { MnbSafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
    declarations: [
        MnbPaginationComponent,
        MnbPaginationPageComponent,
        MnbTextAreaHandlerDirective,
        MnbFeatureRightsRequiredDirective,
        MnbMarketplaceNamePipe,
        MnbCapitalizePipe,
        MnbTimeTenantPipe,
        MnbFrequencyPipe,
        MnbPrettyjsonPipe,
        MnbNotificationOwnerDirective,
        MnbSafeHtmlPipe,
        MnbPrettyjsonPipe,
        UiSrefDirective,
        InputFocusDirective
    ],
    entryComponents: [
        MnbPaginationComponent,
        MnbPaginationPageComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MnbLibModule
    ],
    exports: [
        MnbNotificationOwnerDirective,
        MnbTextAreaHandlerDirective,
        MnbFeatureRightsRequiredDirective,
        MnbPaginationComponent,
        MnbPaginationPageComponent,
        MnbMarketplaceNamePipe,
        MnbCapitalizePipe,
        MnbTimeTenantPipe,
        MnbFrequencyPipe,
        MnbSafeHtmlPipe,
        MnbPrettyjsonPipe,
        UiSrefDirective,
        InputFocusDirective
    ],
    providers: [
        DatePipe,
        MnbMarketplaceNamePipe,
        MnbCapitalizePipe,
        MnbTimeTenantPipe,
        MnbFrequencyPipe,
        MnbSafeHtmlPipe,
        MnbPrettyjsonPipe
    ]
})
export class SharedMiscModule {

}