import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, Resources } from './api.service';

@Injectable()
export class ApiDataService {


    constructor(
        private apiService: ApiService
    ) {

    }

    public loadAttributeData(code: string, search?: string): Observable<any> {
        const params: {[param: string]: string} = {code};
        if (search) {
            params.search = search;
        }
        return this.apiService.get(Resources.DATA_ATTRIBUTE_VALUES, params);
    }

}
