import deepEqual from 'deep-equal';
import { isNullOrUndefined } from 'util';
import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsPlan, QuerySettingsTimeFilter } from './mnb-data-query.model';
import { WidgetVisualizationType } from '../../dashboards/services/mnb-dashboard-chart.service';
import { EntityType } from './mnb-data-entity.model';
import { EntityPreset, EntitySchedule, User } from '@shared-lib/modules/data/model/mnb-data-entity-schedule.model';

export class Dashboard {
    id: number;
    loginId?: number;
    title: string;
    description?: string;
    isPrivate?: boolean;
    isReadOnly?: boolean;
    folder?: string;

    lastViewDateTime?: Date | number;
    lastViewMail?: string;
    lastEditDateTime?: Date | number;
    lastEditMail?: string;

    settings: DashboardSettings = new DashboardSettings();

    widgets: DashboardWidget[] = [];
    schedules?: DashboardSchedule[];
}

export class DashboardSettings {
    timeFilter: QuerySettingsTimeFilter = QuerySettingsTimeFilter.getDefault();
    comparisonFilter?: QuerySettingsComparisonFilter;

    timeLabel?: string;

    filters?: Array<QueryFilter>;
    plan?: QuerySettingsPlan;

    public static hasSameFilters(a: DashboardSettings, b: DashboardSettings): boolean {

        const isFiltersEmpty = (b.filters && !b.filters.length) || isNullOrUndefined(b.filters);
        const isCurrentFiltersEmpty = (a.filters && !a.filters.length) || isNullOrUndefined(a.filters);

        if (isFiltersEmpty || isCurrentFiltersEmpty) {
            return isFiltersEmpty === isCurrentFiltersEmpty;
        }

        return deepEqual(a.filters, b.filters);
    }

    public static hasSameTimeSettings(a: DashboardSettings, b: DashboardSettings): boolean {
        return  QuerySettingsTimeFilter.equals(a.timeFilter, b.timeFilter) &&
                QuerySettingsComparisonFilter.equals(a.comparisonFilter, b.comparisonFilter) &&
                QuerySettingsPlan.equals(a.plan, b.plan);
    }
}

export class DashboardWidget {
    id: number;
    fkDashboard: number;
    visualizationSettings: DashboardWidgetVisualizationSettings;
    querySettings: DashboardWidgetQuerySettings;
    dashboard?: Dashboard;
    description?: string;

    public static isSamePosition(a: DashboardWidget, b: DashboardWidget): boolean {

        return a.visualizationSettings.position.x === b.visualizationSettings.position.x
                && a.visualizationSettings.position.y === b.visualizationSettings.position.y;
    }

    public static isTableWidget(widget: DashboardWidget): boolean {
        return widget.visualizationSettings.typeCode === WidgetVisualizationType.Table;
    }

    public static isListWidget(widget: DashboardWidget): boolean {
        return widget.visualizationSettings.typeCode === WidgetVisualizationType.List;
    }

    public static isSingleValueWidget(widget: DashboardWidget): boolean {
        return widget.visualizationSettings.typeCode === WidgetVisualizationType.SingleValue;
    }

    public static isLineChartWidget(widget: DashboardWidget): boolean {
        return widget.visualizationSettings.typeCode === WidgetVisualizationType.LineChart;
    }

    public static isBarChartWidget(widget: DashboardWidget): boolean {
        return widget.visualizationSettings.typeCode === WidgetVisualizationType.BarChart;
    }

    public static isTimeSeriesChartWidget(widget: DashboardWidget): boolean {
        return widget.visualizationSettings.typeCode === WidgetVisualizationType.TimeSeriesChart;
    }

    public static isTextWidget(widget: DashboardWidget): boolean {
        return widget.visualizationSettings.typeCode === WidgetVisualizationType.Text;
    }

}

export class DashboardWidgetVisualizationSettings {
    typeCode: string;
    measures: Array<DashboardWidgetVisualizationSettingsMeasure>;
    title: string;
    subtitle: string;
    content?: string;
    position: DashboardWidgetPosition;
    breakdownMode: string;
    additionalAxis: boolean;
    additionalMeasureAsLine: boolean;
    timeLabel?: string;

    valueDisplayMode?: string;
    comparisonFormat?: string;

    backgroundColor?: string;

}

export class DashboardWidgetVisualizationSettingsMeasure {
    code: string;
    showValue = true;
    showComparisonValue = false;
    showChange = false;
    showPlanValue = false;
    showPlanDeviation = false;

    constructor(code?: string) {
        this.code = code;
    }
}

export class DashboardWidgetPosition {
    x: number;
    y: number;
    width: number;
    height: number;

    static areOverlapping(position1: DashboardWidgetPosition, position2: DashboardWidgetPosition): boolean {
        if (position1.x > (position2.x + position2.width - 1) || position2.x > (position1.x + position1.width - 1)) {
            return false;
        }
        if (position1.y > (position2.y + position2.height - 1) || position2.y > (position1.y + position1.height - 1)) {
            return false;
        }
        return true;
    }
}


export class DashboardWidgetData {
    breakdown?: {[attributeCode: string]: DashboardWidgetData[]};
    breakdownOther?: {[attributeCode: string]: DashboardWidgetData};
    values?: {[measureCode: string]: number};
    attributes?: {[attributeCode: string]: string};
    comparison?: {[comparisonCode: string]: DashboardWidgetData};
    rows?: DashboardWidgetData[];
    fromDate?: Date;
    toDate?: Date;
    missingModelRights?: MissingModelRights;

    public static getCurrencyCode(data: DashboardWidgetData): string {
        if (!data.attributes) {
            return null;
        }
        return data.attributes['saleOrdOriginalCurrency'];
    }
}

export class DashboardWidgetDetail extends DashboardWidgetData {
    detailAttributeCode: string;
    detailAssistMeasureCodes: string[];
    isDetailTime: boolean;
}

/**
 * TODO: Is this model right here? Naming should then be DashboardWidgetMissingModelRights or something
 */
export class MissingModelRights {
    measureCodes: string[];
    attributeCodes: string[];
    assistMeasureCodes?: Array<string>;
}

export class DashboardWidgetQuerySettings {
    measures?: Array<any>;
    attributes?: Array<any>;
    customDrilldownAttribute?: Array<any>;
    measureCode: string;
    additionalMeasureCode?: string;
    attributeCode?: string;
    breakdownAttributeCode?: string;
    drilldownAttributes?: Array<DrilldownAttribute>;
    filters?: Array<QueryFilter>;
    excludedFilters?: Array<QueryFilter>;
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    sort?: DashboardWidgetQuerySettingsSort;
    plan?: QuerySettingsPlan;
    attributeValuesLimit?: number;
    attributeFilters?: Array<QueryFilter>;
    limitBreakdownAttributeValues?: boolean;
}

export type DrilldownAttribute = {
    attributeCode: string;
    value?: string;
    timeFilter: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    depth: number;
};

export class DashboardWidgetQuerySettingsSort {
    attributeCode?: string;
    measureCode?: string;
    direction: string;
}

export class DashboardSchedule extends EntitySchedule {

    public dashboard?: Dashboard;

    constructor(
        public id: number,
        public fkDashboard?: number,
        public frequency?: string,
        public currentRunDateTime?: number,
        public nextRunDateTime?: number,
        public recipients?: User[],
        public settings?: any,
        public isDeactivated?: boolean,
        public statusCode?: string,
        public targetTime?: string,
        public entityTitle?: string
        ) {
            super(id, frequency, currentRunDateTime, nextRunDateTime, recipients, settings, entityTitle, EntityType.DASHBOARD, isDeactivated, statusCode, targetTime);
        }

    public getFkEntity(): number {
        return this.fkDashboard;
    }

}

export class DashboardDownload {
    public uuid: string;
    public isReady: boolean;
    public viewSettings?: DashboardSettings;
}

export type DashboardPreset = EntityPreset<{ dashboard: Dashboard }>;
