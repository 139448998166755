import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mnb-reports-table-drillup',
  templateUrl: './mnb-reports-table-drillup.component.html',
})
export class MnbReportsTableDrillupComponent {
    @Input() drilldownStack: { attribute?: { code: string }, value?: string }[] = [];
    @Output() drillup = new EventEmitter<DrillupEvent>();

    public onDrillup(idx: number) {
        this.drillup.emit(new DrillupEvent(idx));
    }
}

export class DrillupEvent {
  constructor(public index) {}
}
