import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MnbDashboardChartService, WidgetVisualizationType } from '@shared-lib/modules/dashboards/services/mnb-dashboard-chart.service';
import { DashboardGridWidget } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { MnbDeleteDoubleConfirmComponent } from '@minubo-suite/shared/components/delete-double-confirm/delete-double-confirm.component';
import { QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { TimeComparisonFilter, TimeFilter, TimeFilterOption, TimeFilterOptions } from '@shared-lib/modules/core/services/time/time.model';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { MnbDashboardService } from '@shared-lib/modules/dashboards/services/mnb-dashboard.service';
import { isNullOrUndefined } from 'util';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { TeantDataUpdateTimes } from '@shared-lib/modules/data/model/mnb-data-tenant.model';
import { ChartRenderer } from '@shared-lib/modules/charts/renderers/chart-renderer';
import { DashboardWidget } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { isArrayWithData } from '@shared-lib/modules/core/utils/array.utils';

class DashboardWidgetDisplay {
    public title: string;
    public subtitle: string;
    public isTable: boolean;
    public isSingleValue: boolean;
    public isGaugeChart: boolean;
    public isList: boolean;
    public isText: boolean;
    public isEmpty?: boolean;
}

@Component({
    selector: 'mnb-dashboard-widget',
    templateUrl: './mnb-dashboard-widget.component.html'
})
export class MnbDashboardWidgetComponent implements OnInit, OnDestroy {

    constructor(
        private mnbDashboardService: MnbDashboardService,
        public dashboardChartService: MnbDashboardChartService,
        private timeFilterService: TimeFilterService,
        public elementRef: ElementRef) {
    }

    public get tooltipPlacement(): string {
        const position = this.gridWidget.widget.visualizationSettings.position;
        return position.x + position.width < 11 ? 'bottom' : 'left';
    }

    @ViewChild(MnbDeleteDoubleConfirmComponent, { static: true }) confirmDeleteComponent: MnbDeleteDoubleConfirmComponent;

    @Input() gridWidget: DashboardGridWidget;
    @Input() isViewMode: boolean;
    @Input() isPreviewMode: boolean;

    @Input() dataUpdateTimes: TeantDataUpdateTimes;

    @Output()
    public initialized = new EventEmitter();

    @Output()
    public navigateToDetailView = new EventEmitter<DashboardGridWidget>();

    @Output()
    public navigateToQuery = new EventEmitter();

    public hasDetailView: boolean;
    public hasQueryLink: boolean;
    public load: MnbBusyStatus;
    public display: DashboardWidgetDisplay;
    public renderer: ChartRenderer;
    public timeFilterTooltipContent: string;
    public sizeClass: string;
    public timeFilterOption: TimeFilterOption;
    public timeFilter: QuerySettingsTimeFilter;
    public timeSpan: DateSpan;
    public hasCustomWidgetTime: boolean;
    public isDataWidget: boolean;
    public isRealtime: boolean;

    public alertColor: string;

    @HostBinding('class.is-widget-moving') isWidgetMovingClass = false;
    @HostBinding('class.is-widget-resizing') isWidgetResizingClass = false;
    @HostBinding('class.has-alert') hasAlert: boolean;

    @HostBinding('style.width') widgetWidth: string;
    @HostBinding('style.height') widgetHeight: string;

    private static isValidBreakdown(gridWidget: DashboardGridWidget): boolean {

        const breakdownDataList = gridWidget.data.breakdown;

        if (!breakdownDataList) {
            return true; // Don't need to do any further breakdown validation if there is no breakdown data
        }
        const breakdownKeys = Object.keys(breakdownDataList);

        const validBreakdownDatasets: string[] = breakdownKeys.filter(key => {
            const hasBreakdownAttribute = !!breakdownDataList[key].length;

            if (!hasBreakdownAttribute) {
                return false;
            }

            const indexWithData = breakdownDataList[key]
                .findIndex(b => {
                    if (!b.breakdown && !!b.attributes) {
                        return true; // Accept state of no breakdown, but attributes available as viable breakdown state
                    }
                    const attr = Object.keys(b.breakdown)[0];
                    const breakdownData = b.breakdown[attr];
                    return b.breakdown && isArrayWithData(breakdownData);
                });

            return indexWithData !== -1;
        });

        return validBreakdownDatasets.length === 0;
    }

    ngOnInit(): void {
        this.isDataWidget = !DashboardWidget.isTextWidget(this.gridWidget.widget);
        this.mnbDashboardService.checkWidgetForRealtimeMeasures(this.gridWidget.widget).then(isRealtime => this.isRealtime = isRealtime);

        this.display = new DashboardWidgetDisplay();
        this.load = new MnbBusyStatus();

        this.applyWidget();
        this.initWidgetSize();

        this.gridWidget.subscribe(() => {
            this.load = new MnbBusyStatus();
        }, () => {
            this.applyWidget();
        });

        this.initialized.emit(this);

        this.hasDetailView = this.navigateToDetailView.observers.length > 0;
        this.hasQueryLink = this.navigateToQuery.observers.length > 0;
    }

    private loadTimeFilter(): void {
        this.hasCustomWidgetTime = !isNullOrUndefined(this.gridWidget.widget.querySettings.timeFilter);

        // copy the time filter so that we do only notice changes to the time filter when loadTimeFilter is called
        this.timeFilter = this.hasCustomWidgetTime ? deepCopy(this.gridWidget.widget.querySettings.timeFilter) : deepCopy(this.gridWidget.dashboard.settings.timeFilter);

        if (!this.timeFilter) {
            return;
        }

        const comparisonFilter = this.gridWidget.widget.querySettings.comparisonFilter && this.gridWidget.widget.querySettings.timeFilter ? this.gridWidget.widget.querySettings.comparisonFilter : null;
        this.timeFilterOption = TimeFilterOptions.getByCode(this.timeFilter.optionCode);
        this.timeSpan = this.timeFilterService.getTimePeriod(this.timeFilter);
        const promises = [this.timeFilterService.getTimeLabelWithDateSpan(<TimeFilter>this.timeFilter, this.hasCustomWidgetTime ? this.gridWidget.widget.visualizationSettings.timeLabel : this.gridWidget.dashboard.settings.timeLabel)];

        if (comparisonFilter) {
            promises.push(this.timeFilterService.getComparisonLabelWithDateSpan(<TimeComparisonFilter>comparisonFilter, <TimeFilter>this.timeFilter));
        }

        Promise.all(promises).then((res) => {
            this.timeFilterTooltipContent = res[0];

            if (comparisonFilter) {
                this.timeFilterTooltipContent += '<br>vs. ' + res[1];
            }
        });
    }

    private applyWidget() {
        if (this.gridWidget && this.gridWidget.dataModelPromise) {
            this.gridWidget.dataModelPromise.then(() => {
                this.initWidgetDisplay();
                if (this.isDataWidget && !this.display.isSingleValue && !this.display.isTable && this.gridWidget.data.values || (this.display.isGaugeChart)) {
                    this.renderer = this.dashboardChartService.createRenderer(this.gridWidget.widget, this.gridWidget.dashboard, this.gridWidget.data, this.gridWidget.model);
                } else {
                    this.renderer = null;
                }
                this.load.done();

                if (this.isDataWidget) {
                    this.loadTimeFilter();
                }
            }).catch(error => {
                this.load.httpError(error);
                return Promise.reject(error);
            });
        }
    }

    private initWidgetDisplay() {
        this.display = new DashboardWidgetDisplay();
        this.display.title = this.mnbDashboardService.calcWidgetTitle(this.gridWidget);

        this.display.isSingleValue = this.gridWidget.widget.visualizationSettings.typeCode === WidgetVisualizationType.SingleValue;
        this.display.isGaugeChart = this.gridWidget.widget.visualizationSettings.typeCode === WidgetVisualizationType.GaugeChart;
        this.display.isTable = this.gridWidget.widget.visualizationSettings.typeCode === WidgetVisualizationType.Table;
        this.display.isList = this.gridWidget.widget.visualizationSettings.typeCode === WidgetVisualizationType.List;
        this.display.isText = this.gridWidget.widget.visualizationSettings.typeCode === WidgetVisualizationType.Text;

        if (this.isDataWidget) {
            this.display.subtitle = this.mnbDashboardService.calcWidgetSubtitle(this.gridWidget);
            this.display.isEmpty = this.isEmptyWidget();
        }
    }

    private isEmptyWidget(): boolean {
        if (this.display.isSingleValue || this.display.isGaugeChart) {
            return !Object.keys(this.gridWidget.data.values).length;
        }

        if (this.display.isTable) {
            return !this.gridWidget.data.rows || this.gridWidget.data.rows.length === 0;
        }

        if (this.display.isList) {
            return false;
        }

        return MnbDashboardWidgetComponent.isValidBreakdown(this.gridWidget);
    }

    private initWidgetSize() {
        if (!this.gridWidget.widget) {
            return '';
        }

        const position = this.gridWidget.widget.visualizationSettings.position;
        const size = Math.min(position.width, position.height);

        if (position.width < 3 && position.height < 4) {
            this.sizeClass = 'xsmall';
        } else if (size <= 4) {
            this.sizeClass = 'small ' + (size - 2 ? ('size-' + (size - 2)) : '');
        } else if (size <= 8) {
            this.sizeClass = 'medium ' + (size - 5 ? ('size-' + (size - 5)) : '');
        } else {
            this.sizeClass = 'large ' + (size - 9 ? ('size-' + (size - 9)) : '');
        }

        this.widgetWidth = position.width * 100 + '%';
        this.widgetHeight = position.height * 100 + '%';
    }

    ngOnDestroy(): void { }

    // FUNCTIONS
    public refresh(): void {
        this.initWidgetSize();
        if (this.renderer) {
            this.dashboardChartService.updateRendererConfig(this.renderer, this.gridWidget.widget, this.gridWidget.dashboard, this.gridWidget.data, this.gridWidget.model);
            this.renderer.rerender();
        }
    }

    public goToDetailView() {
        this.navigateToDetailView.emit(this.gridWidget);
    }

    public goToQuery() {
        this.navigateToQuery.emit(this.gridWidget);
    }

}
