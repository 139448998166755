import { Pipe, PipeTransform } from '@angular/core';
import { FilterService } from '../services/filter-service';

@Pipe({ name: 'mnbQueryFilterType' })
export class MnbQueryFilterTypePipe implements PipeTransform {


    constructor(private filterService: FilterService) {
    }

    transform(value: string): string {
        return this.filterService.getFilterTypeName(value);
    }

}
