import { Component, OnInit, Input, forwardRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined } from 'util';

@Component({
    selector: 'mnb-switch-toggle',
    template: `
    <div class="input-switch-toggle" [ngClass]="{ 'toggle-disabled': disabled, 'toggle-on': isSwitchedOn, 'toggle-small': isSmall }">
      <div class="switch-toggle-text-holder">
        <span class="switch-toggle-text">
          {{ (disabled ? 'GENERAL.LABEL.DISABLED' : isSwitchedOn ? 'GENERAL.LABEL.ON' : 'GENERAL.LABEL.OFF') | translate }}
        </span>
      </div>
      <div class="switch-toggle-slider">
        <span class="switch-toggle-button"></span>
      </div>
    </div>
  `,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MnbSwitchToggleComponent),
        multi: true
    }]
})

export class MnbSwitchToggleComponent implements OnInit {

    @Input() disabled = false;
    @Input() isSmall = false;

    public isSwitchedOn: boolean;
    private propagateChange = (_: any) => { };

    constructor() { }

    ngOnInit() { }

    @HostListener('click')
    onclick() {
        if (!this.disabled) {
            this.isSwitchedOn = !this.isSwitchedOn;
            this.value = this.isSwitchedOn;
        }
    }

    get value(): boolean {
        return this.isSwitchedOn;
    }

    set value(value: boolean) {
        if (!isNullOrUndefined(value)) {
            this.isSwitchedOn = value;
            this.propagateChange(this.isSwitchedOn);
        }
    }

    writeValue(value: boolean): void {
        if (!isNullOrUndefined(value)) {
            this.isSwitchedOn = value;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void { }

}
