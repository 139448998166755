import { CalendarConfig } from '@shared-lib/modules/config/model/mnb-config.model';


export class Tenant {

    code: string;
    currencyCode: string;

    calendarConfig: CalendarConfig;
}

export class TeantDataUpdateTimes {

    lastRealtimeProcessingDateTime: Date|number;
    lastDailyUpdateProcessingDateTime: Date|number;
}
