import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'mnbFrequency' })
export class MnbFrequencyPipe implements PipeTransform {

  public dayList = [];
  public dayOfMonthList = [];

  constructor(private translate: TranslateService) {
    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    for (let i = 1; i <= 31; i++) {
      this.dayOfMonthList.push(i + '');
      if (i < 8) {
        const index = i - 1;
        this.dayList.push('GENERAL.TIME.WEEKDAY.FULL.' + days[index]);
      }
    }
  }

  async transform(frequency: string): Promise<string> {
    let result = await this.getIntervalTranslationKey(frequency);
    if (frequency.charAt(0) === 'M') {
      result += ` (${this.getMonth(frequency)}.)`;
    } else if (frequency.charAt(0) === 'W') {
      result += ` (${await this.getDay(frequency)})`;
    }
    return Promise.resolve(result);
  }

  public getIntervalTranslationKey(frequency: string): Promise<string> {
    const intervals = { D: 'GENERAL.TIME.INTERVAL.DAILY', W: 'GENERAL.TIME.INTERVAL.WEEKLY', M: 'GENERAL.TIME.INTERVAL.MONTHLY' };
    return this.translate.get(intervals[frequency.charAt(0)]).toPromise();
  }
  public getMonth(frequency: string): string {
    return frequency.split(':')[1];
  }

  public getDay(frequency: string): Promise<string> {
    const frequencySplit = frequency.split(':');
    const day = frequencySplit[1] ? parseInt(frequencySplit[1], 10) : 1;
    return this.translate.get(this.dayList[day - 1]).toPromise();
  }

}
