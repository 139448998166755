import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiDataProviderService } from '@minubo-portal/modules/api/services/api-data-provider.service';
import { DataProviderDataService } from '@minubo-portal/modules/data-providers/components/data-provider-data.service';
import { DataProviderData, DataProviderSettings } from '@shared-lib/modules/data/model/mnb-data-data-provider.model';

@Injectable()
export class DataProvidersDataProviderService {}

@Injectable()
export class PortalDataProviderDataService implements DataProviderDataService {
    constructor(private apiDataProviderService: ApiDataProviderService) {}

    getData(id: number, adhocSettings?: DataProviderSettings): Observable<DataProviderData> {
        return this.apiDataProviderService.loadDataProviderData(id, adhocSettings);
    }
}
