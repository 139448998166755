import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {DrilldownAttribute, DashboardWidget, DashboardWidgetData} from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import {QueryFilter, QuerySettingsComparisonFilter, QuerySettingsTimeFilter, QuerySettingsTimeFilterFromTo} from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbDateSpanPipe } from '@shared-lib/modules/core/pipes/date-span.pipe';
import { MnbChartService } from '@shared-lib/modules/charts/services/chart.service';
import { LineChartConfig, ChartDataArray, ChartDataType, ChartDataComparisonType } from '@shared-lib/modules/charts/services/chart.models';
import { ChartRenderer } from '@shared-lib/modules/charts/renderers/chart-renderer';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { isNullOrUndefined } from 'util';
import { DashboardGridWidget, DashboardGridWidgetDetail } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { MnbUnitInfo } from '@shared-lib/modules/core/pipes/unit.pipe';
import {ModelAttribute} from '@shared-lib/modules/model/services/mnb-model.service';
import { DrilldownEvent } from '@shared-lib/modules/dashboards/components/dashboard.models';

@Component({
    selector: 'mnb-dashboard-widget-detail',
    templateUrl: './mnb-dashboard-widget-detail.component.html'
})
export class MnbDashboardsWidgetDetailComponent implements OnInit {

    @Input('widget') dashboardWidget: DashboardWidget;
    @Input() handler: MnbDashboardsWidgetDetailHandler;

    @Output()
    public drilldown: EventEmitter<DrilldownEvent> = new EventEmitter<DrilldownEvent>();

    @Output()
    public navigateToQuery = new EventEmitter<DashboardGridWidgetDetail>();
    public hasQueryLink: boolean;

    public load = new MnbBusyStatus();
    public loadMore = new MnbBusyStatus();

    public gridWidget: DashboardGridWidget;
    public data: DashboardGridWidgetDetail;
    public chartRenderer: ChartRenderer;

    public drilldownStack = Array<DrilldownStackElement>();

    constructor(
        private chartService: MnbChartService,
        private timeDateSpanPipe: MnbDateSpanPipe
    ) { }

    ngOnInit() {
        this.loadMore.done();

        this.hasQueryLink = this.navigateToQuery.observers.length > 0;
    }

    drillUp(index: number) {
        for (let i = index; i < this.drilldownStack.length; i++) {
            this.drilldownStack.pop();
        }

        this.update();
    }

    drillDown(event: DrilldownEvent) {
        let timeFilter = null;
        let comparisonFilter = null;
        const filters = new Array<QueryFilter>();

        const row = event.row;
        const value = row.attributes[this.data.model.attribute.code];

        if (row.fromDate) {
            // set time filter
            timeFilter = new QuerySettingsTimeFilter('custom', new QuerySettingsTimeFilterFromTo(null, null, null, row.fromDate), new QuerySettingsTimeFilterFromTo(null, null, null, row.toDate));

            if (row.comparisonFromDate) {
                // set comparison filter
                comparisonFilter = {
                    fromDate: row.comparisonFromDate,
                    toDate: row.comparisonToDate
                };
            }
        } else {
            filters.push(new QueryFilter(this.data.model.attribute.code, 'equals', [value]));
        }

        this.drilldownStack.push({
            value: value,
            attribute: this.data.model.attribute,
            childAttribute: event.attribute,
            timeFilter: timeFilter,
            comparisonFilter: comparisonFilter,
            filters: filters
        });

        this.drilldown.emit(event);

        this.loadDetail();
    }

    public tableDrillDown(event: DrilldownEvent) {
        const filters = new Array<QueryFilter>();

        const attribute = this.gridWidget.model.attributes[0];
        const value = event.row.attributes[attribute.code];

        filters.push(new QueryFilter(attribute.code, 'equals', [value]));

        this.drilldownStack.push({
            value: value,
            attribute: attribute,
            childAttribute: event.attribute,
            timeFilter: null,
            comparisonFilter: null,
            filters: filters
        });

        this.drilldown.emit(event);

        this.update();
    }

    public update() {
        if (this.dashboardWidget) {
            if (DashboardWidget.isTableWidget(this.dashboardWidget)) {
                const widgetCopy: DashboardWidget = this.createWidgetPayload(this.drilldownStack, this.dashboardWidget);
                this.load = new MnbBusyStatus();
                // TODO: Can we connect this to the load detail stuff?
                this.handler.loadTableWidget(widgetCopy).then(gridWidget => {
                    this.gridWidget = gridWidget;
                    this.load.done();
                }).catch(error => {
                    this.load.httpError(error);
                    return Promise.reject(error);
                });
            } else {
                this.loadDetail();
            }
        }
    }

    public clear() {
        this.load = new MnbBusyStatus();
        this.loadMore = new MnbBusyStatus();
        this.loadMore.done();

        this.gridWidget = null;
        this.data = null;
        this.chartRenderer = null;

        this.drilldownStack = Array<any>();
    }

    public showMore() {
        this.loadDetail(true);
    }

    private loadDetail(showMore?: boolean): void {

        if (isNullOrUndefined(showMore)) {
            this.load = new MnbBusyStatus();
        } else {
            this.loadMore = new MnbBusyStatus();
        }

        this.chartRenderer = null;
        const widgetCopy: DashboardWidget = this.createWidgetPayload(this.drilldownStack, this.dashboardWidget);

        this.handler.loadDetail(widgetCopy, showMore).then(detail => {
            this.data = detail;

            if (DashboardWidget.isSingleValueWidget(this.dashboardWidget) || DashboardWidget.isTimeSeriesChartWidget(this.dashboardWidget)) {
                this.initChart(detail);
            }

            if (isNullOrUndefined(showMore)) {
                this.load.done();
            } else {
                this.loadMore.done();
            }
        }).catch(error => {
            this.load.httpError(error);
            return Promise.reject(error);
        });
    }

    private createWidgetPayload(drilldownStack: Array<DrilldownStackElement>, currentWidget: DashboardWidget): DashboardWidget {

        const result: DashboardWidget = deepCopy(currentWidget);
        const mappedDrilldown: Array<DrilldownAttribute> = [];
        const drilldownEnd = drilldownStack.length - 1;
        for (let i = 0; i <= drilldownEnd; i++) {
            const drilldown = drilldownStack[i];

            if (drilldown.timeFilter) {
                result.querySettings.timeFilter = drilldown.timeFilter;
                result.querySettings.comparisonFilter = drilldown.comparisonFilter;
                mappedDrilldown.push({
                    value: undefined,
                    attributeCode: drilldown.attribute.code,
                    timeFilter: drilldown.timeFilter,
                    comparisonFilter: drilldown.comparisonFilter,
                    depth: mappedDrilldown.length,
                });
            }
            for (const drilldownFilter of drilldown.filters) {
                mappedDrilldown.push({
                    value: drilldownFilter.values[0], // Assuming there is only one value in the drilldown filter
                    attributeCode: drilldown.attribute.code,
                    timeFilter: undefined,
                    comparisonFilter: undefined,
                    depth: mappedDrilldown.length,
                });
            }

            // Add child of last element, as this is our current position in the drilldown
            if (i === drilldownEnd) {
                mappedDrilldown.push({
                    value: undefined,
                    timeFilter: undefined,
                    comparisonFilter: undefined,
                    attributeCode: drilldown.childAttribute.code,
                    depth: mappedDrilldown.length,
                });
            }
        }
        result.querySettings.drilldownAttributes = mappedDrilldown;
        return result;
    }


    private initChart(detail: DashboardGridWidgetDetail) {
        const chartConfig = new LineChartConfig();

        const isOriginalCurrency = detail.model.measure.modifiers.isOriginalCurrency;

        const unit = new MnbUnitInfo(detail.model.measure.unit.code, isOriginalCurrency,
            isOriginalCurrency ? QueryFilter.getCurrencyCode(detail.widget.querySettings.filters) || QueryFilter.getCurrencyCode(detail.dashboard.settings.filters) : null);

        chartConfig.value = {
            code: detail.model.measure.code,
            label: detail.model.measure.name,
            unit: unit
        };

        chartConfig.data = [new ChartDataArray(ChartDataType.primary, this.timeDateSpanPipe.transform({ from: new Date(detail.detail.fromDate), to: new Date(detail.detail.toDate) }, { shorten: true }), [])];
        if (detail.model.hasComparisonFilter) {
            const comparisonLabel = this.timeDateSpanPipe.transform({ from: new Date(detail.detail.comparison.comparisonPeriod.fromDate), to: new Date(detail.detail.comparison.comparisonPeriod.toDate) }, { shorten: true });
            chartConfig.data.push(new ChartDataArray(ChartDataType.primary, comparisonLabel, [], ChartDataComparisonType.comparison));
        } else {
            chartConfig.legendConfig = { hide: true };
        }

        let planMeasureCode = null;
        let planArray = null;

        let planName = detail.dashboard.settings.plan && detail.dashboard.settings.plan.name;

        if (detail.widget.querySettings.plan && detail.widget.querySettings.plan.name) {
            planName = detail.widget.querySettings.plan.name;
        }

        if (planName && detail.model.measure.planMeasure) {
            planMeasureCode = detail.model.measure.planMeasure.code;
            planArray = new ChartDataArray(ChartDataType.primary, planName, [], ChartDataComparisonType.plan);
            chartConfig.data.push(planArray);
            chartConfig.legendConfig = { hide: false };
        }

        this.data.detail.breakdown[detail.model.attribute.code].forEach((row: DashboardWidgetData) => {
            const attributeValue = row.attributes[detail.model.attribute.code];
            chartConfig.data[0].data.push({
                label: attributeValue,
                value: row.values[detail.model.measure.code]
            });
            if (detail.model.hasComparisonFilter) {
                chartConfig.data[1].data.push({
                    label: attributeValue,
                    value: row.comparison.comparisonPeriod.values[detail.model.measure.code]
                });
            }
            if (planMeasureCode && planArray) {
                planArray.data.push({
                    label: attributeValue,
                    value: row.values[planMeasureCode]
                });
            }
        });

        this.chartRenderer = this.chartService.createLineChartRenderer(chartConfig);
    }

    public onNavigateToQuery() {
        this.navigateToQuery.emit(this.data);
    }

}

export interface MnbDashboardsWidgetDetailHandler {

    loadTableWidget(widget: DashboardWidget): Promise<DashboardGridWidget>;

    loadDetail(widget: DashboardWidget, showMore?: boolean): Promise<DashboardGridWidgetDetail>;
}

type DrilldownStackElement = {
    timeFilter: QuerySettingsTimeFilter;
    comparisonFilter: QuerySettingsComparisonFilter;
    filters: QueryFilter[]
    value: string;
    attribute: ModelAttribute;
    childAttribute: ModelAttribute;
};
