import { MnbModelService, ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';
import {
    Component,
    Input,
    Output,
    OnInit,
    AfterViewInit,
    ElementRef,
    OnDestroy,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import {
    Report,
    ReportData,
    ReportSettings,
    TableSortingState,
    ReportExperienceGroupDataRow,
} from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { tap, map, takeUntil, distinctUntilChanged, withLatestFrom, skip } from 'rxjs/operators';
import { MediaService } from '@minubo-suite/shared/services/media/media.service';

// target width of columns to calculate visible column count
const MEASURE_COLUMN_WIDTH = 90;
const MEASURE_COLUMN_WIDTH_COMPARISON = 130;

@Component({
    selector: 'mnb-reports-experience-group-display',
    exportAs: 'displayComponent',
    templateUrl: './mnb-reports-experience-group-display.component.html',
})
export class MnbReportsExperienceGroupDisplayComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private modelService: MnbModelService, private mediaService: MediaService) { }

    private destroy$ = new Subject<void>();

    @ViewChild('tile', { static: false })
    private tileElement: ElementRef;

    @Input() set report(report: Report) {
        this.report$.next(report);
    }
    @Input() set viewSettings(viewSettings: ReportSettings) {
        this.viewSettings$.next(viewSettings);
    }
    @Input() set data(data: ReportData) {
        this.data$.next(data);
    }

    @Output() viewSettingsEmitter: EventEmitter<ReportSettings> = new EventEmitter();

    private report$ = new BehaviorSubject<Report>(null);
    private viewSettings$ = new BehaviorSubject<ReportSettings>(null);
    private data$ = new BehaviorSubject<ReportData>(null);

    private availableExperienceGroups$: Observable<string[]>;
    private selectedExperienceGroup$ = new BehaviorSubject<string>('');

    private breakdownAttribute$ = new BehaviorSubject<ModelAttribute>(null);
    private subBreakdownAttribute$ = new BehaviorSubject<ModelAttribute>(null);
    private availableMeasures$ = new BehaviorSubject<ModelMeasure[]>([]);
    private selectedMeasures$: Observable<ModelMeasure[]>;
    private dynamicTable: ScrollableTable;

    private hasComparison$: Observable<boolean>;
    private hasSubRows$: Observable<boolean>;
    public model$: Observable<ExperienceGroupDisplayModel>;

    async ngOnInit(): Promise<void> {

        this.availableExperienceGroups$ = this.data$.pipe(map(data => {
            return data ? data.experienceGroup.availableExperienceGroups : [];
        }));

        const subExpGrp = this.availableExperienceGroups$.pipe(takeUntil(this.destroy$)).subscribe(availableExperienceGroups => {
            // selectedExperienceGroup should only be set once
            if (availableExperienceGroups.length > 0) {
                this.selectedExperienceGroup$.next(availableExperienceGroups[0]);
                subExpGrp.unsubscribe();
            }
        });

        this.selectedExperienceGroup$.pipe(skip(1), takeUntil(this.destroy$)).subscribe((selectedExperienceGroup) => {
            const viewSettings = new ReportSettings();
            viewSettings.experienceGroup = {
                selectedExperienceGroup: selectedExperienceGroup,
            };
            this.viewSettingsEmitter.emit(viewSettings);
        });

        this.dynamicTable = new ScrollableTable(this.destroy$);

        this.selectedMeasures$ = combineLatest([this.viewSettings$, this.availableMeasures$]).pipe(
            map(([viewSettings, availableMeasures]) => {
                if (viewSettings && viewSettings.experienceGroup && viewSettings.experienceGroup.selectedMeasureCodes) {
                    const selectedMeasures: ModelMeasure[] = [];
                    availableMeasures.forEach(measure => {
                        if (viewSettings.experienceGroup.selectedMeasureCodes.includes(measure.code)) {
                            selectedMeasures.push(measure);
                        }
                    });
                    return selectedMeasures;
                }
                return [];
            })
        );

        this.selectedMeasures$
            .pipe(takeUntil(this.destroy$))
            .subscribe((measures) => this.dynamicTable.measures$.next(measures));

        combineLatest([this.report$, this.viewSettings$, this.selectedMeasures$]).pipe(
            // if no sorting is given, apply initial sorting
            tap(([report, viewSettings, selectedMeasures]) => {
                if (report && viewSettings && selectedMeasures) {
                    if (viewSettings.experienceGroup.sort && (viewSettings.experienceGroup.sort.measureCode == null || viewSettings.experienceGroup.selectedMeasureCodes.includes(viewSettings.experienceGroup.sort.measureCode))) {
                        return;
                    }

                    if (viewSettings && viewSettings.experienceGroup && viewSettings.experienceGroup.selectedMeasureCodes && viewSettings.experienceGroup.selectedMeasureCodes.length > 0) {
                        const newViewSettings = new ReportSettings();

                        if (report.settings.experienceGroup.sort && viewSettings.experienceGroup.selectedMeasureCodes.includes(report.settings.experienceGroup.sort.measureCode)) {
                            newViewSettings.experienceGroup = {
                                sort: {
                                    measureCode: report.settings.experienceGroup.sort.measureCode,
                                    directionCode: report.settings.experienceGroup.sort.directionCode,
                                },
                            };
                            this.viewSettingsEmitter.emit(newViewSettings);
                            return;
                        }

                        newViewSettings.experienceGroup = {
                            sort: {
                                measureCode: viewSettings.experienceGroup.selectedMeasureCodes[0],
                                directionCode: 'DESC',
                            },
                        };
                        this.viewSettingsEmitter.emit(newViewSettings);
                    }
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();

        this.viewSettings$.pipe(takeUntil(this.destroy$)).subscribe((viewSettings) => {
            if (viewSettings.experienceGroup.sort) {
                this.dynamicTable.tableSortingState$.next(viewSettings.experienceGroup.sort);
            }
        });

        this.dynamicTable.updateViewSettingsSubject
            .pipe(withLatestFrom(this.dynamicTable.tableSortingState$), takeUntil(this.destroy$))
            .subscribe(([_, tableSortingState]) => {
                const viewSettings = new ReportSettings();
                viewSettings.experienceGroup = {
                    sort: tableSortingState,
                };
                this.viewSettingsEmitter.emit(viewSettings);
            });

        this.report$.pipe(takeUntil(this.destroy$)).subscribe((report) => {
            if (!report) {
                return;
            }
            this.modelService
                .getAttribute(report.settings.experienceGroup.breakdownAttributeCode)
                .then((result) => this.breakdownAttribute$.next(result));

            if (!!report.settings.experienceGroup.subBreakdownAttributeCode) {
                this.modelService
                    .getAttribute(report.settings.experienceGroup.subBreakdownAttributeCode)
                    .then((result) => this.subBreakdownAttribute$.next(result));
            }

            const measurePromises: Promise<ModelMeasure>[] = report.settings.experienceGroup.measureCodes.map(
                (measureCode) => {
                    return this.modelService.getMeasure(measureCode);
                }
            );
            Promise.all(measurePromises).then((result) => this.availableMeasures$.next(result));
        });

        this.hasComparison$ = this.viewSettings$.pipe(
            map(viewSettings => !!viewSettings.experienceGroup.comparisonFilter)
        );

        this.hasSubRows$ = this.data$.pipe(
            map(data => {
                if (data && data.experienceGroup) {
                    return Object.keys(data.experienceGroup.subRowsMap).length > 0;
                } else {
                    return false;
                }
            }
        ));

        this.hasComparison$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((hasComparison) => {
            if (hasComparison) {
                this.dynamicTable.measureColumnWidth$.next(MEASURE_COLUMN_WIDTH_COMPARISON);
            } else {
                this.dynamicTable.measureColumnWidth$.next(MEASURE_COLUMN_WIDTH);
            }
        });

        this.model$ = combineLatest([
            this.breakdownAttribute$,
            this.subBreakdownAttribute$,
            this.data$,
            this.hasComparison$,
            this.hasSubRows$,
            this.dynamicTable.tableModel$,
        ]).pipe(
            map(([breakdownAttribute, subBreakdownAttribute, data, hasComparison, hasSubRows, tableModel]) => {
                if (!data) {
                    return {
                        restricted: true,
                    };
                }
                return {
                    restricted: false,
                    hasComparison: hasComparison,
                    hasSubRows: hasSubRows,
                    breakdownAttribute: breakdownAttribute,
                    subBreakdownAttribute: subBreakdownAttribute,
                    visibleMeasures: tableModel.visibleMeasures,
                    visibleMeasuresModel: tableModel.visibleMeasuresModel,
                    dynamicTable: this.dynamicTable,
                    rows: data.experienceGroup.rows,
                    subRowsMap: data.experienceGroup.subRowsMap,
                };
            })
        );
    }

    ngAfterViewInit(): void {
        this.mediaService.viewSize$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            const tableWidth = this.tileElement.nativeElement.offsetWidth;
            if (tableWidth > 0) {
                this.dynamicTable.tableWidth$.next(this.tileElement.nativeElement.offsetWidth);
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

export type ExperienceGroupDisplayModel = {
    restricted?: boolean;
    hasComparison?: boolean;
    hasSubRows?: boolean;
    breakdownAttribute?: ModelAttribute;
    subBreakdownAttribute?: ModelAttribute;
    visibleMeasures?: ModelMeasure[];
    visibleMeasuresModel?: MeasureState[];
    dynamicTable?: ScrollableTable;
    rows?: ReportExperienceGroupDataRow[];
    subRowsMap?: {[breakdownAttributeValue: string]: ReportExperienceGroupDataRow[]};
};

export class ScrollableTable {
    // viewsettings
    public tableSortingState$: BehaviorSubject<TableSortingState> = new BehaviorSubject({
        measureCode: null,
        directionCode: 'DESC',
    });

    public updateViewSettingsSubject = new Subject<void>();

    // other states
    public measures$ = new BehaviorSubject<ModelMeasure[]>([]);
    public tableWidth$ = new BehaviorSubject<number>(1000);
    public attributeColumnTotalWidth$ = new BehaviorSubject<number>(260);
    public measureColumnWidth$ = new BehaviorSubject<number>(130);

    public scrollIndex$ = new BehaviorSubject<number>(0);
    private visibleMeasuresCount$ = new BehaviorSubject<number>(1);
    private maxScrollIndex$ = new BehaviorSubject<number>(0);
    private isScrollable$ = new BehaviorSubject<boolean>(false);
    private measuresModel$: Observable<MeasureState[]>;

    public tableModel$: Observable<TableModel>;

    constructor(private destroy$: Subject<void>) {
        this.measuresModel$ = combineLatest([
            this.tableSortingState$,
            this.measures$,
            this.isScrollable$,
            this.scrollIndex$,
            this.maxScrollIndex$,
            this.visibleMeasuresCount$,
        ]).pipe(
            map(([sortingModel, measures, isScrollable, scrollIndex, maxScrollIndex, visibleMeasuresCount]) =>
                measures.map((measure, index) => {
                    const name = measure.name;
                    const code = measure.code;
                    let left: HorArrowState, right: HorArrowState;

                    // get left
                    if (isScrollable && scrollIndex === index) {
                        left = scrollIndex === 0 ? 'OFF' : 'ON';
                    } else {
                        left = null;
                    }

                    // get right
                    if (isScrollable && scrollIndex + visibleMeasuresCount === index + 1) {
                        right = scrollIndex === maxScrollIndex ? 'OFF' : 'ON';
                    } else {
                        right = null;
                    }

                    // get mid
                    let mid: VerArrowState;
                    if (sortingModel.measureCode === measure.code) {
                        mid = sortingModel.directionCode === 'ASC' ? 'ASC' : 'DESC';
                    } else {
                        mid = null;
                    }
                    return { name, code, left, mid, right };
                })
            )
        );

        this.tableModel$ = combineLatest([this.measures$, this.measuresModel$, this.tableSortingState$]).pipe(
            map(([measures, measuresModel, tableSortingState]) => {
                return {
                    visibleMeasures: this.getVisible(measures),
                    visibleMeasuresModel: this.getVisible(measuresModel),
                    tableSortingState: tableSortingState,
                };
            })
        );

        combineLatest([this.tableWidth$, this.measureColumnWidth$, this.attributeColumnTotalWidth$]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            ([tableWidth, measureColumnWidth, attributeColumnTotalWidth]) => {
                const visibleMeasuresCount = Math.max(1, Math.floor((tableWidth - attributeColumnTotalWidth) / measureColumnWidth));
                this.visibleMeasuresCount$.next(visibleMeasuresCount);
            }
        );

        combineLatest([this.measures$, this.visibleMeasuresCount$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([measures, visibleMeasuresCount]) => {
                this.isScrollable$.next(visibleMeasuresCount < measures.length);
                const maxScrollIndex = Math.max(0, measures.length - visibleMeasuresCount);
                this.maxScrollIndex$.next(maxScrollIndex);
            });

        combineLatest([this.scrollIndex$, this.maxScrollIndex$])
            .pipe(takeUntil(this.destroy$))
            .subscribe(([scrollIndex, maxScrollIndex]) => {
                const newScrollIndex = this.validateScrollIndex(scrollIndex, maxScrollIndex);
                if (newScrollIndex !== scrollIndex) {
                    this.scrollIndex$.next(newScrollIndex);
                }
            });
    }

    public getVisible<T>(inArray: T[]): T[] {
        // returns the 'visible' slice of an array based on the table scroll position
        const scrollIndex = this.scrollIndex$.getValue();
        const visibleMeasuresCount = this.visibleMeasuresCount$.getValue();
        return inArray.slice(scrollIndex, scrollIndex + visibleMeasuresCount);
    }

    public toggleMeasureSort(measureCode: string) {
        const oldSortingModel = this.tableSortingState$.getValue();
        if (oldSortingModel.measureCode === measureCode) {
            if (oldSortingModel.directionCode === 'DESC') {
                // sort state DESC -> ASC
                this.tableSortingState$.next({
                    measureCode: measureCode,
                    directionCode: 'ASC',
                });
            } else if (oldSortingModel.directionCode === 'ASC') {
                // sort state ASC -> no-sorting
                this.tableSortingState$.next({
                    measureCode: null,
                    directionCode: 'DESC',
                });
            }
        } else {
            // sort state no-sorting -> DESC
            this.tableSortingState$.next({
                measureCode: measureCode,
                directionCode: 'DESC',
            });
        }
        this.updateViewSettingsSubject.next();
    }

    public scrollLeft() {
        this.scrollIndex$.next(this.scrollIndex$.getValue() - 1);
    }

    public scrollRight() {
        this.scrollIndex$.next(this.scrollIndex$.getValue() + 1);
    }

    private validateScrollIndex(scrollIndex: number, maxScrollIndex: number): number {
        // returns closest valid scrollIndex
        scrollIndex = Math.max(0, scrollIndex);
        scrollIndex = Math.min(maxScrollIndex, scrollIndex);
        return scrollIndex;
    }
}

export type TableModel = {
    visibleMeasures: ModelMeasure[];
    visibleMeasuresModel: MeasureState[];
    tableSortingState: TableSortingState;
};

export type HorArrowState = 'ON' | 'OFF' | null;
export type VerArrowState = 'DESC' | 'ASC';
export type MeasureState = {
    name: string;
    code: string;
    left: HorArrowState;
    mid: VerArrowState;
    right: HorArrowState;
};
