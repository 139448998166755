import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'mnbSafeHtml' })
export class MnbSafeHtmlPipe implements PipeTransform {

    public dayList = [];
    public dayOfMonthList = [];

    constructor(private sanitizer: DomSanitizer) {

    }

    transform(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

}