import { Injectable } from '@angular/core';
import { PortalEntity } from '@minubo-portal/modules/api/models/api-portal.model';
import { ApiDashboardService } from '@minubo-portal/modules/api/services/api-dashboard.service';
import { ApiPortalService } from '@minubo-portal/modules/api/services/api-portal.service';
import { DashboardWidgetDetailModel } from '@minubo-suite/analytics-area/services/dashboard/dashboard-service.models';
import { DashboardGridBuilder, DashboardGridWidget, DashboardGridWidgetDataLoader } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { Dashboard, DashboardWidget } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardsDashboardService {


    constructor(
        private apiPortalService: ApiPortalService,
        private apiDashboardService: ApiDashboardService,
        private modelService: MnbModelService
        ) {
    }

    public getEntity(id: number): Observable<PortalEntity> {
        return this.apiPortalService.loadEntity(EntityType.DASHBOARD, id);
    }

    public getDashboard(id: number): Promise<Dashboard> {
        return this.apiDashboardService.loadDashboard(id).toPromise().then(dashboard => <Dashboard> dashboard);
    }

    public loadWidget(gridWidget: DashboardGridWidget, widget: DashboardWidget, dashboard: Dashboard, withoutData?: boolean, forDetail?: boolean): Promise<DashboardGridWidget> {
        return DashboardGridBuilder.loadWidget(this.buildLoader(), gridWidget, widget, dashboard, withoutData, forDetail);
    }

    public loadDetail(widget: DashboardWidget, dashboard: Dashboard, showMore: boolean): Promise<DashboardWidgetDetailModel> {

        return DashboardGridBuilder.loadDetail(this.buildLoader(), widget, dashboard, showMore);
    }

    private buildLoader() {
        const loader: DashboardGridWidgetDataLoader = {
            loadData: (widget: DashboardWidget) => {
                return this.apiDashboardService.loadWidgetData(widget.dashboard.id, widget.id, widget.dashboard.settings).toPromise().then(data => {
                    return data;
                });
            },
            loadDetail: (widget: DashboardWidget, showMore?: boolean) => {
                const params: {showMore?: boolean} = {};
                if (showMore) {
                    params.showMore = showMore;
                }
                return this.apiDashboardService.loadWidgetDetailData(widget.dashboard.id, widget.id, {
                    widgetViewSettings: widget.querySettings,
                    dashboardViewSettings: widget.dashboard.settings,
                }, params).toPromise().then(data => {
                    return data;
                });
            },
            loadMeasure: (code: string) => {
                return this.modelService.getMeasure(code);
            },
            loadAttribute: (code: string) => {
                return this.modelService.getAttribute(code);
            },
            loadAttributes: () => {
                return this.modelService.getAttributes();
            }
        };
        return loader;
    }

    public buildGrid(dashboard: Dashboard, maxWidthArg: number, rows: number) {
        return DashboardGridBuilder.buildGrid(dashboard, this.buildLoader(), maxWidthArg, rows);
    }

}
