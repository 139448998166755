import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { isNullOrUndefined } from 'util';
import { Dashboard, DashboardWidget, DashboardWidgetData, DashboardWidgetDetail, DashboardWidgetPosition } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';

export class DashboardGridBuilder {

    public static buildGrid(dashboard: Dashboard, loader: DashboardGridWidgetDataLoader, maxWidthArg?: number, rows?: number) {
        // dashboard has a fixed number of rows except an old dashboard has more than 60 rows
        const dashboardRows = rows ? rows : DashboardGrid.MAX_ROWS;
        const maxWidth = maxWidthArg || 12;
        const shrink = maxWidth < 12;

        const grid = new DashboardGrid(maxWidth);
        grid.cells = [];

        for (let y = 0; y < (shrink ? 1 : dashboardRows); y++) {
            for (let x = 0; x < maxWidth; x++) {
                grid.cells.push(new DashboardGridCell(x, y));
            }
        }

        dashboard.widgets.sort((w1, w2) => {
            const i1 = w1.visualizationSettings.position.y * grid.width + w1.visualizationSettings.position.x;
            const i2 = w2.visualizationSettings.position.y * grid.width + w2.visualizationSettings.position.x;
            return i1 - i2;
        });

        let nextFreeY = 0;

        dashboard.widgets.forEach(widget => {
            const height = grid.cells.length / grid.width;

            if (shrink) {
                // recalc size
                widget.visualizationSettings.position.x = 0;
                widget.visualizationSettings.position.y = nextFreeY;

                let factor = grid.width / widget.visualizationSettings.position.width;
                if (DashboardWidget.isTableWidget(widget) || DashboardWidget.isListWidget(widget) || DashboardWidget.isBarChartWidget(widget)) {
                    factor = Math.max(factor, 1);
                }
                widget.visualizationSettings.position.height = Math.max(4, Math.ceil(widget.visualizationSettings.position.height * factor));

                nextFreeY += widget.visualizationSettings.position.height;
                widget.visualizationSettings.position.width = grid.width;
            }

            const neededHeight = widget.visualizationSettings.position.y + widget.visualizationSettings.position.height;

            for (let y = height; y < neededHeight; y++) {
                for (let x = 0; x < grid.width; x++) {
                    grid.cells.push(new DashboardGridCell(x, y));
                }
            }

            const cell = grid.getCell(widget.visualizationSettings.position.x, widget.visualizationSettings.position.y);
            if (cell) {
                // TODO: Say grid widget that it was shrunk (if so) and make sure that this means it can't be edited
                cell.widget = new DashboardGridWidget();

                DashboardGridBuilder.loadWidget(loader, cell.widget, widget, dashboard);
            }

        });

        grid.updateAvailableCellsForNewWidgets();

        return grid;
    }

    public static buildWidgetGrid(gridWidget: DashboardGridWidget) {
        const grid = new DashboardGrid();
        grid.cells = [];
        for (let y = 0; y < gridWidget.widget.visualizationSettings.position.height; y++) {
            for (let x = 0; x < 12; x++) {
                grid.cells.push(new DashboardGridCell(x, y));
            }
        }
        grid.getCell(0, 0).widget = gridWidget;
        return grid;
    }

    public static loadWidget(loader: DashboardGridWidgetDataLoader, gridWidget: DashboardGridWidget, widget: DashboardWidget, dashboard: Dashboard, withoutData?: boolean, forDetail?: boolean): Promise<DashboardGridWidget> {

        const widgetCopy: DashboardWidget = deepCopy(widget);

        widgetCopy.dashboard = deepCopy(dashboard);
        delete widgetCopy.dashboard.widgets;

        gridWidget.dashboard = dashboard;
        gridWidget.widget = widget;
        gridWidget.data = null;
        gridWidget.model = null;
        gridWidget.dataModelPromise = null;
        gridWidget.emitLoadStart();

        const promises = new Array<Promise<any>>();

        if (DashboardWidget.isTextWidget(widgetCopy)) {
            return Promise.resolve(null).then(() => {
                gridWidget.dataModelPromise = Promise.resolve();
                gridWidget.emitLoadEnd();
                return gridWidget;
            });
        } else if (DashboardWidget.isTableWidget(widgetCopy)) {
            const filterEmptyFields = function (field) {
                return field.code && field.code !== '';
            };
            widget.querySettings.measures = widget.querySettings.measures.filter(filterEmptyFields);
            widget.querySettings.attributes = widget.querySettings.attributes.filter(filterEmptyFields);

            // Overwriting attributes with drilldown, using last entry in drilldownAttributes as the sole widget attribute
            if (widgetCopy.querySettings.drilldownAttributes != null && widgetCopy.querySettings.drilldownAttributes.length > 0) {
                widget.querySettings.attributes = [{code: widgetCopy.querySettings.drilldownAttributes[widgetCopy.querySettings.drilldownAttributes.length - 1].attributeCode}];
            }

            widget.querySettings.measures.forEach(measure => {
                promises.push(loader.loadMeasure(measure.code));
            });

            widget.querySettings.attributes.forEach(attribute => {
                promises.push(loader.loadAttribute(attribute.code));
            });

            if (!withoutData) {
                if (forDetail) {
                    promises.push(loader.loadDetail(widgetCopy).then(data => {
                        gridWidget.data = data;
                    }));
                } else {
                    promises.push(loader.loadData(widgetCopy).then(data => {
                        gridWidget.data = data;
                    }));
                }
            }

            return Promise.all(promises).then(data => {
                gridWidget.model = {
                    measures: data.slice(0, widget.querySettings.measures.length),
                    attributes: data.slice(widget.querySettings.measures.length,
                        widget.querySettings.measures.length +
                        widget.querySettings.attributes.length)
                };
                gridWidget.dataModelPromise = Promise.resolve();

                const gridAttr = gridWidget.model.attributes[0];
                if (!!gridAttr.hierarchies
                    && !!gridAttr.hierarchies.length
                    && gridAttr.hierarchies.find((h) => h.childLevels.length !== 0)) {

                    loader.loadAttributes().then(availableAttributes => {
                        const childAttributes = new Array<ModelAttribute>();
                        for (const hierarchy of gridAttr.hierarchies) {
                            for (let idx = 0; idx < hierarchy.childLevels.length; ++idx) {
                                const childAttr = availableAttributes.find(attr => hierarchy.childLevels[idx] === attr.code);
                                if (childAttr && !childAttributes.includes(childAttr)) {
                                    childAttributes.push(childAttr);
                                    break;
                                }
                            }
                        }
                        gridWidget.model.childAttributes = childAttributes;

                    }).catch(err => {
                        return Promise.reject(err);
                    });
                }
                gridWidget.emitLoadEnd();
                return gridWidget;
            }).catch(error => {
                gridWidget.emitLoadEnd();
                gridWidget.dataModelPromise = Promise.resolve();
                return Promise.reject(error);
            });
        } else {
            if (!withoutData) {
                promises.push(loader.loadData(widgetCopy).then(data => {
                    gridWidget.data = data;
                }).catch(_ => {
                    gridWidget.data = null;
                    return Promise.resolve(null); // I'm not 100% sure what the call-chain here is exactly, but Promise.reject wouldn't lead to the error message being displayed
                }));
            } else {
                promises.push(Promise.resolve(null));
            }

            if (widget.querySettings.measureCode) {
                promises.push(loader.loadMeasure(widget.querySettings.measureCode));
            } else {
                promises.push(Promise.resolve(null));
            }

            if (widget.querySettings.additionalMeasureCode) {
                promises.push(loader.loadMeasure(widget.querySettings.additionalMeasureCode));
            } else {
                promises.push(Promise.resolve(null));
            }

            if (widget.visualizationSettings.typeCode !== 'SingleValue' && widget.visualizationSettings.typeCode !== 'GaugeChart') {
                promises.push(loader.loadAttribute(widget.querySettings.attributeCode));
                if (widget.querySettings.breakdownAttributeCode) {
                    promises.push(loader.loadAttribute(widget.querySettings.breakdownAttributeCode));
                }
            }


            return Promise.all(promises).then(model => {
                gridWidget.model = { measure: null, attribute: null };

                gridWidget.model.measure = model[1];
                gridWidget.model.additionalMeasure = model[2];
                if (widget.visualizationSettings.typeCode !== 'SingleValue' && widget.visualizationSettings.typeCode !== 'GaugeChart') {
                    gridWidget.model.attribute = model[3];
                    if (widget.querySettings.breakdownAttributeCode) {
                        gridWidget.model.breakdownAttribute = model[4];
                    }
                }
                gridWidget.dataModelPromise = Promise.resolve();
                gridWidget.emitLoadEnd();

                return gridWidget;
            }).catch(error => {
                gridWidget.emitLoadEnd();
                gridWidget.dataModelPromise = Promise.resolve();
                return Promise.reject(error);
            });
        }
    }

    public static loadDetail(loader: DashboardGridWidgetDataLoader, widget: DashboardWidget, dashboard: Dashboard, showMore: boolean): Promise<DashboardGridWidgetDetail> {
        const detail = new DashboardGridWidgetDetail();
        const widgetCopy: DashboardWidget = deepCopy(widget);
        widgetCopy.dashboard = deepCopy(dashboard);
        delete widgetCopy.dashboard.widgets;

        detail.dashboard = dashboard;
        detail.widget = widget;

        if (DashboardWidget.isTableWidget(widgetCopy)) {
            return null;
        } else {

            return loader.loadDetail(widgetCopy, showMore).then(data => {
                detail.detail = data;

                const promises = new Array<Promise<any>>();
                const model = new DashboardGridWidgetDetailModel();
                model.assistMeasures = new Array<ModelMeasure>();
                model.hasComparisonFilter = !isNullOrUndefined(dashboard.settings.comparisonFilter);
                if (widgetCopy.querySettings.timeFilter) {
                    model.hasComparisonFilter = !isNullOrUndefined(widgetCopy.querySettings.comparisonFilter);
                }

                model.planName = !isNullOrUndefined(dashboard.settings.plan) ? dashboard.settings.plan.name : null;

                if (widgetCopy.querySettings.timeFilter) {
                    model.planName = (!isNullOrUndefined(widgetCopy.querySettings.plan) ? widgetCopy.querySettings.plan.name : null);
                }

                if (model.planName) {
                    model.hasPlan = true;
                }

                promises.push(loader.loadMeasure(widget.querySettings.measureCode).then(measure => model.measure = measure));

                promises.push(loader.loadAttribute(data.detailAttributeCode).then(async attribute => {

                    model.attribute = attribute;

                    if (attribute.hierarchies && attribute.hierarchies.length && attribute.hierarchies.find((h) => h.childLevels.length !== 0)) {

                        const availableAttributes = await loader.loadAttributes();
                        const childAttributes = new Array<ModelAttribute>();
                        attribute.hierarchies.forEach(hierarchy => {
                            for (let i = 0; i < hierarchy.childLevels.length; ++i) {
                                const childAttribute = availableAttributes.find((attr) => hierarchy.childLevels[i] === attr.code);
                                if (childAttribute) {
                                    if (childAttributes.indexOf(childAttribute) === -1) {
                                        childAttributes.push(childAttribute);
                                    }
                                    break;
                                }
                            }
                        });
                        model.childAttributes = childAttributes;
                    }
                }));

                if (widget.querySettings.breakdownAttributeCode) {

                    promises.push(loader.loadAttribute(widget.querySettings.breakdownAttributeCode).then(attribute => {

                        model.breakdownAttribute = attribute;
                    }));
                }

                if (widgetCopy.querySettings.additionalMeasureCode) {
                    promises.push(loader.loadMeasure(widgetCopy.querySettings.additionalMeasureCode).then(measure => {
                        model.additionalMeasure = measure;
                    }));
                } else {
                    data.detailAssistMeasureCodes.forEach((measureCode, i) => {
                        model.assistMeasures.push(null);
                        promises.push(loader.loadMeasure(measureCode).then(measure => model.assistMeasures[i] = measure));
                    });
                }

                return Promise.all(promises).then(() => {
                    detail.model = model;
                    return detail;
                });

            });
        }
    }
}

export class DashboardGrid {

    public static readonly MAX_ROWS = 60;

    cells: Array<DashboardGridCell>;
    isNewWidgetAllowed: boolean;

    constructor(public width?: number) {
        this.width = this.width || 12;
    }

    public static findCellForWidget(grid: DashboardGrid, widgets: Array<DashboardGridWidget>, position: DashboardWidgetPosition): DashboardGridCell {
        for (let row = grid.height - 2; row > -1; row--) {
            for (let col = 0; col < grid.width; col++) {
                const newCell = grid.getCell(col, row);
                // check if the widget can stay where it was
                if (this.hasRoomForWidget(grid, widgets, newCell, position)) {
                    return newCell;
                }
            }
        }
        return null;
    }

    public static hasRoomForWidget(grid: DashboardGrid, widgets: Array<DashboardGridWidget>, cell: DashboardGridCell, position: DashboardWidgetPosition): boolean {

        const newWidgetPosition = { x: cell.x, y: cell.y, width: position.width, height: position.height };
        if (newWidgetPosition.x + newWidgetPosition.width > grid.width) {
            return false;
        }
        if (newWidgetPosition.y + newWidgetPosition.height > grid.height) {
            return false;
        }

        return isNullOrUndefined(widgets.find(otherWidget => {
            const widgetPosition = otherWidget.widget.visualizationSettings.position;
            return DashboardWidgetPosition.areOverlapping(widgetPosition, newWidgetPosition);
        }));
    }

    public getCell(x: number, y: number): DashboardGridCell {
        const idx = y * this.width + x;
        return this.cells[idx];
    }

    public getCellArea(startCol: number, startRow: number, endCol: number, endRow: number) {
        const cells = [];
        for (let col = startCol; col <= endCol; col++) {
            for (let row = startRow; row <= endRow; row++) {
                cells.push(this.getCell(col, row));
            }
        }
        return cells;
    }

    get height(): number {
        return (this.cells.length / this.width);
    }

    public isRowEmpty(row: number): boolean {
        return isNullOrUndefined(this.cells.find(cell => {
            if (!cell.widget) {
                return false;
            }
            const position = cell.widget.widget.visualizationSettings.position;
            return position.y <= row && position.y + position.height > row;
        }));

    }

    public getWidgetsInRows(startRow: number, endRow?: number): Array<DashboardGridWidget> {

        return this.cells.slice(startRow * this.width, isNullOrUndefined(endRow) ? this.cells.length : endRow * this.width)
            .filter((cell) => cell.widget)
            .map(cell => cell.widget);
    }

    public getDashboardWidgets(): Array<DashboardGridWidget> {
        const dashboardWidgets: Array<DashboardGridWidget> = [];
        this.cells.forEach((cell) => {
            if (cell.widget) {
                dashboardWidgets.push(cell.widget);
            }
        });
        return dashboardWidgets;
    }


    public updateAvailableCellsForNewWidgets() {
        this.isNewWidgetAllowed = true;
        // Initialize every cell as free
        this.cells.forEach((cell: DashboardGridCell) => {
            cell.isFreeForNewWidget = true;
        });

        const gridHeight = this.height;
        this.cells.forEach((cell: DashboardGridCell) => {
            // if this cell is at the right or bottom edge it is not available
            if (cell.x === (this.width - 1) || cell.y === (gridHeight - 1)) {
                cell.isFreeForNewWidget = false;
            }

            // if this cell is the origin point of a widget...
            if (cell.widget) {
                const position = cell.widget.widget.visualizationSettings.position;
                // set all cells covered by the widget and within 2x2 range to the left and top to be unavailable
                for (let x = -1; x < position.width; x++) {
                    for (let y = -1; y < position.height; y++) {
                        const widgetY = position.y + y;
                        const widgetX = position.x + x;
                        const usedCell = this.getCell(widgetX, widgetY);
                        if (usedCell) {
                            usedCell.isFreeForNewWidget = false;
                        }
                    }
                }
            }

        });

        // find out if there is still space for one small widget
        if (!this.cells.find(cell => cell.isFreeForNewWidget)) {
            // if not, don't allow new widgets
            this.isNewWidgetAllowed = false;
        }
    }
}

export class DashboardGridCell {
    public widget: DashboardGridWidget;
    public widgetMoved: boolean;
    public previewWidget: DashboardGridPreviewWidget;

    public isFreeForNewWidget: boolean;

    isUsedByWidget: boolean;

    constructor(public x: number, public y: number) { }
}

export class DashboardGridPreviewWidget {
    constructor(public widget: DashboardWidget) { }
}

export class DashboardGridWidget {
    private onLoad = new EventEmitter();
    private onData = new EventEmitter();

    public widget: DashboardWidget;
    public dashboard: Dashboard;
    public data: DashboardWidgetData;
    public model: DashboardGridWidgetModel;

    public alert: {};

    // indicates if data and model is updated and in sync with widgetsettings
    public dataModelPromise: Promise<void>;

    constructor() { }

    public subscribe(onLoad: Function, onData: Function): {
        load: Subscription,
        data: Subscription
    } {
        const load: Subscription = this.onLoad.subscribe(onLoad);
        const data: Subscription = this.onData.subscribe(onData);

        return {
            load: load,
            data: data
        };
    }

    public unsubscribe() {
        this.onLoad.unsubscribe();
        this.onData.unsubscribe();
    }

    public emitLoadStart() {
        this.onLoad.emit();
    }

    public emitLoadEnd() {
        this.onData.emit();
    }
}

export class DashbordGridWidgetSize {
    constructor(public width: number, public height: number) {
        this.width = width;
        this.height = height;
    }

    public static getMinSize(widget: DashboardWidget): DashbordGridWidgetSize {
        if (DashboardWidget.isTextWidget(widget)) {
            return new DashbordGridWidgetSize(2, 1);
        } else if (DashboardWidget.isTableWidget(widget)) {
            return DashbordGridWidgetSize.getTableWidgetMinSize(widget);
        } else {
            return new DashbordGridWidgetSize(2, 2);
        }
    }

    public static getRecommendedSize(widget: DashboardWidget): DashbordGridWidgetSize {
        if (DashboardWidget.isTextWidget(widget)) {
            return new DashbordGridWidgetSize(2, 2);
        } else if (DashboardWidget.isTableWidget(widget)) {
            return DashbordGridWidgetSize.getTableWidgetMinSize(widget);
        } else {
            const isNoneBreakdownMode: boolean = isNullOrUndefined(widget.visualizationSettings.breakdownMode);
            const typeCode = widget.visualizationSettings.typeCode;

            if (widget.visualizationSettings.typeCode === 'SingleValue') {
                return new DashbordGridWidgetSize(2, 2);
            } else if (isNoneBreakdownMode && typeCode !== 'List') {
                return new DashbordGridWidgetSize(3, 3);
            } else if (typeCode === 'ColumnChart' || typeCode === 'LineChart') {
                return new DashbordGridWidgetSize(4, 3);
            } else if (typeCode === 'BarChart') {
                return new DashbordGridWidgetSize(3, 4);
            }

            return new DashbordGridWidgetSize(2, 2);
        }
    }

    private static getTableWidgetMinSize(widget: DashboardWidget): DashbordGridWidgetSize {
        const size = new DashbordGridWidgetSize(2, 2);

        size.width = widget.querySettings.attributes.length * 2;

        if (widget.visualizationSettings.measures) {
            widget.visualizationSettings.measures.forEach(visualizationSettingsForMeasure => {
                size.width += visualizationSettingsForMeasure.showValue ? 1 : 0;
                size.width += visualizationSettingsForMeasure.showComparisonValue ? 1 : 0;
                size.width += visualizationSettingsForMeasure.showChange ? 1.5 : 0;
                size.width += visualizationSettingsForMeasure.showPlanValue ? 1 : 0;
                size.width += visualizationSettingsForMeasure.showPlanDeviation ? 1.5 : 0;
            });
        }
        size.width = Math.ceil(size.width);
        size.width = Math.min(12, size.width);

        return size;
    }
}


export class DashboardGridWidgetModel {
    measure?: ModelMeasure;
    additionalMeasure?: ModelMeasure;
    attribute?: ModelAttribute;
    breakdownAttribute?: ModelAttribute;
    measures?: Array<ModelMeasure>;
    attributes?: Array<ModelAttribute>;
    childAttributes?: Array<ModelAttribute>;
}


export class DashboardGridWidgetDetail {

    public widget: DashboardWidget;
    public dashboard: Dashboard;
    public detail: DashboardWidgetDetail;
    public model: DashboardGridWidgetDetailModel;

    constructor() { }

}

export class DashboardGridWidgetDetailModel {

    public measure: ModelMeasure;
    public attribute: ModelAttribute;
    public childAttributes: Array<ModelAttribute>;
    public assistMeasures: Array<ModelMeasure>;
    public additionalMeasure?: ModelMeasure;
    public breakdownAttribute?: ModelAttribute;
    public hasComparisonFilter: boolean;
    public hasPlan: boolean;
    public planName: string;

    constructor() { }


}

export interface DashboardGridWidgetDataLoader {
    loadData: (widget: DashboardWidget) => Promise<DashboardWidgetData>;
    loadDetail: (widget: DashboardWidget, showMore?: boolean) => Promise<DashboardWidgetDetail>;

    loadMeasure: (code: string) => Promise<ModelMeasure>;
    loadAttribute: (code: string) => Promise<ModelAttribute>;

    loadAttributes: () => Promise<ModelAttribute[]>;
}
