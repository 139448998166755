import { Component, Input } from '@angular/core';
import { DashboardWidget } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';

@Component({
    selector: 'mnb-dashboard-widget-data-infotext',
    template: `
    <p class="tile-infotext" *ngIf="widget.visualizationSettings.typeCode === 'SingleValue' || widget.visualizationSettings.typeCode === 'GaugeChart'">
        {{ widget.querySettings.measureCode | mnbModelName:'MEASURE' | async }}
    </p>
    <p class="tile-infotext" *ngIf="widget.visualizationSettings.typeCode !== 'SingleValue' && widget.visualizationSettings.typeCode !== 'GaugeChart' && widget.visualizationSettings.typeCode !== 'Table'">
        {{ widget.querySettings.attributeCode | mnbModelName:'ATTRIBUTE' | async }},
        <span *ngIf="widget.querySettings.breakdownAttributeCode">{{ widget.querySettings.breakdownAttributeCode | mnbModelName:'ATTRIBUTE' | async }},</span>
        {{ widget.querySettings.measureCode | mnbModelName:'MEASURE' | async }}<span *ngIf="widget.querySettings.additionalMeasureCode">, {{ widget.querySettings.additionalMeasureCode | mnbModelName:'MEASURE' | async }}</span>
    </p>
    <p class="tile-infotext" *ngIf="widget.visualizationSettings.typeCode === 'Table'">
        <span *ngFor="let attribute of widget.querySettings.attributes | slice: 0:3; let i = index;">
            {{ i > 0 ? ',' : ''}} {{attribute.code | mnbModelName:'ATTRIBUTE' | async }}
        </span>
        <span *ngFor="let measure of widget.querySettings.measures | slice: 0:(3 - (widget.querySettings.attributes.length < 3 ? widget.querySettings.attributes.length : 3)); let i = index;">
            {{ i + widget.querySettings.attributes.length > 0 ? ',' : ''}} {{ measure.code | mnbModelName:'MEASURE' | async }}
        </span>
        <span *ngIf="widget.querySettings.attributes.length + widget.querySettings.measures.length > 3">
            ...
        </span>
    </p>
    `
})

export class MnbDashboardWidgetDataInfotextComponent {

    @Input() widget: DashboardWidget;


}
