import { Injectable } from '@angular/core';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService  {

    private static readonly DATE_TRANSLATIONS = [
        { deu: 'Mrz', eng: 'Mar' },
        { deu: 'Mai', eng: 'May' },
        { deu: 'Okt', eng: 'Oct' },
        { deu: 'Dez', eng: 'Dec' },
        { deu: 'Januar', eng: 'January' },
        { deu: 'Februar', eng: 'February' },
        { deu: 'März', eng: 'March' },
        { deu: 'Mai', eng: 'May' },
        { deu: 'Juni', eng: 'June' },
        { deu: 'Juli', eng: 'July' },
        { deu: 'Oktober', eng: 'October' },
        { deu: 'Dezember', eng: 'December' },
        { deu: 'Montag', eng: 'Monday' },
        { deu: 'Dienstag', eng: 'Tuesday' },
        { deu: 'Mittwoch', eng: 'Wednesday' },
        { deu: 'Donnerstag', eng: 'Thursday' },
        { deu: 'Freitag', eng: 'Friday' },
        { deu: 'Samstag', eng: 'Saturday' },
        { deu: 'Sonntag', eng: 'Sunday' },
        { deu: 'Heute', eng: 'Today' },
        { deu: 'Gestern', eng: 'Yesterday' },
        { deu: 'Täglich', eng: 'Daily' },
        { deu: 'Wöchentlich', eng: 'Weekly' },
        { deu: 'Monatlich', eng: 'Monthly' }
    ];

    constructor(
        private configService: MnbConfigService
    ) {
    }

    public translateDate(enDateString: string) {
        let dateString = enDateString;
        if (this.configService.langCode === 'deu') {
            LanguageService.DATE_TRANSLATIONS.forEach(word => {
                dateString = dateString.replace(word.eng, word.deu);
            });
        }
        return dateString;
    }
    public getEmptyLabel(): string {
        return this.configService.langCode === 'deu' ? '(leer)' : '(empty)';
    }

}
