import { NgModule } from '@angular/core';
import { DynamicKeyObjectPipe, GenerateEntityLinkPipe } from '@shared-lib/modules/model-entities/pipes/entity-link.pipe';


@NgModule({
    declarations: [
        GenerateEntityLinkPipe,
        DynamicKeyObjectPipe,
    ],
    exports: [
        GenerateEntityLinkPipe,
        DynamicKeyObjectPipe,
    ],
    imports: [],
    providers: []
})
export class EntityLinkServiceModule {

}
