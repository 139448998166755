import { Directive, Input, ElementRef, HostListener, Renderer2, OnInit, Component, Optional, OnDestroy, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[mnbTileDropdownOwner]',
    providers: []
})
export class MnbTileDropdownOwnerDirective implements OnInit, OnDestroy {

    @Input() dropdownPlacement = 'bottom';
    @Input() appendToBody = false;

    @Input() openedClass: string;
    @Output() onOpened = new EventEmitter<void>();

    private owner: HTMLElement;
    private dropdown: HTMLElement;
    private content: HTMLElement;

    private insideClick: boolean;

    private isOpen: boolean;

    private unregisterOnDocumentClick: () => void;
    private unregisterOnWindowScroll: () => void;

    constructor(private elementReference: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.owner = this.elementReference.nativeElement;
        this.isOpen = this.owner.classList.contains('show-dropdown');
        if (this.isOpen) {
            this.registerEvents();
        }
    }

    @HostListener('click')
    onClick() {
        this.toggleVisibility();

        if (this.appendToBody && !this.dropdown) {
            this.dropdown = this.renderer.createElement('div');

            this.renderer.appendChild(this.dropdown, this.content);
            this.renderer.appendChild(document.body, this.dropdown);

            this.renderer.addClass(this.dropdown, 'tile-dropdown-owner');
            this.renderer.addClass(this.dropdown, 'with-absolute-position');

            this.updatePosition();
        } else if (this.dropdown) {
            this.renderer.removeChild(document.body, this.dropdown);
            this.dropdown = null;
        }

        this.insideClick = true;
    }

    private registerEvents() {
        this.unregisterOnDocumentClick = this.renderer.listen('document', 'click', (event) => {
            this.onDocumentClick(event.target);
        });

        this.unregisterOnWindowScroll = this.renderer.listen('window', 'scroll', () => {
            this.onWindowScroll();
        });
    }

    private unregisterEvents() {
        if (this.unregisterOnDocumentClick) {
            this.unregisterOnDocumentClick();
            this.unregisterOnDocumentClick = null;
        }
        if (this.unregisterOnWindowScroll) {
            this.unregisterOnWindowScroll();
            this.unregisterOnWindowScroll = null;
        }
    }
    public onDocumentClick(target: Node) {
        if (!this.insideClick && this.dropdown && !this.dropdown.contains(target)) {
            this.isOpen = false;
            this.renderer.removeClass(this.owner, 'show-dropdown');
            this.renderer.removeClass(this.owner, this.openedClass);
            this.renderer.removeChild(document.body, this.dropdown);
            this.dropdown = null;
            this.unregisterEvents();
        }
        this.insideClick = false;
    }

    registerDropdownContent(content: HTMLElement) {
        this.content = content;
    }

    private toggleVisibility() {
        if (this.isOpen) {
            this.renderer.removeClass(this.owner, 'show-dropdown');
            this.renderer.removeClass(this.owner, this.openedClass);
            this.unregisterEvents();
        } else {
            this.renderer.addClass(this.owner, 'show-dropdown');
            this.renderer.addClass(this.owner, this.openedClass);
            this.registerEvents();
            this.onOpened.emit();
        }
        this.isOpen = !this.isOpen;

    }

    public onWindowScroll() {
        this.updatePosition();
    }

    private updatePosition() {
        setTimeout(() => {
            if (this.dropdown) {

                const computedElementStyle: CSSStyleDeclaration = window.getComputedStyle(this.elementReference.nativeElement);
                const hostPos = this.elementReference.nativeElement.getBoundingClientRect();
                const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

                // Bottom positioning
                const top = hostPos.bottom - Number.parseFloat(computedElementStyle.paddingBottom);
                const left = hostPos.left + Number.parseFloat(computedElementStyle.paddingLeft);

                const y = top + scrollPosition;
                const x = left;

                this.renderer.setStyle(this.dropdown, 'top', `${y}px`);
                this.renderer.setStyle(this.dropdown, 'left', `${x}px`);
                this.renderer.setStyle(this.dropdown, 'right', 'unset');
                this.renderer.setStyle(this.dropdown, 'bottom', 'unset');
                this.renderer.addClass(this.dropdown, 'show-dropdown');
                this.renderer.addClass(this.dropdown, this.openedClass);
            }
        });
    }

    ngOnDestroy() {
        if (this.appendToBody && this.dropdown) {
            this.renderer.removeChild(document.body, this.dropdown);
            this.unregisterEvents();
        }
    }
}


@Component({
    selector: 'mnb-tile-dropdown-content',
    template: '<div class="tile tile-dropdown" [class.with-pointer-events]="withPointerEvents"><ng-content></ng-content></div>'
})
export class MnbTileDropdownContentComponent implements OnInit {

    @Input('withPointerEvents') withPointerEvents: boolean;

    constructor(
        public elementRef: ElementRef,
        @Optional() private ownerDirective: MnbTileDropdownOwnerDirective) {
    }

    ngOnInit(): void {
        if (this.ownerDirective) {
            this.ownerDirective.registerDropdownContent(this.elementRef.nativeElement);
        }
    }
}
