import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { LanguageService } from '@shared-lib/modules/core/services/language/language.service';
import { DatePipe } from '@angular/common';
import { MnbTimeService } from '@shared-lib/modules/core/services/time/time.service';

/*
 * Format the value by using the unit type code.

 * Usage:
 *   date | mnbDate
 * Example:
 *   {{ '2018.01.01' | mnbDate }}
 *   formats to: Jan 01, 2018
*/
@Pipe({ name: 'mnbDate' })
export class MnbDatePipe implements PipeTransform {

    constructor(
        private languageService: LanguageService, 
        private timeService: MnbTimeService,
        private datePipe: DatePipe) {

    }

    // mnbDate Pipe is a migration for the mnbDate pipe in angular js
    // Any changes done in the pipe transformation please update in the old date pipe in the sharedFilters.js 
    transform(value: Date, format?: string, forceUTC?: boolean): string {
        if (isNullOrUndefined(value)) {
            return '';
        }

        let output = '';

        if (format === 'relative') {
            const today = new Date();
            const yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            const inputDate = new Date(value);

            if (inputDate.toDateString() === today.toDateString()) {
                output = 'Today';
            } else if (inputDate.toDateString() === yesterday.toDateString()) {
                output = 'Yesterday';
            } else {
                output = this.datePipe.transform(value, this.timeService.getDateFormat());
            }
        } else {
            const appliedFormat = format || this.timeService.getDateFormat();
            output = forceUTC ? this.datePipe.transform(value, appliedFormat, 'utc') : this.datePipe.transform(value, appliedFormat);
        }

        output = this.languageService.translateDate(output);

        return output;
    }
}
