import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';
import { Days, Periods } from '@minubo-suite/shared/components/date-picker/date-picker-model';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, distinctUntilChanged, takeUntil } from 'rxjs/operators';

export type CalendarDay = {
    label: number;
    cssClass: string;
    date: Date;
};

export type CalendarMonth = {
    label: string;
    cssClass: string;
    date: Date;
};


@Component({
    selector: 'mnb-date-span-picker',
    templateUrl: './mnb-date-span-picker.component.html'
})
export class MnbDateSpanPickerComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<void>();

    @Output() customDateChange = new EventEmitter<DateSpan>();

    @Input() set dateSpan(dateSpan: DateSpan) {
        // no validation here. Assumes FROM <= TO
        this.fromDate$.next(dateSpan.from);
        this.toDate$.next(dateSpan.to);
    }

    @Input() startDay: Days;

    public isOpen = false;

    public fromDate$ = new BehaviorSubject<Date>(new Date(0));
    public fromDateView$ = new BehaviorSubject<Date>(new Date(0));
    public fromMonthSelectionShow = false;

    public toDate$ = new BehaviorSubject<Date>(new Date(0));
    public toDateView$ = new BehaviorSubject<Date>(new Date(0));
    public toMonthSelectionShow = false;

    public fromVisibleDates$ = combineLatest([this.fromDate$, this.toDate$, this.fromDateView$]).pipe(
        map(([fromDate, toDate, fromDateView]) => this.getVisibleDates(fromDate, toDate, fromDateView, 'from'))
    );
    public toVisibleDates$ = combineLatest([this.fromDate$, this.toDate$, this.toDateView$]).pipe(
        map(([fromDate, toDate, toDateView]) => this.getVisibleDates(fromDate, toDate, toDateView, 'to'))
    );

    public fromVisibleMonths$ = combineLatest([this.fromDate$, this.fromDateView$]).pipe(
        map(([fromDate, fromDateView]) => this.getVisibleMonths(fromDate, fromDateView))
    );
    public toVisibleMonths$ = combineLatest([this.toDate$, this.toDateView$]).pipe(
        map(([toDate, toDateView]) => this.getVisibleMonths(toDate, toDateView))
    );

    public months = Periods.Months;
    public days = Periods.Days;

    public openCalendar(): void {
        this.isOpen = true;
    }

    public closeCalendar(): void {
        this.isOpen = false;
    }

    public showMonthSelection(selector: 'from' | 'to') {
        if (selector === 'from') {
            this.fromMonthSelectionShow = true;
        } else {
            this.toMonthSelectionShow = true;
        }
    }

    public setDate(date: Date, selector: 'from' | 'to') {
        date.setUTCHours(0, 0, 0, 0);
        if (selector === 'from') {
            const currentToDate = new Date(this.toDate$.getValue());
            currentToDate.setUTCHours(0, 0, 0, 0);
            if (date.getTime() > currentToDate.getTime()) {
                this.toDate$.next(new Date(date));
            }
            this.fromDate$.next(new Date(date));
        } else {
            const currentFromDate = new Date(this.fromDate$.getValue());
            currentFromDate.setUTCHours(0, 0, 0, 0);
            if (date.getTime() < currentFromDate.getTime()) {
                this.fromDate$.next(new Date(date));
            }
            this.toDate$.next(new Date(date));
        }
        this.customDateChange.emit(new DateSpan(this.toDate$.getValue(), this.fromDate$.getValue()));
    }

    public setDateView(date: Date, selector: 'from' | 'to') {
        const firstOfMonthDate = this.getFirstOfMonth(date);
        if (selector === 'from') {
            this.fromDateView$.next(firstOfMonthDate);
            this.fromMonthSelectionShow = false;
        } else {
            this.toDateView$.next(firstOfMonthDate);
            this.toMonthSelectionShow = false;
        }
    }

    public changeDateViewMonth(selector: 'from' | 'to', direction: 'previous' | 'next') {
        const behaviourSubject = selector === 'from' ? this.fromDateView$ : this.toDateView$;
        const monthChangeAmount = direction === 'previous' ? -1 : 1;
        const currentDateView = behaviourSubject.getValue();
        currentDateView.setUTCMonth(currentDateView.getUTCMonth() + monthChangeAmount);
        behaviourSubject.next(currentDateView);
    }

    public changeDateViewYear(selector: 'from' | 'to', direction: 'previous' | 'next') {
        const behaviourSubject = selector === 'from' ? this.fromDateView$ : this.toDateView$;
        const monthChangeAmount = direction === 'previous' ? -12 : 12;
        const currentDateView = behaviourSubject.getValue();
        currentDateView.setUTCMonth(currentDateView.getUTCMonth() + monthChangeAmount);
        behaviourSubject.next(currentDateView);
    }

    private getVisibleDates(fromDateOrig: Date, toDateOrig: Date, visibleMonth: Date, selector: 'from' | 'to'): CalendarDay[][] {
        // remove hours for proper Date comparison
        const fromDate = new Date(fromDateOrig);
        fromDate.setUTCHours(0, 0, 0, 0);
        const toDate = new Date(toDateOrig);
        toDate.setUTCHours(0, 0, 0, 0);
        const currentDay = this.getFirstVisibleDay(visibleMonth);
        currentDay.setUTCHours(0, 0, 0, 0);

        const rowList: CalendarDay[][] = [];
        for (let rowId = 0; rowId < 6; rowId++) {
            const dayList: CalendarDay[] = [];
            for (let columnId = 0; columnId < 7; columnId++) {
                // determine css
                let cssClass = '';
                if (currentDay.getUTCMonth() !== visibleMonth.getUTCMonth()) {
                    cssClass = 'other';
                }
                if (fromDate.getTime() <= currentDay.getTime() && currentDay.getTime() <= toDate.getTime()) {
                    cssClass = 'date-in-selection';
                }
                if (selector === 'from' && fromDate.getTime() === currentDay.getTime()) {
                    cssClass = 'selected';
                }
                if (selector === 'to' && toDate.getTime() === currentDay.getTime()) {
                    cssClass = 'selected';
                }

                // create object
                const day: CalendarDay = {
                    label: currentDay.getUTCDate(),
                    cssClass: cssClass,
                    date: new Date(currentDay)
                };
                dayList.push(day);
                currentDay.setUTCDate(currentDay.getUTCDate() + 1);
            }
            rowList.push(dayList);
        }
        return rowList;
    }

    private getVisibleMonths(selectedDate: Date, viewDate: Date): CalendarMonth[][] {
        const rowList = [];
        let monthIndex = 0;
        for (let rowId = 0; rowId < 4; rowId++) {
            const monthList: CalendarMonth[] = [];
            for (let columnId = 0; columnId < 3; columnId++) {
                // determine css
                let cssClass = '';
                const firstOfMonthSelected = this.getFirstOfMonth(selectedDate);
                const firstOfMonthViewed = this.getFirstOfMonth(viewDate);
                firstOfMonthViewed.setUTCMonth(monthIndex);
                if (this.dateIsEqual(firstOfMonthSelected, firstOfMonthViewed)) {
                    cssClass = 'selected';
                }

                // create month
                const month = {
                    label: 'GENERAL.TIME.MONTH_NAME.FULL.' + this.months[monthIndex],
                    cssClass: cssClass,
                    date: firstOfMonthViewed
                };
                monthList.push(month);
                monthIndex += 1;
            }
            rowList.push(monthList);
        }
        return rowList;
    }

    private getFirstVisibleDay(visibleMonth: Date): Date {
        const nulledVisibleMonth = this.getFirstOfMonth(visibleMonth);
        const currentDay = nulledVisibleMonth.getUTCDay();
        let shift = (7 + currentDay - this.startDay) % 7;
        shift = shift < 3 ? shift + 7 : shift;
        nulledVisibleMonth.setUTCDate(nulledVisibleMonth.getUTCDate() - shift);
        return nulledVisibleMonth;
    }

    private getFirstOfMonth(date: Date): Date {
        const firstInMonthDate = new Date(date);
        firstInMonthDate.setUTCDate(1);
        firstInMonthDate.setUTCHours(0, 0, 0, 0);
        return firstInMonthDate;
    }

    private dateIsEqual(dateA: Date, dateB: Date): boolean {
        return dateA.getUTCFullYear() === dateB.getUTCFullYear() && dateA.getUTCMonth() === dateB.getUTCMonth() && dateA.getUTCDate() === dateB.getUTCDate();
      }

    constructor(
        private mnbConfigService: MnbConfigService,
    ) { }

    ngOnInit() {
        const tenantWeekStart = this.mnbConfigService.calendarConfig.weekStart;
        if (!this.startDay) {
            this.startDay = tenantWeekStart === 'MONDAY' ? 1 : tenantWeekStart === 'SUNDAY' ? 0 : 6;
        }

        for (let i = this.startDay - 1; i >= 0; i--) {
            const day = this.days.shift();
            this.days.push(day);
        }

        // update viewdate when selected date changes
        this.fromDate$.pipe(
            distinctUntilChanged(this.dateIsEqual),
            takeUntil(this.destroy$)
        ).subscribe((fromDate) => {
            this.setDateView(fromDate, 'from');
        });
        this.toDate$.pipe(
            distinctUntilChanged(this.dateIsEqual),
            takeUntil(this.destroy$)
        ).subscribe((toDate) => {
            this.setDateView(toDate, 'to');
        });

    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
