import { Input, Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: '[mnbFormGroupNotifications]',
  template: '<span *ngFor="let notification of _notifications" class="notification-detail">{{notification | translate}}</span>'
  ,
  host: { class: 'form-group-notifications' }
})
export class MnbFormGroupNotificationsComponent implements OnInit {

  private _notifications: Array<string>;

  @Input('mnbFormGroupNotifications') set notifications(value: Array<string>) {
    if (value && this.timeToLife) {
      setTimeout(() => {
        this._notifications = null;
      }, this.timeToLife);
    }

    this._notifications = value;
  }

  @Input() timeToLife: number;

  @HostBinding('class.has-notifications') get hasNotifications(): boolean {
    return this._notifications && this._notifications.length > 0;
  }

  ngOnInit(): void {}

}
