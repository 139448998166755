import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { MnbDropdownMenuComponent } from './mnb-dropdown-menu.component';

@Component({
    selector: 'mnb-dropdown-menu-list',
    template: `
        <div *ngIf="this.dropdownMenu.open" class="inner-container">
            <div class="anchor" [ngClass]="{'lower-z-index': lowerZIndex}">
                <div class="shadow"></div>
                <div class="body"></div>
            </div>
            <div
                class="list tall" [ngClass]="{'list': true, 'tall': displayTallerList, 'lower-z-index': lowerZIndex}" 
                [style.width.px]="width">
                <ng-content></ng-content>
            </div>
        </div>
    `
})

export class MnbDropdownMenuListComponent implements OnInit {

    @Input() public displayTallerList: boolean;
    @Input() width: number;
    @Input() lowerZIndex: boolean;

    public elementRef: HTMLElement;
    
    constructor (@Inject(MnbDropdownMenuComponent) public dropdownMenu: MnbDropdownMenuComponent, elementRef: ElementRef) {
        this.elementRef = elementRef.nativeElement;
    }

    ngOnInit(): void {
        this.dropdownMenu.registerList(this);
    }
}
