import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { PortalDashboard } from '@minubo-portal/modules/api/models/api-dashboard.model';
import { PortalEntity } from '@minubo-portal/modules/api/models/api-portal.model';
import { ApiDataService } from '@minubo-portal/modules/api/services/api-data.service';
import { ApiSessionService } from '@minubo-portal/modules/api/services/api-session.service';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { MnbDashboardsWidgetDetailComponent, MnbDashboardsWidgetDetailHandler } from '@shared-lib/modules/dashboards/components/mnb-dashboard-widget-detail.component';
import { MnbDashboardService } from '@shared-lib/modules/dashboards/services/mnb-dashboard.service';
import { DashboardGridWidget } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { Dashboard, DashboardWidget } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { filter, map, mergeMap, skip, takeUntil } from 'rxjs/operators';
import { DashboardsFilterModel, DashboardsFiltersModel, mapFiltersModel } from '../../model/dashboards-filters.model';
import { DashboardsDashboardService } from '../../services/dashboards-dashboard.service';
import { DashboardsDashboardPageContext } from '../dashboard/dashboards-dashboard-page.component';

@Component({
    templateUrl: './dashboards-widget-detail-page.component.html'
})
export class DashboardsWidgetDetailPageComponent implements OnInit, OnDestroy {

    public model$ = new BehaviorSubject<DashboardsWidgetDetailPageModel>(null);

    private destroyed$ = new Subject<void>();

    @ViewChild('detailDisplay', { static: false }) detailDisplay: MnbDashboardsWidgetDetailComponent;

    private context: DashboardsDashboardPageContext;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private mnbDashboardService: MnbDashboardService,
        private dashboardService: DashboardsDashboardService,
        private apiDataService: ApiDataService
    ) {
    }

    ngOnInit() {
        const context$ = this.route.data.pipe(
            map((data: { context: DashboardsDashboardPageContext }) => data.context)
        );
        // store context
        context$.subscribe(context => this.context = context);

        const dashboard$ = context$.pipe(
            mergeMap(context => context.dashboard$),
            filter(portalDashboard => !!portalDashboard),
            takeUntil(this.destroyed$)
        );

        const portalEntity$ = context$.pipe(
            mergeMap(context => this.dashboardService.getEntity(context.id))
        );

        // set up model with context and loaded dashboard
        combineLatest([dashboard$, portalEntity$]).subscribe(data => {
            const [ dashboard, portalEntity ] = data;
            this.model$.next(this.initModel(dashboard, portalEntity));
        });

         // skip initial null
         this.model$.pipe(skip(1)).subscribe((model) => {

            // map to new view settings
            const viewSettings = mapFiltersModel(model.filters);

            // update widgets
            model.widget.querySettings = {
                ...model.widget.querySettings,
                timeFilter: viewSettings.timeFilter,
                comparisonFilter: viewSettings.comparisonFilter,
                filters: viewSettings.filters
            };
            setTimeout(() => {
                // timeout is need to let the detail view get the widget ref
                this.detailDisplay.update();
            });
        });
    }

    private initModel(portalDashboard: PortalDashboard, portalEntity: PortalEntity) {
        const widgetId = parseInt(this.route.snapshot.params['widgetId']);
        const widget: DashboardWidget = deepCopy(portalDashboard.widgets.find(w => widgetId === w.id));

        const dashboard: Dashboard = {
            ...portalDashboard
        };

        const filters = portalEntity.settings.filters || [];
        const filterModels = filters.map(settingsFilter => {
            settingsFilter.values = settingsFilter.values || [];
            const filterModel: DashboardsFilterModel = {
                filter: (this.context.filters || []).find(f => f.attributeCode === settingsFilter.attributeCode) || settingsFilter,
                originalFilter: deepCopy(settingsFilter)
            };
            return filterModel;
        });

        const timeFiltersValue: MnbQuickTimeFilterValue = this.initTimeFilter(dashboard, widget);

        const filtersModel: DashboardsFiltersModel = {
            timeFilter: timeFiltersValue.timeFilter,
            comparisonFilter: timeFiltersValue.comparisonFilter,
            filters: filterModels
        };

        this.dashboardService.loadWidget(new DashboardGridWidget(), widget, dashboard, true, true).then(gridWidget => {
            this.route.data.subscribe(data => {
                (<AppHeader> data.header).title = this.mnbDashboardService.calcWidgetTitle(gridWidget);
            });
        });

        const model: DashboardsWidgetDetailPageModel = {
            dashboard,
            widget,
            filters: filtersModel,
            detailHandler: {
                loadTableWidget: (loadWidget: DashboardWidget) => {
                    return this.dashboardService.loadWidget(new DashboardGridWidget(), loadWidget, dashboard, false, true);
                },
                loadDetail: (loadWidget: DashboardWidget, showMore?: boolean) => {
                    return this.dashboardService.loadDetail(loadWidget, dashboard, showMore);
                }
            }
        };

        return model;
    }

    private initTimeFilter(dashboard: Dashboard, widget: DashboardWidget): MnbQuickTimeFilterValue {
        if (widget.querySettings.timeFilter) {
            return widget.querySettings;
        }
        if (this.context.timeFilter) {
            return this.context;
        }
        return dashboard.settings;
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    goBackToDashboard(dashboard: Dashboard) {
        this.router.navigate(['dashboards', dashboard.id]);
    }


    public loadFilterValues = (attributeCode: string, filterSearch?: string) => {
        return this.apiDataService.loadAttributeData(attributeCode, filterSearch).toPromise();
    }

    public onFilterChange(value: QueryFilter, filterModel: DashboardsFilterModel) {
        filterModel.filter = value;
        this.model$.next({
            ...this.model$.value
        });
    }

    public onTimeFilterChange(value: MnbQuickTimeFilterValue): void {
        this.model$.next({
            ...this.model$.value,
            filters: {
                ...this.model$.value.filters,
                timeFilter: value.timeFilter,
                comparisonFilter: value.comparisonFilter
            }
        });
    }
}

export interface DashboardsWidgetDetailPageModel {
    dashboard: Dashboard;
    widget: DashboardWidget;
    filters: DashboardsFiltersModel;

    detailHandler: MnbDashboardsWidgetDetailHandler;
}
