import { Pipe, PipeTransform } from '@angular/core';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';


/**
 * capitalizes the first letter of the input and transforms the rest of the input to lowercase
 */
@Pipe({ name: 'mnbEnumeration' })
export class MnbEnumerationPipe implements PipeTransform {

    constructor(public mnbConfigService: MnbConfigService) {}

    transform(value: number) {
        if (this.mnbConfigService.langCode === 'eng') {
            const lastDigit = value % 10;
            let extra = '';
            switch (lastDigit) {
                case 1:
                    extra = value === 11 ? 'th' : 'st';
                    break;
                case 2:
                    extra = value === 12 ? 'th' : 'nd';
                    break;
                case 3:
                    extra = value === 13 ? 'th' : 'rd';
                    break;
                default:
                    extra = 'th';
            }
            return value + extra;
        } else {
            return value + '.';
        }
    }

}
