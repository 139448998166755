import { Component, ComponentFactoryResolver, Directive, DoCheck, Input, ViewContainerRef } from '@angular/core';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';

@Directive({
    selector: '[mnbLoadingSpinner]'
})
export class MnbLoadingSpinnerDirective implements DoCheck {

    @Input('mnbLoadingSpinner') status: MnbBusyStatus;

    private currentStatus = -99;

    constructor(
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver
    ) { }

    ngDoCheck() {
        if (!this.status || this.currentStatus !== this.status.code) {
            this.viewContainer.clear();
            if (this.status && this.status.code === 1) {
                // possible: show indicator when loading finished
            } else {
                const componentRef = this.viewContainer.createComponent(this.componentFactoryResolver.resolveComponentFactory(MnbLoadingSpinnerComponent));
                componentRef.instance.status = this.status;
            }
            this.currentStatus = this.status.code;
        }
    }
}

@Component({
    template:
        `
        <div *ngIf="status.code !== 1">
            <div *ngIf="status.code === -1" class="spinning-icon small"></div>
            <div *ngIf="status.code === 0" class="load-text">{{ status.message }}</div>
        </div>
        `
})
export class MnbLoadingSpinnerComponent {
    status: MnbBusyStatus;
}
