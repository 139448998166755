import { NgZone } from '@angular/core';
import { MnbUnitInfo, MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { ChartRenderer } from './chart-renderer';
import * as _d3 from 'd3';
import { MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';
import { GaugeChartConfig } from '../services/chart.models';
import { isArray } from 'util';
import { PieArcDatum } from 'd3';
import { MnbChartTooltipData } from '../components/chart-tooltip-content.component';
import { MnbColor } from '@shared-lib/modules/core/utils/mnb-color-util';

export class GaugeChartRenderer extends ChartRenderer {

    private static TEXT_VERTICAL_SPACING = 26;

    constructor(
        protected config: GaugeChartConfig,
        zone: NgZone,
        unitPipe: MnbUnitPipe,
        emptyPipe: MnbEmptyValuePipe,
        unitService?: MnbUnitService
    ) {
        super(zone, unitPipe, emptyPipe, unitService);
    }

    private getPercentageValue() {
        const actualValue = this.config.data[0].data[0].value;
        const goalValue = this.config.goalValue;

        const value = goalValue === 0 ? actualValue === 0 ? 1 : 1.25 : Math.min(actualValue / goalValue, 1.25);

        return Math.max(value, 0);
    }

    private getPercentageDisplay() {
        const actualValue = this.config.data[0].data[0].value;
        const goalValue = this.config.goalValue;

        if (goalValue === 0) {
            return (actualValue < 0 ? '-' : '') + '∞';
        }

        return this.unitPipe.transform(actualValue / goalValue, 'PERCENT', { decimalPlaces: 2 });
    }

    public render(el: HTMLElement | HTMLElement[]) {
        const ele: HTMLElement = isArray(el) ? el[0] : el;

        this.svgElement = d3.select(ele).append('svg')
            .attr('class', 'mnb-chart mnb-gauge-chart')
            .attr('width', '100%')
            .attr('height', '100%');

        this.svgHTMLElement = this.svgElement.node();

        const dimensions: { height: number, width: number } = super.getDimensions(ele);
        const width = dimensions.width;
        const height = dimensions.height;
        const radius = Math.min(width, height) / 2;

        this.gElement = this.svgElement
            .append('g')
            .attr('transform', `translate(${width / 2}, ${(height * 0.65) - (this.config.hideGoalText ? 10 : 0)})`);

        this.drawGauge(radius, width, ele);
    }

    private drawGauge(radius: number, width: number, el: HTMLElement) {
        const outerRadius = radius * 0.6;
        const pie = d3.pie<number>()
            .value(d => d)
            .sort(null)
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2);

        const arc = d3.arc()
            .outerRadius(outerRadius)
            .innerRadius(radius)
            .cornerRadius(0);

        const chartData = [this.getPercentageValue()];
        chartData.push(1.25 - chartData[0]);

        const mainG = this.gElement.append('g');

        mainG
            .selectAll('path')
            .data(pie(chartData))
            .enter()
            .append('path')
            .attr('fill', (_d, index) => !index ? MnbColor.GREEN.color : MnbColor.GREY_LIGHTER_1.color)
            .attr('d', <any>arc);

        mainG.on('mouseenter', () => {
            const left = this.svgHTMLElement.getBoundingClientRect().width / 2;
            const top = this.svgHTMLElement.getBoundingClientRect().height / 2 - (this.config.hideGoalText ? 10 : 0);

            const scrollingTop = document.scrollingElement.contains(el) ? document.scrollingElement.scrollTop : 0;

            this.tooltipContent.tooltipOwner.tooltipPlacement = 'right';
            this.tooltipContent.tooltipData = this.initTooltip();

            this.tooltipContent.tooltipOwner.showTooltipOnPosition(
                this.svgHTMLElement.getBoundingClientRect().top + scrollingTop + top,
                this.svgHTMLElement.getBoundingClientRect().left + left);

            this.tooltipContent.tooltipOwner.detectChanges();
        }).on('mouseleave', () => this.tooltipContent.tooltipOwner.hideTooltip());

        const data: number = this.config.data[0].data[0].value;
        const goal = this.unitPipe.transform(this.config.goalValue, this.config.value.unit);

        const getX = (angle: number, r: number) => Math.cos(angle - Math.PI / 2) * r;
        const getY = (angle: number, r: number) => Math.sin(angle - Math.PI / 2) * r;

        const space = radius * 0.09;

        this.drawMainText(data);
        this.drawRangeText(pie, getX, radius);

        this.gElement
            .selectAll()
            .data(pie([1, 0.25]))
            .enter()
            .append('line')
            .style('pointer-events', 'none')
            .attr('x1', (d: PieArcDatum<number>) => getX(d.endAngle, outerRadius - space))
            .attr('y1', (d: PieArcDatum<number>) => getY(d.endAngle, outerRadius - space * 1.25))
            .attr('x2', (d: PieArcDatum<number>) => getX(d.endAngle, radius + space))
            .attr('y2', (d: PieArcDatum<number>) => getY(d.endAngle, radius + space))
            .attr('stroke', (d, index) => index ? 'transparent' : MnbColor.GREY.color)
            .attr('stroke-width', 2);

        this.drawGoalText(pie, getX, radius, space, getY, goal, width);
    }

    private drawGoalText(pie: _d3.Pie<any, number>, getX: (angle: number, r: number) => number, radius: number, space: number, getY: (angle: number, r: number) => number, goal: string, width: number) {
        if (this.config.hideGoalText) {
            return;
        }

        const anchorG = this.gElement
            .selectAll()
            .data(pie([1, 0.25]))
            .enter()
            .append('g')
            .attr('class', 'medium-text')
            .style('transform', (d: PieArcDatum<number>) => {
                const _x = getX(d.endAngle, radius + space);
                const _y = getY(this.config.noWrapInfoText ? 0 : d.endAngle, radius + space);
                const yMarginBottom = (this.config.noWrapInfoText ? GaugeChartRenderer.TEXT_VERTICAL_SPACING / 4 - 2 : 25 + GaugeChartRenderer.TEXT_VERTICAL_SPACING);

                const x = (_x + goal.length * 5 > width / 2 ? width / 2 : _x + goal.length * 5) - (this.config.noWrapInfoText ? 67 : 0) + 'px';
                const y = _y - yMarginBottom + 'px';

                return 'translate(' + [x, y].join(',') + ')';
            });

        anchorG
            .style('text-anchor', this.config.noWrapInfoText ? '' : 'end')
            .append('text')
            .style('text-anchor', this.config.noWrapInfoText ? 'end' : '')
            .attr('fill', MnbColor.GREY_DARKER_2.color)
            .text((d, index) => index ? '' : goal);

        anchorG
            .append('text')
            .style('text-anchor', this.config.noWrapInfoText ? 'start' : '')
            .attr('fill', MnbColor.GREY.color)
            .style('transform', `translate(${this.config.noWrapInfoText ? 6 : 0}px, ${this.config.noWrapInfoText ? 0 : GaugeChartRenderer.TEXT_VERTICAL_SPACING}px)`)
            .text((d, index) => index ? '' : '100.00 %');
    }

    private drawMainText(data: number) {
        const value = this.unitPipe.transform(data, this.config.value.unit);
        const percentage = this.getPercentageDisplay();
        const marginLeft = Math.abs(percentage.length - 8) * 3.25;

        this.gElement
            .append('text')
            .attr('fill', MnbColor.GREY_DARKER_2.color)
            .attr('class', 'large-text')
            .style('text-anchor', this.config.noWrapInfoText ? 'end' : 'middle')
            .style('transform', this.config.noWrapInfoText ? `translate(${ marginLeft }px, ${GaugeChartRenderer.TEXT_VERTICAL_SPACING}px)` : '')
            .text(value);

        this.gElement
            .append('text')
            .attr('fill', MnbColor.GREY.color)
            .attr('class', 'medium-text')
            .style('text-anchor', this.config.noWrapInfoText ? 'start' : 'middle')
            .style('transform', `translate(${(this.config.noWrapInfoText ? 6 + marginLeft : 0)}px, ${GaugeChartRenderer.TEXT_VERTICAL_SPACING}px)`)
            .text(percentage);
    }

    private drawRangeText(pie: _d3.Pie<any, number>, getX: (angle: number, r: number) => number, radius: number) {
        if (this.config.hideRangeText) {
            return;
        }
        this.gElement
            .selectAll()
            .data(pie([1, 0.25]))
            .enter()
            .append('g')
            .style('transform', (d, index) => 'translate(' + (getX(index ? -180 : 180, radius) + (30 * index ? -1 : 1)) + 'px, ' + GaugeChartRenderer.TEXT_VERTICAL_SPACING + 'px)')
            .attr('class', 'medium-text')
            .attr('class', (d, index) => index ? 'high-gauge-range' : 'low-gauge-range')
            .style('text-anchor', 'middle');

        for (let i = 0; i < 2; i++) {
            const rangeClass = i ? '.high-gauge-range' : '.low-gauge-range';

            this.gElement
                .select(rangeClass)
                .append('text')
                .attr('fill', MnbColor.GREY_DARKER_2.color)
                .text(this.unitPipe.transform(i ? this.config.goalValue * 1.25 : 0, this.config.value.unit));

            this.gElement
                .select(rangeClass)
                .append('text')
                .attr('fill', MnbColor.GREY.color)
                .style('transform', 'translate(0px, ' + GaugeChartRenderer.TEXT_VERTICAL_SPACING + 'px)')
                .text(i ? '125.00 %' : '0.00 %');
        }
    }

    private initTooltip() {
        const tooltipData = new MnbChartTooltipData();

        const unit = this.config.value.unit;
        const unitInfo = unit instanceof MnbUnitInfo ? <MnbUnitInfo> unit : new MnbUnitInfo(<string> unit);

        tooltipData.values = {
            unitCode: unitInfo.code,
            unit: unitInfo
        };

        const value = this.unitPipe.transform(this.config.data[0].data[0].value, unitInfo) + ', ' + this.getPercentageDisplay();

        tooltipData.rows = {
            headerTitle: this.config.value.label,
            values: [
                { label: 'GENERAL.MODEL.VALUE_OPTIONS.ACTUAL_VALUE', type: 'text', value },
                { label: 'GENERAL.LABEL.GAUGE_BENCHMARK', type: 'number', unit: unitInfo, value: this.config.goalValue },
                { label: 'GENERAL.LABEL.GAUGE_MAX', type: 'number', unit: unitInfo, value: this.config.goalValue * 1.25 }
            ]
        };

        return tooltipData;
    }

}
