import { InjectionToken } from '@angular/core';

// use injection token here instead of Language service type as our ajs services are not

export const SESSION_SERVICE = new InjectionToken<any>('SESSION_SERVICE');

export const legacySessionServiceProvider = {
    provide: SESSION_SERVICE,
    useFactory: (i: any) => {
        if (!i.has('mnbSessionService')) {
            return null;
        }
        return i.get('mnbSessionService');
    },
    deps: ['$injector']
};
