import {NgModule} from '@angular/core';
import {PortalInstanceService} from '@minubo-portal/services/portal-instance.service';
import { EmptyEntityLinkFacadeService, ENTITY_LINK_FACADE_SERVICE_TOKEN } from '@shared-lib/modules/model-entities/services/entity-link-facade.service';
import { EmptyModelEntitiesLinkDataService, MODEL_ENTITIES_LINK_DATA_SERVICE_TOKEN } from '@shared-lib/modules/model-entities/services/model-entities-entity-link-data.service';

@NgModule({
    declarations: [],
    imports: [
    ],
    providers: [
        PortalInstanceService,
        { provide: ENTITY_LINK_FACADE_SERVICE_TOKEN, useClass: EmptyEntityLinkFacadeService },
        { provide: MODEL_ENTITIES_LINK_DATA_SERVICE_TOKEN, useClass: EmptyModelEntitiesLinkDataService },
    ]
})
export class ServiceModule {

}
