import { Input, HostBinding, Component } from '@angular/core';
import { ModelField } from '@shared-lib/modules/model/services/mnb-model.service';

@Component({
    selector: '[mnbModelFieldClasses]',
    host: {class: 'model-field mnb-tooltip-owner'},
    template: `
        <span>
            <span class="field-name">
                <ng-content>
                </ng-content>
            </span>
            <span *ngIf="isHidden" class="icon-block" [mnbTooltipOwner]="'GENERAL.MODEL.RIGHTS.MISSING.LABEL' | translate" tooltipPlacement="left" appendToBody="true"></span>
        </span>`
})
export class MnbModelFieldClassesComponent {

    @Input('mnbModelFieldClasses') field: ModelField;

    @HostBinding('class.model-field-hidden')
    get isHidden(): boolean {
        return this.field && this.field.isHidden;
    }

}
