import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PortalReport } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportData, ReportSettings, ReportSettingsTable } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ReportsTemplateDataChangeEvent, ReportsTemplateFormValue, ReportsTemplateViewSettingsAppliedEvent } from '../template/reports-template.component';
import { map, takeUntil } from 'rxjs/operators';
import { ReportsTemplateComponent } from '@minubo-portal/modules/reports/components/template/reports-template.component';
import { MnbReportsTableDrilldownState } from '@shared-lib/modules/reports/components/table/state/mnb-reports-table-drilldown-state';
import { MnbReportsTableDisplayComponent } from '@shared-lib/modules/reports/components/table/display/mnb-reports-table-display.component';
import { LoadAdhocFeedEvent } from '@shared-lib/modules/reports/components/table/download-data-selector/mnb-reports-table-download-data-selector.component';


@Component({
    selector: 'reports-table',
    templateUrl: './reports-table.component.html'
})
export class ReportsTableComponent implements OnInit, OnDestroy {

    public drilldown: MnbReportsTableDrilldownState;

    public form: FormGroup;

    public viewSettings$ = new BehaviorSubject<ReportSettings>({table: {}});

    public report: PortalReport;
    public data: ReportData;

    private viewSettingsFormValue$ = new Subject<ReportsTemplateFormValue>();
    private destroy$ = new Subject<void>();

    @ViewChild(ReportsTemplateComponent, {static: true}) reportsTemplate: ReportsTemplateComponent;
    @ViewChild(MnbReportsTableDisplayComponent, {static: true}) displayComponent: MnbReportsTableDisplayComponent;

    ngOnInit(): void {
        this.form = new FormGroup({
            'timeFilter': new FormControl(null),
            'comparisonFilter': new FormControl(null),
            'filters': new FormControl([]),
            'selectedDownloadAttributeCodes': new FormControl([]),
            'selectedDownloadMeasureCodes': new FormControl([]),
        });

        this.viewSettingsFormValue$
            .pipe(
                map(settings => toViewSettings(settings)),
                takeUntil(this.destroy$)
            ).subscribe(settings => {
            this.receiveViewSettings(settings);
        });

        this.drilldown = new MnbReportsTableDrilldownState(null);
        this.drilldown.viewSettings$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(viewSettings => this.receiveViewSettings(viewSettings));
    }

    receiveViewSettings(newViewSettings: ReportSettings) {
        const viewSettings = new ReportSettings();
        viewSettings.table = {
            ...this.viewSettings$.getValue().table,
            ...newViewSettings.table,
        };
        this.viewSettings$.next(viewSettings);
    }

    onViewSettingsApplied(event: ReportsTemplateViewSettingsAppliedEvent) {
        const settings = event.viewSettings || event.report.settings;
        this.form.get('timeFilter').setValue(settings.table.timeFilter);
        this.form.get('comparisonFilter').setValue(settings.table.comparisonFilter);
        this.form.get('filters').setValue(settings.table.filters || []);
        this.form.get('selectedDownloadAttributeCodes').setValue(settings.table.selectedDownloadAttributeCodes);
        this.form.get('selectedDownloadMeasureCodes').setValue(settings.table.selectedDownloadMeasureCodes);
    }

    onViewSettingsFormValueChanged(formValue: ReportsTemplateFormValue) {
        this.viewSettingsFormValue$.next(formValue);
    }

    onDataChanged(event: ReportsTemplateDataChangeEvent) {
        this.report = event.report;
        this.data = event.data;
        this.drilldown.setDefaultSettings(this.report.settings.table);

        // set defaultSelection if no selection is found
        const viewSettings = this.viewSettings$.getValue();
        const selectedAttributesCount = viewSettings.table.selectedDownloadAttributeCodes && viewSettings.table.selectedDownloadAttributeCodes.length || 0;
        const selectedMeasuresCount = viewSettings.table.selectedDownloadMeasureCodes && viewSettings.table.selectedDownloadMeasureCodes.length || 0;
        if (selectedAttributesCount === 0 || selectedMeasuresCount === 0) {
            const newViewsettings = this.displayComponent.getDefaultDownloadSelection();
            if (newViewsettings != null) {
                this.receiveViewSettings(newViewsettings);
            }
        }
    }

    public onDrilldown(event: { attribute?: { code: string }, value?: string }) {
        this.drilldown.down(event);
    }

    public loadAdhocFeed(event: LoadAdhocFeedEvent) {
        const viewSettings = new ReportSettings();
        viewSettings.table = {
            selectedDownloadAttributeCodes: event.attributeCodes,
            selectedDownloadMeasureCodes: event.measureCodes
        };
        this.receiveViewSettings(viewSettings);
        this.reportsTemplate.loadAdhocFeedUrl(this.report, this.viewSettings$.getValue());
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

function toViewSettings(formValue: Partial<ReportSettingsTable>): ReportSettings {
    const viewSettings = new ReportSettings();
    viewSettings.table = {
        timeFilter: formValue.timeFilter,
        comparisonFilter: formValue.comparisonFilter,
        filters: formValue.filters,
        selectedDownloadAttributeCodes: formValue.selectedDownloadAttributeCodes,
        selectedDownloadMeasureCodes: formValue.selectedDownloadMeasureCodes,
    };
    return viewSettings;
}
