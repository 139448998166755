import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthLoginPageComponent} from './pages/login-page/login-page/auth-login-page.component';
import {FormsModule} from '@angular/forms';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [AuthLoginPageComponent],
    imports: [
        CommonModule,
        FormsModule,
        MnbLibModule,
        RouterModule.forChild([{
            path: '',
            component: AuthLoginPageComponent
        }]),
        TranslateModule
    ]
})
export class AuthModule {
}
