import { InjectionToken } from '@angular/core';

// use injection token here instead of Language service type as our ajs services are not


// use injection token here instead of Language service type as our ajs services are not

export const ANGULAR_STATE = new InjectionToken<any>('ANGULAR_STATE');
export const ANGULAR_ROUTER_SERVICE = new InjectionToken<any>('ANGULAR_ROUTER_SERVICE');
export const LEGACY_ROUTER_SERVICE = new InjectionToken<any>('ROUTER_SERVICE');

export const legacyAngularStateProvider = {
    provide: ANGULAR_STATE,
    useFactory: function(i: any) {
        if (!i.has('$state')) {
            return null;
        }
        return i.get('$state');
    },
    deps: ['$injector']
};

export const legacyAngularRouterServiceProvider = {
    provide: ANGULAR_ROUTER_SERVICE,
    useFactory: function(i: any) {
        return i.get('mnbUiRouterService');
    },
    deps: ['$injector']
};

export const legacyRouterServiceProvider = {
    provide: LEGACY_ROUTER_SERVICE,
    useFactory: function(i: any) {
        return i.get('mnbRouterService');
    },
    deps: ['$injector']
};
