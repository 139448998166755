import {Injectable} from '@angular/core';

export enum AuthenticationType {
    normal = 'normal',
    intersport = 'intersport',
}

@Injectable()
export class PortalInstanceService {

    public instanceConfig: PortalInstanceConfig = {
        authenticationType: AuthenticationType.normal,
        name: 'Partner Portal',
        logoPath: '/app/images/v3/logo_white.svg'
    };

    constructor() {
        const parts = window.location.host.split('.');
        const domainIdx = parts.findIndex(str => str === 'minubo');
        const slicedParts = domainIdx > -1 ? parts.slice(0, domainIdx) : parts;
        if (slicedParts.includes('intersport')) {
            this.instanceConfig = {
                authenticationType: AuthenticationType.intersport,
                name: 'Intersport Retailer Reporting',
                cssClass: 'intersport-rr',
                logoPath: '/app/images/v3/sso/intersport.svg'
            };
        }
    }
}

export type PortalInstanceConfig = {
    name: string,
    logoPath: string
    cssClass?: string,
    authenticationType: AuthenticationType
};
