import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlyDashboardWidgetEditOverlayComponent } from '@minubo-suite/analytics-area/modules/dashboards/components/widget/aly-dashboard-widget-edit-overlay.component';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { Report, ReportData, ReportSettings, ReportStoreAreasDataRow, ReportStoreAreasDataSeason } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { MnbModelService, ModelAttribute, ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'mnb-reports-store-areas-display',
    templateUrl: './mnb-reports-store-areas-display.component.html'
})
export class MnbReportsStoreAreasDisplayComponent implements OnInit {

    constructor(
        private timeFilterService: TimeFilterService,
        private modelService: MnbModelService
    ) {}

    @Input() report: Report;
    @Input() viewSettings: ReportSettings;
    @Input() data: ReportData;

    @Output() seasonSelectionChanged = new EventEmitter<ReportStoreAreasDataSeason>();
    @Output() storeSelectionChanged = new EventEmitter<string>();

    public drilldownSteps: MnbReportsStoreAreasDisplayDrilldownStepModel[] = [];


    public model$ = new BehaviorSubject<MnbReportsStoreAreasDisplayModel>(null);

    async ngOnInit(): Promise<void> {
        await this.loadModel(this.report, this.viewSettings, this.data);
    }


    private async loadModel(report: Report, viewSettings: ReportSettings, data: ReportData) {

        if (!data) {
            this.model$.next({
                restricted: true
            });
            return;
        }

        const storeAttribute = await this.modelService.getAttribute('stoName');

        const settings = viewSettings.storeAreas;

        const isStoreLevel = !settings.storeName;
        if (!isStoreLevel) {
            this.drilldownSteps.push({
                attribute: storeAttribute,
                nextCode: report.settings.storeAreas.attributes.length ? report.settings.storeAreas.attributes[0].code : null,
                value: settings.storeName
            });
        }

        const attributes = await Promise.all(report.settings.storeAreas.attributes.map(a => this.modelService.getAttribute(a.code)));

        const currentAttribute = !isStoreLevel ? attributes[0] : storeAttribute;
        const nextAttribute = !isStoreLevel && attributes.length > 1 ? attributes[1] : null;

        const areaMeasure = await this.modelService.getMeasure('stoStoAreaSqM');
        const totalRowModel = {
            values: {},
            comparisonValues: {}
        };

        const rows = data.storeAreas.rows
            .reduce((array: MnbReportsStoreAreasDisplayRowModel[], row) => {
                const breakdownAttribute = row.attributes[currentAttribute.code];
                let rowModel: MnbReportsStoreAreasDisplayRowModel = array.find(rm => rm.breakdownAttribute === breakdownAttribute);
                if (!rowModel) {
                    rowModel = {
                        breakdownAttribute,
                        values: {},
                        comparisonValues: {}
                    };
                    array.push(rowModel);
                }

                this.addRowToModel(row, rowModel);
                this.addRowToModel(row, totalRowModel);

                if (!isStoreLevel && nextAttribute) {
                    rowModel.childRows = this.buildRowsForPath(report, data, 
                        [{attributeCode: currentAttribute.code, value: breakdownAttribute}],
                        nextAttribute.code, report.settings.storeAreas.revenueMeasureCode);
                }

                return array;
            }, []);
        this.sortRows(rows, report.settings.storeAreas.revenueMeasureCode);

        const model: MnbReportsStoreAreasDisplayModel = {
            attributes: !isStoreLevel ? attributes : [storeAttribute],
            drilldownAttribute: isStoreLevel && attributes.length ? attributes[0] : null,
            revenueMeasure: await this.modelService.getMeasure(report.settings.storeAreas.revenueMeasureCode),
            areaMeasure,
            rows,
            totalRow: totalRowModel,
            seasonAttribute: await this.modelService.getAttribute('ssnPeriod'),
            seasons: data.storeAreas.seasons.map(season => {
                return {
                    ...season,
                    label: this.buildSeasonLabel(season)
                };
            }),
            selectedSeason: this.buildSeasonLabel(data.storeAreas.selectedSeason),
            comparisonSeason: this.buildSeasonLabel(data.storeAreas.comparisonSeason),
            restricted: false,
            hasComparison: !!settings.comparisonFilter,
            dateSpan: this.timeFilterService.getTimePeriod(settings.timeFilter),
        };

        if (!!settings.comparisonFilter) {
            model.comparisonDateSpan = this.timeFilterService.getComparisonPeriod(
                settings.timeFilter, settings.comparisonFilter as TimeComparisonFilter
            );
        }

        this.model$.next(model);
    }

    private buildRowsForPath(
            report: Report,
            data: ReportData,
            steps: MnbReportsStoreAreasDisplayFilterStep[],
            currentAttribute: string,
            revenueMeasureCode: string) {

        const nextAttributeIndex = report.settings.storeAreas.attributes.findIndex((a) => a.code === currentAttribute) + 1;
        const nextAttribute = nextAttributeIndex < report.settings.storeAreas.attributes.length
            ? report.settings.storeAreas.attributes[nextAttributeIndex]
            : null;
        const rows = data.storeAreas.rows
            .filter(row => {
                return steps.every(step => {
                    return row.attributes[step.attributeCode] === step.value;
                });
            })
            .reduce((array: MnbReportsStoreAreasDisplayRowModel[], row) => {
                const breakdownAttribute = row.attributes[currentAttribute];
                let rowModel = array.find(rm => rm.breakdownAttribute === breakdownAttribute);
                if (!rowModel) {
                    rowModel = {
                        breakdownAttribute,
                        values: {},
                        comparisonValues: {}
                    };
                    array.push(rowModel);
                }

                this.addRowToModel(row, rowModel);

                if (nextAttribute) {
                    rowModel.childRows = this.buildRowsForPath(report, data,
                        [...steps, {attributeCode: currentAttribute, value: breakdownAttribute}],
                        nextAttribute.code, revenueMeasureCode);
                }

                return array;
            }, []);

        this.sortRows(rows, revenueMeasureCode);
        return rows;
    }
    private buildSeasonLabel(season: ReportStoreAreasDataSeason): string {
        if (!season) {
            return '-';
        }
        return season.name + ' ' + season.year;
    }

    private addRowToModel(row: ReportStoreAreasDataRow, rowModel: MnbReportsStoreAreasDisplayRowModel) {

        Object.keys(row.values).forEach(key => {
            rowModel.values[key] = (rowModel.values[key] || 0) + (row.values[key] || 0);
        });

        Object.keys(row.comparisonValues).forEach(key => {
            rowModel.comparisonValues[key] = (rowModel.comparisonValues[key] || 0) + (row.comparisonValues[key] || 0);
        });
    }

    private sortRows(rowModels: MnbReportsStoreAreasDisplayRowModel[], measureCode: string) {

        rowModels.sort((a, b) => {
            const valueA = a.values[measureCode] || 0;
            const valueB = b.values[measureCode] || 0;

            return valueB - valueA;
        });
    }

    onSeasonSelectionChanged(season: ReportStoreAreasDataSeason) {
        this.seasonSelectionChanged.emit(season);
    }

    onDrilldown(step: MnbReportsStoreAreasDisplayDrilldownStepModel) {

        this.drilldownSteps.push(step);

        if (this.drilldownSteps.length === 1) {
            this.storeSelectionChanged.emit(step.value);
        }
    }

    onDrillup(step: MnbReportsStoreAreasDisplayDrilldownStepModel) {

        const drillupIndex = this.drilldownSteps.indexOf(step) + 1;
        this.drilldownSteps = this.drilldownSteps.filter((_, i) => i < drillupIndex);
        if (!this.drilldownSteps.length) {
            this.storeSelectionChanged.emit(null);
        }
    }

}

export type MnbReportsStoreAreasDisplayModel = {
    attributes?: ModelAttribute[];
    drilldownAttribute?: {code: string};
    seasonAttribute?: ModelAttribute;
    revenueMeasure?: ModelMeasure;
    areaMeasure?: ModelMeasure;
    rows?: MnbReportsStoreAreasDisplayRowModel[];
    totalRow?: MnbReportsStoreAreasDisplayRowModel,
    seasons?: MnbReportsStoreAreasDisplaySeasonModel[],
    selectedSeason?: string;
    comparisonSeason?: string;
    restricted?: boolean;
    hasComparison?: boolean;
    dateSpan?: DateSpan;
    comparisonDateSpan?: DateSpan;
};

export interface MnbReportsStoreAreasDisplaySeasonModel {
    name: string;
    year: string;
    label: string;
}

export interface MnbReportsStoreAreasDisplayRowModel {
    breakdownAttribute?: string;
    values: { [measureCode: string]: number };
    comparisonValues: { [measureCode: string]: number };

    isExpanded?: boolean;
    childRows?: MnbReportsStoreAreasDisplayRowModel[];
}

export interface MnbReportsStoreAreasDisplayDrilldownStepModel {
    attribute: ModelAttribute;
    value: string;
    nextCode: string;
}

export interface MnbReportsStoreAreasDisplayFilterStep {
    attributeCode: string;
    value: string;
}
