import { Pipe, PipeTransform } from '@angular/core';
import { MnbColor } from '@shared-lib/modules/core/utils/mnb-color-util';

@Pipe({ name: 'mnbColor' })
export class MnbColorPipe implements PipeTransform {

    constructor() {}

    transform(color: string): MnbColor {
        if (!color) {
            return MnbColor.WHITE;
        }

        return MnbColor.colors.find((c: MnbColor) => color === c.color) || MnbColor.WHITE;
    }
}