import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: '[mnbValueBarCell]',
    host: { 'class': 'value-bar-cell' },
    template:
        `<ng-container *ngIf="settings">
            <div class="cell-bar-container hide-split-line" [class.full-bar]="settings.fullBarVisualization" [class.with-good-color]="settings.useGoodColor">
                <div class="inner-container negative-bar" [ngClass]="negativeBar.styleClass" *ngIf="!settings.hideNegative">
                    <div class="bar" [style.width]="negativeBar.percentage + '%'"></div>
                </div>
                <div class="inner-container positive-bar" [ngClass]="positiveBar.styleClass"  *ngIf="!settings.hidePositive">
                    <div class="bar" [style.width]="positiveBar.percentage + '%'"></div>
                </div>
            </div>
        </ng-container>
        <span class="cell-data"><ng-content></ng-content></span>
    `
})

export class MnbValueBarCellComponent implements OnInit, OnChanges {

    @Input('mnbValueBarCell') value: number;
    @Input() settings = new ValueBarCellSettings();
    @Input() revertDirection: boolean;

    public negativeBar: BarModel;
    public positiveBar: BarModel;

    ngOnInit(): void {
        this.applySettings();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !changes.value.firstChange || changes.settings && !changes.settings.firstChange) {
            this.applySettings();
        }
    }

    private applySettings(): void {

        if (!this.settings) {
            this.settings = new ValueBarCellSettings();
        }

        const percentage = Math.min(Math.abs(this.value / this.settings.range) * 100, 100);

        this.negativeBar = new BarModel(this.value < 0 ? percentage : 0);
        this.positiveBar = new BarModel(this.value >= 0 ? percentage : 0);

        if (this.settings.useNegativeColor) {
            if (!this.revertDirection) {
                this.negativeBar.markAsBad();
            } else {
                this.positiveBar.markAsBad();
            }
        }

    }

}

export class ValueBarCellSettings {
    public range = 100;

    public hidePositive?: boolean;
    public hideNegative?: boolean;

    public useNegativeColor?: boolean;
    public useGoodColor?: boolean;

    public fullBarVisualization?: boolean;

    
}

class BarModel {

    public styleClass: string;

    constructor (public percentage: number) {
        this.markAsGood();
    }

    public markAsBad(): void {
        this.styleClass = 'bad';
    }

    public markAsGood(): void {
        this.styleClass = 'good';
    }
}
