import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedMiscModule } from '../misc/shared-misc.module';

import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { MnbExpanderContentComponent } from './components/expander-content.component';
import { MnbExpanderDirective } from './components/expander.directive';
import { MnbFormGroupNotificationsComponent } from './components/form-group-notifications.component';
import { MnbFormSaveActionDirective } from './components/form-save-action.directive';
import { MnbFormUnitInputComponent } from './components/mnb-form-unit-input.component';

@NgModule({
    declarations: [
        MnbExpanderContentComponent,
        MnbExpanderDirective,
        MnbFormGroupNotificationsComponent,
        MnbFormUnitInputComponent,
        MnbFormSaveActionDirective
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MnbLibModule,
        SharedMiscModule
    ],
    entryComponents: [
        MnbExpanderContentComponent,
        MnbFormGroupNotificationsComponent,
        MnbFormUnitInputComponent
    ],
    exports: [
        MnbExpanderContentComponent,
        MnbExpanderDirective,
        MnbFormGroupNotificationsComponent,
        MnbFormUnitInputComponent,
        MnbFormSaveActionDirective
    ],
    providers: [
    ]
})
export class SharedFormModule {
}
