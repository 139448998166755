import { DashboardSettings, DashboardWidgetQuerySettings } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { MnbQuickTimeFilterValue } from '@shared-lib/modules/filters/components/quick-time-filter/mnb-quick-time-filter.component';

export interface DashboardsFiltersModel extends MnbQuickTimeFilterValue {
    filters: DashboardsFilterModel[];
}

export interface DashboardsFilterModel {
    filter: QueryFilter;
    originalFilter: QueryFilter;
}

export function mapFiltersModel(filtersModel: DashboardsFiltersModel): DashboardSettings {
    const settings = new DashboardSettings();
    settings.timeFilter = filtersModel.timeFilter;
    settings.comparisonFilter = filtersModel.comparisonFilter;
    settings.filters = filtersModel.filters.map(f => f.filter);
    return settings;
}

export function mapFiltersModelForWidget(filtersModel: DashboardsFiltersModel): DashboardWidgetQuerySettings {
    const settings = new DashboardWidgetQuerySettings();
    settings.timeFilter = filtersModel.timeFilter;
    settings.comparisonFilter = filtersModel.comparisonFilter;
    settings.filters = filtersModel.filters.map(f => f.filter);
    return settings;
}
