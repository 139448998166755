import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/minubo-portal/src/environments/environment';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, skip, tap } from 'rxjs/operators';
import {PortalInfo, PortalLogin, PortalSession} from '../models/api-login.model';
import { ApiSessionService } from './api-session.service';

@Injectable()
export class ApiAuthService {

    private sessionState$ = this.apiSessionService.sessionState$;
    private initializedState$ = this.apiSessionService.initializedState$;

    constructor(
        private http: HttpClient,
        private router: Router,
        private apiSessionService: ApiSessionService
    ) {

        // Separate subscription for URL handling on login & logout
        this.sessionState$.pipe(
            map(auth => auth.authenticated),
            distinctUntilChanged(),
            skip(1)
        ).subscribe(authenticated => {
            if (!authenticated) {
                void this.router.navigateByUrl('login');
            }
        });
    }

    public login(email: string, password: string): Observable<ApiAuthResponse> {
        return this.sendLogin({
            email: email,
            password: password,
            grantType: 'login'
        });
    }

    public loginByCode(code: string): Observable<ApiAuthResponse> {
        return this.sendLogin({
            code: code,
            grantType: 'code'
        });
    }

    private sendLogin(params: {email: string, password: string, grantType: 'login'}|{code: string, grantType: 'code'}): Observable<ApiAuthResponse> {
        return this.http.post<PortalSession>(`${environment.apiUrl}/v3/auth`, params, {
            withCredentials: true
        }).pipe(
            tap((_: PortalSession) => {
                this.initializedState$.next(false);
                this.apiSessionService.loadSession();
            }),
            map((_: PortalSession): ApiAuthResponse => {
                return { wasSuccessful: true }; // mapping this because there is no 'wasSuccessful' coming from backend),
            }),
            catchError((error: HttpErrorResponse): Observable<ApiAuthResponse> => {
                this.sessionState$.next({ authenticated: false, login: null });
                return of({
                    wasSuccessful: false,
                    isBlacklisted: error.error.code === 'blacklisted'
                });
            })
        );
    }


    public getLogin(): Observable<PortalLogin> {
        return this.sessionState$.pipe(map(value => value.login));

    }
    public getPortalInfo(): Observable<PortalInfo> {
        return this.sessionState$
            .pipe(
                map(session => session.portalInfo),
                distinctUntilChanged()
            );
    }

    public getSession(): Observable<PortalSession> {
        return this.sessionState$;
    }

    public logout(): void {
        this.http.post(`${environment.apiUrl}/v3/auth/logout`, {}, {withCredentials: true}).pipe(
            catchError((_: any) => {
                this.sessionState$.next({ authenticated: false, login: null });
                return EMPTY;
            })
        ).subscribe(() => {
            this.sessionState$.next({ authenticated: false, login: null });
        });
    }

}

export interface ApiAuthResponse {
    wasSuccessful: boolean;
    isBlacklisted?: boolean;
}
