import { NgZone, Renderer2, ElementRef, ChangeDetectorRef, Directive, Output, EventEmitter, AfterViewInit, OnDestroy, ViewRef } from '@angular/core';

@Directive({
    selector: '[mnbClickOutside]'
})
export class MnbClickOutsideDirective implements AfterViewInit, OnDestroy {
    private mouseDownListener: Function;
    private mouseUpListener: Function;

    private startedInside: boolean;

    @Output()
    public mnbClickOutside = new EventEmitter<MouseEvent>();

    constructor(
        private readonly zone: NgZone,
        private readonly renderer: Renderer2,
        private elementRef: ElementRef,
        private ref: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            this.mouseDownListener = this.renderer.listen('document', 'mousedown', (event) => {
                this.onMouseDown(event);
            });
            this.mouseUpListener = this.renderer.listen('document', 'mouseup', (event) => {
                this.onMouseUp(event);
            });
        });
    }

    ngOnDestroy() {
        this.mouseDownListener();
        this.mouseUpListener();
      }

    public onMouseDown(event: MouseEvent): void {
        this.startedInside = event.target && this.elementRef.nativeElement.contains(event.target);
    }

    public onMouseUp(event: MouseEvent): void {
        const endedInside = event.target && this.elementRef.nativeElement.contains(event.target);
        if (!this.startedInside && !endedInside) {
            // click started and ended outside
            this.mnbClickOutside.emit(event);
            if (!(this.ref as ViewRef).destroyed) {
                this.ref.detectChanges();
            }
        }
        this.startedInside = false;
    }
}
