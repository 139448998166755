import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Pipe({ name: 'mnbDuration' })
export class MnbDurationPipe implements PipeTransform {

    constructor() { }

    transform(inputMs: number): string {
        if (isNullOrUndefined(inputMs)) {
            return '';
        }

        const seconds = inputMs / 1000;

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        return hours.toString().padStart(2, '0') + ':' +
            minutes.toString().padStart(2, '0') + ':' +
            remainingSeconds.toString().padStart(2, '0');
    }

}
