import { Input, Component } from '@angular/core';

@Component({
    selector: 'mnb-form-group-validation-errors',
    template: '<div><span *ngFor="let error of errors" class="error-detail">{{error}}</span></div>'
})
export class MnbFormGroupValidationErrorsComponent {
    @Input() errors: Array<string> = [];

    constructor() { }
}
