import { Input, Component, HostBinding, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MnbForm } from '../../model/mnb-form.model';
import { TranslateService } from '@ngx-translate/core';
import { MnbValidators } from '@shared-lib/modules/core/utils/mnb-validators.util';

@Component({
    selector: '[mnbFormGroupErrors]',
    template: `<ng-container *ngFor="let error of errors">
              <span class="error-detail">{{error}}</span>
              <br>
            </ng-container>`,
    host: { class: 'form-group-errors' }
})
export class MnbFormGroupErrorsComponent implements OnInit {

    @Input('mnbFormGroupErrors') private formControlRef: FormControl;

    errors: Array<string>;

    @HostBinding('class.has-errors')
    hasErrors = false;

    constructor(
        private translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this.formControlRef.statusChanges.subscribe(() => {
            this.updateErrors();
        });
    }

    private updateErrors() {
        const showErrors = MnbForm.hasErrors(this.formControlRef);

        if (showErrors) {
            this.buildErrors();
            this.hasErrors = true;
        } else {
            this.errors = [];
            this.hasErrors = false;
        }
    }

    private buildErrors() {
        this.errors = MnbValidators.buildErrorsList(this.formControlRef, this.translate);
    }

}
