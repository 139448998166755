import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { MnbTimeService } from '@shared-lib/modules/core/services/time/time.service';

/*
 *
 * Usage:
 *   date | mnbTime
 * Example:
 *   {{ '2018.01.01 12:44' | mnbTime }}
 *   formats to: 12:44
*/
@Pipe({ name: 'mnbTime' })
export class MnbTimePipe implements PipeTransform {

    constructor(private mnbTimeService: MnbTimeService) {
    }

    transform(value: Date): string {
        const formatTime = this.mnbTimeService.getTimeFormat(true);
        return moment(value).format(formatTime);
    }

}
