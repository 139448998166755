import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DrilldownFilterContext, MnbReportDrilldownStepModel } from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';


@Component({
    selector: 'mnb-reports-drilldown-steps',
    templateUrl: './mnb-reports-drilldown-steps.component.html'
})
export class MnbReportsDrilldownStepsComponent implements OnInit {

    /**
     * @deprecated
     */
    @Input() public drilldownSteps: MnbReportDrilldownStepModel[] = [];
    /**
     * @deprecated
     */
    @Input() public drilldownEntity: MnbReportDrilldownStepModel;
    @Input() public drilldownFiltersContext: DrilldownFilterContext[] = [];
    @Output() public drillup = new EventEmitter<MnbReportDrilldownStepModel>();


    ngOnInit(): void {
    }

    onDrillup(stepModel: any) {
        this.drillup.next(stepModel);
    }

}
