import { Injectable } from '@angular/core';
import { DatePart } from '../time/time.model';
import { CalendarConfig } from '@shared-lib/modules/config/model/mnb-config.model';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';

@Injectable({
    providedIn: 'root'
})
export class MnbTimeService  {

    private static MILLIS_PER_DAY = 86400000;
    private static MILLIS_PER_WEEK = MnbTimeService.MILLIS_PER_DAY * 7;

    private calendarConfig: CalendarConfig;

    private weekStartCalculation: (date: Date) => Date;

    private calendarweekRefCalculation: (date: Date) => Date;

    private fiscalYearStartDate: FiscalYearStartDate | undefined;

    constructor(
         private mnbConfigService: MnbConfigService
    ) {
        mnbConfigService.onAvailable(() => {
            this.calendarConfig = mnbConfigService.calendarConfig;
            this.fiscalYearStartDate = FiscalYearStartDate.of(mnbConfigService.calendarConfig ? mnbConfigService.calendarConfig.fiscalYearStartDate : '2000-01-01');
            switch (this.calendarConfig ? this.calendarConfig.weekStart : null) {
                case 'SUNDAY':
                    this.weekStartCalculation = (date) => {
                        return this.getDateByDayOffset(date, -date.getUTCDay());
                    };
                    this.calendarweekRefCalculation = (date) => {
                        // saturday of this week
                        return this.getDateByDayOffset(date, -date.getUTCDay() + 6);
                    };
                    break;
                case 'SATURDAY':
                    this.weekStartCalculation = (date) => {
                        const weekDay = date.getUTCDay();
                        return this.getDateByDayOffset(date, weekDay === 6 ? 0 : -(weekDay + 1));
                    };
                    this.calendarweekRefCalculation = (date) => {
                        const weekDay = date.getUTCDay();
                        return this.getDateByDayOffset(date, weekDay === 6 ? 6 : -weekDay + 5);
                    };
                    break;
                default:
                    this.weekStartCalculation = (date) => {
                        return this.getDateByDayOffset(date, -(date.getUTCDay() || 7) + 1);
                    };
                    this.calendarweekRefCalculation = (date) => {
                        // thursday of this week
                        return this.getDateByDayOffset(date, -(date.getUTCDay() || 7) + 4);
                    };
            }
        });


    }

    public hasFiscalYearConfigured(): boolean {
        return !!this.fiscalYearStartDate;
    }

    public getCurrentFiscalYearByRef(refDate: Date): Date {
        if (!this.fiscalYearStartDate) {
            return FiscalYearStartDate.of('2000-01-01').getCurrentFiscalYearByRef(refDate);
        }
        return this.fiscalYearStartDate.getCurrentFiscalYearByRef(refDate);
    }

    public getDateFormat(short?: boolean, without?: DatePart.Month | DatePart.Year): string {
        const lang = this.mnbConfigService.langCode;

        if (lang === 'deu') {
            if (!short) {
                return 'dd.MM.yyyy';
            } else if (!without) {
                return 'dd.MM.yy';
            } else if (without === DatePart.Year) {
                return 'dd.MM.';
            } else if (without === DatePart.Month) {
                return 'dd.';
            }
        } else if (lang === 'eng') {
            if (!short) {
                return 'MMM dd, yyyy';
            } else if (!without) {
                return 'MM/dd/yy';
            } else if (without === DatePart.Year) {
                return 'MM/dd';
            } else if (without === DatePart.Month) {
                return 'dd';
            }
        } else {
            if (!short) {
                return 'yyyy-MM-dd';
            } else if (!without) {
                return 'yy-MM-dd';
            } else if (without === DatePart.Year) {
                return 'MM-dd';
            } else if (without === DatePart.Month) {
                return 'dd';
            }
        }
    }

    public getTimeFormat(short?: boolean): string {
        if (short) {
            const lang = this.mnbConfigService.langCode;

            if (lang === 'eng') {
                return 'h:mm A';
            } else {
                return 'HH:mm';
            }
        }
        return 'HH:mm:ss';
    }

    public getTimeLabel(h: number, m: number) {
        if (this.mnbConfigService.langCode === 'deu') {
          return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m} (${this.calendarConfig.zoneId})`;
        } else if (h > 11) {
          return `${h === 12 ? 12 : h - 12}:${m < 10 ? '0' + m : m} pm (${this.calendarConfig.zoneId})`;
        } else {
          return `${h === 0 ? 12 : h}:${m < 10 ? '0' + m : m} am (${this.calendarConfig.zoneId})`;
        }
      }

    public getDiffInDays(from: Date, to: Date): number {
        return Math.abs(Math.round((from.getTime() - to.getTime()) / MnbTimeService.MILLIS_PER_DAY ));
    }

    public getDateByDayOffset(date: Date, offset: number) {
        const d = new Date(date);
        d.setUTCDate(date.getUTCDate() + offset);
        return d;
    }

    public getDateByYearOffset(date: Date, offset: number) {
        if (!date) {
            throw Error('\'date\' can not be null or undefined.');
        }

        const d = new Date(date);
        d.setUTCFullYear(date.getUTCFullYear() + offset);
        return d;
    }

    public getDateByMonthOffset(date: Date, offset: number) {
        const d = new Date(date);

        const dayOfMonth = d.getUTCDate();
        d.setUTCDate(1);
        d.setUTCMonth(date.getUTCMonth() + offset);
        const resultMonth = d.getUTCMonth();
        d.setUTCDate(dayOfMonth);
        // we need to loop until we are back in the month we wanted to go at
        // (eg. if we switch from 30th of march to 2th of march but we want to go to 28th of feb)
        // TODO: does somebody has a better impl?
        while (resultMonth < d.getUTCMonth()) {
            d.setUTCDate(d.getUTCDate() - 1);
        }
        return d;
    }

    public getDateByQuarterOffset(date: Date, offset: number) {
        return this.getDateByMonthOffset(date, offset * 3);
    }

    public getDateByHalfYearOffset(date: Date, offset: number) {
        return this.getDateByMonthOffset(date, offset * 6);
    }

    public getWeekStartOfDate(date: Date): Date {
        return this.weekStartCalculation(date);
    }

    public getMonthStartOfDate(date: Date): Date {
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1));
    }

    public getQuarterStartOfDate(date: Date): Date {
        const month = Math.floor(date.getUTCMonth() / 3) * 3;
        return new Date(Date.UTC(date.getUTCFullYear(), month, 1));
    }

    public getHalfYearStartOfDate(date: Date): Date {
        const month = Math.floor(date.getUTCMonth() / 6) * 6;
        return new Date(Date.UTC(date.getUTCFullYear(), month, 1));
    }

    public getYearStartOfDate(date: Date): Date {
        return new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    }

    public isValidDate(d: any) {
        // not sure why we have to test this, but it was that way already in js
        if (isNaN(d)) {
            return false;
        }
        return d instanceof Date;
    }

    public getLocalTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    public getUTCToday(): Date {
        const now = new Date();
        now.setUTCMinutes(now.getUTCMinutes() + this.calendarConfig.zoneOffsetMinutes);
        const utcNow = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
        return utcNow;
    }

    public getHalfYearCodeOfDate(date) {
        const halfyear = Math.floor(date.getMonth() / 6 + 1);
        return date.getFullYear() + ' HY ' + halfyear;
    }

    public getCalendarweekCodeOfDate(date) {
        const refDate = this.calendarweekRefCalculation(date);
        const year = refDate.getUTCFullYear();
        const firstOfYear = this.getFirstOfYearDateByYear(year);
        const weekNumber = Math.ceil(this.getDifferenceInDays(firstOfYear, refDate) / 7);
        return year + '-CW' + weekNumber;
    }

    public getCalendarweekYearOfDate(date) {
        const refDate = this.calendarweekRefCalculation(date);
        return refDate.getUTCFullYear();
    }

    public getCalendarweekOfDate(date) {
        const refDate = this.calendarweekRefCalculation(date);
        const firstOfYear = this.getFirstOfYearDateByYear(refDate.getUTCFullYear());
        return Math.ceil(this.getDifferenceInDays(firstOfYear, refDate) / 7);
    }

    private getDifferenceInDays(date1, date2) {
        return (date2 - date1) / MnbTimeService.MILLIS_PER_DAY + 1;
    }

    private getFirstOfYearDateByYear(year) {
        return new Date(Date.UTC(year, 0, 1));
    }

    public getDiffInYears(from: Date, to: Date): number {
        return Math.abs(from.getUTCFullYear() - to.getUTCFullYear());
    }

    public getDiffInYearQuarters(from: Date, to: Date): number {
        return Math.ceil(this.getDiffInMonths(from, to) / 3);
    }

    public getDiffInMonths(from: Date, to: Date): number {
        let months = (to.getUTCFullYear() - from.getUTCFullYear()) * 12;
        months -= from.getUTCMonth();
        months += to.getUTCMonth();
        return months <= 0 ? 0 : months;
    }

    public getDiffInWeeks(from: Date, to: Date): number {
        return Math.abs(Math.round((from.getTime() - to.getTime()) / MnbTimeService.MILLIS_PER_WEEK ));
    }

    public getDiffInHalfYears(from: Date, to: Date): number {
        return Math.ceil(this.getDiffInMonths(from, to) / 6);
    }

}

export class FiscalYearStartDate {

    private readonly date: Date;

    private constructor(date: Date) {
        this.date = date;
    }

    /**
     * @fiscalYearStartDate: String in YYYY-MM-dd format
     * @returns FiscalYearStartDate Object based on input string. Uses this years January 1st if input was undefined
    */
     public static of(fiscalYearStartDate: string | undefined): FiscalYearStartDate | undefined {
        if (!fiscalYearStartDate) {
            return undefined;
        }

        const temp = new Date(fiscalYearStartDate);
        return new FiscalYearStartDate(temp);
    }

    public getCurrentFiscalYearByRef(refDate: Date): Date {
        return this.calculateCurrentFiscalYear(refDate, this.date);
    }

    private calculateCurrentFiscalYear(refDate: Date, originDate: Date): Date {

            const currentFiscalYear = new Date(originDate);
            currentFiscalYear.setFullYear(refDate.getFullYear());

            if (currentFiscalYear.getTime() > refDate.getTime()) {
                currentFiscalYear.setFullYear(currentFiscalYear.getFullYear() - 1);
            }
            return currentFiscalYear;
    }
}
