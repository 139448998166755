import { Pipe, PipeTransform } from '@angular/core';
import { DatePart } from '@shared-lib/modules/core/services/time/time.model';
import { DatePipe } from '@angular/common';
import { MnbDateUTCPipe } from '@shared-lib/modules/core/pipes/mnb-date-utc.pipe';
import { MnbTimeService } from '@shared-lib/modules/core/services/time/time.service';

@Pipe({ name: 'mnbTimeDate' })
export class MnbTimeDatePipe implements PipeTransform {

    private defaultConfig = new MnbTimeDateConfig();

    constructor(private timeService: MnbTimeService, private datePipe: DatePipe, private dateUTCPipe: MnbDateUTCPipe) {
    }

    transform(value: Date, config?: MnbTimeDateConfig): string {
        config = config || this.defaultConfig;

        if (config.shorten) {
            const format = this.timeService.getDateFormat(true, config.without);
            return this.datePipe.transform(value, format, 'GMT');
        } else {
            return this.dateUTCPipe.transform(value);
        }
    }

}

export class MnbTimeDateConfig {
    public shorten?: boolean = false;
    public without?: DatePart.Year | DatePart.Month;
}