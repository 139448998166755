import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy, ElementRef } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { MnbDropdownMenuButtonComponent } from './mnb-dropdown-menu-button.component';
import { MnbDropdownMenuListComponent } from './mnb-dropdown-menu-list.component';

@Component({
    selector: 'mnb-dropdown-menu',
    template: `
        <div class="dropdown-menu"
            [ngClass]="{ 'open': open, 'left-direction': isLeftDirection, 'right-direction': isRightDirection, 'fit-content-height': fitContentHeight, 'inline-dropdown': inline, 'fit-content-width': fitContentWidth }">
            <ng-content></ng-content>
        </div>
    `
})

export class MnbDropdownMenuComponent implements OnDestroy {

    @Input() direction: string;
    @Input() open: boolean;
    @Input() appendToBody: boolean;
    @Input() isFixed: boolean;
    @Input() fitContentHeight: boolean;
    @Input() fitContentWidth: boolean;
    @Input() inline: boolean;

    @Output() onSelect = new EventEmitter();
    @Output() onOpen = new EventEmitter();
    @Output() onClose = new EventEmitter();

    private button: MnbDropdownMenuButtonComponent;
    private list: MnbDropdownMenuListComponent;
    private appendedList: HTMLElement;

    private removeTimer: any;

    private unregisterOnDocumentClick: () => void;

    constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

    ngOnDestroy(): void {
        this.toggleDropdown(true);
    }

    public get isRightDirection(): boolean {
        return this.direction === 'right';
    }

    public get isLeftDirection(): boolean {
        return this.direction === 'left';
    }

    private onDocumentClick(event: MouseEvent) {
        if (!this.open) {
            return;
        }

        const target = <HTMLElement> event.target;

        // If Clicked on the any of the following elements then ignore
        const elements = [
            this.button && this.button.elementRefElement, // Dropdown Button
            this.appendedList, // List Appended To Body
            <HTMLElement>this.elementRef.nativeElement // Content
        ];

        const outsideClick = !elements.find(e => e && (e === target || e.contains(target)));

        if (outsideClick) {
            this.toggleDropdown(true);
        }
    }

    public toggleDropdown(off?: boolean): void {
        const forceToggle = !isNullOrUndefined(off);
        this.open = forceToggle ? !off : !this.open;

        if (this.open) {
            this.onOpen.emit();
        } else {
            this.onClose.emit();
        }

        if (this.appendToBody) {
            if (this.open) {
                this.appendListToBody();
            } else if (this.appendedList) {
                this.removeListFromBody();
            }
        }

        if (this.button) {
            this.button.onMenuToggleChange(this.open);
        }

        if (this.open) {
            this.unregisterOnDocumentClick = this.renderer.listen('document', 'click', (event) => {
                this.onDocumentClick(event);
            });
        } else {
            if (this.unregisterOnDocumentClick) {
                this.unregisterOnDocumentClick();
                this.unregisterOnDocumentClick = null;
            }
        }
    }

    private updatePosition() {
        const extraLeft = this.isLeftDirection ? -8 : 8;
        const button = this.button.elementRefElement;
        const sidePanels = Array.from(document.querySelectorAll('.side-panel'));
        const sidePanel = sidePanels.find(panel => panel.contains(button));

        // if button not inside side panel then we consider the page scroll value
        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (sidePanel) {
            // if button is inside side panel then we ignore the page scroll value and consider the side-panel-content scroll value.
            const sidePanelContent = sidePanel.querySelector('.side-panel-content');
            scrollPosition = sidePanelContent && sidePanelContent.contains(button) ? sidePanelContent.scrollTop : 0;
        }

        const buttonClientRect = button.getBoundingClientRect();
        this.appendedList.style.left = buttonClientRect.left + (buttonClientRect.width / 2) + extraLeft  + 'px';
        this.appendedList.style.top = buttonClientRect.bottom + scrollPosition + 'px';
    }

    private removeListFromBody() {
        this.renderer.removeClass(this.appendedList, 'open');
        clearTimeout(this.removeTimer);
        this.removeTimer = setTimeout(() => {
            if (!this.open && document.body.contains(this.appendedList)) {
                document.body.removeChild(this.appendedList);
            }
        }, 350);
    }

    private appendListToBody() {
        if (!this.appendedList) {
            this.appendedList = document.createElement('div');
        }

        document.body.append(this.appendedList);

        this.renderer.removeClass(this.appendedList, 'left-direction');
        this.renderer.removeClass(this.appendedList, 'right-direction');

        if (this.isRightDirection) {
            this.renderer.addClass(this.appendedList, 'right-direction');
        }

        if (this.isLeftDirection) {
            this.renderer.addClass(this.appendedList, 'left-direction');
        }

        this.renderer.addClass(this.appendedList, 'dropdown-menu');
        this.renderer.addClass(this.appendedList, 'append-to-body');
        if (this.isFixed) {
            this.renderer.addClass(this.appendedList, 'fixed');
        }
        if (this.fitContentHeight) {
            this.renderer.addClass(this.appendedList, 'fit-content-height');
        }
        this.renderer.appendChild(this.appendedList, this.list.elementRef);
        this.updatePosition();
        this.renderer.addClass(this.appendedList, 'open');
    }

    public registerButton(button: MnbDropdownMenuButtonComponent) {
        this.button = button;
    }

    public registerList(list: MnbDropdownMenuListComponent) {
        this.list = list;
    }

    public selectItem(value: any): void {
        this.onSelect.emit(value);
        this.toggleDropdown(true);
    }

}
