import { InjectionToken } from '@angular/core';

export const LEGACY_MNB_MEDIA_SERVICE = new InjectionToken<any>('LEGACY_MNB_MEDIA_SERVICE');

export const legacyMnbMediaServiceProvider = {
  provide: LEGACY_MNB_MEDIA_SERVICE,
  useFactory: function(i: any) {
    return i.get('mnbMediaService');
  },
  deps: ['$injector'],
  createProvider: function() {

  }

};
