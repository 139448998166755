import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[inputFocus]'
})

export class InputFocusDirective {
    
    constructor(private el: ElementRef) {}
  
    ngAfterViewInit(){
        this.el.nativeElement.focus();
    }

}