import { Pipe, PipeTransform } from '@angular/core';
import { DatePart } from '@shared-lib/modules/core/services/time/time.model';
import { MnbTimeDatePipe } from '@shared-lib/modules/core/pipes/time-date.pipe';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';

@Pipe({ name: 'mnbDateSpan' })
export class MnbDateSpanPipe implements PipeTransform {

    private defaultConfig = new MnbDateSpanConfig();

    constructor(private datePipe: MnbTimeDatePipe) {
    }

    transform(value: DateSpan, config?: MnbDateSpanConfig): string {
        config = config || this.defaultConfig;

        if (!value) {
            return ''; // TODO: ?
        }

        if (!value.from || !value.to) {
            return ''; // TODO: ?
        }

        const fromDate = value.from instanceof Date ? value.from : new Date(value.from);
        const toDate = value.to instanceof Date ? value.to : new Date(value.to);

        if (fromDate.getTime() === toDate.getTime()) {
            return this.datePipe.transform(toDate, {shorten: config.shorten});
        }

        let without: DatePart.Year | DatePart.Month = null;
        if (config.shorten) {
            if (fromDate.getFullYear() === toDate.getFullYear()) {
                if (fromDate.getMonth() === toDate.getMonth()) {
                    without = DatePart.Month;
                } else {
                    without = DatePart.Year;
                }
            }
        }

        return this.datePipe.transform(fromDate, {shorten: config.shorten, without: without})
            + (config.break ? '<br>' : ' - ')
            + this.datePipe.transform(toDate, {shorten: config.shorten});
    }
}

export class MnbDateSpanConfig {
    public shorten?: boolean = false;
    public break?: boolean = false;
}
