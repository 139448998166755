import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    ReportStoreAreasDataSeason
} from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { MnbReportsStoreAreasDisplayDrilldownStepModel, MnbReportsStoreAreasDisplayModel, MnbReportsStoreAreasDisplayRowModel, MnbReportsStoreAreasDisplaySeasonModel } from '../display/mnb-reports-store-areas-display.component';

@Component({
    selector: 'mnb-reports-store-areas-display-data',
    templateUrl: './mnb-reports-store-areas-display-data.component.html'
})
export class MnbReportsStoreAreasDisplayDataComponent {

    @Input() model: MnbReportsStoreAreasDisplayModel;

    @Output() seasonSelectionChanged = new EventEmitter<ReportStoreAreasDataSeason>();

    @Output() drilldown = new EventEmitter<MnbReportsStoreAreasDisplayDrilldownStepModel>();

    onSeasonSelectionChanged(seasonLabel: string, seasons: MnbReportsStoreAreasDisplaySeasonModel[]) {
        if (this.model != null && this.model.selectedSeason !== seasonLabel) {
            const season = seasons.find(s => s.label === seasonLabel);

            this.seasonSelectionChanged.emit({name: season.name, year: season.year});
        }
    }

    public onDrilldown(rowModel: MnbReportsStoreAreasDisplayRowModel, attribute: {code: string}) {

        const step: MnbReportsStoreAreasDisplayDrilldownStepModel = {
            attribute: this.model.attributes[0],
            value: rowModel.breakdownAttribute,
            nextCode: this.model.drilldownAttribute.code
        };
        this.drilldown.emit(step);
    }

}

