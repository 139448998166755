

export class TenantModelEntityData {

    attributes: {[code: string]: string};
    displayAttributes: {[code: string]: string[]};
    values: {[code: string]: number};
    comparisonValues: {[code: string]: number};

    timeSeries: {
        attribute: string,
        values: {[code: string]: number},
        comparisonValues: {[code: string]: number}
    }[];

    childData: { [code: string]: { hasMore: boolean, children: TenantModelEntityData[] } };

    missingKeyAttributeCodes?: string[];
}