import { Injectable } from '@angular/core';
import { ApiService, Resources } from './api.service';
import { Observable } from 'rxjs';
import { PortalDashboard, PortalDashboardWidgetData, PortalDashboardWidgetDetailData } from '../models/api-dashboard.model';
import { DashboardSettings, DashboardWidgetQuerySettings } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';

@Injectable()
export class ApiDashboardService {


    constructor(
        private apiService: ApiService
    ) {

    }

    public loadDashboards(): Observable<PortalDashboard[]> {
        return this.apiService.query(Resources.DASHBOARD);
    }

    public loadDashboard(id: number): Observable<PortalDashboard> {
        return this.apiService.get(Resources.DASHBOARD, {id});
    }

    public loadWidgetData(dashboardId: number, widgetId: number, settings: DashboardSettings): Observable<PortalDashboardWidgetData> {
        return this.apiService.post(Resources.DASHBOARD_WIDGET_DATA, settings, {dashboardId, widgetId});
    }

    public loadWidgetDetailData(dashboardId: number, widgetId: number, settings: {widgetViewSettings: DashboardWidgetQuerySettings, dashboardViewSettings: DashboardSettings}, params?: {showMore?: boolean}): Observable<PortalDashboardWidgetDetailData> {
        return this.apiService.post(Resources.DASHBOARD_WIDGET_DETAIL_DATA, settings, {dashboardId, widgetId, ... params});
    }

}
