import { NgZone, Injectable } from '@angular/core';
import { BarChartConfig, DonutChartConfig, GaugeChartConfig, LineChartConfig } from './chart.models';
import { BarChartRenderer } from '../renderers/bar-chart-renderer';
import { MnbEmptyValuePipe } from '../../core/pipes/empty-value.pipe';
import { ColumnChartRenderer } from '../renderers/column-chart-renderer';
import { ChartRenderer } from '../renderers/chart-renderer';
import { LineChartRenderer } from '../renderers/line-chart-renderer';
import { DonutChartRenderer } from '../renderers/donut-chart-renderer';
import { GaugeChartRenderer } from '../renderers/gauge-chart-renderer';
import { MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';
import { MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';


@Injectable()
export class MnbChartService {

    constructor(
        private unitPipe: MnbUnitPipe,
        private emptyPipe: MnbEmptyValuePipe,
        private unitService: MnbUnitService,
        private zone: NgZone
    ) { }

    public createBarChartRenderer(config: BarChartConfig): ChartRenderer {
        if (!config) {
            return null;
        }
        return new BarChartRenderer(config, this.zone, this.unitPipe, this.emptyPipe, this.unitService);
    }

    public createGaugeChartRenderer(config: GaugeChartConfig): ChartRenderer {
        if (!config) {
            return null;
        }
        return new GaugeChartRenderer(config, this.zone, this.unitPipe, this.emptyPipe, this.unitService);
    }
    public createColumnChartRenderer(config: BarChartConfig): ChartRenderer {
        if (!config) {
            return null;
        }
        return new ColumnChartRenderer(config, this.zone, this.unitPipe, this.emptyPipe, this.unitService);
    }

    public createLineChartRenderer(config: LineChartConfig): ChartRenderer {
        if (!config) {
            return null;
        }
        return new LineChartRenderer(config, this.zone, this.unitPipe, this.emptyPipe, this.unitService);
    }

    public createDonutChartRenderer(config: DonutChartConfig): ChartRenderer {
        if (!config) {
            return null;
        }
        return new DonutChartRenderer(config, this.zone, this.unitPipe, this.emptyPipe, this.unitService);
    }

}
