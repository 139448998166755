import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';
import { MnbTimeService } from '@shared-lib/modules/core/services/time/time.service';
import { MnbUnitConfig, MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';
import { CURRENCY_SYMBOLS, MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';
import { MnbDurationPipe } from '@shared-lib/modules/core/pipes/duration.pipe';
import { TranslateService } from '@ngx-translate/core';

/*
 * Format the value by using the unit type code.

 * Usage:
 *   value | mnbUnit:'CURRENCY'
 * Example:
 *   {{ 2 | mnbUnit:'CURRENCY':{decimalPlaces: 2} }}
 *   formats to: 2.00 €
*/

@Pipe({ name: 'mnbUnit' })
export class MnbUnitPipe implements PipeTransform {

    private decimalPipe: DecimalPipe;
    currencyFormatter: Function;

    constructor(
        public unitService: MnbUnitService,
        configService: MnbConfigService,
        private timeService: MnbTimeService,
        private datePipe: DatePipe,
        private durationPipe: MnbDurationPipe,
        private translateService: TranslateService
    ) {
        configService.onAvailable(() => {
            this.decimalPipe = new DecimalPipe(configService.langCode === 'deu' ? 'de-DE' : 'en-US');

            if (configService.langCode !== 'eng') {
                this.currencyFormatter = (value: string, input: number, hasSign?: boolean, unitInfo?: MnbUnitInfo): string => {
                    const currencySymbol = this.getCurrencySymbol(unitInfo);

                    return value + (currencySymbol ? ' ' + currencySymbol : '');
                };
            } else {
                this.currencyFormatter = (value: string, input: number, hasSign?: boolean, unitInfo?: MnbUnitInfo): string => {
                    const currencySymbol = this.getCurrencySymbol(unitInfo);

                    return hasSign && input > 0 ?
                        value.charAt(0) + (currencySymbol ? currencySymbol + ' ' : '') + value.substring(1)
                        : (currencySymbol ? currencySymbol + ' ' : '') + value;
                };
            }
        });
    }

    private getCurrencySymbol(unitInfo?: MnbUnitInfo) {
        if (unitInfo && unitInfo.isOriginalCurrency) {
            return (CURRENCY_SYMBOLS[unitInfo.currencyCode] || unitInfo.currencyCode);
        }
        return this.unitService.getCurrencySymbol();
    }

    transform(input: any, unit: string | MnbUnitInfo, config?: MnbUnitConfig): string {
        config = config || MnbUnitConfig.defaultConfig;

        const decimalPlaces = isNullOrUndefined(config.decimalPlaces) ? 2 : config.decimalPlaces;

        const unitInfo: MnbUnitInfo = unit && unit['code'] ? <MnbUnitInfo>unit : null;
        const unitCode = unitInfo ? unitInfo.code : <string>unit;

        if (unitCode === 'NONE') {
            return '' + input;
        } else if (!input && input !== 0) {
            return '-';
        } else if (unitCode === 'QTY') {
            return this.processInput(input, 0, config);
        } else if (unitCode === 'PERCENT') {
            return this.processInput(input * 100, decimalPlaces, config) + (config.isDiff ? ' ' + this.translateService.instant('GENERAL.MISC.PP') : ' %');
        } else if (unitCode === 'CURRENCY') {
            return this.currencyFormatter(this.processInput(input, decimalPlaces, config), input, config.hasSign, unitInfo);
        } else if (unitCode === 'FLOAT') {
            return this.processInput(input, 2, config);
        } else if (unitCode === 'TIME') {
            return this.durationPipe.transform(input * 1000);
        } else if (unitCode === 'DATE') {
            return this.datePipe.transform(new Date(input), this.timeService.getDateFormat());
        } else {
            return '' + input;
        }
    }

    private processInput(input: number, decimalPlaces: number, config: MnbUnitConfig): string {
        let toAdd = '';

        if (input !== Infinity && input !== -Infinity) {
            const refValue = isNullOrUndefined(config.refValue) ? input : config.refValue;
            if (Math.abs(refValue) >= 1E6) {
                toAdd = 'M';
                input = input / 1000000.00;
                decimalPlaces = 3;
            } else if (Math.abs(refValue) >= 1E3) {
                if (config.shortenThousands) {
                    toAdd = 'k'; // or if german?
                    input = input / 1000.00;
                    decimalPlaces = 3;
                } else {
                    decimalPlaces = 0;
                }
            }
        }

        if (!isNullOrUndefined(config.decimalPlaces)) {
            decimalPlaces = config.decimalPlaces;
        }

        const minDecimalPlaces = config.removeDecimalZeros ? 0 : decimalPlaces;

        let result = this.decimalPipe.transform(input, '1.' + minDecimalPlaces + '-' + decimalPlaces);

        if (config.hasSign && input > 0) {
            result = '+' + result;
        }

        return result + toAdd;
    }

}

export class MnbUnitInfo {

    constructor(
        public code: string,
        public isOriginalCurrency?: boolean,
        public currencyCode?: string) { }
}