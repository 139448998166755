import { Directive, HostListener, Input, HostBinding } from '@angular/core';
import { MnbListActionChildDirective } from './mnb-list-action-child.directive';

@Directive({
  selector: '[mnbListActionHandler]'
})
export class MnbListActionHandlerDirective {

    @HostBinding('tabindex') tabIndex = 0;
    @HostBinding('style.outline') outLine = 'none';

    @Input() onEnter: (index: number, element?: MnbListActionChildDirective) => void;

    public listElements: Array<MnbListActionChildDirective> = [];

    @HostListener('keydown.arrowUp', ['$event'])
    onUpKeyDown(event: KeyboardEvent) {
        if (this.listElements.length) {
            // it can com to index changes after registration. thats why we need to resort everytime before navigating up and down
            this.listElements.sort((a, b) => a.index - b.index);

            const prevHoveredIndex = this.getHoveredElementIndex();
            const prevElement = this.listElements[prevHoveredIndex];

            let elementToHoverIndex = this.listElements.length - 1;

            if (prevElement) {
                if (prevHoveredIndex !== 0) {
                    elementToHoverIndex = prevHoveredIndex - 1;
                }
                prevElement.isHovered = false;
            }

            this.listElements[elementToHoverIndex].isHovered = true;
            event.preventDefault();
        }

    }

    @HostListener('keydown.arrowDown', ['$event'])
    onDownKeyDown(event: KeyboardEvent) {
        if (this.listElements.length) {
            // it can com to index changes after registration. thats why we need to resort everytime before navigating up and down
            this.listElements.sort((a, b) => a.index - b.index);

            const prevHoveredIndex = this.getHoveredElementIndex();
            const prevElement = this.listElements[prevHoveredIndex];

            let elementToHoverIndex = 0;
            if (prevElement) {
                if (prevHoveredIndex !== this.listElements.length - 1) {
                    elementToHoverIndex = prevHoveredIndex + 1;
                }
                prevElement.isHovered = false;
            }
            this.listElements[elementToHoverIndex].isHovered = true;
            event.preventDefault();
        }
    }

    @HostListener('keyup.enter', ['$event'])
    onEnterKeyDown(event) {
        if (this.onEnter) {
            event.stopPropagation();
            event.target.blur();
            const index = this.getHoveredElementIndex();
            this.onEnter(index, index === -1 ? null : this.listElements[index]);
        }
    }

    public clearElementHover(index: number): void {
        if (index !== -1) {
            this.listElements[index].isHovered = false;
        }
    }

    public register(element: MnbListActionChildDirective): void {
        this.listElements.push(element);
    }

    public unregister(element: MnbListActionChildDirective): void {
        this.listElements = this.listElements.filter((e) => e !== element);
    }

    public getHoveredElementIndex(): number {
        return this.listElements.findIndex((element) => element.isHovered);
    }
}
