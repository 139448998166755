import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { DataProviderPageComponent, DataProviderPageContextResolver } from '@minubo-portal/modules/data-providers/pages/data-provider-page.component';
import { ApiDataProviderService } from '@minubo-portal/modules/api/services/api-data-provider.service';
import { DATA_PROVIDER_DATA__SERVICE_TOKEN } from './components/data-provider-data.service';
import { DataProviderComponent } from '@minubo-portal/modules/data-providers/components/data-provider.component';
import { PortalDataProviderDataService } from './services/data-providers.service';


@NgModule({
    declarations: [
        DataProviderPageComponent,
        DataProviderComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild([{
            path: ':dataProviderId',
            resolve: {
                context: DataProviderPageContextResolver
            },
            data: {
                header: <AppHeader>{
                    title: ''
                }
            },
            children: [{
                path: '',
                component: DataProviderPageComponent,
            }]
        }])
    ],
    exports: [],
    providers: [
        ApiDataProviderService,
        DataProviderPageContextResolver,
        { provide: DATA_PROVIDER_DATA__SERVICE_TOKEN, useClass: PortalDataProviderDataService }
    ]
})
export class DataProviderModule {}
