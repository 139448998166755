import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { MnbChartComponent } from './components/chart.component';
import { MnbChartTooltipContentComponent } from './components/chart-tooltip-content.component';
import { MnbChartService } from './services/chart.service';
import { MnbCoreModule } from '../core/mnb-core.module';

@NgModule({
    declarations: [
        MnbChartComponent,
        MnbChartTooltipContentComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        MnbCoreModule
    ],
    entryComponents: [
        MnbChartComponent,
        MnbChartTooltipContentComponent
    ],
    providers: [
        MnbChartService
    ],
    exports: [
        MnbChartComponent,
        MnbChartTooltipContentComponent
    ]
})
export class MnbChartsModule {
}
