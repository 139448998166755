import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MnbDashboardService } from './services/mnb-dashboard.service';
import { HttpClientModule } from '@angular/common/http';
import { MnbDashboardWidgetForbiddenComponent } from './components/mnb-dashboard-widget-forbidden.component';
import { MnbDashboardChartService } from './services/mnb-dashboard-chart.service';
import { MnbDashboardListWidgetComponent } from './components/mnb-dashboard-list-widget.component';
import { MnbChartsModule } from '../charts/mnb-charts.module';
import { MnbDashboardSingleValueWidgetComponent } from './components/mnb-dashboard-single-value-widget.component';
import { MnbDashboardTableWidgetComponent } from './components/mnb-dashboard-table-widget.component';
import { MnbDashboardWidgetDataInfotextComponent } from './components/mnb-dashboard-widget-data-infotext.component';
import { MnbQueriesModule } from '../queries/mnb-queries.module';
import { MnbDashboardWidgetComponent } from './components/mnb-dashboard-widget.component';
import { MnbDashboardsWidgetDetailComponent } from './components/mnb-dashboard-widget-detail.component';
import { MnbDashboardWidgetDetailTableComponent } from './components/mnb-dashboard-widget-detail-table.component';
import { MnbDashboardTestWidgetComponent } from './components/mnb-dashboard-text-widget.component';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { MnbDashboardWidgetAlertComponent } from './components/mnb-dashboard-widget-alert.component';
import { MnbCoreModule } from '../core/mnb-core.module';
import { EntityLinkServiceModule } from '@shared-lib/modules/model-entities/entity-link-service.module';
import { AlyModelEntitiesLinkModule } from '@shared-lib/modules/model-entities/model-entities-link.module';

;

@NgModule({
    declarations: [
        MnbDashboardWidgetForbiddenComponent,
        MnbDashboardListWidgetComponent,
        MnbDashboardSingleValueWidgetComponent,
        MnbDashboardTableWidgetComponent,
        MnbDashboardWidgetDataInfotextComponent,
        MnbDashboardWidgetComponent,
        MnbDashboardsWidgetDetailComponent,
        MnbDashboardWidgetDetailTableComponent,
        MnbDashboardTestWidgetComponent,
        MnbDashboardWidgetAlertComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        MnbLibModule,
        MnbCoreModule,
        MnbChartsModule,
        MnbQueriesModule,
        EntityLinkServiceModule,
        AlyModelEntitiesLinkModule
    ],
    entryComponents: [
    ],
    providers: [
        MnbDashboardService,
        MnbDashboardChartService
    ],
    exports: [
        MnbDashboardWidgetForbiddenComponent,
        MnbDashboardListWidgetComponent,
        MnbDashboardSingleValueWidgetComponent,
        MnbDashboardTableWidgetComponent,
        MnbDashboardWidgetDataInfotextComponent,
        MnbDashboardWidgetComponent,
        MnbDashboardsWidgetDetailComponent,
        MnbDashboardWidgetDetailTableComponent,
        MnbDashboardTestWidgetComponent,
        MnbDashboardWidgetAlertComponent,

        MnbChartsModule,
        MnbQueriesModule
    ]
})
export class MnbDashboardsModule {
}
