import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { observeElementResize } from '@shared-lib/modules/dashboards/util/mnb-dashboard-widget.util';

@Component({
    selector: 'mnb-dashboard-text-widget',
    templateUrl: './mnb-dashboard-text-widget.component.html'
})
export class MnbDashboardTestWidgetComponent implements OnDestroy, OnInit {

    @ViewChild('content', { static: true }) contentElementRef: ElementRef;

    private widgetSizeChange$ = new Subject<void>();
    private _content: string;

    @Input() set content(value: string) {
        this._content = value;

        if (!isNullOrUndefined(this.content)) {
            this.updateContentTextBinary();
        }
    }

    get content(): string {
        return this._content;
    }

    constructor(
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        this.widgetSizeChange$.subscribe(() => {
            if (this.content) {
                this.updateContentTextBinary();
            }
        });

        observeElementResize(this.widgetSizeChange$, this.elementRef.nativeElement.parentElement);
    }

    ngOnDestroy(): void {
        this.widgetSizeChange$.complete();
    }

    private updateContentTextBinary() {
        const contentElement = document.createElement('div');
        const style = contentElement.style as any;

        style.position = 'absolute';
        style['overflow-wrap'] = 'break-word';
        style['white-space'] = 'pre-wrap';
        style.top = '-9999px';
        style.left = '-9999px';
        this.contentElementRef.nativeElement.textContent = this.content;
        const containerHeight = this.elementRef.nativeElement.getBoundingClientRect().height;
        style.width = `${this.elementRef.nativeElement.getBoundingClientRect().width}px`;

        document.body.appendChild(contentElement);

        let start = 0;
        let end = this.content.length;
        let shortestText = this.content;


        while (start <= end) {
            const mid = Math.floor((start + end) / 2);
            const text = this.content.slice(0, mid) + '...';
            contentElement.textContent = text;
            if (contentElement.clientHeight > containerHeight) {
                end = mid - 1;
            } else {
                start = mid + 1;
                shortestText = text;
            }
        }

        document.body.removeChild(contentElement);

        this.contentElementRef.nativeElement.textContent = shortestText.length === this.content.length + 3 ? shortestText.slice(0, -3) : shortestText;
    }

}
