import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';
import { Login, TenantLogin } from '@minubo-suite/shared/services/resource/tenant.model';

export enum EntityPresetUseCase {
    OVERVIEW = 'overview',
    MARKETING = 'marketing',
    PRODUCT = 'product',
    SUPPLIER = 'supplier',
    CUSTOMER = 'customer',
    INVENTORY = 'inventory',
    SALES_CHANNEL = 'salesChannel'
}

export interface EntityPresetDetails {
    code: string;
    layout?: boolean;
    title: string;
    description: string;
    useCase: EntityPresetUseCase;
}

export type EntityPreset<T> = T & EntityPresetDetails;

export abstract class EntitySchedule {

    constructor(
        public id: number,
        public frequency?: string,
        public currentRunDateTime?: number,
        public nextRunDateTime?: number,
        public recipients?: User[],
        public settings?: any,
        public entityTitle?: string,
        public entityType?: EntityType,
        public isDeactivated?: boolean,
        public statusCode?: string,
        public targetTime?: string,
        public isAccessible?: boolean) {}

    public abstract getFkEntity(): number;

}

export class User {
    constructor(public login: Login, public tenantLogin: TenantLogin) {}
}

export class UsersList {

    public isExpanded: boolean;

    constructor(public values: User[], public config: ListConfig) {
        values.sort((a, b) => a.login.email.localeCompare(b.login.email));
    }
}
export interface ListConfig {
    expanderLabel: string;
    sectionHeaderLabel?: string;
    showExternalUsersOnly?: boolean;
    showRegularUsersOnly?: boolean;
}

