import { NgZone } from '@angular/core';
import { ChartConfig, ChartData, ChartDataArray, LineChartConfig, BarChartConfig, ChartDataType, ChartDataComparisonType } from '../services/chart.models';
import { MnbUnitInfo, MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { ScaleLinear } from 'd3';
import { isNullOrUndefined } from 'util';
import { MnbChartTooltipContentComponent, MnbChartTooltipData } from '@shared-lib/modules/charts/components/chart-tooltip-content.component';
import { MnbUnitConfig, MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';

export abstract class ChartRenderer {

    protected gElement: d3.Selection<SVGGElement, {}, null, undefined>;
    protected svgElement: d3.Selection<SVGSVGElement, {}, null, undefined>;
    protected svgHTMLElement: Element;

    protected config: ChartConfig;
    protected unitConfig: MnbUnitConfig;
    public specifiedHeight: number;

    el: HTMLElement;
    tooltipContent: MnbChartTooltipContentComponent;

    public parentHeight: number;

    constructor(
        protected zone: NgZone,
        protected unitPipe?: MnbUnitPipe,
        protected emptyPipe?: MnbEmptyValuePipe,
        protected unitService?: MnbUnitService
    ) { }


    public static getBandSpacing(band: d3.ScaleBand<any>, index: number, removeOuterSpacing: boolean) {
        const domainLen = band.domain().length;
        const bandWidth = band.bandwidth();
        if (domainLen === 1 || !removeOuterSpacing) {
            return bandWidth / 2;
        }
        return bandWidth * index / (domainLen - 1);
    }

    abstract render(el: HTMLElement | HTMLElement[]): void;

    public rerender(withoutHeightRecalc?: boolean) {
        this.zone.runOutsideAngular(() => {
            if (this.el) {
                if (!withoutHeightRecalc) {
                this.el.style.height = null;
                // wait to recalc height
                setTimeout(() => {
                    const height = !isNullOrUndefined(this.specifiedHeight) ? this.specifiedHeight : this.getDimensions(this.el.parentElement.parentElement).height;
                    this.el.style.height = height + 'px';
                    this.el.textContent = '';
                    this.render(this.el);
                }, 0);
                } else {
                    this.el.textContent = '';
                    this.render(this.el);
                }
            }
        });
    }

    public updateConfig(config: ChartConfig, updateData?: boolean) {
        if (!updateData) {
            delete config.data;

            delete config.value;

            delete config.additionalValue;
        }

        this.config = Object.assign(this.config, config);
    }

    protected getDimensions(ele: HTMLElement): { height: number, width: number } {
        const computedElementStyle: CSSStyleDeclaration = window.getComputedStyle(ele);
        return {
            height: ele.clientHeight - Number.parseFloat(computedElementStyle.paddingBottom) - Number.parseFloat(computedElementStyle.paddingTop),
            width: ele.clientWidth - Number.parseFloat(computedElementStyle.paddingLeft) - Number.parseFloat(computedElementStyle.paddingRight)
        };
    }

}

export class LinearAxisRenderer {

    public static TICK_SIZE = 5;

    constructor(
        private gElement: d3.Selection<SVGGElement, {}, null, undefined>,
        private config: LineChartConfig | BarChartConfig,
        private isSecondary: boolean,
        private hasSecondary: boolean,
        private unitPipe: MnbUnitPipe) {
    }

    public renderY(chartWidth: number, height: number, limit?: number): { scale: d3.ScaleLinear<number, number>, width: number, formatter: (d: number) => string } {

        const unit = this.isSecondary && this.config.additionalValue.unit ? this.config.additionalValue.unit : (this.config.value.unit ? this.config.value.unit : 'QTY');;

        let unitInfo = unit instanceof MnbUnitInfo ? <MnbUnitInfo>unit : new MnbUnitInfo(<string>unit);

        if (this.config.breakdownMode === 'fill-stacked' || unitInfo.code === 'PERCENT') {
            unitInfo = new MnbUnitInfo('PERCENT');
        }

        // if data.length is zero we need to include zero otherwise min/max is the same and there will be no scale
        // includeZero = includeZero || data.length === 1;

        const y: d3.ScaleLinear<number, number> = d3.scaleLinear().rangeRound([height, 0]);

        const yAxis = this.isSecondary ? d3.axisRight(y) : d3.axisLeft(y);

        const { step, minStep, maxStep, tickCount } = this.calcTickConfig(unitInfo.code, height, limit);

        // TODO: if all data points have the same val min is equals to max what causes provlems in the step generation
        // we need to handle that here!

        (<ScaleLinear<any, any>>y).domain([minStep, maxStep]).nice(tickCount);

        let maxLabelWidth = 0;

        const unitConfig = this.unitPipe.unitService.calcUnitConfig(maxStep, step);

        // breakdownMode full always has a scale from 0-100%
        if (this.config.breakdownMode === 'fill-stacked' || unitInfo.code === 'PERCENT') {
            const stepValue = (step * 100).toString();
            unitConfig.decimalPlaces = stepValue.includes('.') ? stepValue.split('.')[1].length : 0;
        }

        const formatter = (d: number) => {
            return this.unitPipe.transform(d, unitInfo, unitConfig);
        };

        if (!this.config.yAxisConfig.hide) {

            yAxis.tickFormat(formatter).tickSize(this.hasSecondary ? -LinearAxisRenderer.TICK_SIZE : -chartWidth);

            let yAxisElement = this.gElement.append('g')
                .attr('class', 'axis axis--y')
                .call(yAxis.ticks(tickCount))
                .call(g => {
                    g.selectAll('text').each(function () {
                        const size = (<any>this).getBBox().width;
                        maxLabelWidth = Math.max(maxLabelWidth, size);
                    });
                });

            if (this.isSecondary) {
                // TODO: This 2 * seems random - but it works for now, so lets leave it this way
                yAxisElement = yAxisElement.attr('transform', 'translate( ' + (chartWidth - maxLabelWidth - LinearAxisRenderer.TICK_SIZE) + ', 0 )');
            }

            if (!this.hasSecondary) {
                // Remove the yAxis line for line charts and bar charts
                yAxisElement.call(g => g.select('.domain').remove());
            }
        }

        return { scale: y, width: maxLabelWidth + LinearAxisRenderer.TICK_SIZE, formatter: formatter };
    }

    public renderX(width: number, height: number, limit?: number, inverted?: boolean): { scale: d3.ScaleLinear<number, number> } {
        const x: d3.ScaleLinear<number, number> = d3.scaleLinear().rangeRound([0, width]);

        const xAxis = !this.isSecondary && this.hasSecondary ? d3.axisTop(x) : d3.axisBottom(x);
        const yTranslate: number = !this.isSecondary && this.hasSecondary ? 0 : height;

        const unit = this.isSecondary ? this.config.additionalValue.unit : this.config.value.unit;

        const unitInfo = unit instanceof MnbUnitInfo ? <MnbUnitInfo>unit : new MnbUnitInfo(<string>unit);

        const { step, minStep, maxStep, tickCount } = this.calcTickConfig(unitInfo.code, width, limit, inverted);

        (<ScaleLinear<any, any>>x).domain([minStep, maxStep]).nice(tickCount);

        if (!this.config.xAxisConfig.hide) {

            const gContainerX = this.gElement.append('g')
                .attr('class', 'axis axis--x')
                .attr('transform', 'translate(0, ' + yTranslate + ')');

            const tickSize = this.hasSecondary ? -LinearAxisRenderer.TICK_SIZE : -height;
            const unitConfig = this.unitPipe.unitService.calcUnitConfig(maxStep, step);

            let unitCode: string = unitInfo.code;

            if (this.config.breakdownMode === 'fill-stacked' || unitCode === 'PERCENT') {
                unitCode = 'PERCENT';
                unitConfig.decimalPlaces = 0;
            }

            xAxis.tickFormat((d: any) => {
                return this.unitPipe.transform(parseFloat(d), unitCode, unitConfig);
            }).tickSize(tickSize);

            const xAxisContainer = gContainerX.call(xAxis);
            xAxisContainer.call(g => g.select('.domain').remove());

            const rotate = 60;
            let dx = 5;
            let textAnchor = 'end';

            if (!this.isSecondary && this.hasSecondary) {
                dx = -5;
                textAnchor = 'start';
            }

            xAxisContainer.selectAll('text')
                .attr('dx', -dx)
                .attr('transform', 'rotate(-' + rotate + ')')
                .style('text-anchor', textAnchor);
        }
        return { scale: x };
    }

    public calcAxisHeight(): number {
        return (this.config.xAxisConfig.hide ? 5 : (this.config.rotateXLabels ? 50 : 30));
    }

    protected calcTickConfig(unitCode: string, availableSpace: number, limit?: number, inverted?: boolean): { step: number, minStep: number, maxStep: number, tickCount: number } {
        const { min, max } = this.calcMinMax(limit, inverted);

        const tickCount = Math.max(2, Math.ceil(availableSpace / 60)) + 2;

        const step: number = d3.tickStep(min, max, tickCount);

        const minStep = Math.floor(min / step) * step;
        const maxStep = Math.ceil(max / step) * step;

        if (unitCode === 'QTY' && minStep == 0 && maxStep == 1) {
            return { step: 1, minStep: minStep, maxStep: maxStep, tickCount: 1 };

        } else {
            return { step: step, minStep: minStep, maxStep: maxStep, tickCount: tickCount };
        }
    }

    private calcMinMax(limit?: number, inverted?: boolean): { min: number, max: number } {
        const values: number[] = [];

        if (this.config.breakdownMode === 'stacked') {
            const labelValues: { [label: string]: { pos: number, neg: number } } = {};
            this.config.data
                .filter(array => array.type === ChartDataType.breakdown)
                .forEach(array => {
                    const data = array.getData(limit, inverted);
                    data.forEach(dataEle => {
                        const val = labelValues[dataEle.label] = labelValues[dataEle.label] || { pos: 0, neg: 0 };
                        if (dataEle.value < 0) {
                            val.neg += dataEle.value;
                        } else {
                            val.pos += dataEle.value;
                        }
                    });
                });

            Object.values(labelValues).forEach(v => {
                values.push(v.pos);
                values.push(v.neg);
            });

        } else if (this.config.breakdownMode === 'fill-stacked') {
            return { min: 0, max: 1 };
        } else {
            this.config.data
                .filter(array => !this.isSecondary ? (!this.hasSecondary ? true : array.type !== ChartDataType.secondary) : array.type === ChartDataType.secondary)
                .filter(array => this.config.breakdownMode === 'parallel' ? array.type === ChartDataType.breakdown : true)
                .forEach(array => array.getData(limit, inverted).forEach(dataEle => values.push(dataEle.value)));
        }


        let min = Math.min(...values);
        if (min > 0) {
            min = 0;
        }
        let max = Math.max(...values);
        if (max < 0) {
            max = 0;
        }

        /**
         * in case of min max all values in the series are the same,
         * we need min & max to be different, otherwise we can't render a scale
         */
        if (min === max) {
            max++;
        }

        return { min: min, max: max };
    }
}

export abstract class DataRenderer {

    abstract switchHighlight(index: number, highlight: boolean);

}

export class TooltipRenderer {

    constructor(
        protected config: LineChartConfig | BarChartConfig,
        private tooltipContent: MnbChartTooltipContentComponent,
        private dataRenderers: DataRenderer[],
        private sortItems?: boolean,
        private removeOuterSpacing?: boolean
    ) { }

    // types nedd to be added in here
    /**
     * @param scaleBand by default treated as the x-axis
     * @param linearScale by default treated as the y-axis
     * @param scaleBandIsYAxis if set to true linearScale is treated as x-axis and scaleBand as y-axis
     */
    public render(
        svg: d3.Selection<SVGGElement, {}, null, undefined>,
        el: HTMLElement,
        innerRect: Rectangle,
        scaleBand: d3.ScaleBand<string>,
        linearScale: d3.ScaleLinear<number, number>,
        limit: number,
        inverted: boolean,
        scaleBandIsYAxis?: boolean
    ) {

        const bandWidth = scaleBand.step();
        let lastI = -1;
        let ix: number;

        const primaryData = this.config.getDataArray(ChartDataType.primary).getData(limit, inverted);

        svg.append('rect')
            .attr('transform', `translate( ${innerRect.x} , ${innerRect.y})`)
            .attr('class', 'chart-overlay')
            .attr('width', Math.max(innerRect.width, 0))
            .attr('height', Math.max(innerRect.height, 0))
            .on('mouseout', () => {
                // Time out is need so it does not interfere with the mouse move event
                setTimeout(() => {
                    this.dataRenderers.forEach(dataRenderer => {
                        dataRenderer.switchHighlight(ix, false);
                    });

                    this.tooltipContent.tooltipOwner.hideTooltip();
                    lastI = -1;
                });
            })
            .on('mousemove', (d, i, nodes) => {
                if (!this.config.showTooltips) {
                    return;
                }

                const mouse: [number, number] = d3.mouse(nodes[i]);
                const innerx: number = mouse[0];
                const innery: number = mouse[1];

                const xDomainLen = scaleBand.domain().length;

                ix = scaleBandIsYAxis ? xDomainLen - 1 - Math.floor(innery / bandWidth) : Math.floor(innerx / bandWidth);

                if (ix === -1) {
                    ix = 0;
                } else if (ix > xDomainLen - 1) {
                    ix = xDomainLen - 1;
                }

                if (lastI !== ix) {
                    this.dataRenderers.forEach(dataRenderer => {
                        dataRenderer.switchHighlight(ix, true);
                    });

                    const yValue: number = this.initTooltipData(ix, this.config, limit, inverted);

                    const scaleBandVal = scaleBand(primaryData[ix].label) + ChartRenderer.getBandSpacing(scaleBand, ix, this.removeOuterSpacing);

                    const scaleLinearVal = linearScale(yValue);

                    const dy = (scaleBandIsYAxis ? scaleBandVal : scaleLinearVal);
                    const dx = (scaleBandIsYAxis ? scaleLinearVal : scaleBandVal);

                    const scrollingTop = document.scrollingElement.contains(el) ? document.scrollingElement.scrollTop : 0;

                    this.tooltipContent.tooltipOwner.tooltipPlacement = (d3.event.pageX / window.innerWidth < 0.66) ? 'right' : 'left';

                    this.tooltipContent.tooltipOwner.showTooltipOnPosition(
                        el.getBoundingClientRect().top + innerRect.y + dy + scrollingTop,
                        el.getBoundingClientRect().left + innerRect.x + dx
                    );

                    lastI = ix;
                }
                this.tooltipContent.tooltipOwner.detectChanges();
            });
    }


    protected initTooltipData(index: number, config: LineChartConfig | BarChartConfig, limit: number, inverted: boolean): number {

        const dataArray = config.getDataArray(ChartDataType.primary);

        const data = dataArray.getData(limit, inverted)[index];

        let yValue = data.value;

        if (config.customTooltip) {
            this.tooltipContent.customTooltip = {
                index,
                config: config.customTooltip
            };
            return yValue;
        }

        const unit = this.config.value.unit
        const unitInfo = unit instanceof MnbUnitInfo ? <MnbUnitInfo>unit : new MnbUnitInfo(<string>unit);

        let unitCode: string = unitInfo.code;

        const tooltipData = new MnbChartTooltipData();
        tooltipData.values = {
            unitCode: unitCode,
            unit: unitInfo
        };

        tooltipData.headerData = {
            label: data.label
        };

        if (!config.breakdownMode) {
            if (unitInfo.isOriginalCurrency && dataArray.containsCurrency) {
                tooltipData.values.unit = new MnbUnitInfo(unitInfo.code, true, data.label);
            }
            tooltipData.data = {
                label: this.config.value.label,
                value: data.value
            };

            // compare data
            const compareData = config.getDataArray(ChartDataType.primary, ChartDataComparisonType.comparison);
            if (compareData) {
                tooltipData.withComparison = true;
                const d = compareData.getData(limit, inverted)[index];
                tooltipData.data.comparisonValue = d ? d.value : null;
            }


            // plan data
            const planData = this.config.getDataArray(ChartDataType.primary, ChartDataComparisonType.plan);
            if (planData) {
                tooltipData.plan = planData.label;
                const d = planData.getData(limit, inverted)[index];
                tooltipData.data.planValue = d ? d.value : null;
            }

            // secondary data
            const secondaryData = config.getDataArray(ChartDataType.secondary);
            if (secondaryData) {
                const unit = config.additionalValue.unit;
                const unitInfo = unit instanceof MnbUnitInfo ? <MnbUnitInfo>unit : new MnbUnitInfo(<string>unit);

                let unitCode: string = unitInfo.code;

                tooltipData.secondaryValues = {
                    unitCode: unitCode,
                    unit: unitInfo
                };

                if (unitInfo.isOriginalCurrency && dataArray.containsCurrency) {
                    tooltipData.secondaryValues.unit = new MnbUnitInfo(unitInfo.code, true, data.label);
                }

                const s = secondaryData.getData(limit, inverted)[index];
                tooltipData.secondaryData = {
                    label: this.config.additionalValue.label,
                    value: s ? s.value : null
                };

                const secondaryCompare = config.getDataArray(ChartDataType.secondary, ChartDataComparisonType.comparison);
                if (secondaryCompare) {
                    const d = secondaryCompare.getData(limit, inverted)[index];
                    tooltipData.secondaryData.comparisonValue = d ? d.value : null;
                }

                const secondaryPlanData = this.config.getDataArray(ChartDataType.secondary, ChartDataComparisonType.plan);
                if (secondaryPlanData) {
                    const d = secondaryPlanData.getData(limit, inverted)[index];
                    tooltipData.secondaryData.planValue = d ? d.value : null;
                }

            }

        } else {
            // breakdown data
            tooltipData.withSecondHeaderLabel = true;
            tooltipData.headerData.label2 = this.config.value.label;

            tooltipData.headerData.value = data.value;

            tooltipData.breakdownData = config.data
                .filter(array => array.type === ChartDataType.breakdown)
                .map(array => {
                    let breakdownUnitInfo = unitInfo;

                    if (breakdownUnitInfo.isOriginalCurrency && !breakdownUnitInfo.currencyCode && array.isCurrencyBreakdown) {
                        breakdownUnitInfo = new MnbUnitInfo(breakdownUnitInfo.code, true, array.label);
                    }

                    return {
                        label: array.label,
                        value: array.getData(limit, inverted)[index].value,
                        unit: breakdownUnitInfo
                    };
                });

            switch (config.breakdownMode) {
                case 'stacked':
                case 'fill-stacked': {

                    const isFill = config.breakdownMode === 'fill-stacked';

                    const columnTotal = tooltipData.breakdownData.reduce((s, ele) => (isFill ? Math.max(0, ele.value) : ele.value) + s, 0);

                    if (isFill) {
                        tooltipData.withShares = true;
                        tooltipData.headerData.sum = columnTotal;

                        //breakdownData.forEach(ele => ele.value = ele.value / columnTotal);
                        yValue = 0.5;
                    } else {
                        yValue = columnTotal / 2;
                    }


                    break;
                }
                case 'parallel': {

                    if (this.sortItems) {
                        tooltipData.breakdownData.sort((a, b) => b.value - a.value);
                    }

                    if (config instanceof BarChartConfig) {

                        const yMax = Math.max(...tooltipData.breakdownData.map(ele => ele.value));
                        yValue = yMax / 2;
                    } else {
                        const ySum = tooltipData.breakdownData.map(ele => ele.value).reduce((a, b) => a + b, 0);
                        yValue = ySum / tooltipData.breakdownData.length;
                    }

                    break;
                }
                default: // no breakdown configured
            }

        }

        this.tooltipContent.tooltipData = tooltipData;

        return yValue;
    }

}

export class BandAxisRenderer {

    static MAX_X_LABEL_LENGTH = 70;
    static MAX_Y_LABEL_LENGTH = 100;

    constructor(
        private gElement: d3.Selection<SVGGElement, {}, null, undefined>,
        private config: LineChartConfig | BarChartConfig,
        private centerLabels: boolean,
        private emptyPipe: MnbEmptyValuePipe
    ) { }

    public renderX(width: number, height: number, limit?: number): { scale: d3.ScaleBand<string> } {
        const xAxisHeight: number = this.calcXHeight();

        const x = d3.scaleBand().range([0, width]).paddingInner(0);
        const primaryData = this.config.getDataArray(ChartDataType.primary);

        x.domain(primaryData.getData(limit).map((d: ChartData) => d.label));

        if (this.config.xAxisConfig.hide) {
            // return scale but do not render it
            return { scale: x };
        }

        const xAxis = d3.axisBottom(x).tickFormat(value => this.emptyPipe.transform(value));

        const gContainer = this.gElement.append('g')
            .attr('class', 'axis axis--x');

        const axisG = gContainer.call(xAxis);

        let textLength = Math.max(BandAxisRenderer.MAX_X_LABEL_LENGTH, x.bandwidth() - 8);
        let textWidth = textLength;

        const nodes = axisG.selectAll('text');
        if (this.config.rotateXLabels) {
            if (this.centerLabels) {
                axisG.call(function (a) {
                    a.selectAll('.tick line')
                        .attr('transform', 'translate(' + x.bandwidth() / 2 + ', 0)');
                });
            }

            const rotate = this.config.yAxisConfig.hide ? 90 : 60;
            const dy = rotate === 90 ? -8 : 0;
            const dx = 15;

            textLength = (xAxisHeight - dx) / Math.sin(rotate * (Math.PI / 180));
            textWidth = this.config.yAxisConfig.hide ? 10 : 17; //We could also calc this

            nodes
                .attr('dy', dy + 'px')
                .attr('dx', -dx)
                .attr('transform', 'rotate(-' + rotate + ')')
                .style('text-anchor', 'end');
        }

        let labelDisplayDividor = 1;
        let labelStart = 0;
        if (nodes.size() * textWidth > width) {
            labelDisplayDividor = Math.ceil(nodes.size() * textWidth / width);
            if (this.config.rotateXLabels) {
                labelStart = 1;
            } else {
                labelStart = -1;
            }
        }

        axisG.selectAll('text').each((d, i, nodes) => {

            const node = <HTMLElement>nodes[i];
            const text = d3.select(node);

            this.wrapText(text, textLength - 5);

            node.style.display = 'none';
            if ((i + labelStart) % labelDisplayDividor === 0) {
                node.style.display = 'inherit';
            }
        });

        gContainer.attr('transform', 'translate(0,' + height + ')');
        return { scale: x };
    }

    public calcMaximumBands(size: number, rendererConfig: RendererConfig): number {
        const neededSize = this.config.breakdownMode === 'parallel' ? 40 : (rendererConfig.hasSecondaryData() ? 30 : 20);
        const primaryData = this.config.getDataArray(ChartDataType.primary);
        const items = primaryData.getData().length;
        const bands = Math.min(Math.floor(size / neededSize), items);
        return bands;
    }

    public calcXHeight(): number {
        return (this.config.xAxisConfig.hide ? 5 : (this.config.rotateXLabels ? 70 : 30));
    }

    public renderY(height: number, width: number, limit?: number): { scale: d3.ScaleBand<string>, width: number } {

        const primaryData = this.config.getDataArray(ChartDataType.primary);

        const y = d3.scaleBand().range([height, 0]);
        const yAxis = d3.axisLeft(y).tickFormat(value => this.emptyPipe.transform(value));

        y.domain(primaryData.getData(limit, true).map((d: ChartData) => d.label));

        if (this.config.yAxisConfig.hide) {
            // return scale but do not render it
            return { scale: y, width: 0 };
        }

        const maxAllowedLength = Math.max(BandAxisRenderer.MAX_Y_LABEL_LENGTH, width / 3);
        const maxLabelWidth = Math.min(this.getMaxLabelLength(), maxAllowedLength);
        const axisWidth = maxLabelWidth + 10;

        const gContainerY = this.gElement.append('g')
            .attr('class', 'axis axis--y')
            .attr('transform', `translate(0,0)`);

        const containerAxisY = gContainerY.call(yAxis);

        containerAxisY.call((a) => {
            a.selectAll('.tick line')
                .attr('transform', 'translate(0,' + y.bandwidth() / 2 + ')');
        });

        if (maxLabelWidth == maxAllowedLength) {
            gContainerY.selectAll('text').each((d, i, nodes) => {

                const node = <HTMLElement>nodes[i];
                const text = d3.select(node);

                this.wrapText(text, maxLabelWidth);
            });
        }

        //containerAxisY.call(g => g.select('.domain').remove());
        return { scale: y, width: axisWidth };
    }

    private getMaxLabelLength(): number {

        const labelLengths: number[] = [];
        // render labels, get width and remove them afterwards
        this.gElement.append('g')
            .selectAll('.temp-text')
            .data(this.config.getDataArray(ChartDataType.primary).getData())
            .enter()
            .append('text')
            .attr('font-family', 'sans-serif')
            .attr('font-size', '10px')
            .attr('opacity', 0.0)
            .text((d) => this.emptyPipe.transform(d.label))
            .each(function (d, i) {
                const thisWidth = this.getComputedTextLength();
                labelLengths.push(thisWidth);
                this.remove(); // remove them just after displaying them
            });

        return Math.max(...labelLengths);
    }

    private wrapText(e: any, width: number) {
        let textLength = e.node().getComputedTextLength();
        let text = e.text();
        while (textLength > width && text.length > 0) {
            text = text.slice(0, -1);
            e.text(text + '...');
            textLength = e.node().getComputedTextLength();
        }
    }
}

export abstract class StackedRenderer extends DataRenderer {

    constructor(private filled: boolean) {
        super();
    }

    protected createSeries(arrays: ChartDataArray[], keys: string[], limit?: number, inverted?: boolean) {
        arrays = [...arrays].reverse();
        const stack = d3.stack().keys(arrays.map(array => array.label));

        const columnTotals: number[] = [];
        if (this.filled) {
            keys.forEach((key, i) => {
                columnTotals[i] = 0;
                arrays.forEach((array) => {
                    columnTotals[i] += Math.max(0, array.getData(limit, inverted)[i].value);
                });
            });
        }
        const data = keys.map((key, i) => {
            const res = {};
            arrays.forEach(array => {
                const value = array.getData(limit, inverted)[i].value;
                res[array.label] = this.filled ? Math.max(value, 0) / columnTotals[i] : value;
            });
            res['label'] = key;
            return res;
        });
        stack.offset(d3.stackOffsetDiverging);

        const series = stack(data);
        return series;
    }

    protected getKeys(arrays: ChartDataArray[], limit?: number, inverted?: boolean): string[] {
        const keySet = new Set<string>();
        arrays.forEach(array => array.getData(limit, inverted).forEach(data => {
            keySet.add(data.label);
        }));
        return Array.from(keySet);
    }

}

export class Rectangle {
    public x: number;
    public y: number;
    public width: number;
    public height: number;
}

export class RendererConfig {

    private primaryData: ChartDataArray;
    private comparisonData: ChartDataArray;
    private secondaryData: ChartDataArray;
    private secondaryComparisonData: ChartDataArray;
    private breakdownData: ChartDataArray[];
    private primaryPlanData: ChartDataArray;
    private secondaryPlanData: ChartDataArray;

    constructor(private config: LineChartConfig | BarChartConfig) {
        this.primaryData = this.config.getDataArray(ChartDataType.primary);
        this.comparisonData = this.config.getDataArray(ChartDataType.primary, ChartDataComparisonType.comparison);
        this.secondaryData = this.config.getDataArray(ChartDataType.secondary);
        this.secondaryComparisonData = this.config.getDataArray(ChartDataType.secondary, ChartDataComparisonType.comparison);
        this.breakdownData = this.config.getDataArrays(ChartDataType.breakdown);
        this.primaryPlanData = this.config.getDataArray(ChartDataType.primary, ChartDataComparisonType.plan);
        this.secondaryPlanData = this.config.getDataArray(ChartDataType.secondary, ChartDataComparisonType.plan);
    }

    public hasComparisonData(): boolean {
        return !isNullOrUndefined(this.comparisonData) || !isNullOrUndefined(this.secondaryComparisonData);
    }

    public hasSecondaryData(): boolean {
        return !isNullOrUndefined(this.secondaryData);
    }

    public hasBreakdownData(): boolean {
        return !isNullOrUndefined(this.breakdownData) && this.breakdownData.length !== 0;
    }

    public hasPlanData(): boolean {
        return !isNullOrUndefined(this.primaryPlanData);
    }

    public hasSecondaryAxis(): boolean {
        return this.config.additionalAxis;
    }

    public getPrimaryData(): ChartDataArray {
        return this.primaryData;
    }

    public getComparisonData(): ChartDataArray {
        return this.comparisonData;
    }

    public getSecondaryData(): ChartDataArray {
        return this.secondaryData;
    }

    public getSecondaryComparisonData(): ChartDataArray {
        return this.secondaryComparisonData;
    }

    public getBreakdownData(): ChartDataArray[] {
        return this.breakdownData;
    }

    public getPrimaryPlanData(): ChartDataArray {
        return this.primaryPlanData;
    }

    public getSecondaryPlanData(): ChartDataArray {
        return this.secondaryPlanData;
    }
}

export class LegendRenderer {

    constructor(
        private config: LineChartConfig | BarChartConfig,
        private emptyPipe: MnbEmptyValuePipe
    ) { }


    /**
     * @return height of the legend
     */
    public render(svg: d3.Selection<SVGSVGElement, {}, null, undefined>, el: HTMLElement, data: ChartDataArray[], width?: number): number {

        // no legend if none or only one data entry -> height is zero
        if (data.length <= 1) {
            return 0;
        }

        const iconWidth = 16;
        const iconMargin = 6;

        const itemsPerLine = Math.max(data.length, 5);
        //hint: this approach is flawd since there might be some items significantly smaller than 120 and therefor there is more space for others - but i have no time at the moment to calculate this...
        const maxNodeLength = Math.max(width / itemsPerLine, 120);
        const maxTextLength = maxNodeLength - iconWidth - iconMargin; // subtract with of the legend symbol
        const isBreakdownLegend = data.filter(series => series.type === ChartDataType.breakdown).length > 0;

        const legendData: LegendData[] = this.createLegendData(data);

        const legend = svg.selectAll('legend')
            .data(legendData)
            .enter()
            .append('g')
            .attr('class', 'legend');

        legend.append('path')
            .attr('class', (leg) => leg.className)
            .style('stroke', leg => leg.color)
            .attr('width', 12)
            .attr('height', 8);

        const lineHeight = 14;
        const textMargin = 12;

        let currentX = 0;
        let currentY = 9;

        const xy: Array<{ x: number, y: number }> = [];

        const drawLine = d3.line<{ x: number, y: number }>()
            .x((n) => n.x)
            .y((n) => n.y);

        legend.append('text').text((leg) => {
            return leg.label;
        }).each((d, i, nodes) => {
            if (isBreakdownLegend) {
                // cut off too long labels
                const node = d3.select(nodes[i]);
                while (node.node().getComputedTextLength() > maxTextLength) {
                    node.text(node.text().slice(0, -4));
                    node.text(node.text() + '...');
                }
            }
        }).attr('y', (d, i, nodes) => {
            const hasLabel = legendData[i].label;
            const textWidth = nodes[i].getBoundingClientRect().width;
            const nodeWidth = textWidth + iconMargin + iconWidth + (hasLabel ? textMargin : 0);

            //if there is no legend we 'combine' this item with the next one
            const combinedWidth = !hasLabel && nodes.length > i + 1
                ? nodes[i + 1].getBoundingClientRect().width + iconWidth + iconMargin + textMargin + nodeWidth
                : nodeWidth;

            if (currentX + combinedWidth > width) {
                currentX = 0;
                currentY += lineHeight;
            }

            xy.push({ x: currentX, y: currentY });

            currentX += nodeWidth;

            return currentY;
        }).attr('x', (d, i, nodes) => {
            const rect = nodes[i].parentElement.querySelector('path');
            const xLeft = xy[i].x;
            const xRight = xLeft + iconWidth
            const y = xy[i].y - 4;

            d3.select(rect).attr('d', drawLine([{ x: xLeft, y: y }, { x: xRight, y: y }]));

            return xLeft + iconWidth + iconMargin;
        });

        return xy.map((v) => v.y).reduce((a, b) => Math.max(a, b), 0) + 12;
    }

    private createLegendData(data: ChartDataArray[]): LegendData[] {
        const hasSecondaryComparison = data.filter(series => series.type === ChartDataType.secondary && series.isComparison === true).length > 0;
        const hasSecondaryLegends = data.find(series => series.type === ChartDataType.secondary) && (this.config instanceof LineChartConfig || this.config.secondaryAsLine);

        if (this.config.breakdownMode) {
            data = data.filter(series => series.type === ChartDataType.breakdown);
        } else {
            data = data
                .filter(series => series.type === ChartDataType.secondary ? !series.isComparison && !series.isPlan : true)
                .sort((a, b) => {
                    const sortIndexA = this.getSortIndex(a);
                    const sortIndexB = this.getSortIndex(b);
                    return sortIndexA - sortIndexB;
                });
        }

        const legendData: LegendData[] = [];

        data.forEach((ele: ChartDataArray, idx: number) => {
            const legendEle = new LegendData(this.emptyPipe.transform(ele.label), '', null);
            let secondaryLegendEle: LegendData;

            if (ele.type === ChartDataType.primary) {
                legendEle.className = 'primary-series';
            } else if (ele.type === ChartDataType.secondary) {
                legendEle.className = 'secondary-series';
            } else if (ele.type === ChartDataType.breakdown) {
                legendEle.color = ele.color;
                legendEle.className = 'chart-stroke-color-' + (idx);
            }

            if (ele.isComparison || ele.isPlan) {
                legendEle.className = ele.isComparison ? 'comparison-series' : 'plan-series';

                if (hasSecondaryComparison || (this.config instanceof BarChartConfig)) {
                    legendEle.className += hasSecondaryLegends && !(<any>this.config).secondaryAsLine ? (ele.isComparison ? ' chart-stroke-color-light-green' : ' chart-stroke-color-lighter-green') : ' grey';
                }

                if (hasSecondaryLegends) {
                    secondaryLegendEle = new LegendData(legendEle.label, ele.isComparison ? 'comparison-series' : 'plan-series', null);
                    secondaryLegendEle.className += ele.isComparison ? ' chart-stroke-color-light-purple' : ' chart-stroke-color-lighter-purple';
                    legendEle.label = null;
                }
            }

            if (this.config instanceof BarChartConfig && !(this.config.secondaryAsLine && ele.type === ChartDataType.secondary)) {
                legendEle.className += ' bar';
            }

            legendData.push(legendEle);

            if (secondaryLegendEle) {
                legendData.push(secondaryLegendEle);
            }

        });


        return legendData;
    }

    private getSortIndex(array: ChartDataArray): number {
        if (array.isPlan) {
            return 3;
        }
        if (array.isComparison) {
            return 2;
        } else {
            return array.type === ChartDataType.primary ? 0 : 1;
        }
    }

}

export class LegendData {
    constructor(public label: string, public className: string, public color: string) { }
}
