/**
 * Null-safe Convenience function to check if an object is an Array and if it contains data
 * @param data null-safe. Will be checked if it's an array with data
 * @returns false if data is not an array or is an array but doesn't contain any data
 */
export function isArrayWithData(data: unknown): boolean {
    if (!Array.isArray(data)) {
        return false;
    }
    return data.length > 0;
}
