import { TimeTypeCode } from '@shared-lib/modules/data/model/mnb-data-query.model';

export class PeriodOption {
    constructor(public code: TimeTypeCode, public label: string) {}
}

export class Periods {
    public static getLabelCode(period: TimeTypeCode, singular?: boolean) {
        switch (period) {
            case TimeTypeCode.Days:
                return singular ? 'GENERAL.TIME.LABEL.DAY' : 'GENERAL.TIME.LABEL.DAYS'
            case TimeTypeCode.Weeks:
                return singular ? 'GENERAL.TIME.LABEL.WEEK' : 'GENERAL.TIME.LABEL.WEEKS'
            case TimeTypeCode.Months:
                return singular ? 'GENERAL.TIME.LABEL.MONTH' : 'GENERAL.TIME.LABEL.MONTHS'
            case TimeTypeCode.Years:
                return singular ? 'GENERAL.TIME.LABEL.YEAR' : 'GENERAL.TIME.LABEL.YEARS'
        }
        return null;
    }
}
