import { NgModule } from '@angular/core';
import { AlyModelEntitiesEntityLinkComponent } from '@shared-lib/modules/model-entities/components/aly-model-entities-entity-link.component';
import { MnbCoreModule } from '@shared-lib/modules/core/mnb-core.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [
        AlyModelEntitiesEntityLinkComponent,
    ],
    imports: [
        MnbCoreModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,

    ],
    entryComponents: [

    ],
    exports: [
        AlyModelEntitiesEntityLinkComponent
    ],
    providers: [
    ]
})
export class AlyModelEntitiesLinkModule {
    constructor() {

    }
}
