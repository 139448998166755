import { Injectable } from '@angular/core';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';
import { isNullOrUndefined } from 'util';


export class MnbUnitConfig {

    static defaultConfig = new MnbUnitConfig();

    /**
     * Deicalmal places to show. If not given a default value
     */
    decimalPlaces?: number;
    hasSign?: boolean;
    shortenThousands?: boolean;
    refValue?: number;
    removeDecimalZeros?: boolean;
    isDiff?: boolean;
}

export class CurrenyConfig {
    constructor(public symbol: string, public isBefore?: boolean) { }
}

@Injectable()
export class MnbUnitService {

    constructor(private mnbConfigService: MnbConfigService) { }

    public getCurrencySymbol(): string {
        return this.mnbConfigService.currencySymbol;
    }

    public calcUnitConfig(refValue: number, stepValue?: number): MnbUnitConfig {
        const config = new MnbUnitConfig();
        refValue = Math.abs(refValue);
        config.refValue = refValue;

        if (isNullOrUndefined(stepValue)) {
            return config;
        }

        if (refValue < 1) {
            config.decimalPlaces = 2;
        } else if (refValue < 1000) {
            if (stepValue < 1) {
                config.decimalPlaces = 2;
            } else {
                config.decimalPlaces = 0;
            }
        } else if (refValue < 1000000) {

            if (stepValue < 1) {
                config.decimalPlaces = 2;
            } else if (stepValue <= 9) {
                config.decimalPlaces = 0;
            } else if (stepValue <= 99) {
                config.decimalPlaces = 2;
                config.shortenThousands = true;
            } else if (stepValue <= 999) {
                config.decimalPlaces = 1;
                config.shortenThousands = true;
            } else {
                config.decimalPlaces = 0;
                config.shortenThousands = true;
            }
        } else {
            if (stepValue < 1) {
                config.decimalPlaces = 2;
            } else if (stepValue <= 999) {
                config.decimalPlaces = 0;
            } else if (stepValue <= 9999) {
                config.decimalPlaces = 3;
            } else if (stepValue <= 99999) {
                config.decimalPlaces = 2;
            } else if (stepValue <= 999999) {
                config.decimalPlaces = 1;
            } else {
                config.decimalPlaces = 0;
            }
        }

        return config;
    }



}
