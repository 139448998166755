import { Component, Inject, Input } from '@angular/core';
import { MnbDropdownMenuComponent } from './mnb-dropdown-menu.component';

@Component({
    selector: 'mnb-dropdown-menu-item',
    template: `
    <div
        class="item" [ngClass]="{
            'item-no-padding': item.firstChild.nodeName === 'A',
            'item-no-hover': noHover,
            'item-none-selectable': !isSelectable
        }"
        (click)="onClick()" #item>
        <ng-content></ng-content>
    </div>
    `
})

export class MnbDropdownMenuItemComponent {

    private dropdownMenu: MnbDropdownMenuComponent;
    public noPadding: boolean;

    @Input() value: any;
    @Input() propertyName: string;
    @Input() noHover: boolean;
    @Input() isSelectable: boolean = true;

    constructor (@Inject(MnbDropdownMenuComponent) component) {
        this.dropdownMenu = component;
    }

    public onClick(): void {
        if (this.isSelectable) {
            const value = this.propertyName ? this.value[this.propertyName] : this.value;
            this.dropdownMenu.selectItem(value);
        }
    }

}
