import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MnbPaginationPageComponent } from './pagination-page.component';

@Component({
    selector: 'mnb-pagination',
    templateUrl: './pagination.component.html',
    host: { 'class': 'mnb-pagination' }
})
export class MnbPaginationComponent implements OnInit {

    @Input() config: PaginationConfig;
    @Output() onPageChange = new EventEmitter<number>();
    @Output() onFinalButtonClick = new EventEmitter();

    selectedNumber: number = -1;
    selectedPage: MnbPaginationPageComponent;

    pages: MnbPaginationPageComponent[] = [];

    ngOnInit(): void {
        this.config = this.config || new PaginationConfig();
        if (this.config.numberOfPages) {
            this.pages = new Array(this.config.numberOfPages).fill(null);
        }
    }

    selectPage(value: number, emitChange: boolean = true) {
        if (this.selectedPage) {
            this.selectedPage.show = false;
        }

        this.selectedPage = this.pages[value - 1];
        this.selectedNumber = value;
        
        if (this.selectPage) {
            this.selectedPage.show = true;
        }

        if (emitChange) {
            this.onPageChange.emit(this.selectedNumber);
        }
    }

    next() {
        const maxCount = this.pages.length;
        if (this.selectedNumber < maxCount) {
            this.selectPage(this.selectedNumber + 1);
        }
    }

    previous() {
        if (this.selectedNumber - 1 > 0) {
            this.selectPage(this.selectedNumber - 1);
        }
    }

    registerPage(page: MnbPaginationPageComponent) {
        this.pages.push(page);
        if (this.selectedNumber == -1) {
            this.selectPage(1, false);
        }
    }
    
    unRegisterPage(page: MnbPaginationPageComponent) {
        this.pages = this.pages.filter(p => p !== page);
    }

}

export enum PageButtonType {
    DOTS = 'dots',
    NUMBERS = 'numbers'
}

export class PaginationConfig {
    isDotStyle?: boolean;
    numberOfPages?: number;
    hideNavigationButtons?: boolean;
    centerButtons?: boolean;
    wrapButtons?: boolean;
    finalButtonLabel?: string;
}