import { Injectable } from '@angular/core';
import { ValidatorFn, FormControl, AsyncValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from 'util';
import { escapeRegex } from '../../../../../../minubo-suite/src/app/shared/utils/regex.util';

export class MnbFormError {

    public exists: (errors: any) => boolean;

    constructor(public key: string, public label: string | ((errors: any) => string), public getData?: (errors: any) => any) {
        this.exists = errors => !isNullOrUndefined(errors[this.key]);
    }

    customDetection(method: (errors: any) => boolean) {
        this.exists = method;
        return this;
    }

}

@Injectable({ providedIn: 'root' })
export class MnbValidators {

    public static ALLOWED_PASSWORD_SPECIAL_CHARS = ' !\"#$%&\'()*+,-./;<=>?@[\\]^_`{|}~].*';
    public static FORBIDDEN_PASSWORT_SPECIAL_CHARS = ':';
    public static PASSWORD_MIN_LENGTH = 8;

    private static Errors = [
        new MnbFormError('required', 'GENERAL.ERROR.REQUIRED'),

        new MnbFormError('min', 'GENERAL.ERROR.MIN', errors => ({ minValue: errors.min.min })),
        new MnbFormError('minCompare', 'GENERAL.ERROR.MIN', errors => ({ minValue: errors.minCompare.min })),
        new MnbFormError('max', 'GENERAL.ERROR.MAX', errors => ({ maxValue: errors.max.max })),
        new MnbFormError('maxlength', 'GENERAL.ERROR.MAX_LENGTH', errors => ({ length: errors.maxlength.requiredLength })),

        new MnbFormError('email', 'GENERAL.ERROR.EMAIL'),
        new MnbFormError('number', 'GENERAL.ERROR.NUMBER'),
        new MnbFormError('noSpace', 'GENERAL.ERROR.NO_SPACE'),

        new MnbFormError('date', 'GENERAL.ERROR.DATE'),
        new MnbFormError('dateRangeError', 'GENERAL.ERROR.DATE_RANGE'),

        new MnbFormError('bandRangeError', 'GENERAL.ERROR.BAND_RANGE'),

        new MnbFormError('passwordMatch', 'GENERAL.ERROR.PASSWORD_MATCH'),
        new MnbFormError('passwordCorrect', 'GENERAL.ERROR.PASSWORD_CORRECT'),
        new MnbFormError('passwordErrorSpecialChar', 'GENERAL.ERROR.PASSWORD_SPECIAL_CHAR', () => ({ specialChars: MnbValidators.ALLOWED_PASSWORD_SPECIAL_CHARS })),
        new MnbFormError('passwordErrorForbiddenSpecialChar', 'GENERAL.ERROR.FORBIDDEN_PASSWORD_SPECIAL_CHAR', () => ({ specialChars: MnbValidators.FORBIDDEN_PASSWORT_SPECIAL_CHARS })),
        new MnbFormError('passwordErrorLowercase', 'GENERAL.ERROR.PASSWORD_LOWERCASE'),
        new MnbFormError('passwordErrorUppercase', 'GENERAL.ERROR.PASSWORD_UPPERCASE'),
        new MnbFormError('passwordErrorNumber', 'GENERAL.ERROR.PASSWORD_NUMBER'),
        new MnbFormError('passwordErrorMin', 'GENERAL.ERROR.PASSWORD_MIN', () => ({ minLength: MnbValidators.PASSWORD_MIN_LENGTH })),
        new MnbFormError('invalidModelName', 'GENERAL.ERROR.FORBIDDEN_SPECIAL_CHARS')
    ];

    public static buildErrorsList(control: FormControl, translateService: TranslateService) {
        const errors = [];
        const controlErrs = control.errors;

        if (!controlErrs) {
            return errors;
        }

        MnbValidators.Errors.forEach(error => {
            const hasError = error.exists(controlErrs);

            if (hasError) {
                const label = typeof error.label == 'string' ? error.label : error.label(controlErrs);
                const data = error.getData && error.getData(controlErrs);
                translateService.get(label, data).subscribe((res) => errors.push(res));
            }
        });

        if (controlErrs.customErrors) {
            controlErrs.customErrors.forEach(err => errors.push(err.description));
        }

        return errors;
    }

    constructor() { }

    public static isValidDate(): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            let date: any;
            let parseError: boolean;
            try {
                // parse it to json
                date = new Date(control.value);
                parseError = !(date instanceof Date && !isNaN(date.getTime())) && control.value !== '';
            } catch (ex) {
                // set parse error if it fails
                parseError = true;
            }
            return parseError ? { 'date': parseError } : null;
        };
    }

    public static isValidDateRange(toControl: FormControl): ValidatorFn {
        return (fromControl: FormControl): { [key: string]: any } | null => {

            if (fromControl.invalid || toControl.invalid) {
                return null;
            }

            toControl.valueChanges.subscribe(() => {
                if (!fromControl.hasError('date') && !fromControl.hasError('required')) {
                    fromControl.updateValueAndValidity();
                }
            });

            if (fromControl.value && toControl.value && fromControl.value > toControl.value) {
                return {
                    dateRangeError: true
                };
            }
            return null;
        };
    }

    // This is a async validator
    public static minCompare(fromControl: FormControl, config?: { formatter?: (value: number) => string }): AsyncValidatorFn {
        return (toControl: FormControl): Promise<{ [key: string]: any } | null> => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (toControl.invalid || fromControl.invalid) {
                        resolve(null);
                    }

                    const hasValue = !isNullOrUndefined(fromControl.value) && !isNullOrUndefined(toControl.value);
                    const formatter = config && config.formatter;

                    if (hasValue && fromControl.value >= toControl.value) {
                        resolve({
                            minCompare: { min: formatter ? formatter(fromControl.value) : fromControl.value }
                        });
                    }

                    resolve(null);
                });
            });
        };
    }

    public static isValidEmail(): ValidatorFn {
        return (formControl: FormControl): { [key: string]: any } | null => {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{1,})$/i;
            return !regex.test(formControl.value) ? { email: true } : null;
        };
    }

    public static isValidPassword(): ValidatorFn {
        return (formControl: FormControl): { [key: string]: any } | null => {

            const password = formControl.value;

            if (isNullOrUndefined(password) || password === '') {
                return null;
            }

            const errors: {
                passwordErrorUppercase?: boolean,
                passwordErrorLowercase?: boolean,
                passwordErrorSpecialChar?: boolean,
                passwordErrorNumber?: boolean,
                passwordErrorMin?: boolean,
                passwordErrorForbiddenSpecialChar?: boolean
            } = {};

            if (password.length < this.PASSWORD_MIN_LENGTH) {
                errors.passwordErrorMin = true;
            }

            if (!new RegExp('.*[a-z].*').test(password)) {
                errors.passwordErrorLowercase = true;
            }

            if (!new RegExp('.*[A-Z].*').test(password)) {
                errors.passwordErrorUppercase = true;
            }

            if (!new RegExp('.*[0-9].*').test(password)) {
                errors.passwordErrorNumber = true;
            }

            if (!new RegExp(`.*[${escapeRegex(MnbValidators.ALLOWED_PASSWORD_SPECIAL_CHARS)}].*`).test(password)) {
                errors.passwordErrorSpecialChar = true;
            }

            if (new RegExp(`.*[${escapeRegex(MnbValidators.FORBIDDEN_PASSWORT_SPECIAL_CHARS)}].*`).test(password)) {
                errors.passwordErrorForbiddenSpecialChar = true;
            }

            return Object.keys(errors).length > 0 ? errors : null;
        };
    }

    public static isValidModelFieldName(): ValidatorFn {
        return (formControl: FormControl): { [key: string]: any } | null => {

            const name = formControl.value;

            if (isNullOrUndefined(name) || name === '') {
                return null;
            }

            /*
            console.log('^[A-Za-z0-9 ' + escapeRegex('_-%ø$€&!?*+#()[]') + ']*$');
            if (!new RegExp('^[A-Za-z0-9 ' + escapeRegex('_-%ø$€&!?*+#()[]') + ']*$').test(name)) {
              return {invalidModelName: true};
            }*/

            return null;
        };
    }

    public static hasCustomErrors(array: MnbCustomError[]): ValidatorFn {
        return (formControl: FormControl): { [key: string]: any } | null => {
            if (array) {
                for (let i = 0; i < array.length; i++) {
                    const e = array[i];
                    if (e.removeOnChange) {
                        if (e['_value'] === undefined) {
                            e['_value'] = formControl.value;
                        } else if (e['_value'] !== formControl.value) {
                            array.splice(i, 1);
                            i--;
                        }
                    }
                }
            }
            return array && array.length ? { customErrors: array } : null;
        };
    }

}

export class MnbCustomError {
    description: string;
    removeOnChange?: boolean;
}
