import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { MnbChartsModule } from '../charts/mnb-charts.module';
import { MnbQueriesModule } from '../queries/mnb-queries.module';

import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { MnbCoreModule } from '../core/mnb-core.module';
import { MnbReportsMeasureSelectorComponent } from '@shared-lib/modules/reports/components/measure-selector/mnb-reports-measure-selector.component';
import {
    MnbReportsInventoryDisplayComponent,
    MnbReportsDrilldownStepsComponent,
    MnbReportsEntityDrilldownDisplayDataComponent, MnbReportsEntityDrilldownDisplayComponent
} from '@shared-lib/modules/reports/components';
import { MnbReportsRankComparisonDisplayComponent } from '@shared-lib/modules/reports/components/rank-comparison/mnb-reports-rank-comparison-display.component';
import { MnbFiltersModule } from '@shared-lib/modules/filters/mnb-filters.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MnbReportsTableDisplayComponent } from '@shared-lib/modules/reports/components/table/display/mnb-reports-table-display.component';
import { MnbReportsTableDownloadDataSelectorComponent } from '@shared-lib/modules/reports/components/table/download-data-selector/mnb-reports-table-download-data-selector.component';
import { MnbReportsTableDrillupComponent } from '@shared-lib/modules/reports/components/table/drillup/mnb-reports-table-drillup.component';
import { MnbReportsStoreAreasDisplayComponent } from '@shared-lib/modules/reports/components/store-areas/display/mnb-reports-store-areas-display.component';
import { MnbReportsStoreAreasDisplayDataComponent } from './components/store-areas/display-data/mnb-reports-store-areas-display-data.component';
import { MnbReportsStoreAreasDrilldownStepsComponent } from './components/store-areas/drilldown-steps/mnb-reports-store-areas-drilldown-steps.component';
import { MnbReportsExperienceGroupDisplayComponent } from './components/experience-group/mnb-reports-experience-group-display.component';
import { EntityLinkServiceModule } from '@shared-lib/modules/model-entities/entity-link-service.module';
import { AlyModelEntitiesLinkModule } from '@shared-lib/modules/model-entities/model-entities-link.module';

@NgModule({
    declarations: [
        MnbReportsEntityDrilldownDisplayComponent,
        MnbReportsDrilldownStepsComponent,
        MnbReportsMeasureSelectorComponent,
        MnbReportsTableDownloadDataSelectorComponent,
        MnbReportsEntityDrilldownDisplayDataComponent,
        MnbReportsInventoryDisplayComponent,
        MnbReportsTableDisplayComponent,
        MnbReportsTableDrillupComponent,
        MnbReportsRankComparisonDisplayComponent,
        MnbReportsStoreAreasDisplayComponent,
        MnbReportsStoreAreasDisplayDataComponent,
        MnbReportsStoreAreasDrilldownStepsComponent,
        MnbReportsExperienceGroupDisplayComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        TranslateModule,
        MnbLibModule,
        MnbCoreModule,
        MnbChartsModule,
        MnbQueriesModule,
        MnbFiltersModule,
        EntityLinkServiceModule,
        AlyModelEntitiesLinkModule,
    ],
    entryComponents: [],
    providers: [],
    exports: [
        MnbReportsEntityDrilldownDisplayComponent,
        MnbReportsEntityDrilldownDisplayDataComponent,
        MnbReportsDrilldownStepsComponent,
        MnbReportsMeasureSelectorComponent,
        MnbReportsTableDownloadDataSelectorComponent,
        MnbReportsInventoryDisplayComponent,
        MnbReportsTableDisplayComponent,
        MnbReportsTableDrillupComponent,
        MnbReportsRankComparisonDisplayComponent,
        MnbReportsStoreAreasDisplayComponent,
        MnbReportsExperienceGroupDisplayComponent,
        MnbChartsModule,
        MnbQueriesModule
    ]
})
export class MnbReportsModule {
}
