export class LocalSettingsStore<T> {
    private readonly keyPrefix: string;

    public constructor(prefix: string) {
        this.keyPrefix = 'v1.' + prefix;
    }

    public store(settingsId: string|number, settings: T) {
        const localSettings = this.loadSettingsFromStorage();
        const entityEmbedmentCodes = Object.keys(localSettings);
        const updateOrder = Object.keys(localSettings).map(key => localSettings[key].updateOrder).reduce((a, b) => Math.max(a, b), 0) + 1;

        const settingsKey = this.keyPrefix + '_' + settingsId;
        localSettings[settingsKey] =  {
            settings: settings,
            updateOrder: (localSettings[settingsKey] && localSettings[settingsKey].updateOrder === updateOrder - 1 ? updateOrder - 1  : updateOrder)
        };

        if (entityEmbedmentCodes.length > 100) {
            const leastUpdateOrder = entityEmbedmentCodes.reduce((a, b) => {
                const aTemp = localSettings[a];
                const bTemp = localSettings[b];
                return aTemp.updateOrder < bTemp.updateOrder ? a : b;
            });

            delete localSettings[leastUpdateOrder];
        }

        this.storeSettingsToStorage(localSettings);
    }

    public load(settingsId: string|number): T {
        const localSettings = this.loadSettingsFromStorage();
        const settingsKey = this.keyPrefix + '_' + settingsId;
        return localSettings[settingsKey] ? localSettings[settingsKey].settings : null;
    }

    private loadSettingsFromStorage(): LocalSettings<T> {
        const json = window.localStorage.getItem('mnb-portal-settings');
        const localSettings = json ? JSON.parse(json) : null;
        return localSettings ? localSettings : new LocalSettings<T>();
    }

    private storeSettingsToStorage(value: LocalSettings<T>): void {
        window.localStorage.setItem('mnb-portal-settings', JSON.stringify(value));
    }

}

class LocalSettings<T> {
    [entityId: string]: { settings: T, updateOrder: number };
}
