import { Injectable, InjectionToken } from '@angular/core';
import { TenantModelEntityData } from '@shared-lib/modules/model-entities/models/tenant.model';

export const MODEL_ENTITIES_LINK_DATA_SERVICE_TOKEN = new InjectionToken<ModelEntitiesEntityLinkDataService>('ModelEntitiesEntityLinkDataService');
export interface ModelEntitiesEntityLinkDataService {
    loadData(object: unknown, args?: unknown): Promise<TenantModelEntityData>;
}


@Injectable()
export class EmptyModelEntitiesLinkDataService implements ModelEntitiesEntityLinkDataService {

    constructor() {

    }

    loadData(object: unknown, args?: unknown): Promise<TenantModelEntityData> {
        return Promise.resolve(new TenantModelEntityData());
    }
}
