import { Component, OnInit, Input, HostBinding, AfterContentChecked } from '@angular/core';
import { DashboardGridWidget } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';
import { TimeFilter, TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { MnbDateSpanPipe } from '@shared-lib/modules/core/pipes/date-span.pipe';
import { DashboardWidgetPosition } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { isNullOrUndefined } from 'util';
import { MnbUnitInfo, MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';

@Component({
    templateUrl: './mnb-dashboard-single-value-widget.component.html',
    selector: 'mnb-dashboard-single-value-widget'
})
export class MnbDashboardSingleValueWidgetComponent implements OnInit, AfterContentChecked {

    @Input() gridWidget: DashboardGridWidget;
    @Input() isViewMode: boolean;

    @HostBinding('class.value-wrapper') valueWrapper = true;

    public model: MnbDashboardSingleValueWidgetModel;

    constructor(
        private timeFilterService: TimeFilterService,
        private datePipe: MnbDateSpanPipe,
        private translateService: TranslateService,
        private unitPipe: MnbUnitPipe
    ) {}

    ngOnInit() {
        this.model = new MnbDashboardSingleValueWidgetModel(this.gridWidget, this.timeFilterService, this.unitPipe, this.datePipe, this.translateService);
    }

    ngAfterContentChecked(): void {
        const position = this.gridWidget.widget.visualizationSettings.position;
        const isPositionChanged = position.width !== this.model.position.width || position.height !== this.model.position.height;
        if (this.model.values.length && isPositionChanged) {
            this.model.onPositionChange(position);
        }
    }

}

interface ChangeFormatDisplay {
    isPercentage: boolean;
    isAbsValue: boolean;
    isAbsChange: boolean;
}

class SingleValueTooltip {
    constructor(public title: string, public body: any, public isBodyCotainDate?: boolean) {}
}

class SingleValueBadge {
    constructor(public label: { short: string, full: string },  public isFullBadgeSize?: (position: DashboardWidgetPosition) => boolean) {}
}

class SingleValueContent {
    
    public tooltipContent: string;
    public isActualValue: boolean;
    public isFullBadge: boolean;

    constructor(public code: string, public data: string, private badge?: SingleValueBadge, private tooltip?: SingleValueTooltip) {
        this.isActualValue = this.code === 'actualValue';
        this.isFullBadge = true;
    }

    private updateTooltip(datePipe: MnbDateSpanPipe) {
        //TODO: This is not angular => move to template
        const isTitle = this.tooltip.title != null;
        const titleHtml = '<span style="display:block; font-size: 14px; margin-bottom: 6px;"><b>' + this.tooltip.title + '</b></span>';
        const bodyHtml = '<span style="font-size: 12px;">' + (this.tooltip.isBodyCotainDate ? datePipe.transform(this.tooltip.body) : this.tooltip.body) + '</span>';
        this.tooltipContent = (isTitle ? titleHtml : '') + bodyHtml;
    }

    update(position: DashboardWidgetPosition, datePipe: MnbDateSpanPipe) {
        if (this.badge && this.badge.isFullBadgeSize) {
            this.isFullBadge = this.badge.isFullBadgeSize(position);
        }
        
        if (this.tooltip) {
            this.updateTooltip(datePipe);
        }
    }
}

class MnbDashboardSingleValueWidgetModel {

    public changeFormat: ChangeFormatDisplay;

    public position: DashboardWidgetPosition;
    public timeFilterTooltipContent: string;

    public values: SingleValueContent[] = [];

    constructor(
        gridWidget: DashboardGridWidget,
        timeFilterService: TimeFilterService,
        unitPipe: MnbUnitPipe,
        private datePipe: MnbDateSpanPipe,
        translateService: TranslateService
    ) {
        this.position = {...gridWidget.widget.visualizationSettings.position};
        this.initChangeFormat(gridWidget);
        this.initValues(gridWidget, timeFilterService, unitPipe, translateService);
    }

    private hasPlan(gridWidget: DashboardGridWidget) {
        const plan = gridWidget.widget.querySettings.plan;
        const dashboardPlan = gridWidget.dashboard.settings.plan;
        
        const timeFilter = gridWidget.widget.querySettings.timeFilter;
        const hasPlanMeasure = !isNullOrUndefined(gridWidget.model.measure.planMeasure);

        return ((plan && plan.name) || (dashboardPlan && dashboardPlan.name && !timeFilter)) && hasPlanMeasure;
    }

    private initChangeFormat(gridWidget: DashboardGridWidget) {
        const changeFormat = gridWidget.widget.visualizationSettings.comparisonFormat;

        if (!changeFormat) {
            // Default
            this.changeFormat = {
                isPercentage: true,
                isAbsValue: true,
                isAbsChange: false
            };
            return;
        }

        const perChangeIndex = ['percAndAbsVal', 'percAndAbsChg'].indexOf(changeFormat);
        const absChangeIndex = ['absChgAndAbsVal', 'absChgAndPerc'].indexOf(changeFormat);

        const isAbsolute = perChangeIndex == -1;
        const index = (isAbsolute ? absChangeIndex : perChangeIndex);

        this.changeFormat = {
            isPercentage: !isAbsolute,
            isAbsValue: index === 0,
            isAbsChange: perChangeIndex === 1
        };
    }

    private initChanges(code: string, actualValue: number, comparisonValue: number, badge: SingleValueBadge, tooltip: SingleValueTooltip, unit: MnbUnitInfo, unitPipe: MnbUnitPipe) {
        const comparisonChange = actualValue - comparisonValue;
        const comparisonValues: string[] = [];
        
        const percentage = unitPipe.transform(comparisonChange / comparisonValue, 'PERCENT', { decimalPlaces: 2, hasSign: true });
        const change = unitPipe.transform(comparisonChange, unit, { hasSign: true });
        
        comparisonValues.push(this.changeFormat.isPercentage ? percentage : change);
        
        if (this.changeFormat.isAbsValue) {
            comparisonValues.push(unitPipe.transform(comparisonValue, unit));
        } else if (this.changeFormat.isAbsChange) {
            comparisonValues.push(change);
        } else {
            comparisonValues.push(percentage);
        }
        
        const content = new SingleValueContent(code, comparisonValues.join(this.changeFormat.isAbsValue ? ' vs ' : ' = '), badge, tooltip);
        content.update(this.position, this.datePipe);
        
        this.values.push(content);
    }

    private isFullBadge(position: DashboardWidgetPosition, isShortText?: boolean) {
        const isFullBadge = !(position.width <= 4 || (position.width > 2 && position.height === 2));
        const showFullBadgeForPlan = position.width !== 2 && isFullBadge;

        return isShortText ? showFullBadgeForPlan : isFullBadge;
    }

    private initValues(gridWidget: DashboardGridWidget, timeFilterService: TimeFilterService, unitPipe: MnbUnitPipe, translateService: TranslateService) {
        const dashboardSettings = gridWidget.dashboard.settings;
        if (dashboardSettings) {
            const widgetSettings = gridWidget.widget.querySettings;
            const timeFilter = widgetSettings.timeFilter || dashboardSettings.timeFilter;
            const comparisonFilter = widgetSettings.timeFilter ? widgetSettings.comparisonFilter : dashboardSettings.comparisonFilter;
            
            let promises = [timeFilterService.getTimeLabelWithDateSpan(<TimeFilter> timeFilter)];

            if (comparisonFilter) {
              promises.push(timeFilterService.getComparisonLabelWithDateSpan(<TimeComparisonFilter> comparisonFilter, <TimeFilter> timeFilter));
            }
        
            Promise.all(promises).then((res) => {
              this.timeFilterTooltipContent = res[0];
        
              if(comparisonFilter) {
                this.timeFilterTooltipContent += '<br>vs. ' + res[1];
              }
            });

            const measure = gridWidget.model.measure;
            const isOriginalCurrency = measure.modifiers.isOriginalCurrency;

            const unit = new MnbUnitInfo(measure.unit.code, isOriginalCurrency,
                isOriginalCurrency ? QueryFilter.getCurrencyCode(gridWidget.widget.querySettings.filters) || QueryFilter.getCurrencyCode(gridWidget.dashboard.settings.filters) : null);
            
            const val = gridWidget.data.values[gridWidget.model.measure.code];
            const actualValue = new SingleValueContent(
                'actualValue',
                unitPipe.transform(val, unit),
                new SingleValueBadge({ short: 'GENERAL.TIME.ACTUAL_SHORT', full: 'GENERAL.TIME.ACTUAL' }, (pos) => pos.width > 3)
            );
            actualValue.update(this.position, this.datePipe);
            
            this.values.push(actualValue);
            
            if (comparisonFilter && gridWidget.data.comparison) {
                const comparisonValue = gridWidget.data.comparison.comparisonPeriod.values[gridWidget.model.measure.code];
                const badge = new SingleValueBadge({ short: 'GENERAL.TIME.COMPARISON_PERIOD_SHORT', full: 'GENERAL.TIME.COMPARISON_PERIOD' }, (pos) => this.isFullBadge(pos));
                const comparisonDateSpan = timeFilterService.getComparisonPeriod(timeFilter, <TimeComparisonFilter> comparisonFilter); 
                const tooltip = new SingleValueTooltip(translateService.instant('GENERAL.TIME.COMPARISON_PERIOD'), comparisonDateSpan, true)
                
                this.initChanges('comparisonValue', val, comparisonValue, badge, tooltip, unit, unitPipe);
            }

            if (this.hasPlan(gridWidget)) {
                const planValue = gridWidget.data.values[gridWidget.model.measure.planMeasure.code];
                const badge = new SingleValueBadge({ short: 'Plan', full: 'Plan' });
                const planName = dashboardSettings.plan ? dashboardSettings.plan.name : widgetSettings.plan.name;
                const tooltip = new SingleValueTooltip('Plan', planName);
                this.initChanges('planValue', val, planValue, badge, tooltip, unit, unitPipe);
            }

            const displayMode = gridWidget.widget.visualizationSettings.valueDisplayMode;

            if (displayMode) {
                const orderPriority = {
                    actualValue: 3,
                    comparisonValue: 2,
                    planValue: 1
                };

                this.values.sort((a, b) => {
                    const valueA = displayMode == a.code ? 4 : orderPriority[a.code];
                    const valueB = displayMode == b.code ? 4 : orderPriority[b.code];

                    return valueB - valueA;
                });
            }
        }
    }

    onPositionChange(position: DashboardWidgetPosition) {
        this.position = {...position};
        this.values.forEach(value => value.update(position, this.datePipe));
    }

}
