import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiAuthService } from '@minubo-portal/modules/api/services/api-auth.service';
import { ApiSessionService } from '@minubo-portal/modules/api/services/api-session.service';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: './routing-switch-partner-page.component.html'
})
export class RoutingSwitchPartnerPageComponent implements OnInit, OnDestroy {

    constructor(
        private authService: ApiAuthService,
        private apiSessionService: ApiSessionService
    ) {
    }

    public model$ = new BehaviorSubject<RoutingSwitchPartnerPageModel>(null);

    public load = new MnbBusyStatus();

    private destroy$ = new Subject<void>();

    ngOnInit() {
        this.model$.subscribe((model) => {
            if (model) {
                this.load.done();
            }
        });
        this.authService.getSession()
            .pipe(
                takeUntil(this.destroy$),
                map(session => {
                    const model: RoutingSwitchPartnerPageModel = {
                        partners: (session.availablePartners || []).map(partner => {
                            const isSelected = session.portalInfo.portalPartnerId === partner.id;
                            return {
                                id: partner.id,
                                name: partner.name,
                                isSelected
                            };
                        })
                    };
                    model.partners.sort((a, b) => (a.name || '').localeCompare((b.name || '')));
                    return model;
                })
            )
            .subscribe(model => this.model$.next(model));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    switchTo(partnerModel: RoutingSwitchPartnerPagePartnerModel) {
        this.load = new MnbBusyStatus();
        this.apiSessionService.changePartner(partnerModel.id);
    }
}

export interface RoutingSwitchPartnerPageModel {
    partners: RoutingSwitchPartnerPagePartnerModel[];
}

export interface RoutingSwitchPartnerPagePartnerModel {
    id: number;
    name: string;
    isSelected: boolean;
}
