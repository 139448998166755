import { DashboardWidget, Dashboard, DashboardWidgetDetail } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { ModelMeasure, ModelAttribute } from '@shared-lib/modules/model/services/mnb-model.service';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { isNullOrUndefined } from 'util';
import { QueryFilter } from '../../../../../../shared-lib/src/lib/modules/data/model/mnb-data-query.model';
import { DashboardWidgetPosition } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { DashboardGrid, DashboardGridCell, DashboardGridPreviewWidget, DashboardGridWidget, DashbordGridWidgetSize } from '@shared-lib/modules/dashboards/util/mnb-dashboards-grid.util';

export class DashboardGridWidgetMove {

    private startCell: DashboardGridCell;

    private otherWidgets: Array<DashboardGridWidget>;

    constructor(private grid: DashboardGrid, public gridWidget: DashboardGridWidget) {}
    
    start() {
        const position = this.gridWidget.widget.visualizationSettings.position;

        this.startCell = this.grid.getCell(position.x, position.y);
        
        this.startCell.previewWidget = new DashboardGridPreviewWidget(this.gridWidget.widget);
        
        this.otherWidgets = this.grid.getDashboardWidgets().filter(otherWidget => otherWidget !== this.gridWidget);

    }

    apply(cell: DashboardGridCell): boolean {
        if (cell) {
            const position = this.gridWidget.widget.visualizationSettings.position;
            const oldCell = this.grid.getCell(position.x, position.y);

            let newCell = null;

            if (DashboardGrid.hasRoomForWidget(this.grid, this.otherWidgets, cell, position)) {
                newCell = cell;
            } else {
                const cellOptions: DashboardGridCell[] = this.grid.getCellArea(Math.max(0, cell.x - position.width + 1), Math.max(0, cell.y - position.height + 1), cell.x, cell.y)
                    .filter((c) => DashboardGrid.hasRoomForWidget(this.grid, this.otherWidgets, c, position));

                newCell = (cellOptions.length ? cellOptions.reduce((a, b) => a.x >= b.x && a.y >= b.y ? a : b ) : null);
            }
    
            if (newCell && (position.x !== newCell.x || position.y !== newCell.y)) {
                position.x = newCell.x;
                position.y = newCell.y;
                
                newCell.previewWidget = oldCell.previewWidget;
                oldCell.previewWidget = null;
                return true;
              }
        }
    
        return false;
    }

    finish() {
        this.startCell.widget = null;

        const newPosition = this.gridWidget.widget.visualizationSettings.position;
        const newCell = this.grid.getCell(newPosition.x, newPosition.y);
        newCell.widget = this.gridWidget;
        newCell.previewWidget = null;
    }

}

export class DashboardGridWidgetResize {

    private rightWidgets: Array<DashboardGridWidget>;
    private lowerWidgets: Array<{previewPosition: DashboardWidgetPosition, widget: DashboardGridWidget}>;
    private emptyRows: Array<number>;

    constructor(private minSize: DashbordGridWidgetSize, private grid: DashboardGrid, public gridWidget: DashboardGridWidget) {

    }

    start() {

        const position = this.gridWidget.widget.visualizationSettings.position;

        this.lowerWidgets = this.grid.getWidgetsInRows(position.y + position.height).map(lowerWidget => {
            return {
                previewPosition: <DashboardWidgetPosition> deepCopy(lowerWidget.widget.visualizationSettings.position),
                widget: lowerWidget
            }
        });
        
        const rowCount = this.grid.height;
        const nextUsedRow = this.lowerWidgets.map(lowerWidget => {       
            const widgetPosition = lowerWidget.widget.widget.visualizationSettings.position;
            return widgetPosition.y;
        }).reduce((a, b) => Math.min(a,b), rowCount);

        const rightPosition = {
            x: position.x + position.width,
            y: position.y,
            width: this.grid.width - (position.x + position.width),
            height: nextUsedRow - position.y
        };
        
        this.rightWidgets = this.grid.getDashboardWidgets().filter(widget => {
            return DashboardWidgetPosition.areOverlapping(rightPosition, widget.widget.visualizationSettings.position);
        });

        //find empty rows that we can use to get space when resizing
        this.emptyRows = [];

        if (this.lowerWidgets.length > 0) {

            for (var row = nextUsedRow; row < rowCount; row ++) {
                if (this.grid.isRowEmpty(row)) {
                    this.emptyRows.push(row);
                }
            }
        }
    }

    apply(cell: DashboardGridCell): boolean {
        
        if (cell) {
            const position = this.gridWidget.widget.visualizationSettings.position;
            const x = position.x;
            const y = position.y;
            
            const minWidth = Math.min(this.minSize.width, position.width);
            const minHeight = Math.min(this.minSize.height, position.height);
            const width = Math.max(cell.x - x + 1, minWidth);
            const height = Math.max(cell.y - y + 1, minHeight);

            if (width !== position.width || height !== position.height) {
                const lastWidth = position.width;
                const lastHeight = position.height;
       
                position.height = height;
                position.width = width;

                const hasOverlap: boolean = !isNullOrUndefined(this.rightWidgets.find(rightWidget => {
                    const widgetPosition = rightWidget.widget.visualizationSettings.position;
                    return DashboardWidgetPosition.areOverlapping(widgetPosition, position);
                }));
                
                if (hasOverlap) {
                    //not enough space
                    position.width = lastWidth;
                    position.height = lastHeight;
                    return false;
                }

                if (height !== lastHeight) {
                    
                
                    const rowsToMove = this.lowerWidgets.map(lowerWidget => {
                        
                        const widgetPosition = lowerWidget.widget.widget.visualizationSettings.position;
                        
                        if (DashboardWidgetPosition.areOverlapping(widgetPosition, position)) {
                            return position.y + position.height - widgetPosition.y;
                        }
                        return 0;
                    }).reduce((a, b) => Math.max(a,b), 0);

                    if (rowsToMove > 0) {
                        
                        if (this.emptyRows.length < rowsToMove) {
                            //not enough space
                            position.width = lastWidth;
                            position.height = lastHeight;
                            return false;
                        }

                        this.lowerWidgets.forEach((lowerWidget) => {
                            const widgetPosition = lowerWidget.widget.widget.visualizationSettings.position;
                            const previewPosition = lowerWidget.previewPosition;
                            let oldCell = this.grid.getCell(previewPosition.x, previewPosition.y);
                            const orgCell = this.grid.getCell(widgetPosition.x, widgetPosition.y);
                            oldCell.previewWidget = null;
                            lowerWidget.previewPosition.y = widgetPosition.y;
                            orgCell.widgetMoved = false;
                        });

                        for (let rowsMoved = 0; rowsMoved < rowsToMove; rowsMoved++) {
                            const emptyRow = this.emptyRows[rowsMoved];
                            this.lowerWidgets.forEach((lowerWidget) => {
                                const widgetPosition = lowerWidget.widget.widget.visualizationSettings.position;
                                if (emptyRow > widgetPosition.y) {
                                    const orgCell = this.grid.getCell(widgetPosition.x, widgetPosition.y);
        
                                    const previewPosition = lowerWidget.previewPosition;
                                    let oldCell = this.grid.getCell(previewPosition.x, previewPosition.y);
                                    previewPosition.y = previewPosition.y + 1;
                                    let newCell = this.grid.getCell(previewPosition.x, previewPosition.y);
                                    
                                    oldCell.previewWidget = null;
                                    newCell.previewWidget = new DashboardGridPreviewWidget(lowerWidget.widget.widget);
                                    orgCell.widgetMoved = true;
                                }
                            });
                        }
                    } else {
                        //clean up temp muving
                        this.lowerWidgets.forEach((lowerWidget) => {
                            const widgetPosition = lowerWidget.widget.widget.visualizationSettings.position;
                            const orgCell = this.grid.getCell(widgetPosition.x, widgetPosition.y);

                            const previewPosition = lowerWidget.previewPosition;
                            let oldCell = this.grid.getCell(previewPosition.x, previewPosition.y);
                            previewPosition.y = widgetPosition.y;
                            
                            oldCell.previewWidget = null;
                            
                            orgCell.widgetMoved = false;
                        });
                    }
                }


                return true;
            }
        }

        return false;
    }

    finish() {
        // clean up
        this.lowerWidgets.forEach((lowerWidget) => {
            const widgetPosition = lowerWidget.widget.widget.visualizationSettings.position;
            const orgCell = this.grid.getCell(widgetPosition.x, widgetPosition.y);
            
            orgCell.widgetMoved = false;
            orgCell.widget = null;
        });
 
        //let the removal 'sink in' in the grid and then assign the new widgets in the next 'loop'
        setTimeout(() => {
            // set new position
            this.lowerWidgets.forEach((lowerWidget) => {
                
                const previewPosition = lowerWidget.previewPosition;
                
                lowerWidget.widget.widget.visualizationSettings.position.y = previewPosition.y;
                
                let newCell = this.grid.getCell(previewPosition.x, previewPosition.y);
                newCell.widgetMoved = false;
                newCell.widget = lowerWidget.widget;
                
                newCell.previewWidget = null;
            });
        });
    }
}

export class DashboardGridWidgetPositioning {

    constructor(private grid: DashboardGrid, private gridWidget: DashboardGridWidget, private changedWidget: DashboardWidget) {

    }

    apply() {
        const otherWidgets = this.grid.getDashboardWidgets().filter(otherWidget => otherWidget.widget !== this.gridWidget.widget);
        const lastUsedRow = otherWidgets.map(otherWidget => {       
            const widgetPosition = otherWidget.widget.visualizationSettings.position;
            return widgetPosition.y + widgetPosition.height;
        }).reduce((a, b) => Math.max(a,b), 0) - 1;

        const position = this.changedWidget.visualizationSettings.position;
        
        const hadPosition = position.x != null && position.y != null;
        
        let posCell: DashboardGridCell = null;
        if (hadPosition) {
            const oldCell = this.grid.getCell(position.x, position.y);
            //check if the widget can stay where it was
            if (DashboardGrid.hasRoomForWidget(this.grid, otherWidgets, oldCell, position)) {
                posCell = oldCell;
            }
        }
        

        if (!posCell) {
            //need to find a (new) position

            //1. look for empty rows at the end
            if (lastUsedRow + position.height < this.grid.height) {
                posCell = this.grid.getCell(0, lastUsedRow + 1);
            }

            //2. we loop through all rows (beginning from the end to find a space)
            if (!posCell) {
                posCell = DashboardGrid.findCellForWidget(this.grid, otherWidgets, position);
            }

            //3. we make the widget smaller and look if this helps
            if (!posCell) {
                const changedPosition = {x: position.x, y: position.y, height: position.height, width: position.width};
                
                while (changedPosition.width > 1) {
                    changedPosition.height = position.height;
                    while (changedPosition.height > 1) {
                        posCell = DashboardGrid.findCellForWidget(this.grid, otherWidgets, changedPosition);
                        
                        if (posCell) {
                            break;
                        }
                        changedPosition.height--;
                    }
                    if (posCell) {
                        break;
                    }
                    changedPosition.width--;
                }
                if (posCell) {
                    position.width = changedPosition.width;
                    position.height = changedPosition.height;
                }
            }

            //(as long as we only allow to add widgets when there is a 2x2 space, we alsways will find a cell)
        }

        position.x = posCell.x;
        position.y = posCell.y;
        
        
        posCell.widget = this.gridWidget;
        this.gridWidget.widget = this.changedWidget;

        this.grid.updateAvailableCellsForNewWidgets();

    }

}

export class DashboardGridWidgetModel {
    measure?: ModelMeasure;
    additionalMeasure?: ModelMeasure;
    attribute?: ModelAttribute;
    breakdownAttribute?: ModelAttribute;
    measures?: Array<ModelMeasure>;
    attributes?: Array<ModelAttribute>;
}

export class DashboardWidgetDetailModel {

    public widget: DashboardWidget;
    public dashboard: Dashboard;
    public detail: DashboardWidgetDetail;
    public model: DashboardWidgetDetailModelModel;

    constructor() {}

}

export class DashboardWidgetDetailModelModel {

    public measure: ModelMeasure;
    public attribute: ModelAttribute;
    public childAttributes: Array<ModelAttribute>;
    public assistMeasures: Array<ModelMeasure>;
    public additionalMeasure?: ModelMeasure;
    public breakdownAttribute?: ModelAttribute;
    public hasComparisonFilter: boolean;
    public hasPlan: boolean;
    public planName: string;

    constructor() {}


}

/** Is this needed ? */
export class ContextModel {
    id: string;
    getDashboard: Function;
    getDashboardGrid: Function;
    getWidgetContext: Function;
    isNew: Function;
}

export class QueryModel {
    typeCode = 'table';
    settings: QuerySettings;
}

export class QuerySettings {
    attributes: Array<any>;
    measures: Array<any>;
    timeFilter: any;
    filters: QueryFilter[];
}

export class WidgetVisualisationOption {
    public code: string;
    public typeCode: string;
    public nameCode: string;
    public shortNameCode: string;
    public className: string;
    public breakdownMode?: string;
    public additionalMeasureMode?: string;
    public additionalMeasureAsLine?: boolean;
}

export class WidgetVisualisationOptionGroup {
    public nameCode: string;
    public subGroups: WidgetVisualisationOptionSubGroup[];
}

export class WidgetVisualisationOptionSubGroup {
    public code: string;
    public options: Array<WidgetVisualisationOption>;
}
