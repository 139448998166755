import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { MediaService } from '@minubo-suite/shared/services/media/media.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: '[mnbAutosizedValue]',
    template: '<svg #svg preserveAspectRatio="xMaxYMin meet"><svg:text #text x="0%" y="50%" dominant-baseline="middle" text-anchor="left">{{ value }}</svg:text></svg>'
})
export class MnbAutosizedValueComponent implements AfterViewInit, OnDestroy {

    @Input('mnbAutosizedValue') value: string;

    @ViewChild('svg', {static: true}) svgElementRef: ElementRef;
    @ViewChild('text', {static: true}) textElementRef: ElementRef;

    private destroy$ = new Subject<void>();

    constructor(
        private renderer: Renderer2,
        mediaService: MediaService) {

        mediaService.viewSize$
            .pipe(takeUntil(this.destroy$))
            .subscribe(_ => this.adjustValueDisplay());
    }

    ngAfterViewInit(): void {
        this.adjustValueDisplay();
    }

    private adjustValueDisplay() {
        if (this.textElementRef) {
            const width = this.textElementRef.nativeElement.getBBox().width;
            const height = this.textElementRef.nativeElement.getBBox().height;

            this.renderer.setAttribute(this.svgElementRef.nativeElement, 'viewBox', `0 0 ${width} ${height}`);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
