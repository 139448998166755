import { NgModule } from '@angular/core';
import { MnbCoreModule } from './modules/core/mnb-core.module';
import { MnbModelModule } from './modules/model/mnb-model.module';
import * as _d3 from 'd3';
import { MnbConfigModule } from './modules/config/mnb-config.module';

declare global {
    const d3: typeof _d3;
}

@NgModule({
    declarations: [],
    imports: [
        MnbCoreModule,
        MnbModelModule.forChild(),
        MnbConfigModule.forChild()
    ],
    exports: [
        MnbCoreModule,
        MnbModelModule,
        MnbConfigModule
    ]
})
export class MnbLibModule { }
