import { Injectable, Inject, Injector, Type } from '@angular/core';
import { CalendarConfig } from '../model/mnb-config.model';

@Injectable({providedIn: 'root'})
export class MnbConfigService  {

    public langCode: string;

    public calendarConfig: CalendarConfig;

    public currencySymbol: string;
    public currencyCode: string;
    public fiscalYearStartDateStr: string | undefined;

    private _waitForConfig: Promise<void>;
    private hasConfig: boolean;

    constructor(
        @Inject('mnbConfig') config: MnbConfigConfig,
        injector: Injector
    ) {
        const configProvider = injector.get(config.provider);
        this._waitForConfig = configProvider.getConfig().then(configProvided => {
            this.hasConfig = true;
            this.langCode = configProvided.langCode;
            this.calendarConfig = configProvided.calendarConfig;
            this.currencySymbol = CURRENCY_SYMBOLS[configProvided.currencyCode] || configProvided.currencyCode;
            this.currencyCode = configProvided.currencyCode;
        });
    }

    public onAvailable(call: () => void) {
        if (this.hasConfig) {
            call();
        } else {
            this._waitForConfig.then(call);
        }
    }
}


export interface MnbConfigProvider {

    getConfig(): Promise<MnbConfig>;
}


export class MnbConfigConfig {
    provider: Type<MnbConfigProvider>;
}

export class MnbConfig {

    public langCode: string;

    public calendarConfig: CalendarConfig;

    public currencyCode: string;
}

// there is a copy of this in angular js. so add symbols here AND there!
export const CURRENCY_SYMBOLS = {
    EUR: '€',
    USD: '$',
    GBP: '£',
    CHF: 'CHF',
    AUD: 'A$',
    NZD: 'NZ$',
    RUB: 'RUB',
    DKK: 'DKK',
    CAD: 'CAD',
    HUF: 'HUF',
    CZK: 'CZK',
    RON: 'RON',
    BGN: 'BGN',
    NOK: 'NOK'
};
