import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MnbReportsStoreAreasDisplayDrilldownStepModel } from '../display/mnb-reports-store-areas-display.component';


@Component({
    selector: 'mnb-reports-store-areas-drilldown-steps',
    templateUrl: './mnb-reports-store-areas-drilldown-steps.component.html'
})
export class MnbReportsStoreAreasDrilldownStepsComponent implements OnInit {

    @Input() public drilldownSteps: MnbReportsStoreAreasDisplayDrilldownStepModel[] = [];
    @Output() public drillup = new EventEmitter<MnbReportsStoreAreasDisplayDrilldownStepModel>();

    ngOnInit(): void {
    }

    onDrillup(stepModel: MnbReportsStoreAreasDisplayDrilldownStepModel) {
        this.drillup.next(stepModel);
    }
}
