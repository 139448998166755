import { Component, OnInit, Input, HostListener, forwardRef, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { isNullOrUndefined, isUndefined } from 'util';

@Component({
    selector: 'mnb-dropdown-input',
    templateUrl: './mnb-dropdown-input.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MnbDropdownInputComponent),
        multi: true
    }]
})
export class MnbDropdownInputComponent<T> implements OnInit, OnChanges, ControlValueAccessor {

    @Input() list: Array<T>;
    @Input() propertyName: string;
    @Input() outputPropertyName: string;
    @Input() displayPropertyName: string;
    @Input() infoPropertyName: string;
    @Input() placeholder: string;
    @Input() disable: boolean;
    @Input() searchInput: boolean;
    @Input() groupConfig: MnbDropdownInputGroupConfig;
    @Input() itemsClass: string;
    @Input() disabledLabel: string = null;

    @ViewChild('searchInputElement', { static: true }) searchInputElement: ElementRef;

    public visible = false;
    public selectedEntry: any;
    public displayList: Array<T>;
    public groupIsEmpty = new Map<MnbDropdownInputGroup, boolean>();

    private _value: any;
    private selectedIndex: number;
    private propagateChange = (_: any) => { };

    constructor() {}

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
        this.propagateChange(this._value);
    }

    ngOnInit() {
        if (this.groupConfig) {
            this.groupConfig.groups.forEach(group => {
                const isEmpty = !this.list.find(item =>  item[this.groupConfig.byProperty] === group.value);
                if (isEmpty) {
                    this.groupIsEmpty.set(group, true);
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.list) {
            this.writeValue(this._value);
        }
    }

    @HostListener('keydown.escape', ['$event'])
    onEscapeKeyDown(event) {
        event.stopPropagation();
        this.toggleOff();
    }

    public onEnter = (index) => {
        if (index !== -1) {
            this.select(index);
        }
    }

    public selectEmpty() {
        this.selectedIndex = -1;
        this.value = null;
    }

    public select(displayListIndex: number, event?: any) {
        if (event) {
            event.stopPropagation();
        }
        this.toggleOff();
        const newValue = this.displayList[displayListIndex];

        const listIndex = this.list.findIndex(item => this.propertyName ? (item[this.propertyName] === this.displayList[displayListIndex][this.propertyName]) : item === this.displayList[displayListIndex]);

        if (listIndex !== -1) {
            this.selectedIndex = listIndex;
        }

        this.selectedEntry = newValue;
        if (this.propertyName) {
            if (!this.outputPropertyName) {
                this.value = newValue[this.propertyName];
            } else {
                this.value = newValue[this.outputPropertyName];
            }
        } else {
            this.value = newValue;
        }
    }

    public isSelected(index: number) {
        return this.selectedIndex === index;
    }

    public onClick() {
        if (this.searchInput) {
            this.toggleOn();
        } else {
            this.toggleVisibility();
        }
    }

    public toggleVisibility() {
        if (this.visible) {
            this.toggleOff();
        } else {
            this.toggleOn();
        }
    }

    public toggleOff() {
        this.visible = false;
    }

    public toggleOn() {
        this.displayList = deepCopy(this.list);
        this.visible = true;

        setTimeout(() => {
            if (this.searchInputElement) {
                this.searchInputElement.nativeElement.focus();
            }
        });
    }

    public getDisplayValueForInput(): string {
        if (this.selectedEntry === null) {
            return this.disabledLabel;
        }

        if (this.displayPropertyName) {
            return this.selectedEntry ? this.selectedEntry[this.displayPropertyName] : (this.placeholder ? this.placeholder : '');
        } else {
            return this.selectedEntry && this.selectedEntry.label ? this.selectedEntry.label :
                this.selectedEntry && this.selectedEntry.value ? this.selectedEntry.value :
                    this.selectedEntry ? this.selectedEntry : this.placeholder;
        }
    }

    public isPlaceholder(): boolean {
        return isUndefined(this.selectedEntry);
    }

    writeValue(value: any): void {
        this._value = value;

        this.list.forEach((entry, i) => {
            if (this.outputPropertyName) {
                if (entry[this.outputPropertyName] === this._value) {
                    this.selectedIndex = i;
                }
            } else if (this.propertyName) {
                if (entry[this.propertyName] === this._value) {
                    this.selectedIndex = i;
                }
            } else if (entry === this._value) {
                this.selectedIndex = i;
            }
        });

        if (!isNullOrUndefined(this.selectedIndex)) {
            this.selectedEntry = this.list[this.selectedIndex];
        }

        if (!value) {
            this.selectedIndex = -1;
            this.selectedEntry = null;
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    public onSearchInputChange(searchInput: string): void {
        if (searchInput !== '') {
            this.displayList = this.list.filter(item => item[this.displayPropertyName].toLowerCase().includes(searchInput.toLowerCase()));
        } else {
            this.displayList = deepCopy(this.list);
        }
    }
}

export class MnbDropdownInputGroup {
    constructor(public label: string, public value: any) {}
}

export class MnbDropdownInputGroupConfig {
    constructor(public groups: MnbDropdownInputGroup[], public byProperty: string) {}
}
