import { InjectionToken } from '@angular/core';

export const REDIRECT = new InjectionToken<any>('REDIRECT');

export const legacyRedirectProvider = {
    provide: REDIRECT,
    useFactory: (i: any) => {
        if (!i.has('mnbRedirect')) {
            return null;
        }
        return i.get('mnbRedirect');
    },
    deps: ['$injector']
};
