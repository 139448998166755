import { Pipe, PipeTransform } from '@angular/core';
import { MnbDatePipe } from './mnb-date.pipe';
import { MnbTimeService } from '@shared-lib/modules/core/services/time/time.service';

/*
 * Format the value to UTC date by using the unit type code.

 * Usage:
 *   date | mnbDateUTC
 * Example:
 *   {{ '2018.01.01' | mnbDateUTC }}
 *   formats to: Jan 01, 2018
*/
@Pipe({ name: 'mnbDateUTC' })
export class MnbDateUTCPipe implements PipeTransform {

    constructor(private datePipe: MnbDatePipe, private timeService: MnbTimeService) { }

    transform(value: Date, format?: string, withDayAbbreviation?: boolean): string {
        if (value) {
            if (!(value instanceof Date)) {
                value = new Date(value);
            }

            // create utc date
            value = new Date(value.getUTCFullYear(), value.getUTCMonth(), value.getUTCDate());
        }
        let _format = !!format ? format : this.timeService.getDateFormat();
        if (withDayAbbreviation) {
            _format = 'EEE, ' + _format;
        }

        return this.datePipe.transform(value, _format);
    }
}
