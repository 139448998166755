import { NgZone } from '@angular/core';
import { ChartData, LineChartConfig, ChartDataArray, ChartDataType, ChartDataComparisonType } from '../services/chart.models';
import { MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { ChartRenderer, LinearAxisRenderer, TooltipRenderer, DataRenderer, BandAxisRenderer, StackedRenderer, Rectangle, RendererConfig, LegendRenderer } from './chart-renderer';
import * as _d3 from 'd3';
import { isNullOrUndefined } from 'util';
import { MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';

export class LineChartRenderer extends ChartRenderer {

    CHANGE_CHART_HEIGHT = 50;

    private data: Array<ChartDataArray> = [];
    private rerenderConfig: RendererConfig;

    constructor(
        protected config: LineChartConfig,
        zone: NgZone,
        unitPipe: MnbUnitPipe,
        emptyPipe: MnbEmptyValuePipe,
        unitService: MnbUnitService
    ) {
        super(zone, unitPipe, emptyPipe, unitService);
    }

    render(el: HTMLElement | HTMLElement[]) {
        this.data = this.config.data;
        this.rerenderConfig = new RendererConfig(this.config);
        // change this later when mnb-chart passes in ele[0] instead of ele
        const temp: any = el;
        const ele: HTMLElement = temp.length ? temp[0] : temp;

        const svg = d3.select(ele).append('svg')
            .attr('class', 'mnb-chart mnb-line-chart')
            .attr('width', '100%')
            .attr('height', '100%');

        const dimensions: { height: number, width: number } = super.getDimensions(ele);

        let legendHeight = 5;

        if (!this.config.legendConfig.hide) {
            legendHeight += new LegendRenderer(this.config, this.emptyPipe).render(svg, this.el, this.data, dimensions.width - 1);
        }

        const chartRect: Rectangle = {
            x: 0,
            y: legendHeight,
            width: dimensions.width - 1,
            height: dimensions.height - legendHeight
        };

        const lineChartRect: Rectangle = {
            x: chartRect.x,
            y: chartRect.y,
            width: chartRect.width,
            height: chartRect.height - (this.config.showChange ? this.CHANGE_CHART_HEIGHT : 0)
        };

        this.gElement = svg.append('g');

        const yAxisRenderer = new LinearAxisRenderer(this.gElement, this.config, false, this.rerenderConfig.hasSecondaryAxis(), this.unitPipe);
        const xAxisRenderer = new BandAxisRenderer(this.gElement, this.config, false, this.emptyPipe);

        const xAxisHeight = xAxisRenderer.calcXHeight();
        const innerHeight = lineChartRect.height - xAxisHeight;

        const yAxis = yAxisRenderer.renderY(lineChartRect.width, innerHeight);

        let secondaryYAxis: { scale: d3.ScaleLinear<number, number>, width: number };

        if (this.rerenderConfig.hasSecondaryAxis()) {
            const secondaryYAxisRenderer = new LinearAxisRenderer(this.gElement, this.config, true, true, this.unitPipe);
            secondaryYAxis = secondaryYAxisRenderer.renderY(lineChartRect.width - yAxis.width, innerHeight);
        }

        const innerRect: Rectangle = {
            x: lineChartRect.x + yAxis.width,
            y: lineChartRect.y,
            width: lineChartRect.width - yAxis.width - (secondaryYAxis ? secondaryYAxis.width : 0) - (this.config.removeOuterSpacing ? 5 : 0),
            height: innerHeight
        };

        this.gElement.attr('transform', `translate(${innerRect.x},${innerRect.y})`);

        const xAxis = xAxisRenderer.renderX(innerRect.width, innerRect.height);

        const lineRenderers = new Array<LineRenderer>();
        const dataRenderers = new Array<DataRenderer>();

        // Draw only if has more than one line or xAxis is not hidden
        if (this.config.data.length > 1 || !this.config.xAxisConfig.hide) {
            // draw highlight lines here so that they are rendered beneath the data lines, guides, etc
            const highlightRenderer = new HightlightRenderer(this.gElement, this.config.removeOuterSpacing);
            highlightRenderer.render(xAxis.scale, yAxis.scale);
            dataRenderers.push(highlightRenderer);
        }

        switch (this.config.breakdownMode) {
            case 'stacked':
                const stackedAreaRenderer = new StackedAreaRenderer(this.gElement);
                stackedAreaRenderer.render(this.config.getDataArrays(ChartDataType.breakdown), xAxis.scale, yAxis.scale);
                dataRenderers.push(stackedAreaRenderer);
                break;
            case 'fill-stacked':
                const fillStackedAreaRenderer = new StackedAreaRenderer(this.gElement, true);
                fillStackedAreaRenderer.render(this.config.getDataArrays(ChartDataType.breakdown), xAxis.scale, yAxis.scale);
                dataRenderers.push(fillStackedAreaRenderer);
                break;
            case 'parallel':
                this.config.getDataArrays(ChartDataType.breakdown).forEach((array, i) => lineRenderers.push(new LineRenderer(this.gElement, array, i)));
                break;
            default:
                this.config.data.forEach(array => lineRenderers.push(new LineRenderer(this.gElement, array, null, array.type === ChartDataType.secondary ? this.rerenderConfig.hasSecondaryAxis() : false, this.config.removeOuterSpacing)));
        }

        if (lineRenderers.length) {
            // render lines in reverse order -> lines that were added to lineRenderers first will have the highest "z-index"
            lineRenderers.reverse().forEach(line => line.render(xAxis.scale, !line.secondaryAxis ? yAxis.scale : secondaryYAxis.scale));
            lineRenderers.forEach(line => line.renderGuides(xAxis.scale, !line.secondaryAxis ? yAxis.scale : secondaryYAxis.scale));

            if (this.config.showChange && this.rerenderConfig.hasComparisonData()) {
                const changeRect: Rectangle = {
                    x: innerRect.x,
                    y: innerRect.y + innerRect.height + xAxisHeight,
                    width: innerRect.width,
                    height: chartRect.height - innerRect.height - xAxisHeight
                };

                this.drawChange(svg, changeRect, xAxis.scale, yAxis.formatter);
            }
        }

        new TooltipRenderer(this.config, this.tooltipContent, [...lineRenderers, ...dataRenderers], this.config.breakdownMode === 'parallel', this.config.removeOuterSpacing)
            .render(svg, ele, innerRect, xAxis.scale, yAxis.scale, null, false);
    }

    private drawChange(svg: _d3.Selection<SVGSVGElement, {}, null, undefined>, changeRect: Rectangle, x: _d3.ScaleBand<string>, formatter: (d: number) => string) {

        const changeG = svg.append('g')
            .attr('transform', `translate(${changeRect.x},${changeRect.y})`);
        const yChange = d3.scaleLinear().range([changeRect.height, 0]);
        const changeSeries = [];

        const primaryData = this.config.getDataArray(ChartDataType.primary);
        const comparisonData = this.config.getDataArray(ChartDataType.primary, ChartDataComparisonType.comparison);

        primaryData.data.forEach((d, i) => {
            const o = comparisonData.data[i];
            const value = d.value - o.value;
            changeSeries.push({
                label: d.label,
                value: value
            });
        });

        const domain = d3.extent(changeSeries, (d) => d.value);

        if (domain[0] > 0) {
            domain[0] = 0;
        }
        if (domain[1] < 0) {
            domain[1] = 0;
        }

        yChange.domain(domain);

        const yChangeAxis = d3.axisLeft(yChange)
            .ticks(2)
            .tickFormat(formatter)
            .tickSize(-LinearAxisRenderer.TICK_SIZE)
            .tickSizeOuter(0);


        const yAxisElement = changeG.append('g')
            .attr('class', 'axis axis--y')
            .call(yChangeAxis);

        yAxisElement.call(g => g.select('.domain').remove());

        const barPadding = x.bandwidth() * 0.25;
        const zeroLineY = yChange(0);

        changeG.selectAll('.bar')
            .data(changeSeries)
            .enter().append('rect')
            .attr('class', (d) => 'bar')
            .attr('x', (d) => x(d.label) + barPadding)
            .attr('y', (d) => Math.min(yChange(d.value), zeroLineY))
            .attr('width', (x.bandwidth() - 2 * barPadding))
            .attr('height', (d) => Math.abs(yChange(d.value) - zeroLineY));
        const xChangeAxis = d3.axisBottom(x)
            .tickSizeOuter(0);
        changeG.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0,${zeroLineY})`)
            .call(xChangeAxis)
            .call(a => a.selectAll('.tick line').attr('transform', 'translate(' + x.bandwidth() / 2 + ', 0)'))
            .selectAll('text').remove();
    }

}

export class LineRenderer extends DataRenderer {

    private className: string;

    constructor(
        private gElement: d3.Selection<SVGGElement, {}, null, undefined>,
        private data: ChartDataArray,
        private index?: number,
        public secondaryAxis?: boolean,
        public removeOuterSpacing?: boolean
    ) {
        super();

        if (data.type === ChartDataType.primary && data.comparisonType === ChartDataComparisonType.plan) {
            this.className = 'plan-serie';
        } else if (data.type === ChartDataType.secondary && data.comparisonType === ChartDataComparisonType.plan) {
            this.className = 'plan-serie-secondary';
        } else if (data.type === ChartDataType.primary) {
            this.className = !data.isComparison ? 'main-serie' : 'comparison-serie';
        } else if (data.type === ChartDataType.secondary) {
            this.className = !data.isComparison ? 'main-serie-secondary' : 'comparison-serie-secondary';
        } else if (data.type === ChartDataType.breakdown) {
            this.className = 'parallel-serie';
        }
    }

    public render(x: d3.ScaleBand<string>, y: d3.ScaleLinear<number, number>, limit?: number) {
        const containers = this.gElement.selectAll(this.getClassSelector())
            .data([this.data.getData(limit)])
            .enter().append('g')
            .attr('class', this.className + (isNullOrUndefined(this.index) ? '' : ' ' + this.className + '-' + this.index));

        // TODO: Remove duplicate code
        if (x.domain().length === 1) {
            // add a short line in case there is only 1 data point available
            containers.append('path')
                .attr('fill', 'none')
                .attr('class', () => isNullOrUndefined(this.index) ? 'line' : 'line chart-stroke-color-' + this.index)
                .style('stroke', () => this.data.color)
                .attr('d', (d: ChartData[]) => {
                    const fixY = y(d[0].value);
                    const fixX = x(d[0].label);
                    const lineData: { xVal: number }[] = [
                        {
                            xVal: fixX - 6
                        },
                        {
                            xVal: fixX + 6
                        }
                    ];
                    return d3.line<{ xVal: number }>()
                        .x((dataEle: { xVal: number }, i) => dataEle.xVal + ChartRenderer.getBandSpacing(x, i, this.removeOuterSpacing))
                        .y(() => fixY)
                        (lineData);
                });

        } else {
            containers.append('path')
                .attr('fill', 'none')
                .attr('class', () => isNullOrUndefined(this.index) ? 'line ' : 'line chart-stroke-color-' + this.index)
                .style('stroke', () => this.data.color)
                .attr('d', d3.line<ChartData>()
                .x((d: ChartData, i) => x(d.label) + ChartRenderer.getBandSpacing(x, i, this.removeOuterSpacing))
                .y((d: ChartData) => y(d.value)));
        }
    }

    private getClassSelector() {
        return '.' + this.className + (isNullOrUndefined(this.index) ? '' : '.' + this.className + '-' + this.index);
    }

    public renderGuides(x: d3.ScaleBand<string>, y: d3.ScaleLinear<number, number>, limit?: number) {
        const guideContainer = this.gElement.select(this.getClassSelector()).selectAll('dot')
            .data(this.data.getData(limit))
            .enter().append('g').attr('class', 'guide');
        guideContainer.append('circle').attr('class', 'dot')
            .attr('r', 3.5)
            .attr('cx', (d, i) => x(d.label) + ChartRenderer.getBandSpacing(x, i, this.removeOuterSpacing))
            .attr('cy', (d) => y(d.value));
    }

    public switchHighlight(index: number, highlight: boolean) {
        const targetNodes = <Array<HTMLElement>> this.gElement.select(this.getClassSelector()).selectAll('.guide').nodes();

        targetNodes.forEach(node => node.classList.remove('show'));

        if (highlight) {
            if (targetNodes.length === 0) {
                return;
            }
            targetNodes[index].classList.add('show');
        }
    }
}

export class StackedAreaRenderer extends StackedRenderer {

    constructor(private gElement: d3.Selection<SVGGElement, {}, null, undefined>, filled?: boolean) {
        super(filled);
    }

    public render(arrays: Array<ChartDataArray>, x: _d3.ScaleBand<string>, y: _d3.ScaleLinear<number, number>) {
        const keys = super.getKeys(arrays);
        const series = super.createSeries(arrays, keys);

        this.gElement.selectAll('.fill-stacked-areas')
            .data([series])
            .enter().append('g')
            .attr('class', 'fill-stacked-areas')
            .selectAll('fill-stacked-area')
            .data(d => d).enter()
            .append('path')
            .attr('class', (d, i) => 'fill-stacked-area chart-color-' + (series.length - i - 1))
            .style('fill', (d, i) => arrays[series.length - i - 1].color)
            .attr('d', data => {
                const area = d3.area<any>()
                    .x((d, i) => {
                        const xKey: string = keys[i];
                        return x(xKey) + x.bandwidth() / 2;
                    })
                    .y0(d => y(d[0]))
                    .y1(d => y(!isNaN(d[1]) ? d[1] : 0));

                return area(data);
            });

        // move the hilight-lines node to the bottom of the DOM so that the lines overlay the area chart
        const node: HTMLElement = this.gElement.select('.highlight-lines').node() as HTMLElement;
        node.parentElement.appendChild(node);
    }

    public switchHighlight(index: number, highlight: boolean) { }
}

export class HightlightRenderer extends DataRenderer {

    constructor(private gElement: d3.Selection<SVGGElement, {}, null, undefined>, private removeOuterSpacing?: boolean) {
        super();
    }

    public render(x: d3.ScaleBand<string>, y: d3.ScaleLinear<number, number>) {

        const height = y.range()[0];

        this.gElement.selectAll('.highlight-lines')
            .data([x.domain()])
            .enter().append('g')
            .attr('class', 'highlight-lines')
            .selectAll('highlight-line')
            .data(d => d).enter()
            .append('rect')
            .attr('class', 'guide highlight-line')
            .attr('width', '1')
            .attr('height', Math.max(height, 0))
            .attr('x', (d, index) => x(d) + ChartRenderer.getBandSpacing(x, index, this.removeOuterSpacing))
            .attr('y', '0')
            .attr('z', '0');
    }

    public switchHighlight(index: number, highlight: boolean) {
        const targetNodes = <Array<HTMLElement>> this.gElement.select('.highlight-lines').selectAll('.guide').nodes();

        targetNodes.forEach(node => node.classList.remove('show'));

        if (highlight) {
            if (targetNodes.length === 0) {
                return;
            }
            targetNodes[index].classList.add('show');
        }
    }
}
