import { Component, Input, OnInit } from '@angular/core';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import {
    Report, ReportData, ReportInventoryProductData, ReportInventoryProductVariationData, ReportInventoryProductSizeData, ReportSettings
} from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { MnbModelService, ModelAttribute, ModelAttributeCode, ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';
import { BehaviorSubject } from 'rxjs';


@Component({
    selector: 'mnb-reports-inventory-display',
    templateUrl: './mnb-reports-inventory-display.component.html',
    styleUrls: ['./mnb-reports-inventory-display.component.less']
})
export class MnbReportsInventoryDisplayComponent implements OnInit {

    @Input() report: Report;
    @Input() viewSettings: ReportSettings;
    @Input() data: ReportData;

    public model$ = new BehaviorSubject<AlyReportingInventoryDisplayModel>(null);

    constructor(
        private modelService: MnbModelService,
    ) { }

    async ngOnInit(): Promise<void> {
        this.loadModel(this.report, this.viewSettings, this.data);
    }

    private async loadModel(report: Report, viewSettings: ReportSettings, data: ReportData) {

        if (!data.inventory) {
            this.model$.next({
                restricted: true
            });
            return;
        }

        const model: AlyReportingInventoryDisplayModel = {
            nameAttribute: await this.modelService.getAttribute(ModelAttributeCode.PRD_NAME),
            inventoryMeasure: await this.modelService.getMeasure(data.inventory.inventoryMeasureCode),
            salesQtyMeasure: await this.modelService.getMeasure(data.inventory.salesQtyMeasureCode),
            profitRateMeasure: await this.modelService.getMeasure(data.inventory.profitRateMeasureCode),
            products: data.inventory.products.map(productData => {
                const productSizes: {name: string, sortCode: string}[] = [];
                const productSizeNames = new Set<string>();
                productData.variations.forEach(variationData => {
                    variationData.sizes
                        .filter(sizeData => !productSizeNames.has(sizeData.name))
                        .forEach(sizeData => {
                            productSizeNames.add(sizeData.name);
                            productSizes.push({name: sizeData.name, sortCode: sizeData.sortCode || sizeData.name});
                        });
                });

                productSizes.sort((a, b) => (a.sortCode || '').localeCompare(b.sortCode || ''));

                return {
                    data: productData,
                    expanded: false,
                    sizes: productSizes.map(size => {
                        return {
                            data: productData.sizes.find(s => s.name === size.name) || {salesQty: 0, inventory: 0, profitRate: 0, name: size.name}
                        };
                    }),
                    variations: productData.variations.map(variationData => {
                        return {
                            data: variationData,
                            sizes: productSizes.map(size => {
                                return {
                                    data: variationData.sizes.find(s => s.name === size.name) || {salesQty: 0, inventory: 0, profitRate: 0, name: size.name}
                                };
                            })
                        };
                    })
                };
            }),
            hasExpandAllButton: true,
        };

        model.maxSizeCount = model.products.map(p => p.sizes.length).reduce((a, b) => Math.max(a, b), 0);

        // warehouseOverview
        const overviewProductSizes: {name: string, sortCode: string}[] = [];
        const overviewProductSizeCodes = new Set<string>();
        if (data.inventory.warehouseOverview) {
            model.maxSizeCount = Math.max(model.maxSizeCount, data.inventory.warehouseOverview.sizes.length);

            data.inventory.warehouseOverview.sizes
                    .filter(sizeData => !overviewProductSizeCodes.has(sizeData.sortCode || sizeData.name))
                    .forEach(sizeData => {
                        overviewProductSizeCodes.add(sizeData.sortCode || sizeData.name);
                        overviewProductSizes.push({name: sizeData.name, sortCode: sizeData.sortCode || sizeData.name});
                    });
            overviewProductSizes.sort((a, b) => (a.sortCode || '').localeCompare(b.sortCode || ''));

            model.warehouseOverview = {
                data: data.inventory.warehouseOverview,
                expanded: false,
                sizes: overviewProductSizes.map(size => {
                    return {
                        data: data.inventory.warehouseOverview.sizes.find(s => s.sortCode === size.sortCode) || {salesQty: 0, inventory: 0, profitRate: 0, name: size.name}
                    };
                }),
                variations: data.inventory.warehouseOverview.variations.map(variationData => {
                    return {
                        data: variationData,
                        sizes: overviewProductSizes.map(size => {
                            return {
                                data: variationData.sizes.find(s => s.sortCode === size.sortCode) || {salesQty: 0, inventory: 0, profitRate: 0, name: size.name}
                            };
                        })
                    };
                })
            };
        }

        this.model$.next(model);
    }

    toggleSingleExpand(productModel: AlyReportingInventoryDisplayProductModel) {
        productModel.expanded = !productModel.expanded;
        this.model$.getValue().hasExpandAllButton = true;
    }

    public toggleExpandAll() {
        const newModel = deepCopy(this.model$.getValue());
        if (!!newModel.hasExpandAllButton) {
            newModel.products.map(
                prod => prod.expanded = true
            );
            newModel.hasExpandAllButton = false;
        } else {
            newModel.products.map(
                prod => prod.expanded = false
            );
            newModel.hasExpandAllButton = true;
        }
        this.model$.next(newModel);
    }

}

export type AlyReportingInventoryDisplayModel = {
    nameAttribute?: ModelAttribute;
    inventoryMeasure?: ModelMeasure;
    salesQtyMeasure?: ModelMeasure;
    profitRateMeasure?: ModelMeasure;
    products?: AlyReportingInventoryDisplayProductModel[];
    maxSizeCount?: number;
    restricted?: boolean;
    warehouseOverview?: AlyReportingInventoryDisplayProductModel;
    hasExpandAllButton?: boolean;
};

export type AlyReportingInventoryDisplayProductModel = {
    data: ReportInventoryProductData;
    expanded: boolean;
    variations: AlyReportingInventoryDisplayProductVariationModel[];
    sizes: AlyReportingInventoryDisplayProductVariationSizeModel[];
};

export type AlyReportingInventoryDisplayProductVariationModel = {
    data: ReportInventoryProductVariationData;
    sizes: AlyReportingInventoryDisplayProductVariationSizeModel[];
};

export type AlyReportingInventoryDisplayProductVariationSizeModel = {
    data: ReportInventoryProductSizeData;
};
