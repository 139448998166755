import { Injectable, Inject, Optional, EventEmitter } from '@angular/core';
import { LEGACY_MNB_MEDIA_SERVICE } from '../../modules/legacy/services/legacy-media-service.provider';
import { BehaviorSubject, Observable, ReplaySubject, Subject, fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MediaService  {

    private _isSmall: boolean;

    public readonly onViewSizeChanged = new EventEmitter<{width: number, height: number}>();

    public readonly viewSize$: Subject<{width: number, height: number}>;

    constructor(
        @Optional() @Inject(LEGACY_MNB_MEDIA_SERVICE) private legacyMediaService: any
    ) {
        if (!legacyMediaService) {
            this._isSmall = window.innerWidth <= 767;
            window.addEventListener('resize', () => {
                this._isSmall = window.innerWidth <= 767;
            });
        }
        const initialSize = this.getWindowSize();
        this.viewSize$ = new BehaviorSubject<{width: number, height: number}>(initialSize);

        fromEvent(window, 'resize').pipe(
            map(() => this.getWindowSize()),
            debounceTime(500)
        ).subscribe((size) => {
            this.onViewSizeChanged.emit(size);
            this.viewSize$.next(size);
        });
    }

    public isSmall(): boolean {
        if (this.legacyMediaService) {
            return this.legacyMediaService.isSmall();
        } else {
            return this._isSmall;
        }
    }

    public forceSizeUpdate() {
        const size = this.getWindowSize();
        this.onViewSizeChanged.emit(size);
        this.viewSize$.next(size);
    }

    private getWindowSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

}