import { Pipe, PipeTransform } from '@angular/core';
import { MnbComparisonUtil } from '@shared-lib/modules/core/utils/mnb-comparison.util';

@Pipe({ name: 'mnbComparisonPerc' })
export class MnbComparisonPercentPipe implements PipeTransform {

    constructor() {
    }

    transform(value: number, comparisonValue: number): number {
        return MnbComparisonUtil.calcPercentage(value, comparisonValue);
    }
}
