import * as _d3 from 'd3';

export class MnbColor {

    // not sure if CI colors are needed, those a sometimes differnt from the other colors we use throughout the frontend
    // public static readonly CI_CYAN = new MnbColor('ci-cyan', '#6cc2ee', 0);
    // public static readonly CI_GREEN = new MnbColor('ci-green', '#76b733', 0);
    // public static readonly CI_VIOLET = new MnbColor('ci-purple', '#8968aa', 0);
    // public static readonly CI_YELLOW = new MnbColor('ci-yellow', '#ffec85', 0);
    // public static readonly CI_ORANGE = new MnbColor('ci-orange', '#ed765f', 0);
    // public static readonly CI_PINK = new MnbColor('ci-pink', '#d97eb2', 0);

    public static readonly RED = new MnbColor('red', '#ff2a00', 0);
    public static readonly RED_LIGHTER_1 = new MnbColor('red', '#ff7f66', -1);
    public static readonly RED_LIGHTER_2 = new MnbColor('red', '#ffd4cc', -2);
    public static readonly RED_DARKER_1 = new MnbColor('red', '#b31d00', 1);
    public static readonly RED_DARKER_2 = new MnbColor('red', '#661100', 2);

    public static readonly ORANGE = new MnbColor('orange', '#ff9600', 0);
    public static readonly ORANGE_LIGHTER_1 = new MnbColor('orange', '#ffc066', -1);
    public static readonly ORANGE_LIGHTER_2 = new MnbColor('orange', '#ffeacc', -2);
    public static readonly ORANGE_DARKER_1 = new MnbColor('orange', '#b36900', 1);
    public static readonly ORANGE_DARKER_2 = new MnbColor('orange', '#663c00', 2);

    public static readonly GREEN = new MnbColor('green', '#00e99e', 0);
    public static readonly GREEN_LIGHTER_1 = new MnbColor('green', '#50ffc7', -1);
    public static readonly GREEN_LIGHTER_2 = new MnbColor('green', '#b6ffe8', -2);
    public static readonly GREEN_DARKER_1 = new MnbColor('green', '#009d6a', 1);
    public static readonly GREEN_DARKER_2 = new MnbColor('green', '#005036', 2);

    public static readonly BLUE = new MnbColor('blue', '#19a8ec', 0);
    public static readonly BLUE_LIGHTER_1 = new MnbColor('blue', '#77ccf4', -1);
    public static readonly BLUE_LIGHTER_2 = new MnbColor('blue', '#d5effc', -2);
    public static readonly BLUE_DARKER_1 = new MnbColor('blue', '#0e78aa', 1);
    public static readonly BLUE_DARKER_2 = new MnbColor('blue', '#084664', 2);

    public static readonly PURPLE = new MnbColor('purple', '#5500d6', 0);
    public static readonly PURPLE_LIGHTER_1 = new MnbColor('purple', '#8a3dff', -1);
    public static readonly PURPLE_LIGHTER_2 = new MnbColor('purple', '#c8a3ff', -2);
    public static readonly PURPLE_DARKER_1 = new MnbColor('purple', '#37008a', 1);
    public static readonly PURPLE_DARKER_2 = new MnbColor('purple', '#18003d', 2);

    public static readonly PINK = new MnbColor('pink', '#b816ae', 0);
    public static readonly PINK_LIGHTER_1 = new MnbColor('pink', '#f843e5', -1);
    public static readonly PINK_LIGHTER_2 = new MnbColor('pink', '#f4a6ef', -2);
    public static readonly PINK_DARKER_1 = new MnbColor('pink', '#740e6d', 1);
    public static readonly PINK_DARKER_2 = new MnbColor('pink', '#2f062d', 2);

    public static readonly GREY = new MnbColor('grey', '#a2a4a6', 0);
    public static readonly GREY_LIGHTER_1 = new MnbColor('grey', '#d3d6d8', -1);
    public static readonly GREY_LIGHTER_2 = new MnbColor('grey', '#f0f3f6', -2);
    public static readonly GREY_DARKER_1 = new MnbColor('grey', '#77787a', 1);
    public static readonly GREY_DARKER_2 = new MnbColor('grey', '#5f6162', 2);

    public static readonly YELLOW = new MnbColor('yellow', '#ffde00', 0);
    public static readonly YELLOW_LIGHTER_1 = new MnbColor('yellow', '#ffeb66', -1);
    public static readonly YELLOW_LIGHTER_2 = new MnbColor('yellow', '#fff8cc', -2);
    public static readonly YELLOW_DARKER_1 = new MnbColor('yellow', '#b39b00', 1);
    public static readonly YELLOW_DARKER_2 = new MnbColor('yellow', '#665900', 2);


    public static readonly WHITE = new MnbColor('white', '#ffffff', 0);

    public static colors: MnbColor[] = [
        MnbColor.RED, MnbColor.RED_LIGHTER_1, MnbColor.RED_LIGHTER_2, MnbColor.RED_DARKER_1, MnbColor.RED_DARKER_2,
        MnbColor.GREEN, MnbColor.GREEN_LIGHTER_1, MnbColor.GREEN_LIGHTER_2, MnbColor.GREEN_DARKER_1, MnbColor.GREEN_DARKER_2,
        MnbColor.PINK, MnbColor.PINK_LIGHTER_1, MnbColor.PINK_LIGHTER_2, MnbColor.PINK_DARKER_1, MnbColor.PINK_DARKER_2,
        MnbColor.BLUE, MnbColor.BLUE_LIGHTER_1, MnbColor.BLUE_LIGHTER_2, MnbColor.BLUE_DARKER_1, MnbColor.BLUE_DARKER_2,
        MnbColor.ORANGE, MnbColor.ORANGE_LIGHTER_1, MnbColor.ORANGE_LIGHTER_2, MnbColor.ORANGE_DARKER_1, MnbColor.ORANGE_DARKER_2,
        MnbColor.PURPLE, MnbColor.PURPLE_LIGHTER_1, MnbColor.PURPLE_LIGHTER_2, MnbColor.PURPLE_DARKER_1, MnbColor.PURPLE_DARKER_2,
        MnbColor.YELLOW, MnbColor.YELLOW_LIGHTER_1, MnbColor.YELLOW_LIGHTER_2, MnbColor.YELLOW_DARKER_1, MnbColor.YELLOW_DARKER_2,
        MnbColor.GREY, MnbColor.GREY_LIGHTER_1, MnbColor.GREY_LIGHTER_2, MnbColor.GREY_DARKER_1, MnbColor.GREY_DARKER_2,
        MnbColor.WHITE
    ];

    public static ORDERED_COLORS: MnbColor[] = [
        MnbColor.GREEN, MnbColor.PURPLE, MnbColor.PINK, MnbColor.ORANGE, MnbColor.YELLOW, MnbColor.BLUE,
        MnbColor.GREEN_LIGHTER_1, MnbColor.PURPLE_LIGHTER_1, MnbColor.PINK_LIGHTER_1, MnbColor.ORANGE_LIGHTER_1, MnbColor.YELLOW_LIGHTER_1, MnbColor.BLUE_LIGHTER_1,
        MnbColor.GREEN_LIGHTER_2, MnbColor.PURPLE_LIGHTER_2, MnbColor.PINK_LIGHTER_2, MnbColor.ORANGE_LIGHTER_2, MnbColor.YELLOW_LIGHTER_2, MnbColor.BLUE_LIGHTER_2,
        MnbColor.GREEN_DARKER_1, MnbColor.PURPLE_DARKER_1, MnbColor.PINK_DARKER_1, MnbColor.ORANGE_DARKER_1, MnbColor.YELLOW_DARKER_1, MnbColor.BLUE_DARKER_1];

    // DEPRECATED _ NOT IN USE
    public static ORDERED_COLORS_OLD: MnbColor[] = [
        MnbColor.BLUE, MnbColor.PURPLE, MnbColor.RED, MnbColor.ORANGE, MnbColor.YELLOW, MnbColor.GREEN,
        MnbColor.BLUE_LIGHTER_1, MnbColor.PURPLE_LIGHTER_1, MnbColor.RED_LIGHTER_1, MnbColor.ORANGE_LIGHTER_1, MnbColor.YELLOW_LIGHTER_1, MnbColor.GREEN_LIGHTER_1,
        MnbColor.BLUE_LIGHTER_2, MnbColor.PURPLE_LIGHTER_2, MnbColor.RED_LIGHTER_2, MnbColor.ORANGE_LIGHTER_2, MnbColor.YELLOW_LIGHTER_2, MnbColor.GREEN_LIGHTER_2,
        MnbColor.BLUE_DARKER_1, MnbColor.PURPLE_DARKER_1, MnbColor.RED_DARKER_1, MnbColor.ORANGE_DARKER_1, MnbColor.YELLOW_DARKER_1, MnbColor.GREEN_DARKER_1];

    public readonly fitLightText: boolean;

    public static get(baseColor: string, shade: number) {
        return MnbColor.colors.find(color => color.shade === shade && color.baseColor === baseColor);
    }


    public clone(color: string, shade: number) {
        return new MnbColor(this.baseColor, color, shade);
    }


    private constructor(public readonly baseColor: string, public readonly color: string, public readonly shade: number) {
        const _color = this.color.substring(1, 7);
        const r = parseInt(_color.substring(0, 2), 16); // hexToR
        const g = parseInt(_color.substring(2, 4), 16); // hexToG
        const b = parseInt(_color.substring(4, 6), 16); // hexToB
        const uicolors = [r / 255, g / 255, b / 255];
        const c = uicolors.map((col) => {
            if (col <= 0.03928) {
                return col / 12.92;
            }
            return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
        this.fitLightText = L <= 0.40; // Threshold
    }

}

export interface MnbGradientColorConfig {
    color: MnbColor;
    colorCount?: number;
}

export class MnbGradientColor {

    constructor(private colors: MnbColor[], private minValue: number, private maxValue: number) { }

    getColor(value: number): MnbColor {
        const lastIndex = this.colors.length - 1;
        const index = Math.min(Math.ceil(lastIndex * (value - this.minValue) / (this.maxValue - this.minValue)), lastIndex);
        return this.colors[index];
    }

}

export class MnbColorUtil {

    public static getGroupedByBaseColor(): MnbColor[][] {

        const colorsByBaseColor = new Map<string, MnbColor[]>();

        MnbColor.colors.forEach(color => {
            if (colorsByBaseColor.has(color.baseColor)) {
                colorsByBaseColor.get(color.baseColor).push(color);
            } else {
                colorsByBaseColor.set(color.baseColor, [color]);
            }
        });

        return Array.from(colorsByBaseColor.keys()).map(baseColor => {
            return colorsByBaseColor.get(baseColor).sort((a, b) => {
                return a.shade === b.shade ? 0 : a.shade > b.shade ? 1 : -1;
            });
        });
    }

    private static getRGB(mnbColor: MnbColor) {
        const ctx = document.createElement('canvas').getContext('2d');

        ctx.fillStyle = mnbColor.color;
        ctx.fillRect(0, 0, 1, 1);

        const _color = ctx.getImageData(0, 0, 1, 1);

        const r = _color.data[0];
        const g = _color.data[1];
        const b = _color.data[2];

        return `rgb(${r},${g},${b})`;
    }

    public static createGradientColor(values: number[], config: MnbGradientColorConfig): MnbGradientColor {
        const minValue = values.reduce((a, b) => Math.min(a, b));
        const maxValue = values.reduce((a, b) => Math.max(a, b));

        const colors = d3.interpolateRgb('rgb(255,255,255)', this.getRGB(config.color));
        const length = config.colorCount || 10;
        const scaleStep = 1 / (length - 1);

        const gradientColors = new Array(length)
            .fill(null)
            .map((_val, index) => {
                const rgb = colors(index ? index * scaleStep : 0);
                const shade = index - length + 1;
                return config.color.clone(rgb, shade);
            });

        return new MnbGradientColor(gradientColors, minValue, maxValue);
    }

}