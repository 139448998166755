import { Component, HostBinding, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppHeader } from './models/app-header.model';
import { PortalLogin, PortalSession } from './modules/api/models/api-login.model';
import { ApiAuthService } from './modules/api/services/api-auth.service';
import { ApiSessionService } from './modules/api/services/api-session.service';
import { PortalInstanceConfig, PortalInstanceService } from './services/portal-instance.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    public year: number;

    public initializedState$: Observable<boolean> = this.apiSessionService.initializedState$;

    public loginDetails$: Observable<PortalLogin> = this.authService.getLogin();
    public session$: Observable<PortalSession> = this.authService.getSession();

    public header: Observable<AppHeader>;
    public tosLink: string;
    public privacyLink: string;

    public currentLanguage: string;

    public languageOptions = [{
        code: 'de', label: 'DE'
    }, {
        code: 'en', label: 'EN'
    }];

    public instanceConfig: PortalInstanceConfig;

    @HostBinding('class')
    public get cssClass(): string {
        return this.instanceConfig.cssClass || '';
    }

    constructor(
        private authService: ApiAuthService,
        private apiSessionService: ApiSessionService,
        private translateService: TranslateService,
        portalInstanceService: PortalInstanceService,
        private title: Title,
        router: Router,
        route: ActivatedRoute) {

        this.year = new Date().getFullYear();
        this.instanceConfig = portalInstanceService.instanceConfig;
        this.title.setTitle(this.instanceConfig.name);
        this.header = router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => route.snapshot),
            map(routeSnapshot => {
                while (routeSnapshot.firstChild) {
                    routeSnapshot = routeSnapshot.firstChild;
                }
                return routeSnapshot;
            }),
            map(routeSnapshot => routeSnapshot.data && routeSnapshot.data.header ? routeSnapshot.data.header : null)
        );
    }

    ngOnInit(): void {
        this.translateService.onLangChange.subscribe((language) => {
            this.currentLanguage = language.lang;

            if (this.currentLanguage !== 'de') {
                this.tosLink = 'https://www.minubo.com/en/agb';
                this.privacyLink = 'https://www.minubo.com/en/privacy-policy';
            } else {
                this.tosLink = 'https://www.minubo.com/de/agb';
                this.privacyLink = 'https://www.minubo.com/de/datenschutzerklaerung';
            }
        });
    }

    public logout() {
        this.authService.logout();
    }

    public changeLanguage(languageCode: string) {
        this.apiSessionService.changeLanguage(languageCode);
    }

}
