import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Report, ReportData, ReportSettings, ReportSettingsDrilldownStep } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { DrilldownFilterContext, MnbReportDrilldownStepModel } from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';

import {
    MnbReportsEntityDrilldownDisplayDrilldownMeasureModel,
} from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';


@Component({
    selector: 'mnb-reports-entity-drilldown-display',
    templateUrl: './mnb-reports-entity-drilldown-display.component.html'
})
export class MnbReportsEntityDrilldownDisplayComponent implements OnInit {

    @Input() public report: Report;
    @Input() public viewSettings: ReportSettings;
    @Input() public data: ReportData;
    @Input() public dataViewSettings: ReportSettings;
    @Input() public drilldownContext: DrilldownFilterContext[] = [];

    @Output() public drilldown = new EventEmitter<ReportSettingsDrilldownStep>();
    @Output() public drillup = new EventEmitter<MnbReportDrilldownStepModel | QueryFilter>();

    @Output() availableMeasures = new EventEmitter<MnbReportsEntityDrilldownDisplayDrilldownMeasureModel[]>();
    @Output() saveSelectedMeasureCodes = new EventEmitter<string[]>();

    ngOnInit(): void {
    }

    onDrilldown(step: ReportSettingsDrilldownStep) {
        this.drilldown.next(step);
    }

    onDrillup(step: MnbReportDrilldownStepModel | QueryFilter) {
        this.drillup.next(step);
    }

    onAvailableMeasures(availableMeasures: MnbReportsEntityDrilldownDisplayDrilldownMeasureModel[]) {
        this.availableMeasures.emit(availableMeasures);
    }

    onSaveSelectedMeasureCodes(selectedMeasureCodes: string[]) {
        this.saveSelectedMeasureCodes.emit(selectedMeasureCodes);
    }

}

