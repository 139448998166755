import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { MnbValidators } from '@shared-lib/modules/core/utils/mnb-validators.util';
import { TranslateService } from '@ngx-translate/core';
import { MnbForm } from '@shared-lib/modules/core/model/mnb-form.model';

@Component({
    selector: 'mnb-form-group-validation',
    template:
    `<div>
        <ng-content></ng-content>
        <mnb-form-group-validation-errors [errors]="errors"></mnb-form-group-validation-errors>
    </div>`
})
export class MnbFormGroupValidationComponent implements OnInit, OnDestroy {
    private _formControlRef: FormControl;
    private mnbForm: MnbForm;
    public parentElement: HTMLElement;

    public errors: Array<any> = [];

    constructor(
        private translate: TranslateService,
        private renderer: Renderer2,
        public elementRef: ElementRef,
        @Inject(FormGroupDirective) private formDirective: FormGroupDirective) { }

    @Input() set formControlRef(formControl: FormControl) {
        this._formControlRef = formControl;
    }

    @Input() expanderCode: string;
    @Input() controlName: string;

    get formControlRef(): FormControl {
        return this._formControlRef;
    }

    ngOnInit(): void {
        /* TODO_OM: This does not seem to be the angular way to get this element (i know it was that way before!) */
        // Add mnbFormGroup Directive
        let parentElement = this.elementRef.nativeElement.parentElement;
        while (parentElement && !parentElement.classList.value.includes('form-group')) {
            parentElement = parentElement.parentElement;
        }
        this.parentElement = parentElement;

        this.formControlRef.valueChanges.subscribe(() => {
            this.errorChangeHandler();
        });

        if (this.formDirective && this.formDirective.form instanceof MnbForm) {
            this.mnbForm = this.formDirective.form;
            // this.mnbForm.connectFormGroupValidation(this);
        }

    }

    ngOnDestroy(): void {
        if (this.mnbForm) {
            // this.mnbForm.disconnectFormGroupValidation(this);
        }
    }

     // HELPERS
    public errorChangeHandler() {

        // TODO: only checks the given formControl, we could also check the whole form
        const showErrors = MnbForm.hasErrors(this.formControlRef);

        if (showErrors) {
            this.buildErrors();
            this.renderer.addClass(this.parentElement, 'has-errors');
        } else {
            this.errors = [];
            this.renderer.removeClass(this.parentElement, 'has-errors');
        }

        // if (this.mnbForm) {
        //     this.mnbForm.formGroupValidationErrorListener(this, showErrors);
        // }
    }

    private buildErrors() {
        this.errors = MnbValidators.buildErrorsList(this.formControlRef, this.translate);
    }

}
