import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MnbPaginationComponent } from './pagination.component';

@Component({
    selector: 'mnb-pagination-page',
    template: `<ng-content></ng-content>`,
    host: { 'class': 'pagination-page' }
})
export class MnbPaginationPageComponent implements OnInit, OnDestroy {
    
    public show: boolean;

    @HostBinding('style.display') get display() {
        return this.show ? '' : 'none';
    }

    constructor(private pagination: MnbPaginationComponent) {}

    ngOnInit(): void {
        this.pagination.registerPage(this);
    }

    ngOnDestroy(): void {
        this.pagination.unRegisterPage(this);
    }

}