import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ModelEntityLink } from '@shared-lib/modules/data/model/mnb-data-model.model';
import { QueryFilter, QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { ENTITY_LINK_FACADE_SERVICE_TOKEN, EntityLinkFacadeService } from '@shared-lib/modules/model-entities/services/entity-link-facade.service';

@Pipe({ name: 'generateEntityLink' })
export class GenerateEntityLinkPipe implements PipeTransform {

    constructor(
        @Inject(ENTITY_LINK_FACADE_SERVICE_TOKEN) private entityLinkFacadeService: EntityLinkFacadeService,
    ) {
    }

    async transform(attributes: { [code: string]: string }, settings: EntityLinkPipeSettings = {}): Promise<ModelEntityLink | null> {
        const linkEntity = await this.entityLinkFacadeService.loadLinkEntity(Object.keys(attributes).map(code => ({code})));
        if (!linkEntity) {
            return null;
        }
        return ModelEntityLink.create(linkEntity, attributes, settings.timeFilter, settings.comparisonFilter, settings.filters);
    }
}

@Pipe({name: 'dynamicKeyObject'})
export class DynamicKeyObjectPipe implements PipeTransform {
    transform<T>(code: string, value: T): { [key: string]: T } {
        return { [code]: value };
    }
}

export type EntityLinkPipeSettings = {
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    filters?: QueryFilter[];
};
