import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MnbChannelTypes } from '@shared-lib/modules/data/model/mnb-data-model.model';

@Pipe({ name: 'mnbChannelTypeName' })
export class MnbChannelTypeNamePipe implements PipeTransform {


    constructor(private translate: TranslateService) {

    }


    transform(input: any): string {
        const channelType = MnbChannelTypes.getByCode(input);
        if (!channelType) {
            return '-';
        }

        return this.translate.instant(channelType.label);
    }


}
