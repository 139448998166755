import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAuthResponse, ApiAuthService } from '@minubo-portal/modules/api/services/api-auth.service';
import { ApiSessionService } from '@minubo-portal/modules/api/services/api-session.service';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import {AuthenticationType, PortalInstanceService} from '@minubo-portal/services/portal-instance.service';

@Component({
    templateUrl: './auth-login-page.component.html'
})
export class AuthLoginPageComponent implements OnDestroy {

    public load: MnbBusyStatus;
    public authResponse$ = new BehaviorSubject<ApiAuthResponse>(null);

    public email: string;
    public password: string;

    public portalName: string;
    public isIntersportAuth: boolean;

    private sessionSubscription: Subscription;

    constructor(
        private apiAuthService: ApiAuthService,
        private apiSessionService: ApiSessionService,
        portalInstanceService: PortalInstanceService,
        route: ActivatedRoute,
        router: Router
    ) {
        this.portalName = portalInstanceService.instanceConfig.name;
        this.load = MnbBusyStatus.DONE;
        this.isIntersportAuth = !route.snapshot.queryParams['mail'] && portalInstanceService.instanceConfig.authenticationType === AuthenticationType.intersport;

        let triedCode = false;
        this.sessionSubscription = this.apiSessionService.sessionState$.subscribe((session) => {
            if (session.authenticated) {
                router.navigateByUrl('');
            } else if (!triedCode) {
                triedCode = true;
                const code: string = route.snapshot.queryParams['code'];
                if (code) {
                    this.load = new MnbBusyStatus();
                    this.apiAuthService.loginByCode(code).subscribe(r => {
                        this.authResponse$.next(r);
                        if (!r.wasSuccessful) {
                            this.load.done();
                        }
                    });
                }
            }
        });
    }

    login(): void {
        this.load = new MnbBusyStatus();

        this.apiAuthService.login(this.email, this.password).subscribe(r => {
            this.authResponse$.next(r);
            if (!r.wasSuccessful) {
                this.load.done();
            }
        });
    }

    onCredentialsChanged() {
        this.authResponse$.next(null);
    }

    ngOnDestroy(): void {
        this.sessionSubscription.unsubscribe();
    }
}
