import { Injectable } from '@angular/core';
import { MnbConfig, MnbConfigProvider } from '@shared-lib/modules/config/services/mnb-config.service';
import { ApiSessionService } from '../services/api-session.service';
import {filter, take} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiConfigProvider implements MnbConfigProvider {

    constructor(
        private sessionService: ApiSessionService,
        private translateService: TranslateService) {
    }

    async getConfig(): Promise<MnbConfig> {
        const session = await this.sessionService.sessionState$
            .pipe(
                filter(s => s.authenticated && !!s.login && !!s.tenant),
                take(1)
            ).toPromise();

        this.translateService.use(session.login.language);

        return {
            langCode: session.login.language === 'de' ? 'deu' : 'eng',
            calendarConfig: session.tenant.calendarConfig,
            currencyCode: session.tenant.currencySymbol,
        };
    }

}
