import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { ModelAttribute, ModelChannel, MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';
import { MnbBusyStatus } from '@shared-lib/modules/core/model/mnb-core-busy-status.model';
import { QuerySettingsComparisonFilter, QuerySettingsTimeFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { ModelEntityLink } from '@shared-lib/modules/data/model/mnb-data-model.model';
import { TenantModelEntityData } from '@shared-lib/modules/model-entities/models/tenant.model';
import { MODEL_ENTITIES_LINK_DATA_SERVICE_TOKEN, ModelEntitiesEntityLinkDataService } from '@shared-lib/modules/model-entities/services/model-entities-entity-link-data.service';

@Component({
    selector: 'aly-model-entities-entity-link',
    templateUrl: './aly-model-entities-entity-link.component.html'
})
export class AlyModelEntitiesEntityLinkComponent implements OnInit {

    @Input() public link: ModelEntityLink;
    @Input() public openInSameTab: boolean;
    @Input() public alwaysShowIcon = false;

    public href: string;

    public channel: ModelChannel;

    public model: AlyModelEntitiesEntityLinkModel;

    public load = new MnbBusyStatus();

    constructor(
        @Inject(MODEL_ENTITIES_LINK_DATA_SERVICE_TOKEN) private dataService: ModelEntitiesEntityLinkDataService,
        private modelService: MnbModelService,
        private ref: ChangeDetectorRef
        ) {

    }

    ngOnInit() { }

    public async onOpened() {
        if (!this.model) {

            const params = this.link.entity.keyAttributes.map(ka => ka.code + '=' + encodeURIComponent(this.link.attributes[ka.code] || ''));

            if (this.link.timeFilter) {
                params.push('time=' + QuerySettingsTimeFilter.convertToString(this.link.timeFilter));
            }

            if (this.link.comparisonFilter) {
                params.push('comparison=' + QuerySettingsComparisonFilter.convertToString(this.link.comparisonFilter));
            }

            const codeOrId = (this.link.entity.code ? this.link.entity.code : this.link.entity.id);

            this.href = '#/modelentities/v3/' + codeOrId + '?' + params.join('&');

            const dataParams: any = {codeOrId, ... this.link.attributes};

            Object.keys(dataParams).forEach(param => dataParams[param] = dataParams[param] || '');

            dataParams.attributesOnly = true;

            const data = await this.dataService.loadData({}, dataParams ).catch(err => null);

            const model = new AlyModelEntitiesEntityLinkModel();

            if (!data) {
                this.model = model;
                this.load.done();
                this.ref.markForCheck();
                return;
            }

            const entity = this.link.entity;

            model.keyAttributes = await this.mapAttributes(entity.keyAttributes, data);
            model.nameAttributes = await this.mapAttributes(entity.nameAttributes, data);
            model.displayAttributes = (await this.mapDisplayAttributes(entity.displayAttributes, data)).filter(a => !a.attribute.isHidden);

            if (entity.imageAttribute) {
                model.imageUrl = data.attributes[entity.imageAttribute.code];
            }

            this.channel = await this.modelService.getChannel(this.link.attributes);

            this.model = model;
            this.load.done();
            this.ref.markForCheck();
        }
    }

    private async mapAttributes(attributes: {code: string}[], data: TenantModelEntityData) {
        return Promise.all(attributes.map(attribute => {
            return this.modelService.getAttribute(attribute.code).then(modelAttribute => {
                const attributeModel = new AlyModelEntitiesEntityLinkAttributeModel();
                attributeModel.attribute = modelAttribute;
                attributeModel.value = data.attributes[attribute.code];

                return  attributeModel;
            }, () => {
                return new AlyModelEntitiesEntityLinkAttributeModel();
            });
        })).then(attributeModels => attributeModels.filter(a => !!a.attribute));
    }

    private async mapDisplayAttributes(attributes: {code: string, isAdditional: boolean}[], data: TenantModelEntityData) {
        return Promise.all(attributes.filter(attribute => !attribute.isAdditional).map(attribute => {
            return this.modelService.getAttribute(attribute.code).then(modelAttribute => {

                const attributeModel = new AlyModelEntitiesEntityLinkAttributeModel();
                attributeModel.attribute = modelAttribute;
                const displayAttributeData =  (data && data.displayAttributes ? data.displayAttributes[modelAttribute.code] : null) || [];

                attributeModel.displayValues = displayAttributeData;

                return  attributeModel;
            }, () => {
                return new AlyModelEntitiesEntityLinkAttributeModel();
            });
        })).then(attributeModels => attributeModels.filter(a => !!a.attribute));
    }
}


export class AlyModelEntitiesEntityLinkModel {

    keyAttributes: AlyModelEntitiesEntityLinkAttributeModel[];
    nameAttributes: AlyModelEntitiesEntityLinkAttributeModel[];
    displayAttributes: AlyModelEntitiesEntityLinkAttributeModel[];

    imageUrl: string;
}

export class AlyModelEntitiesEntityLinkAttributeModel {

    attribute: ModelAttribute;
    value: string;

    displayValues?: string[];
}
