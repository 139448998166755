import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { MnbQueryFilterTypePipe } from '@shared-lib/modules/queries/pipes/query-filter-type.pipe';
import { MnbLibModule } from '@shared-lib/shared-lib.module';
import { MnbCoreModule } from '@shared-lib/modules/core/mnb-core.module';
import { MnbQueryTimeInfotextComponent } from '@shared-lib/modules/queries/components/mnb-query-time-infotext.component';
import { MnbQueryFilterInfotextComponent } from '@shared-lib/modules/queries/components/mnb-query-filter-infotext.component';

@NgModule({
    declarations: [
        MnbQueryTimeInfotextComponent,
        MnbQueryFilterInfotextComponent,
        MnbQueryFilterTypePipe
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule,
        MnbLibModule,
        MnbCoreModule
    ],
    entryComponents: [
    ],
    providers: [
        MnbQueryFilterTypePipe
    ],
    exports: [
        MnbQueryTimeInfotextComponent,
        MnbQueryFilterInfotextComponent,
        MnbQueryFilterTypePipe
    ]
})
export class MnbQueriesModule {
}
