import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


/**
 * capitalizes the first letter of the input and transforms the rest of the input to lowercase
 */
@Pipe({ name: 'mnbCapitalize' })
export class MnbCapitalizePipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(input: string): string {
        return input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    }
}