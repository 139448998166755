import { ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { DashboardSettings, DashboardWidgetQuerySettings, DashboardWidgetVisualizationSettings } from './mnb-data-dashboard.model';

export enum EntityType {
    DASHBOARD = 'dashboard',
    REPORT = 'report',
    QUERY = 'query',
    ANALYSIS = 'analysis',
    SEGMENTATION = 'segmentation'
}

export class EntityEmbedment {

    constructor(
        public id?: number,
        public fkCustomer?: number,
        public entityType?: EntityType,
        public entityId?: number,
        public code?: string,
        public settings?: EntityEmbedmentSettings) {}
}

export class EntityEmbedmentSettings {
    filters: Array<EntityEmbedmentSettingsFilter>;
}

export class EntityEmbedmentSettingsFilter {
    attributeCode: string;
    typeCode: string;
    values: Array<string>;
    title?: string;
}


export type EntityState = {
    reminderNotes?: EntityReminderNote[];
    alertNotes?: EntityAlertNote[];

    id: number;
    entityId: number;
    entityType: EntityType;

    entitySettings: EntitySettingsData;
};

export type EntitySettingsData = {
    dashboardWidget?: {
        visualizationSettings: DashboardWidgetVisualizationSettings,
        querySettings: DashboardWidgetQuerySettings,
        dashboardSettings: DashboardSettings,
        dashboardWidgetId: number
    };
    report?: {
        settings: ReportSettings
    };
    segmentation?: {
        settings: any
    };
};

export type EntitySchedule = {
    id: number;
    isDeactivated: boolean;
    title: string;
    typeCode: 'ALERT' | 'REMINDER';
    frequency: string;
    entityTypeCode: EntityType;
    entityId: number;
    recipientLoginIds: number[];
};


export type EntityAlertSchedule = EntitySchedule & {
    typeCode: 'ALERT';
    alertCriteriaSettings?: EntityAlertScheduleCriteria;
};

export type EntityReminderSchedule = EntitySchedule & {
    typeCode: 'REMINDER';
};

export type EntityReminderNote = {
    id: number;
    title: string;
    scheduleFrequency: string;

    entityStateRefId?: number;

    // TODO: Not needed - move to tenant state / rename to tenantDay
    validOnDay?: Date;
};

export type EntityAlertNote = {
    id: number;
    title: string;
    scheduleFrequency: string;

    entityStateRefId?: number;
    // TODO: Not needed - move to tenant state / rename to tenantDay
    validOnDay?: Date;

    resultData: EntityAlertNoteResultData;
};

export type EntityAlertNoteResultData = {
    sentiment: EntityAlertScheduleSentiment;
    criteria: EntityAlertScheduleCriteria;
    count?: EntityAlertNoteResultDataCount;
    planValue?: EntityAlertNoteResultDataPlanValue;
    planPercent?: EntityAlertNoteResultDataPlanPercent;
    value?: EntityAlertNoteResultDataValue;
    fixedValue?: EntityAlertNoteResultFixedValue;
};

export type EntityAlertNoteResultFixedValue = {
    condition: EntityAlertScheduleCriteriaCondition;
    currentValue: number;
};

export type EntityAlertScheduleCriteria = {
    count?: EntityAlertScheduleCriteriaCount;
    planValue?: EntityAlertScheduleCriteriaPlanValue;
    planPercent?: EntityAlertScheduleCriteriaPlanPercent;
    value?: EntityAlertScheduleCriteriaValue;
};

export type EntityAlertScheduleCriteriaCount = {
    condition: EntityAlertScheduleCriteriaCondition;
    count: number;
};

export type EntityAlertScheduleCriteriaPlanValue = {
    condition: EntityAlertScheduleCriteriaCondition;
    value: number;
    byAttributes: boolean; // if the condition should be checked by item or on totals
    attributeValues?: string[]; // if the condition should be checked on a specific combination of values

    // TODO: Current fields coming from backend. PM: Discuss with Ole if above is required for this type
    measureCode: string;
    planMeasureCode: string;
    currentValue: number;
    plannedValue: number;
};
export type EntityAlertScheduleCriteriaPlanPercent = {
    condition: EntityAlertScheduleCriteriaCondition;
    percent: number;
};

export type EntityAlertScheduleCriteriaValue = {
    condition: EntityAlertScheduleCriteriaCondition;
    measureCode?: string;
    value?: number;
    byAttributes: boolean; // if the condition should be checked by item or on totals
    attributeValues?: string[]; // if the condition should be checked on a specific combination of values
};


export type EntityAlertNoteResultDataCount = {
    count: number;
};

export type EntityAlertNoteResultDataPlanValue = {
    // TODO: rename to plaValue and value
    plannedValue?: number;
    currentValue?: number;

    byAttributes?: {
        attributeValues: string[],
        planValue: number,
        value: number
    }[];
    // TODO: Remove
    measureCode?: string;
    planMeasureCode?: string;
};

export type EntityAlertNoteResultDataPlanPercent = {
    planValue?: number;
    value?: number;
};

export type EntityAlertNoteResultDataValue = {
    value?: number;

    byAttributes?: {
        attributeValues: {[code: string]: string},
        value: number
    }[];
};

export enum EntityAlertScheduleCriteriaCondition {
    GREATER = 'GREATER',
    GREATER_EQUALS = 'GREATER_EQUALS',
    SMALLER = 'SMALLER',
    SMALLER_EQUALS = 'SMALLER_EQUALS',
    EXISTS = 'EXISTS'
}

export type EntityAlertScheduleCriteriaConditionOption = {
    value: EntityAlertScheduleCriteriaCondition,
    label: string
};

export class EntityAlertScheduleCriteriaConditionUtil {

    public static getSign(condition: EntityAlertScheduleCriteriaCondition) {
        switch (condition) {
        case EntityAlertScheduleCriteriaCondition.GREATER:
            return '>';
        case EntityAlertScheduleCriteriaCondition.GREATER_EQUALS:
            return '≥';
        case EntityAlertScheduleCriteriaCondition.SMALLER:
            return '<';
        case EntityAlertScheduleCriteriaCondition.SMALLER_EQUALS:
            return '≤';
        }
        return '';
    }

    public static buildOptionList(conditions: EntityAlertScheduleCriteriaCondition[]): EntityAlertScheduleCriteriaConditionOption[] {
        return conditions.map(value => {
            return {value, label: EntityAlertScheduleCriteriaConditionUtil.getSign(value)};
        });
    }

}


export enum EntityAlertScheduleSentiment {
    GOOD = 'GOOD',
    INFO = 'INFO',
    NEUTRAL = 'NEUTRAL',
    BAD = 'BAD',
    VERY_BAD = 'VERY_BAD'
}
