import { Injectable } from '@angular/core';
import { ApiService, Resources } from '@minubo-portal/modules/api/services/api.service';
import { TrackingCode, TrackingToolCode } from '@shared-lib/modules/core/model/tracking.model';

@Injectable()
export class ApiTrackingService {


    constructor(
        private apiService: ApiService
    ) {

    }

    public tracking(toolCode: TrackingToolCode, eventCode: TrackingCode,
                    parameters?:  {[param: string]: string | number} ): void {

        const trackingEvent = {
            toolCode: toolCode,
            eventCode: eventCode,
            parameters: !!parameters ? parameters : {}
        };
        this.apiService.save(Resources.TRACKING, trackingEvent, {}).subscribe(_ => {});
    }
}
