import { Directive, Input, ElementRef, NgZone, Renderer2, ChangeDetectorRef, OnInit, Component, Optional, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Directive({
    selector: '[mnbTooltipOwner]'
})
export class MnbTooltipOwnerDirective implements OnDestroy, AfterViewInit {

    @Input('mnbTooltipOwner') tooltipContent: string;
    @Input() tooltipPlacement: 'bottom' | 'left' | 'right' | 'top' | 'top-left' = 'bottom';
    @Input() tooltipDisabled = false;

    @Input() appendToBody = false;
    @Input() stretchContent: boolean;
    @Input() position: { left?: number, right?: number, top?: number, bottom?: number };

    private tooltip: HTMLElement;
    private content: MnbTooltipContentComponent;
    private tooltipContentRoot: HTMLElement = document.getElementById('mnb-tooltip-content-root');

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef
    ) { }

    ngAfterViewInit() {
        if (!this.appendToBody) {
            this.renderer.addClass(this.elementRef.nativeElement, 'mnb-tooltip-owner');
        }
        this.zone.runOutsideAngular(() => {
            this.renderer.listen(this.elementRef.nativeElement, 'mouseenter', (event) => {
                this.onMouseEnter();
            });
            this.renderer.listen(this.elementRef.nativeElement, 'mouseleave', (event) => {
                this.onMouseLeave();
            });
        });
    }

    public detectChanges() {
        this.cdr.detectChanges();
    }

    private onMouseEnter() {
        if (!this.tooltipDisabled) {

            if (!this.tooltip) {
                this.tooltip = this.renderer.createElement('div');
                const cssClasses = ['mnb-tooltip-content'];
                if (this.stretchContent) {
                    cssClasses.push('no-wrap');
                }
                this.tooltip.innerHTML = '<div class="' + cssClasses.join(' ') + '">' + this.tooltipContent + '</div>'; // tooltip content
                this.applyCustomPosition();
                this.renderer.addClass(this.tooltip, 'mnb-tooltip-container');
                this.renderer.appendChild(this.elementRef.nativeElement, this.tooltip);
            }

            if (this.content) {
                this.content.initContent();
            }

            this.initPlacemntClass();

            if (this.appendToBody) {
                this.initTooltipContentRoot();
                this.renderer.addClass(this.tooltip, 'with-absolute-position');
                this.renderer.appendChild(this.tooltipContentRoot, this.tooltip);
                this.updatePosition();
            }
            this.cdr.detectChanges();
        }
    }

    private onMouseLeave() {
        setTimeout(() => {
            this.hideTooltip();
        });
    }

    private initTooltipContentRoot(): void {
        // is already set
        if (this.tooltipContentRoot) {
            return;
        }

        // exists but needs to be set
        const tooltipContentRoot = document.getElementById('mnb-tooltip-content-root');
        if (tooltipContentRoot && document.body.contains(tooltipContentRoot)) {
            this.tooltipContentRoot = tooltipContentRoot;
            return;
        }

        // doesnt exist
        this.tooltipContentRoot = this.renderer.createElement('div');
        this.renderer.setAttribute(this.tooltipContentRoot, 'id', 'mnb-tooltip-content-root');
        this.renderer.appendChild(document.body, this.tooltipContentRoot);
    }

    private applyCustomPosition() {
        if (this.position) {
            this.tooltip.style.left = this.position.left ? this.position.left + 'px' : null;
            this.tooltip.style.top = this.position.top ? this.position.top + 'px' : null;
            this.tooltip.style.bottom = this.position.bottom ? this.position.bottom + 'px' : null;
            this.tooltip.style.right = this.position.right ? this.position.right + 'px' : null;
        }
    }

    registerTooltipContent(content: MnbTooltipContentComponent) {
        this.content = content;
        this.tooltip = content.elementRef.nativeElement;
        this.applyCustomPosition();
        if (this.stretchContent && !this.tooltip.classList.contains('no-wrap')) {
            this.renderer.addClass(this.tooltip, 'no-wrap');
        }
    }

    initPlacemntClass() {

        this.renderer.removeClass(this.tooltip, 'mnb-tooltip-bottom');
        this.renderer.removeClass(this.tooltip, 'mnb-tooltip-left');
        this.renderer.removeClass(this.tooltip, 'mnb-tooltip-right');
        this.renderer.removeClass(this.tooltip, 'mnb-tooltip-top');

        if (this.tooltipPlacement === 'bottom') {
            this.renderer.addClass(this.tooltip, 'mnb-tooltip-bottom');
        } else if (this.tooltipPlacement === 'left') {
            this.renderer.addClass(this.tooltip, 'mnb-tooltip-left');
        } else if (this.tooltipPlacement === 'right') {
            this.renderer.addClass(this.tooltip, 'mnb-tooltip-right');
        } else if (this.tooltipPlacement === 'top') {
            this.renderer.addClass(this.tooltip, 'mnb-tooltip-top');
        } else if (this.tooltipPlacement === 'top-left') {
            this.renderer.addClass(this.tooltip, 'mnb-tooltip-top-left');
        }
    }

    public showTooltipOnPosition(top: number, left: number) {
        this.initPlacemntClass();

        if (this.appendToBody) {
            this.initTooltipContentRoot();

            this.renderer.appendChild(this.tooltipContentRoot, this.tooltip);
            this.renderer.addClass(this.tooltip, 'with-absolute-position');
            this.renderer.addClass(this.tooltip, 'with-individual-position');

            this.updatePosition(left, top);
        }
        if (this.content) {
            this.content.initContent();
        }
    }

    public hideTooltip() {
        if (this.appendToBody && !this.tooltipDisabled) {
            this.renderer.removeClass(this.tooltip, 'mnb-tooltip-visible');
        }
    }

    private updatePosition(x?: number, y?: number) {
        setTimeout(() => {

            if (isNullOrUndefined(x)) {

                const computedElementStyle: CSSStyleDeclaration = window.getComputedStyle(this.elementRef.nativeElement);
                const hostPos = this.elementRef.nativeElement.getBoundingClientRect();
                const tooltipRect = this.tooltip.getBoundingClientRect();
                const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

                let top: number, left: number;
                if (this.tooltipPlacement === 'bottom') {
                    top = hostPos.bottom - Number.parseFloat(computedElementStyle.paddingBottom);
                    left = hostPos.left + Number.parseFloat(computedElementStyle.paddingLeft);
                }

                if (this.tooltipPlacement === 'left') {
                    top = hostPos.top + Number.parseFloat(computedElementStyle.paddingTop);
                    left = hostPos.left + Number.parseFloat(computedElementStyle.paddingLeft);
                }

                if (this.tooltipPlacement === 'right') {
                    top = hostPos.top;
                    left = hostPos.right;
                }

                if (this.tooltipPlacement === 'top') {
                    top = hostPos.top - tooltipRect.height;
                    left = hostPos.left;
                }

                y = top + scrollPosition;
                x = left;
            }

            this.renderer.setStyle(this.tooltip, 'top', `${y}px`);
            this.renderer.setStyle(this.tooltip, 'left', `${x}px`);
            this.renderer.setStyle(this.tooltip, 'right', 'unset');
            this.renderer.setStyle(this.tooltip, 'bottom', 'unset');
            this.renderer.addClass(this.tooltip, 'mnb-tooltip-visible');
        });
    }

    ngOnDestroy() {
        if (this.appendToBody && this.tooltip) {
            this.renderer.removeChild(this.tooltip.parentElement, this.tooltip);
        }
    }
}


@Component({
    selector: 'mnb-tooltip-content',
    host: {
        'class': 'mnb-tooltip-container'
    },
    template: '<div class="mnb-tooltip-content" [style.max-width.px]="maxWidth"><ng-content *ngIf="initialized"></ng-content></div>'
})
export class MnbTooltipContentComponent implements OnInit {
    @Input() maxWidth: number;

    @Output() onInit = new EventEmitter<void>();

    initialized: boolean;

    constructor(
        public elementRef: ElementRef,
        private cdr: ChangeDetectorRef,
        @Optional() private ownerDirective: MnbTooltipOwnerDirective
    ) {}

    ngOnInit(): void {
        if (this.ownerDirective) {
            this.ownerDirective.registerTooltipContent(this);
        }
    }

    public initContent() {
        if (!this.initialized) {
            this.onInit.emit();
            this.initialized = true;
            this.cdr.detectChanges();
        }
    }
}
