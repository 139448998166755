import { Component, ElementRef, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MnbFormSectionDirective } from '../../directives/form-section/mnb-form-section.directive';
import { MnbForm, MnbFormGroupHandler } from '../../model/mnb-form.model';

@Component({
  selector: '[mnbFormGroup]',
  template:
    `
        <ng-content></ng-content>
        <div *ngIf="!withoutErrors && !withoutValidation" [mnbFormGroupErrors]="formControlRef"></div>
    `,
    host: { class: 'form-group' }
})
export class MnbFormGroupComponent implements OnInit, OnDestroy, MnbFormGroupHandler {

    @Input() public withoutErrors: boolean;
    @Input() public withoutValidation: boolean;

    @Input('mnbFormGroup') private formControlRef: AbstractControl;

    private element: HTMLElement;

    constructor(
        @Optional() private formSection: MnbFormSectionDirective, elementRef: ElementRef) {
            this.element = elementRef.nativeElement;
    }

    ngOnInit(): void {
        if (this.formSection) {
            this.formSection.registerGroup(this);
        }

        this.formControlRef.valueChanges.subscribe((val) => {
            this.errorChangeHandler();
            if (this.hasErrorsToShow) {
                this.element.classList.add('has-errors');
            } else {
                this.element.classList.remove('has-errors');
            }
        });
    }

    ngOnDestroy(): void {
        if (this.formSection) {
            this.formSection.unregisterGroup(this);
        }
    }

    get hasErrorsToShow(): boolean {
        return MnbForm.hasErrors(this.formControlRef);
    }

    private errorChangeHandler() {
        if (this.formSection) {
            this.formSection.refreshErrorState();
        }
    }

}
