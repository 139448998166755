import { NgZone } from '@angular/core';
import { ChartData, BarChartConfig, ChartDataType, ChartDataArray } from '../services/chart.models';
import { MnbUnitPipe } from '@shared-lib/modules/core/pipes/unit.pipe';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { LinearAxisRenderer, TooltipRenderer, BandAxisRenderer, Rectangle, LegendRenderer } from './chart-renderer';
import * as _d3 from 'd3';
import { ColumnBarChartRenderer, ColumnBarChartAttrHandler, ColumnBarRenderer, StackedColumnBarRenderer, ParallelColumnBarRenderer } from './column-bar-chart-renderer';
import { LineRenderer } from './line-chart-renderer';
import { MnbUnitService } from '@shared-lib/modules/core/services/unit/mnb-unit.service';


export class ColumnChartRenderer extends ColumnBarChartRenderer {

  constructor(
    protected config: BarChartConfig,
    zone: NgZone,
    unitPipe: MnbUnitPipe,
    emptyPipe: MnbEmptyValuePipe,
    unitService: MnbUnitService
  ) {
    super(config, zone, unitPipe, emptyPipe, unitService);
  }

  render(el: HTMLElement | HTMLElement[]) {
    // change this later when mnb-chart passes in ele[0] instead of ele
    const temp: any = el;
    const ele: HTMLElement = temp.length ? temp[0] : temp;
    this.ele = ele;

    this.svgElement = this.createSvgElement(ele);
    this.svgHTMLElement = this.svgElement.node();

    let legendHeight = this.config.legendConfig.hide ? 5 : 20;

    const dimensions: { height: number, width: number } = super.getDimensions(ele);

    if (!this.config.legendConfig.hide) {
      legendHeight = new LegendRenderer(this.config, this.emptyPipe).render(this.svgElement, this.el, this.config.data, dimensions.width - 1);
    }

    if (!legendHeight) {
      legendHeight = 5;
    }

    const chartRect: Rectangle = {
      x: 0,
      y: legendHeight,
      width: dimensions.width - 1,
      height: dimensions.height - legendHeight
    };

    this.gElement = this.svgElement.append('g');

    const yAxisRenderer = new LinearAxisRenderer(this.gElement, this.config, false, this.rendererConfig.hasSecondaryAxis(), this.unitPipe);
    const xAxisRenderer = new BandAxisRenderer(this.gElement, this.config, true, this.emptyPipe);
    const dataLimit = this.config.limitXAxis ? xAxisRenderer.calcMaximumBands(
      chartRect.width -  (!this.config.yAxisConfig.hide ? 25 + (this.rendererConfig.hasSecondaryAxis() ? 25 : 0) : 0),
      this.rendererConfig) : 100000;

    const xAxisHeight = xAxisRenderer.calcXHeight();
    const innerHeight = chartRect.height - xAxisHeight;

    const yAxis = yAxisRenderer.renderY(chartRect.width, innerHeight, dataLimit);

    let secondaryYAxis: { scale: d3.ScaleLinear<number, number>, width: number };

    if (this.rendererConfig.hasSecondaryAxis()) {
      const secondaryYAxisRenderer = new LinearAxisRenderer(this.gElement, this.config, true, true, this.unitPipe);
      secondaryYAxis = secondaryYAxisRenderer.renderY(chartRect.width - yAxis.width, innerHeight, dataLimit);
    }


    const innerRect: Rectangle = {
      x: chartRect.x + yAxis.width,
      y: chartRect.y,
      width: chartRect.width - yAxis.width - (secondaryYAxis ? secondaryYAxis.width : 0),
      height: innerHeight
    };

    this.gElement.attr('transform', `translate(${innerRect.x},${innerRect.y})`);

    const xAxis = xAxisRenderer.renderX(innerRect.width, innerRect.height, dataLimit);

    const columnRenderers: ColumnRenderer[] = [];
    const lineRenderers: LineRenderer[] = [];

    const secondaryAsLine = this.config.secondaryAsLine;

    // comparison series
    if (this.rendererConfig.hasComparisonData()) {
      // primary
      columnRenderers.push(new ColumnRenderer(this.gElement, this.rendererConfig.getComparisonData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData() && !secondaryAsLine));
      if (this.rendererConfig.hasSecondaryData() && !secondaryAsLine) {
        columnRenderers.push(new ColumnRenderer(this.gElement, this.rendererConfig.getSecondaryComparisonData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData(), this.rendererConfig.hasSecondaryAxis()));
      }
    }

    if (this.config.breakdownMode) {
      // render with breakdown data. breakdown doesn't render any comparison
      switch (this.config.breakdownMode) {
        case 'stacked':
          const stackedRenderer = new StackedColumnRenderer(this.gElement, false);
          stackedRenderer.render(this.config.getDataArrays(ChartDataType.breakdown), xAxis.scale, yAxis.scale, dataLimit);
          break;
        case 'fill-stacked':
          const fillStackedRenderer = new StackedColumnRenderer(this.gElement, true);
          fillStackedRenderer.render(this.config.getDataArrays(ChartDataType.breakdown), xAxis.scale, yAxis.scale, dataLimit);
          break;
        case 'parallel':
          const parallelRenderer = new ParallelColumnRenderer(this.gElement);
          parallelRenderer.render(this.config.data, xAxis.scale, yAxis.scale);
          break;
        default:
          console.error('breakdownData without a configured breakdownMode');
      }
    } else {
      // primary
      columnRenderers.push(new ColumnRenderer(this.gElement, this.rendererConfig.getPrimaryData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData() && !secondaryAsLine));
      if (this.rendererConfig.hasSecondaryData()) {
        if (!secondaryAsLine) {
          columnRenderers.push(new ColumnRenderer(this.gElement, this.rendererConfig.getSecondaryData(),  this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData(), this.rendererConfig.hasSecondaryAxis()));
        } else {
          lineRenderers.push(new LineRenderer(this.gElement, this.rendererConfig.getSecondaryData(), null, this.rendererConfig.hasSecondaryAxis()));
          if (this.rendererConfig.hasComparisonData()) {
            lineRenderers.push(new LineRenderer(this.gElement, this.rendererConfig.getSecondaryComparisonData(), null, this.rendererConfig.hasSecondaryAxis()));
          }
        }
      }

      if (this.rendererConfig.hasPlanData()) {
        columnRenderers.push(new ColumnRenderer(this.gElement, this.rendererConfig.getPrimaryPlanData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData(), null, secondaryAsLine));
      }
      if (this.rendererConfig.hasSecondaryData() && this.rendererConfig.getSecondaryPlanData() && !secondaryAsLine) {
        columnRenderers.push(new ColumnRenderer(this.gElement, this.rendererConfig.getSecondaryPlanData(), this.rendererConfig.hasComparisonData(), this.rendererConfig.hasSecondaryData(), this.rendererConfig.hasSecondaryAxis()));
      } else if (this.rendererConfig.hasSecondaryData() && this.rendererConfig.getSecondaryPlanData()) {
          lineRenderers.push(new LineRenderer(this.gElement, this.rendererConfig.getSecondaryPlanData(), null, this.rendererConfig.hasSecondaryAxis()));
      }
    }

    new TooltipRenderer(this.config, this.tooltipContent, lineRenderers).render(this.svgElement, ele, innerRect, xAxis.scale, yAxis.scale, dataLimit, false);

    columnRenderers.forEach(column => column.render(xAxis.scale, !column.secondaryAxis ? yAxis.scale : secondaryYAxis.scale, dataLimit));

    lineRenderers.forEach(line => line.render(xAxis.scale, !line.secondaryAxis ? yAxis.scale : secondaryYAxis.scale, dataLimit));
    lineRenderers.forEach(line => line.renderGuides(xAxis.scale, !line.secondaryAxis ? yAxis.scale : secondaryYAxis.scale, dataLimit));

  }

}

export class ColumnRenderer extends ColumnBarRenderer {

  constructor(
      gElement: d3.Selection<SVGGElement, {}, null, undefined>,
      private data: ChartDataArray,
      hasComparison: boolean,
      private hasSecondaryData: boolean,
      public secondaryAxis?: boolean,
      private secondaryAsLine?: boolean) {

    super(gElement, data, hasComparison);

  }

  public render(x: _d3.ScaleBand<string>, y: _d3.ScaleLinear<number, number>, limit?: number) {
    const padding = this.calcPadding(x);
    const width = (!this.hasSecondaryData ? x.bandwidth() : x.bandwidth() / 2) - (padding / (this.data.isComparison ? 1.5 : 1));
    const xAdjustment = (ChartDataType.secondary === this.data.type ? x.bandwidth() / 2 : 0)  + (padding / (this.data.isComparison ? 1.5 : 1)) / 2;

    const zeroY = y(0);
    // primary and not comparison case
    const handlers: ColumnBarChartAttrHandler = {
      x: (d: ChartData): number => x(d.label) + xAdjustment,
      y: (d: ChartData): number => Math.min(y(d.value), zeroY),
      height: (d: ChartData): number => Math.abs(zeroY - y(d.value)),
      width: (): number => width,
      class: (d: ChartData): string => [this.className || '', d.color || ''].join(' ')
    };

    if (ChartDataType.primary === this.data.type && this.data.isPlan ) {
      handlers.height = () => 2;
      handlers.width = () => x.bandwidth();
      handlers.y = (d: ChartData) => y(d.value) - 1;
      handlers.x = (d: ChartData) => x(d.label);
      if (this.hasSecondaryData && !this.secondaryAsLine) {
        handlers.width = () => x.bandwidth() / 2;
      }
    } else if (ChartDataType.secondary === this.data.type && this.data.isPlan) {
      handlers.height = () => 2;
      handlers.y = (d: ChartData) => y(d.value) - 1;
      handlers.width = () => x.bandwidth() / 2;
      handlers.x = (d: ChartData) => x(d.label) + x.bandwidth() / 2;
    }

    super.renderInternal(this.data, handlers, limit);
  }

  private calcPadding(x: _d3.ScaleBand<string>): number {
    const minWidth = 1;
    const calculatedPadding = ((!this.hasSecondaryData ? x.bandwidth() : x.bandwidth() / 2) - minWidth) * (this.data.isComparison ? 1.5 : 1);
    return Math.min(calculatedPadding, this.hasComparison && !this.data.isComparison ? 12 : 9);
  }

  public switchHighlight(index: number, highlight: boolean) {

  }

}

export class StackedColumnRenderer extends StackedColumnBarRenderer {

  constructor(gElement: d3.Selection<SVGGElement, {}, null, undefined>, filled?: boolean) {
    super(gElement, filled);
  }

  public render(arrays: Array<ChartDataArray>, x: _d3.ScaleBand<string>, y: _d3.ScaleLinear<number, number>, limit?: number) {
    const handlers: ColumnBarChartAttrHandler = {
      x: (d: ChartData | any) => x(<string><unknown>d.data.label) + this.PADDING / 2,
      y: (d: Array<number>) => Math.max(y(!isNaN(d[1]) ? d[1] : 0), 0),
      height: (d: Array<number>) => y(d[0]) - y(!isNaN(d[1]) ? d[1] : 0),
      width: () => x.bandwidth() - this.PADDING
    };

    super.renderInternal(arrays, handlers, limit);

  }

  public switchHighlight(index: number, highlight: boolean) {

  }
}

export class ParallelColumnRenderer extends ParallelColumnBarRenderer {

  constructor(
    gElement: d3.Selection<SVGGElement, {}, null, undefined>
    ) {
    super(gElement);
  }


  public render(arrays: Array<ChartDataArray>, x: _d3.ScaleBand<string>, y: _d3.ScaleLinear<number, number>) {

    const breakdownData = arrays.filter(dataArray => dataArray.type === ChartDataType.breakdown);
    const primaryData = arrays.find(dataArray => dataArray.type === ChartDataType.primary && !dataArray.isComparison);
    // build breakdownSeries, group by key
    const breakdownSeries = super.createBreakdownSeriesParallel(breakdownData);

    // scale for breakdown groups
    const xBreakdown: d3.ScaleBand<number> = d3.scaleBand<number>()
      .domain(d3.range(breakdownSeries.length))
      .range([5, x.bandwidth() - 5]);

    const zeroY = y(0);

    const handlers: ColumnBarChartAttrHandler = {
      x: (d, i) => x(primaryData.data[i].label),
      y: (d) => Math.min(y(d.value), zeroY),
      width: () => xBreakdown.bandwidth(),
      height: (d) => Math.abs(zeroY - y(d.value)),
      transform: (d, i) => `translate( ${xBreakdown(i)} ,0)`
    };

    super.renderInternal(breakdownSeries, handlers);
  }
}
