import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[mnbNotificationOwner]'
})
export class MnbNotificationOwnerDirective implements OnInit {

    @Input('mnbNotificationOwner') numberOfNotifications: number;
    @Input() notificationVerticalPosition: string;
    @Input() notificationHorizontalPosition: string;
    @Input() notificationSize: string;
    @Input() notificationColor: string;

    @HostBinding('class.mnb-notification-owner') cssClass = true;

    @HostBinding('attr.mnbNotification') get number(): number {
        return this.numberOfNotifications;
    }

    /****** POSITIONS ******/

    @HostBinding('class.notification-position-top') get isPositionedTop(): boolean {
        return this.notificationVerticalPosition === 'top';
    }

    @HostBinding('class.notification-position-left') get isPositionedLeft(): boolean {
        return this.notificationHorizontalPosition === 'left';
    }

    /****** SIZES ******/

    @HostBinding('class.notification-size-small') get isSmall(): boolean {
        return this.notificationSize === 'small';
    }

    @HostBinding('class.notification-size-large') get isLarge(): boolean {
        return this.notificationSize === 'large';
    }

    /****** COLORS ******/

    @HostBinding('class.primary-notification') get isBlue(): boolean {
        return this.notificationColor === 'blue';
    }

    @HostBinding('class.notification-color-purple') get isPurple(): boolean {
        return this.notificationColor === 'purple';
    }

    @HostBinding('class.notification-color-red') get isRed(): boolean {
        return this.notificationColor === 'red';
    }

    @HostBinding('class.notification-color-green') get isGreen(): boolean {
        return this.notificationColor === 'green';
    }

    ngOnInit(): void {}
}
