import { Injectable } from '@angular/core';
import { LineChartConfig, ChartData, BarChartConfig, DonutChartConfig, ChartValue, ChartConfig, ChartDataArray, ChartDataType, ChartDataComparisonType, GaugeChartConfig } from '@shared-lib/modules/charts/services/chart.models';
import { DashboardWidget, DashboardWidgetData, Dashboard } from '@shared-lib/modules/data/model/mnb-data-dashboard.model';
import { isNullOrUndefined } from 'util';
import { MnbChartService } from '@shared-lib/modules/charts/services/chart.service';
import { ChartRenderer } from '@shared-lib/modules/charts/renderers/chart-renderer';
import { DashboardGridWidgetModel } from '@minubo-suite/analytics-area/services/dashboard/dashboard-service.models';
import { TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { TranslateService } from '@ngx-translate/core';
import { MnbEmptyValuePipe } from '@shared-lib/modules/core/pipes/empty-value.pipe';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { DonutChartRenderer } from '@shared-lib/modules/charts/renderers/donut-chart-renderer';
import { MnbModelService, ModelAttribute } from '@shared-lib/modules/model/services/mnb-model.service';
import { MnbUnitInfo } from '@shared-lib/modules/core/pipes/unit.pipe';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { GaugeChartRenderer } from '@shared-lib/modules/charts/renderers/gauge-chart-renderer';
import { MnbColor } from '@shared-lib/modules/core/utils/mnb-color-util';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { MnbDateSpanPipe } from '@shared-lib/modules/core/pipes/date-span.pipe';


export enum WidgetVisualizationType {
    SingleValue = 'SingleValue',
    DonutChart = 'DonutChart',
    ColumnChart = 'ColumnChart',
    BarChart = 'BarChart',
    LineChart = 'LineChart',
    TimeSeriesChart = 'TimeSeriesChart',
    Table = 'Table',
    List = 'List',
    GaugeChart = 'GaugeChart',
    Text = 'Text'
}


@Injectable()
export class MnbDashboardChartService {

    constructor(
        private chartService: MnbChartService,
        private emptyPipe: MnbEmptyValuePipe,
        private timeFilterService: TimeFilterService,
        private timeDateSpanPipe: MnbDateSpanPipe,
        private translate: TranslateService,
        private modelService: MnbModelService
    ) { }

    public createRenderer(widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: DashboardGridWidgetModel): ChartRenderer {
        switch (widget.visualizationSettings.typeCode) {
            case WidgetVisualizationType.LineChart:
            case WidgetVisualizationType.TimeSeriesChart:
                return this.createLineChartRenderer(widget, dashboard, data, model);
            case WidgetVisualizationType.ColumnChart:
                return this.createColumnChartRenderer(widget, dashboard, data, model);
            case WidgetVisualizationType.BarChart:
                return this.createBarChartRenderer(widget, dashboard, data, model);
            case WidgetVisualizationType.DonutChart:
                return this.createDonutChartRenderer(widget, dashboard, data, model);
            case WidgetVisualizationType.GaugeChart:
                return this.createGaugeChartRenderer(widget, dashboard, data, model);
            case WidgetVisualizationType.SingleValue:
            case WidgetVisualizationType.Table:
            case WidgetVisualizationType.List:
                // no renderer needed
                return null;
            default:
                console.error('unknown WidgetVisualizationType ', widget.visualizationSettings.typeCode);
        }
    }

    private createGaugeChartRenderer(widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: DashboardGridWidgetModel): ChartRenderer {
        const config = new GaugeChartConfig();
        const displayMode = widget.visualizationSettings.valueDisplayMode;
        const hasPlan = !widget.querySettings.timeFilter && dashboard.settings.plan && dashboard.settings.plan.name || widget.querySettings.plan && widget.querySettings.plan.name;
        const isComparisonFromDashboard = dashboard.settings.comparisonFilter && !widget.querySettings.timeFilter;
        const isComparisonFromWidget = widget.querySettings.timeFilter && widget.querySettings.comparisonFilter;
        const hasComparisonFilter = isComparisonFromDashboard || isComparisonFromWidget;

        this.applyPositionDependentConfig(config, widget);

        const value = data.values[model.measure.code];
        let goal = value;

        const avaliableOptions = new Map<string, number>();

        if (hasComparisonFilter) {
            avaliableOptions.set('comparisonValue', data.comparison['comparisonPeriod'].values[model.measure.code]);
        }

        if (hasPlan && model.measure.planMeasure) {
            avaliableOptions.set('planValue', data.values[model.measure.planMeasure.code]);
        }

        if (avaliableOptions.size !== 0) {
            if (avaliableOptions.has(displayMode)) {
                goal = avaliableOptions.get(displayMode);
            } else {
                const key: string = avaliableOptions.keys().next().value;
                goal = avaliableOptions.get(key);
            }
        }

        config.data.push(new ChartDataArray(ChartDataType.primary, '', [new ChartData(model.measure.name, value)]));

        Array.from(avaliableOptions.keys()).forEach(key => {
            const optionValue = avaliableOptions.get(key);
            const type = key === 'comparisonValue' ? ChartDataComparisonType.comparison : ChartDataComparisonType.plan;
            config.data.push(new ChartDataArray(ChartDataType.primary, '', [new ChartData('', optionValue)], type));
        });

        config.goalValue = goal;
        config.value = this.createConfigValue(dashboard, widget, model);

        return this.chartService.createGaugeChartRenderer(config);
    }

    public updateRendererConfig(renderer: ChartRenderer, widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: any) {
        let config: ChartConfig;

        if (renderer instanceof DonutChartRenderer) {
            config = new DonutChartConfig();
        } else if (renderer instanceof GaugeChartRenderer) {
            config = new GaugeChartConfig();
        } else {
            config = new ChartConfig();
        }

        this.applyPositionDependentConfig(config, widget);
        renderer.updateConfig(config);
    }

    private createLineChartRenderer(widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: DashboardGridWidgetModel): ChartRenderer {

        const { breakdownAttributeCode, additionalMeasureCode } = widget.querySettings;

        const config: LineChartConfig = new LineChartConfig();
        this.applyPositionDependentConfig(config, widget);
        config.seriesLabels = new Array<string>();
        config.value = this.createConfigValue(dashboard, widget, model);
        config.showChange = false;
        config.rotateXLabels = true;
        config.additionalAxis = widget.visualizationSettings.additionalAxis;
        config.breakdownMode = widget.visualizationSettings.breakdownMode;

        const isComparisonFromDashboard = dashboard.settings.comparisonFilter && !widget.querySettings.timeFilter;
        const isComparisonFromWidget = widget.querySettings.timeFilter && widget.querySettings.comparisonFilter;
        const hasComparisonFilter = isComparisonFromDashboard || isComparisonFromWidget;
        const hasBreakdownAttribute = !isNullOrUndefined(breakdownAttributeCode);
        const hasAdditionalMeasure = !isNullOrUndefined(additionalMeasureCode);

        const hasPlan = !widget.querySettings.timeFilter && dashboard.settings.plan && dashboard.settings.plan.name || widget.querySettings.plan && widget.querySettings.plan.name;
        const timeFilter = widget.querySettings.timeFilter ? widget.querySettings.timeFilter : dashboard.settings.timeFilter;
        const dateSpan = this.timeFilterService.getTimePeriod(timeFilter);

        config.data = [this.createDataArray(ChartDataType.primary, widget, data, model.measure, dateSpan)];
        config.data[0].containsCurrency = ModelAttribute.isCurrency(model.attribute.code);

        if (hasBreakdownAttribute) {
            this.createBreakdownDataArrays(widget, data, model).forEach(array => config.data.push(array));
        } else {

            if (hasComparisonFilter) {
                const comparisonFilter = isComparisonFromWidget ? widget.querySettings.comparisonFilter : dashboard.settings.comparisonFilter;
                const comparisonDateSpan = this.timeFilterService.getComparisonPeriod(timeFilter, <TimeComparisonFilter>comparisonFilter);
                config.data.push(this.createComparisonArray(ChartDataType.primary, widget, data, model.measure, comparisonDateSpan));
            }

            if (hasAdditionalMeasure) {
                config.additionalValue = this.createConfigValue(dashboard, widget, model, true);
                config.data.push(this.createDataArray(ChartDataType.secondary, widget, data, model.additionalMeasure, dateSpan));

                if (hasComparisonFilter) {
                    const comparisonFilter = isComparisonFromWidget ? widget.querySettings.comparisonFilter : dashboard.settings.comparisonFilter;
                    const comparisonDateSpan = this.timeFilterService.getComparisonPeriod(timeFilter, <TimeComparisonFilter>comparisonFilter);
                    config.data.push(this.createComparisonArray(ChartDataType.secondary, widget, data, model.additionalMeasure, comparisonDateSpan));
                }
            }
            if (hasPlan && model.measure.planMeasure) {
                config.data.push(this.createPlanSeries(widget, data, model.measure.planMeasure.code, ChartDataType.primary));
                config.seriesLabels.push(dashboard.settings.plan.name);
            }
            if (hasPlan && hasAdditionalMeasure && model.additionalMeasure.planMeasure) {
                config.data.push(this.createPlanSeries(widget, data, model.additionalMeasure.planMeasure.code, ChartDataType.secondary));
            }
        }

        return this.chartService.createLineChartRenderer(config);
    }

    private applyPositionDependentConfig(config: ChartConfig, widget: DashboardWidget) {
        config.xAxisConfig.hide = widget.visualizationSettings.position.width >= 2 && widget.visualizationSettings.position.height === 2;
        config.yAxisConfig.hide = widget.visualizationSettings.position.width === 2 && widget.visualizationSettings.position.height >= 2;
        config.legendConfig.hide = !(widget.visualizationSettings.position.height >= 3 && widget.visualizationSettings.position.width >= 3);

        if (config instanceof DonutChartConfig) {
            config.paddingForLabels = config.xAxisConfig.hide || config.yAxisConfig.hide ? 0 : 60;
        }

        if (config instanceof GaugeChartConfig) {
            config.hideRangeText = config.noWrapInfoText = widget.visualizationSettings.position.width <= 3;
            config.hideGoalText = widget.visualizationSettings.position.width === 2;
        }

        if (widget.visualizationSettings.typeCode === 'BarChart') {
            if (!config.xAxisConfig.hide && widget.visualizationSettings.position.height < 4 && widget.visualizationSettings.additionalAxis) {
                config.xAxisConfig.hide = true;
            }
        }

    }

    private createColumnChartRenderer(widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: DashboardGridWidgetModel): ChartRenderer {
        const config: BarChartConfig = this.createColumnBarChartConfig(widget, dashboard, data, model);
        return this.chartService.createColumnChartRenderer(config);
    }

    private createBarChartRenderer(widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: DashboardGridWidgetModel): ChartRenderer {
        const config: BarChartConfig = this.createColumnBarChartConfig(widget, dashboard, data, model);
        config.data.forEach(dataArray => dataArray.data.reverse());

        return this.chartService.createBarChartRenderer(config);
    }

    private createDataArray(type: ChartDataType, widget: DashboardWidget, data: DashboardWidgetData, measure: any, dateSpan: DateSpan): ChartDataArray {
        const label = widget.querySettings.additionalMeasureCode ? measure.name : this.timeDateSpanPipe.transform(<any>dateSpan, { shorten: true });
        return new ChartDataArray(type, label, data.breakdown[widget.querySettings.attributeCode].map(entry => {
            return new ChartData(
                entry.attributes[widget.querySettings.attributeCode],
                entry.values[measure.code]);
        }));
    }

    private createComparisonArray(type: ChartDataType, widget: DashboardWidget, data: DashboardWidgetData, measure: any, comparisonDateSpan: DateSpan): ChartDataArray {
        const label = widget.querySettings.additionalMeasureCode ? this.translate.instant('GENERAL.TIME.COMPARISON_PERIOD') : this.timeDateSpanPipe.transform(<any>comparisonDateSpan
            , { shorten: true });
        return new ChartDataArray(type, label, data.breakdown[widget.querySettings.attributeCode].map(entry => {
            return new ChartData(
                entry.attributes[widget.querySettings.attributeCode],
                entry.comparison.comparisonPeriod.values[measure.code]);
        }), ChartDataComparisonType.comparison);
    }

    private createBreakdownDataArrays(widget: DashboardWidget, data: DashboardWidgetData, model: DashboardGridWidgetModel): Array<ChartDataArray> {
        const { attributeCode, measureCode, breakdownAttributeCode } = widget.querySettings;

        const breakdownKeys = new Array<string>();
        const values = new Map<string, { key: string, total: number, data: Map<string, ChartData> }>();
        const attributeKeys = new Array<string>();

        const otherArray = new Array<ChartData>();

        data.breakdown[attributeCode].forEach((entry, index) => {
            const attributeKey = entry.attributes[attributeCode];
            attributeKeys.push(attributeKey);
            entry.breakdown[breakdownAttributeCode].forEach(breakdownEntry => {
                const breakdownKey = breakdownEntry.attributes[breakdownAttributeCode];
                let breakdownValues = values.get(breakdownKey);
                if (!breakdownValues) {
                    breakdownValues = { key: breakdownKey, total: 0, data: new Map<string, ChartData>() };
                    values.set(breakdownKey, breakdownValues);
                    breakdownKeys.push(breakdownKey);
                }
                const breakdownValue = breakdownEntry.values[measureCode];
                breakdownValues.data.set(attributeKey, new ChartData(attributeKey, breakdownValue));
                breakdownValues.total += breakdownValue;
            });

            const breakdownOther = entry.breakdownOther[breakdownAttributeCode].values[measureCode];

            otherArray.push(new ChartData(attributeKey, breakdownOther, null, MnbColor.GREY_LIGHTER_1.color));

        });

        const breakdowns: Array<{ key: string, total: number, array: Array<ChartData> }> = breakdownKeys.map(breakdownKey => {
            const breakdownValues = values.get(breakdownKey);
            return {
                key: breakdownKey,
                total: breakdownValues.total,
                array: attributeKeys.map(attributeKey => {
                    return breakdownValues.data.has(attributeKey) ? breakdownValues.data.get(attributeKey) : new ChartData(attributeKey, null);
                })
            };
        });

        breakdowns.sort((a, b) => b.total - a.total);

        const arrays = breakdowns.map((breakdown, idx) => {
            const dataArray = new ChartDataArray(ChartDataType.breakdown, breakdown.key, breakdown.array, null, this.modelService.getColor(model.breakdownAttribute, breakdown.key, idx).color);
            dataArray.isCurrencyBreakdown = ModelAttribute.isCurrency(model.breakdownAttribute.code);
            return dataArray;
        });

        if (otherArray.find(o => Math.round(o.value * 1000) !== 0)) {
            arrays.push(new ChartDataArray(ChartDataType.breakdown, this.translate.instant('GENERAL.LABEL.OTHER'), otherArray, null, MnbColor.GREY_LIGHTER_1.color));
        }

        return arrays;
    }

    private createColumnBarChartConfig(widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: DashboardGridWidgetModel): BarChartConfig {
        const { breakdownAttributeCode, additionalMeasureCode } = widget.querySettings;

        const config: BarChartConfig = new BarChartConfig();
        this.applyPositionDependentConfig(config, widget);
        config.seriesLabels = new Array<string>();
        config.value = this.createConfigValue(dashboard, widget, model);
        config.rotateXLabels = true;
        config.breakdownMode = widget.visualizationSettings.breakdownMode;
        config.additionalAxis = widget.visualizationSettings.additionalAxis;
        config.secondaryAsLine = widget.visualizationSettings.additionalMeasureAsLine;
        config.limitXAxis = !this.isTimeChart(model);

        const isComparisonFromDashboard = !isNullOrUndefined(dashboard.settings.comparisonFilter) && isNullOrUndefined(widget.querySettings.timeFilter);
        const isComparisonFromWidget = !isNullOrUndefined(widget.querySettings.timeFilter) && !isNullOrUndefined(widget.querySettings.comparisonFilter);
        const hasBreakdownAttribute = !isNullOrUndefined(breakdownAttributeCode);
        const hasAdditionalMeasure = !isNullOrUndefined(additionalMeasureCode);
        const hasComparison = (isComparisonFromDashboard || isComparisonFromWidget) && !hasBreakdownAttribute;
        const timeFilter = widget.querySettings.timeFilter ? widget.querySettings.timeFilter : dashboard.settings.timeFilter;
        const hasPlan = !widget.querySettings.timeFilter && dashboard.settings.plan && dashboard.settings.plan.name || widget.querySettings.plan && widget.querySettings.plan.name;
        const dateSpan = this.timeFilterService.getTimePeriod(timeFilter);

        config.data = [this.createDataArray(ChartDataType.primary, widget, data, model.measure, dateSpan)];
        config.data[0].containsCurrency = ModelAttribute.isCurrency(model.attribute.code);

        if (hasBreakdownAttribute) {
            const breakdownData = this.createBreakdownDataArrays(widget, data, model);
            breakdownData.forEach(array => config.data.push(array));
        }

        if (hasComparison) {
            const comparisonFilter = isComparisonFromWidget ? widget.querySettings.comparisonFilter : dashboard.settings.comparisonFilter;
            const comparisonDateSpan = this.timeFilterService.getComparisonPeriod(timeFilter, <TimeComparisonFilter>comparisonFilter);
            config.data.push(this.createComparisonArray(ChartDataType.primary, widget, data, model.measure, comparisonDateSpan));

        }

        if (hasAdditionalMeasure) {
            config.additionalValue = this.createConfigValue(dashboard, widget, model, true);
            config.data.push(this.createDataArray(ChartDataType.secondary, widget, data, model.additionalMeasure, dateSpan));
            if (hasComparison) {
                const comparisonFilter = isComparisonFromWidget ? widget.querySettings.comparisonFilter : dashboard.settings.comparisonFilter;
                const comparisonDateSpan = this.timeFilterService.getComparisonPeriod(timeFilter, <TimeComparisonFilter>comparisonFilter);
                config.data.push(this.createComparisonArray(ChartDataType.secondary, widget, data, model.additionalMeasure, comparisonDateSpan));
            }
        }

        if (hasPlan && model.measure.planMeasure) {
            config.data.push(this.createPlanSeries(widget, data, model.measure.planMeasure.code, ChartDataType.primary));
            config.seriesLabels.push(dashboard.settings.plan.name);
        }
        if (hasPlan && hasAdditionalMeasure && model.additionalMeasure.planMeasure) {
            config.data.push(this.createPlanSeries(widget, data, model.additionalMeasure.planMeasure.code, ChartDataType.secondary));
        }

        return config;
    }

    private createPlanSeries(widget: DashboardWidget, data: DashboardWidgetData, measureCode: string, chartDataType: ChartDataType): ChartDataArray {
        return new ChartDataArray(chartDataType, 'Plan', data.breakdown[widget.querySettings.attributeCode].map(entry => {
            return new ChartData(
                entry.attributes[widget.querySettings.attributeCode],
                entry.values[measureCode] ? entry.values[measureCode] : 0.0); // TODO
        }), ChartDataComparisonType.plan);
    }

    private createDonutChartRenderer(widget: DashboardWidget, dashboard: Dashboard, data: DashboardWidgetData, model: DashboardGridWidgetModel): ChartRenderer {
        const config: DonutChartConfig = new DonutChartConfig();
        config.value = this.createConfigValue(dashboard, widget, model);
        this.applyPositionDependentConfig(config, widget);
        const breakdowns = data.breakdown[widget.querySettings.attributeCode];
        const total: number = data.values[widget.querySettings.measureCode];

        config.data = [new ChartDataArray(ChartDataType.primary, null, [])];
        config.data[0].containsCurrency = ModelAttribute.isCurrency(model.attribute.code);

        const slices = breakdowns.map((breakdownEntry, idx) => {
            const value = this.emptyPipe.transform(breakdownEntry.attributes[widget.querySettings.attributeCode]);
            const attributeColor = this.modelService.getColor(model.attribute, value, idx);
            return new ChartData(
                value,
                breakdownEntry.values[widget.querySettings.measureCode], null, attributeColor ? attributeColor.color : null);
        });

        config.data[0].data = slices;

        const othersValue = data.breakdownOther[widget.querySettings.attributeCode].values[widget.querySettings.measureCode];

        if (othersValue) {
            // add a slice with value = total overall - total of entries as "others".
            const otherSlice = new ChartData(this.translate.instant('GENERAL.LABEL.OTHER'), othersValue, null, MnbColor.GREY_LIGHTER_1.color);
            config.data[0].data.push(otherSlice);
        }

        config.total = total;

        if (data.comparison) {
            const comparisonTotal: number = data.comparison.comparisonPeriod.values[widget.querySettings.measureCode];
            const comparisonSlices = breakdowns.map(breakdownEntry => {
                return new ChartData(null, breakdownEntry.comparison.comparisonPeriod.values[widget.querySettings.measureCode]);
            });
            config.comparisonTotal = comparisonTotal;
            config.data.push(new ChartDataArray(ChartDataType.primary, null, comparisonSlices, ChartDataComparisonType.comparison));

            if (othersValue) {
                const otherComparisonSlice = new ChartData(null, data.breakdownOther[widget.querySettings.attributeCode].comparison.comparisonPeriod.values[widget.querySettings.measureCode]);
                config.data[1].data.push(otherComparisonSlice);
            }
        }

        return this.chartService.createDonutChartRenderer(config);
    }

    private createConfigValue(dashboard: Dashboard, widget: DashboardWidget, model: DashboardGridWidgetModel, additionalValue?: boolean): ChartValue {
        const chartValue: ChartValue = new ChartValue();

        const measure = additionalValue ? model.additionalMeasure : model.measure;
        chartValue.label = measure.name;

        const isOriginalCurrency = measure.modifiers && measure.modifiers.isOriginalCurrency;

        if (isOriginalCurrency) {
            const dashboardCurrencyCode = QueryFilter.getCurrencyCode(dashboard.settings.filters);
            const widgetCurrencyCode = QueryFilter.getCurrencyCode(widget.querySettings.filters) || dashboardCurrencyCode;

            chartValue.unit = new MnbUnitInfo(measure.unit.code, true, widgetCurrencyCode);
        } else {
            chartValue.unit = measure.unit.code;
        }

        return chartValue;
    }

    private isTimeChart(model: DashboardGridWidgetModel): boolean {
        // TODO: This needs to be done somewhere else and safer!
        return model.attribute.context.code === 'time';
    }
}
