
import { Pipe, PipeTransform } from '@angular/core';
import { MnbModelService } from '@shared-lib/modules/model/services/mnb-model.service';

/*
 * Format the value by using the unit type code.

 * Usage:
 *   value | mnbModelName:'MEASURE|ATTRIBUTE'
 * Example:
 *   {{ 'omsOrdVal' | mnbModelName:'MEASURE' }}
 *   formats to: Order Value
*/
@Pipe({
    name: 'mnbModelName',
    pure: true
})
export class MnbModelNamePipe implements PipeTransform {

    constructor(private modelService: MnbModelService) {}

    transform(value: string, typeCode: string, shortname?: boolean): Promise<string> {
        if (!value) {
            return Promise.resolve('-');
        }
        if (typeCode === 'ATTRIBUTE') {
            return this.modelService.getAttribute(value).then(attribute => shortname ? attribute.shortName : attribute.name);
        } else {
            return this.modelService.getMeasure(value).then(measure => measure.name);
        }
    }
}
