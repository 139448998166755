import { InjectionToken } from '@angular/core';

export const LEGACY_MNB_MODEL_SERVICE = new InjectionToken<any>('LEGACY_MNB_MODEL_SERVICE');

export const legacyMnbModelServiceProvider = {
  provide: LEGACY_MNB_MODEL_SERVICE,
  useFactory: (i: any) => {
    if (!i.has('mnbModelService')) {
      return null;
    }
    return i.get('mnbModelService');
  },
  deps: ['$injector']
};
