import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mnbComparisonAbs' })
export class MnbComparisonAbsolutePipe implements PipeTransform {

    constructor() {
    }

    transform(value: number, comparisonValue: number): number {
        return value - comparisonValue;
    }
}
