import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AppHeader } from '@minubo-portal/models/app-header.model';
import { ApiDataProviderService } from '@minubo-portal/modules/api/services/api-data-provider.service';
import { ApiTrackingService } from '@minubo-portal/modules/api/services/api-tracking.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalSettingsStore } from '@minubo-portal/utils/local-settings-store.util';
import { PortalDataProvider } from '@minubo-portal/modules/api/models/api-data-provider.model';
import { DataProviderSettings } from '@shared-lib/modules/data/model/mnb-data-data-provider.model';


@Component({
    templateUrl: './data-provider-page.component.html'
})
export class DataProviderPageComponent implements OnInit, OnDestroy {

    constructor(
        private route: ActivatedRoute
    ) { }

    public context$: Observable<DataProviderPageContext>;

    private destroy$ = new Subject<void>();

    ngOnInit(): void {

        this.context$ = this.route.data.pipe(
            map((data: {context: DataProviderPageContext}) => data.context)
        );

    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

@Injectable()
export class DataProviderPageContextResolver implements Resolve<DataProviderPageContext> {

    private settingsStore = new LocalSettingsStore<DataProviderSettings>('data-provider');

    constructor(
        private apiDataProviderService: ApiDataProviderService,
        private trackingService: ApiTrackingService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<DataProviderPageContext> {
        const dataProviderId = route.params['dataProviderId'];

        const localSettings = this.settingsStore.load(dataProviderId);

        const result = this.apiDataProviderService.loadDataProvider(dataProviderId).pipe(
            map(dataProvider => {
                (<AppHeader>route.data.header).title = dataProvider.title;
                return {
                    dataProvider: dataProvider,
                    localSettings: localSettings
                };
            }),
        );
        result.subscribe(dataProvider => {
            const parameters = {
                'dataProviderId': dataProvider.dataProvider.id,
                'dataProviderTitle': dataProvider.dataProvider.title,
            };
            this.trackingService.tracking('dataProvider', 'view', parameters);
        });
        return result;
    }
}

export type DataProviderPageContext = {

    dataProvider: PortalDataProvider;
    localSettings: DataProviderSettings | null;

};
