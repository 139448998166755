import { Pipe, PipeTransform } from '@angular/core';
import { MnbComparisonUtil } from '@shared-lib/modules/core/utils/mnb-comparison.util';
import { MnbUnitInfo, MnbUnitPipe } from './unit.pipe';

@Pipe({ name: 'mnbComparisonDisplay' })
export class MnbComparisonDisplayPipe implements PipeTransform {

    constructor(
        private unitPipe: MnbUnitPipe
    ) {
    }

    transform(value: number, comparisonValue: number, unit: string | MnbUnitInfo): string {
        const unitInfo: MnbUnitInfo = unit && unit['code'] ? <MnbUnitInfo>unit : null;
        const unitCode = unitInfo ? unitInfo.code : <string>unit;

        if (unitCode === 'PERCENT') {
            return this.unitPipe.transform(MnbComparisonUtil.calcValue(value, comparisonValue), unitCode, {isDiff: true, hasSign: true});
        } else {
            return this.unitPipe.transform(MnbComparisonUtil.calcPercentage(value, comparisonValue), 'PERCENT', {hasSign: true});
        }
    }
}
