import { isNullOrUndefined } from 'util';
import { TimeComparisonOptionCode } from '@shared-lib/modules/core/services/time/time.model';
import { ModelAttribute } from '@shared-lib/modules/model/services/mnb-model.service';
import { EntityPreset } from '@shared-lib/modules/data/model/mnb-data-entity-schedule.model';

export class Query {

    id: number;
    fkLogin: number;
    title: string;
    description: string;
    isPrivate: boolean;
    isReadOnly: boolean;
    folder: string;
    typeCode: QueryType;

    settings: QuerySettings;

    lastViewDateTime: Date | number;
    lastViewMail: string;
    lastEditDateTime: Date | number;
    lastEditMail: string;

}

export class QuerySettings {
    attributes: Array<QueryAttribute>;
    measures: Array<QueryMeasure>;
    timeFilter: QuerySettingsTimeFilter;
    filters: QueryFilter[];
    comparisonFilter?: QuerySettingsComparisonFilter;
    plan?: QuerySettingsPlan;
    sort?: QuerySettingsSort;
}

export enum QueryType {
    RAW = 'raw',
    TABLE = 'table',
    TIME_SERIES = 'timeSeries',
    PIVOT = 'pivot'
}

export class QueryMeasure {
    constructor(public code: string, public settings?: QueryMeasureSettings) { }
}

export class QueryMeasureSettings {
    constructor(
      public showValue: boolean,
      public showPlanValue: boolean,
      public showPlanDeviation: boolean,
      public showComparisonValue: boolean,
      public showComparisonChange: boolean,
      public showPercentageOfTotal: boolean,
      public showDifferenceToTotal: boolean,
      public showPercentageDifferenceToTotal: boolean) { }

}

export class QuerySettingsSort {
  constructor(public directionCode: string, public attributeCode?: string, public measureCode?: string) {}
}

export class QueryAttribute {
    constructor(public code: string, public isColumns?: boolean, public sort?: QuerySettingsSort) { }

    public static isSameAttribute(a: QueryAttribute, b: QueryAttribute): boolean {
        if (!a) {
            return !b;
        } else if (!b) {
            return false;
        } else {
            return a.code === b.code;
        }
    }
}

export class QueryFilter {
    constructor(public attributeCode: string, public typeCode: string, public values: Array<string>) {}

    public static getCurrencyCode(filters: QueryFilter[]): string {
        if (!filters) {
            return null;
        }
        let value = null;
        filters.forEach(filter => {
            if (ModelAttribute.isCurrency(filter.attributeCode) && filter.typeCode === 'equals' && filter.values && filter.values.length === 1) {
                value = filter.values[0];
            }
        });
        return value;
    }

    public static getValueForAttributeFromFilters(attributeCode: string, filters: QueryFilter[]): string {
        if (!filters) {
            return null;
        }
        let value = null;
        filters.forEach(filter => {
            if (filter.typeCode === 'equals' && filter.values && filter.values.length === 1 && filter.attributeCode === attributeCode) {
                value = filter.values[0];
            }
        });
        return value;
    }

    public static hasValueForAttributeInFilters(attributeCode: string, filters: QueryFilter[]): boolean {
        return filters && !!filters.find(filter => filter.typeCode === 'equals' && filter.values && filter.values.length === 1 && filter.attributeCode === attributeCode);
    }

}

export class QuerySettingsPlan {

    name: string;

    constructor(name?: string) {
        this.name = name;
    }

    public static equals(a: QuerySettingsPlan, b: QuerySettingsPlan): boolean {

        if (isNullOrUndefined(a)) {
            a = new QuerySettingsPlan();
        }

        if (isNullOrUndefined(b)) {
            b = new QuerySettingsPlan();
        }

        return a.name === b.name;
    }
}

class Time {
    public static equalDates(a: Date | number, b: Date | number): boolean {
        if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
            return isNullOrUndefined(a) === isNullOrUndefined(b);
        }

        return new Date(a).getTime() === new Date(b).getTime();
    }
}

export class QuerySettingsTimeFilter {

    public static getDefault(): QuerySettingsTimeFilter {
        return new QuerySettingsTimeFilter('yesterday', new QuerySettingsTimeFilterFromTo(-1, TimeTypeCode.Days), new QuerySettingsTimeFilterFromTo(-1, TimeTypeCode.Days));
    }

    public static getLast12Months(): QuerySettingsTimeFilter {
        return new QuerySettingsTimeFilter('last12Months', new QuerySettingsTimeFilterFromTo(-12, TimeTypeCode.Months), new QuerySettingsTimeFilterFromTo(-1, TimeTypeCode.Months));
    }

    public static getLast365Days(): QuerySettingsTimeFilter {
        return new QuerySettingsTimeFilter('last365Days', new QuerySettingsTimeFilterFromTo(-365, TimeTypeCode.Days), new QuerySettingsTimeFilterFromTo(-1, TimeTypeCode.Days));
    }

    constructor(public optionCode: string | QuerySettingsTimeFilterOptionCode, public from: QuerySettingsTimeFilterFromTo, public to: QuerySettingsTimeFilterFromTo) {

    }

    // somehow is not working as non static function
    public static equals(tf1: QuerySettingsTimeFilter, tf2: QuerySettingsTimeFilter): boolean {

        // both undefined or null
        if (!tf1 && !tf2) {
            return true;
        }

        // one undefined or null the other not
        if ((!tf1 && tf2) || (tf1 && !tf2)) {
            return false;
        }

        if (tf1.optionCode !== tf2.optionCode) {
            return false;
        }

        // test from
        if (tf1.from.typeCode !== tf2.from.typeCode) {
            return false;
        }
        if (tf1.from.index !== tf2.from.index) {
            return false;
        }

        if (!Time.equalDates(<any>tf1.from.date, <any>tf2.from.date)) {
            return false;
        }
        if (tf1.from.relativeToYesterday !== tf2.from.relativeToYesterday) {
            return false;
        }

        // test to
        if (tf1.to.typeCode !== tf2.to.typeCode) {
            return false;
        }
        if (tf1.to.index !== tf2.to.index) {
            return false;
        }
        if (!Time.equalDates(<any>tf1.to.date, <any>tf2.to.date)) {
            return false;
        }
        if (tf1.to.relativeToYesterday !== tf2.to.relativeToYesterday) {
            return false;
        }

        return true;
    }

    public static convertToString(settings: QuerySettingsTimeFilter): string {
        return (settings.optionCode || 'custom') + '(' + QuerySettingsTimeFilter.convertPartToString(settings.from) + ')(' + QuerySettingsTimeFilter.convertPartToString(settings.to) + ')';
    }

    private static convertPartToString(part: QuerySettingsTimeFilterFromTo): string {
        if (part.date) {
            return new Date(part.date).toISOString().substring(0, 10);
        }
        return part.typeCode + ':' + (part.relativeToYesterday ? 'Y' : 'T') + (part.index >= 0 ? '+' : '') + part.index;
    }

    public static parseString(value: string): QuerySettingsTimeFilter {
        const optionCodeEnd = value.indexOf('(');
        const optionCode = value.substring(0, optionCodeEnd);

        const fromPartEnd = value.indexOf(')', optionCodeEnd + 1);

        return new QuerySettingsTimeFilter(optionCode,
            QuerySettingsTimeFilter.parsePartString(value.substring(optionCodeEnd + 1, fromPartEnd)),
            QuerySettingsTimeFilter.parsePartString(value.substring(fromPartEnd + 2, value.length - 1)));
    }

    private static parsePartString(value: string): QuerySettingsTimeFilterFromTo {
        const typeCodeEnd = value.indexOf(':');

        if (typeCodeEnd < 0) {
            return new QuerySettingsTimeFilterFromTo(null, null, false, new Date(value));
        }

        const typeCode = value.substring(0, typeCodeEnd);

        const relativeToYesterday = value.charAt(typeCodeEnd + 1) === 'Y';

        const index = parseInt(value.substring(typeCodeEnd + 2));

        return new QuerySettingsTimeFilterFromTo(index, typeCode, relativeToYesterday);
    }
}

export class QuerySettingsTimeFilterFromTo {
    constructor(public index?: number, public typeCode?: string | TimeTypeCode, public relativeToYesterday?: boolean, public date?: Date | number) {
    }
}

export class QuerySettingsComparisonFilter extends Time {

    public optionCode?: TimeComparisonOptionCode;

    public index?: number;

    public typeCode?: TimeTypeCode | string;

    public fromDate?: Date | number;

    public toDate?: Date | number;

    public static equals(a: QuerySettingsComparisonFilter, b: QuerySettingsComparisonFilter): boolean {
        if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
            return isNullOrUndefined(a) === isNullOrUndefined(b);
        }
        return a.index === b.index &&
            a.typeCode === b.typeCode &&
            this.equalDates(a.fromDate, b.fromDate) &&
            this.equalDates(a.toDate, b.toDate);
    }

    public static convertToString(settings: QuerySettingsComparisonFilter): string {
        if (!settings) {
            return null;
        }

        let calc: string;
        if (settings.fromDate) {
            calc = new Date(settings.fromDate).toISOString().substring(0, 10) + '-' + new Date(settings.toDate).toISOString().substring(0, 10);
        } else {
            calc = settings.typeCode + ':' + settings.index;
        }
        return (settings.optionCode || 'custom') + '(' + calc + ')';
    }

    public static parseString(value: string): QuerySettingsComparisonFilter {
        if (!value) {
            return null;
        }
        const optionCodeEnd = value.indexOf('(');
        const optionCode = value.substring(0, optionCodeEnd);

        const typeCodeEnd = value.indexOf(':', optionCodeEnd + 1);

        const filter = new QuerySettingsComparisonFilter();
        filter.optionCode = optionCode as TimeComparisonOptionCode;

        if (typeCodeEnd < 0) {
            filter.fromDate = new Date(value.substring(optionCodeEnd + 1, optionCodeEnd + 11));
            filter.toDate = new Date(value.substring(optionCodeEnd + 12, optionCodeEnd + 22));
        } else {
            filter.typeCode = value.substring(optionCodeEnd + 1, typeCodeEnd);
            filter.index = parseInt(value.substring(typeCodeEnd + 1, value.length - 1));
        }
        return filter;
    }

}

/**************** RAW QUERY DATA ****************/

export interface QueryDataRawRow {
    attributes: {[attributeCode: string]: string};
    values: {[measureCode: string]: number};
    period: string;
}

export interface QueryDataRaw {
    rows: QueryDataRawRow[];
}

/**************** PIVOT QUERY DATA ****************/
export interface QueryDataPivotValues {
    values: {[measureCode: string]: number};
    compareValues: {[measureCode: string]: number};
}

export interface QueryDataPivotGroup extends QueryDataPivotValues {
    attribute: string;
    attributeCode: string;
    attributeDateRange: QueryAttributeDateRange;
    children?: QueryDataPivotGroup[];
}

export interface QueryDataPivotCell extends QueryDataPivotValues {
    attributes: {[attributeCode: string]: string};
    attributeDateRange: QueryAttributeDateRange;
}

export interface QueryDataPivot extends QueryDataPivotValues {
    measures: { measureCode: string }[];
    columns: QueryDataPivotGroup[];
    rows: QueryDataPivotGroup[];
    cells: QueryDataPivotCell[];
}

/**************** END ****************/

export interface QueryData {
    pivot?: QueryDataPivot;
    timeSeries?: any;
    raw?: QueryRaw;
}

export interface QueryRaw {
    rows?: QueryRow[];
    hasMore: boolean;
}

export interface QueryAttributeDateRange {
    [attributeCode: string]: {
        fromDate: number;
        toDate: number;
    };
}

export interface QueryRow {
    attributeDateRange: QueryAttributeDateRange;
    period: string;
    values: {[attributeCode: string]: number};
    attributes: {[attributeCode: string]: string};
}

export enum TimeTypeCode {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
    Quarters = 'quarters',
    Years = 'years',
    FiscalYears = 'fiscalYears',
    Custom = 'custom'
}

export enum QuerySettingsTimeFilterOptionCode {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    LAST_7_DAYS = 'last7Days',
    LAST_30_DAYS = 'last30Days',
    LAST_365_DAYS = 'last365Days',
    THIS_WEEK_TO_DATE = 'thisWeekToDate',
    LAST_WEEK = 'lastWeek',
    LAST_52_WEEKS = 'last52Weeks',
    THIS_MONTH_TO_DATE = 'thisMonthToDate',
    LAST_MONTH = 'lastMonth',
    LAST_12_MONTHS = 'last12Months',
    LAST_QUARTER = 'lastQuarter',
    LAST_3_QUARTERS = 'last3Quarters',
    THIS_QUARTER_TO_DATE = 'thisQuarterToDate',
    THIS_YEAR_TO_DATE = 'thisYearToDate',
    LAST_YEAR = 'lastYear',
    THIS_FISCAL_YEAR_TO_DATE = 'thisFiscalYearToDate',
    LAST_FISCAL_YEAR = 'lastFiscalYear',
    CUSTOM = 'custom',
}

export type QueryPreset = EntityPreset<{ query: Query }>;

// TODO MNB-12568: Might have to adapt or move these types
export type EntityType = 'dashboard' | 'query';
export type EntityStateInformation = {
    reminderList: ReminderNote[];
    id: number;
    entityId: number;
    entityType: EntityType;
};
export type ReminderNote = {
    id: number;
    entityStateId: number
    validOnDateTime: Date;
};
