
export const filterTypeOptions: QueryFilterTypeOption[] = [
    { code: 'equals', label: 'GENERAL.OPTION.FILTER_OPERATORS.EQUALS', isEqualsFilterType: true },
    { code: 'notEqual', label: 'GENERAL.OPTION.FILTER_OPERATORS.DOES_NOT_EQUAL', isEqualsFilterType: true },
    { code: 'equalsAll', label: 'GENERAL.OPTION.FILTER_OPERATORS.EQUALS_ALL', isForMultiValueField: true, isEqualsFilterType: true },
    { code: 'notEqualAll', label: 'GENERAL.OPTION.FILTER_OPERATORS.DOES_NOT_EQUAL_ALL', isForMultiValueField: true, isEqualsFilterType: true },
    { code: 'contains', label: 'GENERAL.OPTION.FILTER_OPERATORS.CONTAINS' },
    { code: 'doesNotContain', label: 'GENERAL.OPTION.FILTER_OPERATORS.DOES_NOT_CONTAIN' },
    { code: 'beginsWith', label: 'GENERAL.OPTION.FILTER_OPERATORS.BEGINS_WITH' },
    { code: 'notBeginsWith', label: 'GENERAL.OPTION.FILTER_OPERATORS.BEGINS_NOT_WITH' },
    { code: 'endsWith', label: 'GENERAL.OPTION.FILTER_OPERATORS.ENDS_WITH' },
    { code: 'notEndsWith', label: 'GENERAL.OPTION.FILTER_OPERATORS.ENDS_NOT_WITH' }
];

export class QueryFilterTypeOption {
    code: string;
    label: string;
    isForMultiValueField ? = false;
    isEqualsFilterType?: boolean;
}

