import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { QuerySettingsTimeFilter, QuerySettingsPlan, QuerySettingsComparisonFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { TimeComparisonFilter, TimeFilter } from '@shared-lib/modules/core/services/time/time.model';

@Component({
    template: `<p class="tile-infotext">{{ time }}</p>
    <p *ngIf="hasComparison" class="tile-infotext">vs. {{ comparison }}</p>
    <p *ngFor="let comparisonText of comparisons" class="tile-infotext">vs. {{ comparisonText }}</p>
    <p *ngIf="hasPlan" class="tile-infotext">vs. {{ plan }}</p>`,
    selector: 'mnb-query-time-infotext'
})

export class MnbQueryTimeInfotextComponent implements OnInit, OnChanges {

    @Input() inheritedSettings: QueryTimeInfoTextInheritedSettings = {};
    @Input() querySettings: QueryTimeInfoTextQuerySettings = {};
    @Input() customLabel: string;

    @Input()
    set referenceFromDate(value: Date) {
        if (value) {
            this._referenceFromDate = new Date(value);
        } else {
            this._referenceFromDate = null;
        }
    }

    get referenceFromDate(): Date {
        return this._referenceFromDate;
    }

    public time: string;
    public comparison: string;
    public comparisons: Array<string> = [];
    public plan: string;

    public hasComparison: boolean;
    public hasPlan: boolean;

    private _referenceFromDate: Date;

    constructor(
        private timeFilterService: TimeFilterService) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            const useInheritedTime = isNullOrUndefined(this.querySettings.timeFilter);

            const timeFilter = useInheritedTime ? this.inheritedSettings.timeFilter : this.querySettings.timeFilter;
            const comparisonFilter = useInheritedTime ? this.inheritedSettings.comparisonFilter : this.querySettings.comparisonFilter;

            this.loadLabelFor(timeFilter).then((text) => {
                this.time = text;
            });

            if (this.querySettings.comparisonSettings) {
                this.querySettings.comparisonSettings.forEach(async (setting) => {
                    await this.loadLabelFor(timeFilter, setting.comparisonFilter).then(text => this.comparisons.push(text));
                });
            } else {
                this.loadLabelFor(timeFilter, comparisonFilter).then((text) => {
                    this.comparison = text;
                });
            }

            const hasInheritedPlan = useInheritedTime && !isNullOrUndefined(this.inheritedSettings.plan) && !isNullOrUndefined(this.inheritedSettings.plan.name);
            const hasPlan = !isNullOrUndefined(this.querySettings.plan) && !isNullOrUndefined(this.querySettings.plan.name);

            this.plan = useInheritedTime && hasInheritedPlan ? this.inheritedSettings.plan.name : (!useInheritedTime && hasPlan ? this.querySettings.plan.name : null);

            this.hasComparison = !isNullOrUndefined(useInheritedTime ? this.inheritedSettings.comparisonFilter : this.querySettings.comparisonFilter);
            this.hasPlan = useInheritedTime ? hasInheritedPlan : hasPlan;
        }
    }

    private async loadLabelFor(timeFilter: QuerySettingsTimeFilter, comparisonFilter?: QuerySettingsComparisonFilter): Promise<string> {
        if (comparisonFilter) {
            return this.timeFilterService.getComparisonLabelWithDateSpan(<TimeComparisonFilter>comparisonFilter, <TimeFilter>timeFilter, null, this.referenceFromDate);
        } else {
            return this.timeFilterService.getTimeLabelWithDateSpan(<TimeFilter>timeFilter, this.customLabel, null, this.referenceFromDate);
        }
    }

}

export interface QueryTimeInfoTextInheritedSettings {
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
    plan?: QuerySettingsPlan;
}

export interface QueryTimeInfoTextQuerySettings extends QueryTimeInfoTextInheritedSettings {
    comparisonSettings?: { comparisonFilter: QuerySettingsComparisonFilter }[]
}
