import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    Report,
    ReportData,
    ReportRankComparisonDataRow,
    ReportSettings,
    ReportSettingsDrilldownStep,
    MeasureDisplayMode,
    ReportRankComparisonDrilldownStep,
    ReportRankComparison, DrilldownPath
} from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { BehaviorSubject } from 'rxjs';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { TimeComparisonFilter } from '@shared-lib/modules/core/services/time/time.model';
import { MnbModelService, ModelMeasure } from '@shared-lib/modules/model/services/mnb-model.service';
import { FormControl } from '@angular/forms';
import {
    buildEntityLink,
    DrilldownFilterContext,
    MnbReportDrilldownStepModel, MnbReportsEntityDrilldownDisplayDrilldownLinkModel,
} from '@shared-lib/modules/reports/components/entity-drilldown/entity-drilldown.model';
import { QueryFilter } from '@shared-lib/modules/data/model/mnb-data-query.model';

@Component({
    selector: 'mnb-reports-rank-comparison-display',
    templateUrl: './mnb-reports-rank-comparison-display.component.html'
})
export class MnbReportsRankComparisonDisplayComponent implements OnInit {

    MeasureDisplayMode = MeasureDisplayMode;

    constructor(
        private timeFilterService: TimeFilterService,
        private modelService: MnbModelService
    ) {
    }

    @Input() report: Report;
    @Input() viewSettings: ReportSettings;
    @Input() data: ReportData;
    @Input() showRankComparison: boolean;


    @Input() drilldownContext: DrilldownFilterContext[] = [];

    @Output() benchmarkSelectionChanged = new EventEmitter<string>();
    @Output() storeFilterSelectionChanged = new EventEmitter<string>();

    @Output() drilldown = new EventEmitter<ReportSettingsDrilldownStep>();
    @Output() drillup = new EventEmitter<MnbReportDrilldownStepModel>();

    public model$ = new BehaviorSubject<RankComparisonDisplayModel>(null);

    public benchmarkControl = new FormControl();
    public selectedStoreControl = new FormControl();

    protected readonly Object = Object;

    async ngOnInit(): Promise<void> {
        await this.loadModel(this.report, this.viewSettings, this.data);
    }

    private async loadModel(report: Report, viewSettings: ReportSettings, data: ReportData) {

        const measureDisplayModes: MeasureDisplayMode[] = report.settings.rankComparison.measures.map(measure => {
            if (measure.displayMode === MeasureDisplayMode.ABS) {
                return MeasureDisplayMode.ABS;
            }
            return MeasureDisplayMode.REL;
        });

        if (!data || !data.rankComparison) {
            this.model$.next({
                restricted: true
            });
            return;
        }
        const settings = viewSettings.rankComparison;

        const selectedBenchmark = !!settings.selectedBenchmark ?
            settings.selectedBenchmark
            : data.rankComparison.availableBenchmarks.length > 0 ?
                data.rankComparison.availableBenchmarks[0]
                : null;
        this.benchmarkControl.setValue(selectedBenchmark);

        const selectedStore = !!settings.selectedStore || settings.selectedStore === null ?
            settings.selectedStore
            : data.rankComparison.availableStores.length > 0 ?
                data.rankComparison.availableStores[0]
                : null;

        this.selectedStoreControl.setValue(selectedStore);

        const firstMeasureCode = report.settings.rankComparison.measures[0].code;

        const hasComparison = !!settings.comparisonFilter;

        const dataRows = data.rankComparison.rows.map(row => {
            // compare/benchmark column
            // this assumes that compare/benchmark always has a value for current time period. There might be edge cases in isSortedByCompareRanks=false that need to be investigated.
            let hasValueCurrently = true;
            let hasValueComparisonPeriod = !!row.compareChanges && this.hasValueCompare(row.compareChanges[firstMeasureCode]);
            const compareValueState = this.getValueState(row.compareRank, hasValueCurrently);
            const compareExtraState = this.getExtraState(row.compareRank, row.compareComparisonRank, hasValueCurrently, hasValueComparisonPeriod, hasComparison);
            const compareRankState = { valueState: compareValueState, extraState: compareExtraState };

            // main/rang column
            hasValueCurrently = !!row.values && this.hasValueMain(row.values[firstMeasureCode]);
            hasValueComparisonPeriod = !!row.comparisonValues && this.hasValueMain(row.comparisonValues[firstMeasureCode]);
            const mainValueState = this.getValueState(row.rank, hasValueCurrently);
            const mainExtraState = this.getExtraState(row.rank, row.comparisonRank, hasValueCurrently, hasValueComparisonPeriod, hasComparison);
            const mainRankState = { valueState: mainValueState, extraState: mainExtraState };

            const link: MnbReportsEntityDrilldownDisplayDrilldownLinkModel =  buildEntityLink(report.settings.rankComparison.drilldownPath, row);

            const rowModel: RankComparisonDisplayRowModel = {
                row: row,
                mainRankState: mainRankState,
                compareRankState: compareRankState,
                link: link,
            };
            return rowModel;
        });

        const codes = this.getCurrentAttributeCodes(report.settings.rankComparison, viewSettings.rankComparison.selectedDrilldownFilters);

        const model: RankComparisonDisplayModel = {
            rows: dataRows,
            measures: await Promise.all(report.settings.rankComparison.measures.map(measure => {
                return this.modelService.getMeasure(measure.code);
            })),
            measureDisplayModes,
            restricted: false,
            hasComparison,
            dateSpan: this.timeFilterService.getTimePeriod(settings.timeFilter),
            measureColumnCount: report.settings.rankComparison.measures.map(measure => !settings.comparisonFilter ? 1 : measure.displayMode === 'REL' ? 4 : 2),
            isBenchmarkComparison: report.settings.rankComparison.isBenchmarkComparison,
            isSortedByCompareRanks: report.settings.rankComparison.isSortedByCompareRanks,
            availableBenchmarks: data.rankComparison.availableBenchmarks,
            availableStores: data.rankComparison.availableStores,
            selectedBenchmark: selectedBenchmark,
            selectedStore: selectedStore,
            currentKeyAttributeCode: codes.keyAttributeCode,
            currentDisplayAttributeCodes: codes.displayAttributeCodes,
        };
        if (settings.comparisonFilter) {
            model.comparisonDateSpan = this.timeFilterService.getComparisonPeriod(
                settings.timeFilter, settings.comparisonFilter as TimeComparisonFilter
            );
        }
        this.model$.next(model);
    }


    private getCurrentAttributeCodes(reportSettings: ReportRankComparison, selectedDrilldownFilters: QueryFilter[]): { keyAttributeCode: string; displayAttributeCodes: string[] } {
        if (!reportSettings.drilldownPath || !selectedDrilldownFilters || selectedDrilldownFilters.length === 0) {
            return {
                keyAttributeCode: reportSettings.keyAttributeCode,
                displayAttributeCodes: reportSettings.displayAttributeCodes,
            };
        }
        let nextDrilldownIndex = -1;
        const latestDrilldownFilter = selectedDrilldownFilters[selectedDrilldownFilters.length - 1];

        for (let i = 0; i < reportSettings.drilldownPath.path.length; i++) {
            const element = reportSettings.drilldownPath.path[i];
            if (element.keyAttributes[0].code === latestDrilldownFilter.attributeCode) {
                nextDrilldownIndex = i + 1;
                break;
            }
        }
        return {
            keyAttributeCode: reportSettings.drilldownPath.path[nextDrilldownIndex].keyAttributes[0].code,
            displayAttributeCodes: reportSettings.drilldownPath.path[nextDrilldownIndex].nameAttributes.map(attr => attr.code),
        };
    }

    public onDrilldown(rowModel: RankComparisonDisplayRowModel, model: RankComparisonDisplayModel) {

        const linkModel = rowModel.link;
        const nameValues: { [code: string]: string } = {};
        const settings = this.viewSettings.rankComparison;
        if (!settings) {
            return;
        }
        const drilldownIndex = this.report.settings.rankComparison.drilldownPath.path.findIndex(e => {
           return e.elementName === linkModel.selectedElementName;
        });
        const filterData = {
            ...linkModel.keyValues,
        };
        const drilldownData = {
            filterData: filterData,
            nextDrilldownIndex: drilldownIndex + 1,
        };


        const step: ReportSettingsDrilldownStep = {
            keyValues: drilldownData.filterData,
            nameValues: {},
        };
        this.drilldown.emit(step);
    }

    onDrillup(stepModel: MnbReportDrilldownStepModel) {
        this.drillup.next(stepModel);
    }

    onBenchmarkSelectionChanged(benchmarkName: string) {
        if (this.model$.value != null && this.model$.value.selectedBenchmark !== benchmarkName) {
            this.benchmarkSelectionChanged.emit(benchmarkName);
        }
    }

    onStoreFilterSelectionChanged(storeFilterValue: string) {
        if (this.model$.value != null && this.model$.value.selectedStore !== storeFilterValue) {
            this.storeFilterSelectionChanged.emit(storeFilterValue);
        }
    }

    private getExtraState(mainRank: number | null, compRank: number | null, mainHasValues: boolean, compHasValues: boolean, hasComparison: boolean): rankExtraStateType {
        const mainState: rankValueStateType = this.getValueState(mainRank, mainHasValues);
        const compareState: rankValueStateType = this.getValueState(compRank, compHasValues);

        if (!hasComparison) {
            return 'none';
        }

        if (mainState === 'insideTopX') {
            if (compareState === 'insideTopX') {
                return 'changed';
            } else if (compareState === 'outsideTopX') {
                return 'raiseUp';
            } else {
                return 'new';
            }
        } else if (mainState === 'outsideTopX') {
            if (compareState === 'insideTopX') {
                return 'raiseDown';
            } else if (compareState === 'outsideTopX') {
                return 'none';
            } else {
                return 'new';
            }
        } else { // mainstate == 'unknown', comparestate == any
            return 'none';
        }
    }

    private getValueState(rank: number | null, hasValue: boolean): 'insideTopX' | 'outsideTopX' | 'unknown' {
        if (typeof rank === 'number') {
            return 'insideTopX';
        }
        if (hasValue) {
            return 'outsideTopX';
        }
        return 'unknown';
    }

    private hasValueMain(num: any): boolean {
        return typeof num === 'number' && num !== 0;
    }

    private hasValueCompare(num: any): boolean {
        return typeof num === 'number';
    }
}

type rankValueStateType = 'insideTopX' | 'outsideTopX' | 'unknown';
type rankExtraStateType = 'changed' | 'raiseUp' | 'raiseDown' | 'new' | 'none';

export type RankComparisonDisplayModel = {
    restricted?: boolean;
    rows?: RankComparisonDisplayRowModel[];
    measures?: ModelMeasure[],
    measureDisplayModes?: MeasureDisplayMode[],
    dateSpan?: DateSpan;
    hasComparison?: boolean;
    comparisonDateSpan?: DateSpan;
    measureColumnCount?: number[];
    isBenchmarkComparison?: boolean;
    isSortedByCompareRanks?: boolean;
    availableBenchmarks?: string[];
    availableStores?: string[];
    selectedBenchmark?: string;
    selectedStore?: string;
    currentKeyAttributeCode?: string;
    currentDisplayAttributeCodes?: string[];
};

export interface RankRowState {
    valueState: rankValueStateType;
    extraState: rankExtraStateType;
}

export interface RankComparisonDisplayRowModel {
    link?: MnbReportsEntityDrilldownDisplayDrilldownLinkModel;
    row: ReportRankComparisonDataRow;
    mainRankState: RankRowState;
    compareRankState: RankRowState;
}
