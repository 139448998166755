import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';

@Component({
    selector: 'mnb-form-unit-input',
    templateUrl: './mnb-form-unit-input.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MnbFormUnitInputComponent),
        multi: true
    }]
})

export class MnbFormUnitInputComponent implements OnChanges {

    @Input() disabled: boolean;
    @Input() set unitCode(value: string) {
        const isChanged = value !== this._unitCode;
        this._unitCode = value;
        switch (value) {
            case 'PERCENT':
                this.isLeftSymbol = false;
                break;
            case 'CURRENCY':
                this.isLeftSymbol = this.configService.langCode !== 'deu';
                break;
            default:
                this.isLeftSymbol = true;
                break;
        }

        // Trigger Value Update on UnitCode Change
        if (isChanged) {
            this.setDisplayValue(this._displayValue);
        }
    }
    @Input() forceRange: InputRange;
    @Input() forceIntegers: boolean;

    @ViewChild('input', { static: true }) input: ElementRef;

    public currencySymbol: string;
    public isLeftSymbol = true;

    private _unitCode: string;
    private _displayValue: string;
    private _value: number;
    private decimalPipe: DecimalPipe;
    private isTouched: boolean;

    private propagateChange = (_: any) => {};

    constructor(private configService: MnbConfigService) {
        this.decimalPipe = new DecimalPipe(configService.langCode === 'deu' ? 'de-DE' : 'en-US');
        this.currencySymbol = this.configService.currencySymbol;
    }

    get unitCode() {
        return this._unitCode;
    }

    set displayValue(value: string) {
        this.setDisplayValue(value);
    }

    get displayValue(): string {
        return this._displayValue;
    }

    get value() {
        return this._value;
    }

    @Input()
    set value(value: number) {
        this._value = value;
        this.adjustDisplayValue();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const update = this._value && changes.unitCode && (!changes.unitCode.firstChange && changes.unitCode.previousValue !== changes.unitCode.currentValue);
        if (update) {
            const {input} = this.calcInput(this.displayValue);
            this._value = this.calcNumber(input);
        }
    }

    private isValid(value: string) {
        if (isNullOrUndefined(value)) {
            return true;
        }
        const result = value.match(new RegExp('^-?[0-9]*(\\.|\\,)?[0-9]*'));

        return result && result.includes(value);
    }

    private setDisplayValue(value: string, silent?: boolean): void {
        if (!value) {
            this._displayValue = null;
            this._value = null;

            if (!silent) {
                this.propagateChange(this._value);
            }

            return;
        }

        if (this.forceIntegers && value && (value.slice(-1) === '.' || value.slice(-1) === ',')) {
            this.input.nativeElement.value = this.displayValue;
            return;
        }


        if (this.forceRange && !this.forceRange.isInRange(parseInt(value))) {
            this.input.nativeElement.value = this.displayValue;
            return;
        }

        const {input, isValid} = this.calcInput(value);
        const number = this.calcNumber(input);

        this._displayValue = isValid ? value : input;
        this._value = number;

        if (!silent) {
            this.propagateChange(this._value);
        }

        if (!isValid) {
            this.input.nativeElement.value = this.displayValue;
        }
    }

    writeValue(value: number): void {
        this._value = value;

        this.adjustDisplayValue();
        if (!this.isTouched) {
            this.isTouched = true;
            this.propagateChange(value);
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    adjustDisplayValue() {
        if (!isNullOrUndefined(this.value) && !isNaN(this.value)) {
            this._displayValue = this.calcDisplayValue(this.value);
        } else {
            this._displayValue = null;
        }
    }

    private calcInput(value: string): { input: string, isValid?: boolean } {
        const isEnglish = this.configService.langCode === 'eng';
        const replaceSymbol = isEnglish ? ',' : '.';
        value = value.split(replaceSymbol).join('');

        if (!isEnglish) {
            value = value.split(',').join('.');
        }

        const isValid = this.isValid(value);

        if (!isValid) {
            const firstDotOccuranceIndex = value.search(/\./) + 1;
            value = value.substring(0, firstDotOccuranceIndex) + value.slice(firstDotOccuranceIndex).replace(/\./g, '');
            const firstMinusOccuranceIndex = value.search(/-/) + 1;
            value = value.substring(0, firstMinusOccuranceIndex) + value.slice(firstMinusOccuranceIndex).replace(/-/g, '');
        }

        return { input: value, isValid };
    }

    private calcDisplayValue(value: number) {
        let display = value + '';
        if (!isNullOrUndefined(value) && this.unitCode) {
            if (this.unitCode === 'PERCENT') {
                value = parseFloat((value * 100).toPrecision(value.toString().length));
            }

            const valueSplit = value.toString().split('.');
            const enteredDecimalPlaces = valueSplit.length > 1 ? valueSplit[1].length : 0;
            display = this.decimalPipe.transform(value, '1.' + enteredDecimalPlaces + '-' + enteredDecimalPlaces);
        }

        return display;
    }

    private calcNumber(input: string) {
        const isValid = this.isValid(input);
        let number = isValid ? parseFloat(input) : null;

        if (isValid && this.unitCode) {
            switch (this.unitCode) {
                case 'QTY':
                    number = Math.round(number);
                    break;
                case 'PERCENT':
                    number = parseFloat((number * 0.01).toPrecision(number.toString().length));
                    break;
            }
        }

        return number;
    }
}

export class InputRange {
    constructor(public min: number, public max: number) { }

    public isInRange(value: number): boolean {
        return value >= this.min && value <= this.max;
    }
}
