import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MnbDashboardWidgetComponent } from './mnb-dashboard-widget.component';

@Component({
    selector: 'mnb-dashboard-widget-alert',
    templateUrl: './mnb-dashboard-widget-alert.component.html'
})
export class MnbDashboardWidgetAlertComponent implements OnDestroy, OnInit {

    @Input() color: string;

    constructor(
        private widget: MnbDashboardWidgetComponent
    ) { }

    ngOnInit(): void {
        this.widget.hasAlert = true;
        this.widget.alertColor = this.color;
    }

    ngOnDestroy(): void {

    }

}
