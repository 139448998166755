import { Injectable } from '@angular/core';
import { ApiService, Resources } from './api.service';
import { Observable } from 'rxjs';
import { PortalReport, PortalReportData } from '@minubo-portal/modules/api/models/api-report.model';
import { ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';

@Injectable()
export class ApiReportService {


    constructor(
        private apiService: ApiService
    ) {

    }

    public loadReports(): Observable<PortalReport[]> {
        return this.apiService.query(Resources.REPORT);
    }

    public loadReport(id: number): Observable<PortalReport> {
        return this.apiService.get(Resources.REPORT, {id});
    }

    public loadReportData(id: number, settings: ReportSettings): Observable<PortalReportData> {
        return this.apiService.post(Resources.REPORT_DATA, settings, { id });
    }

    public loadReportAdhocFeedUrl(id: number, settings: ReportSettings): Observable<{ url: string }> {
        return this.apiService.post(Resources.REPORT_ADHOC_FEED, settings, { id });
    }
}
