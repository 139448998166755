import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService, Resources } from './api.service';
import { EntityType } from '@shared-lib/modules/data/model/mnb-data-entity.model';
import { PortalEntity } from '@minubo-portal/modules/api/models/api-portal.model';

@Injectable()
export class ApiPortalService {


    constructor(
        private apiService: ApiService
    ) {

    }

    public loadEntity(type: EntityType, id: number): Observable<PortalEntity> {
        return this.apiService.get(Resources.PORTAL_ENTITY, {typeCode: type, id});
    }

}
