import {Injectable} from '@angular/core';
import { ReportData, ReportSettings } from '@shared-lib/modules/data/model/mnb-data-reports.model';
import { Observable } from 'rxjs';
import { ApiReportService } from '@minubo-portal/modules/api/services/api-report.service';
import { ReportDataService } from '@shared-lib/modules/reports/components/report-data.service';


@Injectable()
export class ReportsReportService {

}


@Injectable()
export class PortalReportDataService implements ReportDataService {

    constructor(private reportService: ApiReportService) {

    }

    getData(id: number, adhocSettings?: ReportSettings): Observable<ReportData> {
        return this.reportService.loadReportData(id, adhocSettings);
    }
}
