import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiSessionService } from '@minubo-portal/modules/api/services/api-session.service';
import { MnbConfigService } from '@shared-lib/modules/config/services/mnb-config.service';

@Injectable()
export class RoutingAuthedGuard implements CanActivate {

    constructor(
        public router: Router,
        private apiSessionService: ApiSessionService
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.apiSessionService.sessionState$
            .pipe(
                map(auth => {
                    if (!auth.authenticated) {
                        return this.router.parseUrl('login');
                    }
                    return true;
                })
            );
    }
}

@Injectable()
export class RoutingUnauthedGuard implements CanActivate {

    constructor(
        public router: Router,
        private apiSessionService: ApiSessionService
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return this.apiSessionService.sessionState$
            .pipe(
                map((res) => {
                    if (res.authenticated) {
                        return this.router.parseUrl('');
                    }
                    return true;
                }));
    }
}

@Injectable()
export class RoutingConfigGuard implements CanActivate {

    constructor(
        public router: Router,
        private configService: MnbConfigService
    ) {
    }

    canActivate(): Observable<boolean | UrlTree> {
        return from(new Promise<boolean>(resolve => {
            this.configService.onAvailable(() => {
                resolve(true);
            });
        }));
    }
}
