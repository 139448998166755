import { Input, Component, HostBinding } from '@angular/core';

@Component({
  selector: '[mnbExpanderContent]',
  template:
  `
  <ng-content *ngIf="expanded"></ng-content>
  `
})
export class MnbExpanderContentComponent {

  @Input('mnbExpanderContent') expanderCode: string;

  @HostBinding('attr.hidden') attrHidden: string;

  private _value: string;
  public expanded = false;

  @Input()
  get value() {
    return this._value;
  }

  set value(value) {
    this._value = value;
    this.expanded = this.expanderCode === this.value;
    this.attrHidden = this.expanded ? null : 'true';
  }

}
