import { Directive, Input, ElementRef, HostListener, Renderer2, OnDestroy, OnInit } from '@angular/core';

@Directive({
    selector: '[mnbTooltip]'
})
export class MnbTooltipDirective implements OnDestroy {

    @Input('mnbTooltip') tooltipContent: string;
    @Input() tooltipPlacement: string;
    @Input() showTooltip = true;
    @Input() textAlign: string;
    tooltipDelay = 500;
    tooltipOffset = 10;
    tooltip: HTMLElement;

    // This is a hack, used because LifeCyle hooks don't work for this directive inside the mnbExpander
    private removeHandler: () => void =  () => {
        if (this.tooltip) {
            this.tooltip.remove();
            this.tooltip = null;
        }
        document.removeEventListener('click', this.removeHandler);
    }

    constructor(private elementReference: ElementRef, private renderer: Renderer2) { }
    
    ngOnDestroy(): void {
        this.onMouseLeave();
    }

    @HostListener('mouseenter') onMouseEnter() {
        if (this.showTooltip) {
            this.create();
            this.setPosition();
            document.addEventListener('click', this.removeHandler);
        }
    }

    @HostListener('mouseleave') onMouseLeave() {
        if (this.tooltip) {
            this.tooltip.remove();
            this.tooltip = null;
        }
    }

    create() {
        this.tooltip = this.renderer.createElement('div');
        this.tooltip.innerHTML = this.tooltipContent; // tooltip content

        this.renderer.appendChild(document.body, this.tooltip);
        this.renderer.addClass(this.tooltip, 'mnb-tooltip');

        // delay
        this.renderer.setStyle(this.tooltip, '-webkit-transition', `opacity ${this.tooltipDelay}ms`);
        this.renderer.setStyle(this.tooltip, '-moz-transition', `opacity ${this.tooltipDelay}ms`);
        this.renderer.setStyle(this.tooltip, '-o-transition', `opacity ${this.tooltipDelay}ms`);
        this.renderer.setStyle(this.tooltip, 'transition', `opacity ${this.tooltipDelay}ms`);
        if (this.textAlign) {
            this.renderer.setStyle(this.tooltip, 'text-align', this.textAlign);
        }

    }

    setPosition() {
        const hostPos = this.elementReference.nativeElement.getBoundingClientRect();
        const tooltipPosition = this.tooltip.getBoundingClientRect();
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        let top: number, left: number;

        if (this.tooltipPlacement === 'top') {
            top = hostPos.top - tooltipPosition.height - this.tooltipOffset;
            left = hostPos.left + (hostPos.width - tooltipPosition.width) / 2;
        }

        if (this.tooltipPlacement === 'bottom') {
            top = hostPos.bottom + this.tooltipOffset;
            left = hostPos.left + (hostPos.width - tooltipPosition.width) / 2;
        }

        if (this.tooltipPlacement === 'left') {
            top = hostPos.top + (hostPos.height - tooltipPosition.height) / 2;
            left = hostPos.left - tooltipPosition.width - this.tooltipOffset;
        }

        if (this.tooltipPlacement === 'right') {
            top = hostPos.top + (hostPos.height - tooltipPosition.height) / 2;
            left = hostPos.right + this.tooltipOffset;
        }

        this.renderer.setStyle(this.tooltip, 'top', `${top + scrollPosition}px`);
        this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
        this.renderer.addClass(this.tooltip, `mnb-tooltip-${this.tooltipPlacement}`);
    }

}
